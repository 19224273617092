import moment from 'moment';

moment.updateLocale('id', {
  months: [
    'Januari',
    'Februari',
    'Maret',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Agustus',
    'September',
    'Oktober',
    'November',
    'Desember',
  ],
  monthsShort: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'Mei',
    'Jun',
    'Jul',
    'Agu',
    'Sep',
    'Okt',
    'Nov',
    'Des',
  ],
  weekdays: ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'],
  weekdaysShort: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
});

export const dateFormat = (date, format) => {
  return moment(date).format(format);
};

export const dateNow = (format = 'YYYY-MM-DD') => {
  return moment().format(format);
};
export const monthNow = (format = 'MM') => {
  return moment().add(-1, 'M').format(format);
};

export const lastYear = (format = 'YYYY') => {
  return moment().add(-1, 'M').format(format);
};
export const yearNow = (format = 'YYYY') => {
  return moment().format(format);
};

export const addDate = (date, value, type, format) => {
  return moment(date).add(value, type).format(format);
};

export const addDateUnformat = (date, value, type) => {
  return moment(date).add(value, type);
};

export const countDate = (date1, date2) => {
  date1 = moment(date1);
  date2 = moment(date2);

  return moment.duration(date2.diff(date1));
};

export const formatDate = (inputDate) => {
  const months = [
    'Januari',
    'Februari',
    'Maret',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Agustus',
    'September',
    'Oktober',
    'November',
    'Desember',
  ];

  const date = new Date(inputDate);
  const day = date.getDate().toString().padStart(2, '0');
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
};

export const formatDateTime = (inputDate) => {

  const months = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
  ];

  const date = new Date(inputDate);
  const day = date.getDate().toString().padStart(2, '0');
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  const hours = (date.getHours() === 0 ? "-" : date.getHours());
  const minute = (date.getHours() === 0 ? "-" :date.getMinutes());

  return `${day}-${month}-${year}   ${hours} : ${minute}`;
};

export const DateFormat = (inputDate)=>{
  const months = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
  ];

  const date = new Date(inputDate);
  const day = date.getDate().toString().padStart(2, '0');
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;

}
