import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ShortDesc from './ShortDesc';
import General from './General';
import Invoices from './Invoices';
import Subscriptions from './Subscriptions';
import DataUsage from './DataUsage';
import Maps from './Maps';
import { useState } from 'react';

const ProfileOverview = ({ request }) => {
  const params = useParams();
  const [customer, setCustomer] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    request.get(`/customers/${params.id}`).then((response) => {
      setCustomer(response);
      setLoading(false);
    });
  }, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className='flex w-full flex-col gap-5'>
      <div className='w-ful mt-3 flex h-fit flex-col gap-5 lg:grid lg:grid-cols-12'>
        <div className='col-span-4 lg:!mb-0'>
          <ShortDesc customer={customer} loading={loading} />
        </div>

        <div className='col-span-3 lg:!mb-0'>
          <DataUsage customer={customer} />
        </div>

        <div className='z-0 col-span-5 lg:!mb-0'>
          <Maps customer={customer} loading={loading} />
        </div>
      </div>
      {/* all project & ... */}

      <div className='grid h-full grid-cols-1 gap-5 lg:!grid-cols-12'>
        <div className='3xl:col-span-4 col-span-5 lg:col-span-6 lg:mb-0'>
          <Subscriptions customer_id={params.id} request={request} />
        </div>
        <div className='3xl:col-span-5 col-span-5 lg:col-span-6 lg:mb-0'>
          <General customer={customer} loading={loading} />
        </div>

        <div className='3xl:!col-span-3 col-span-5 lg:col-span-12 lg:mb-0'>
          <Invoices request={request} customer_id={params.id} />
        </div>
      </div>
    </div>
  );
};

export default ProfileOverview;
