import React, { useContext } from 'react';
import Navbar from 'components/navbar';
import Sidebar from 'components/sidebar';
import Footer from 'components/footer/Footer';
import { NavContext } from 'contexts/NavContext';
import { Link } from 'react-router-dom';

export default function Admin({ children, ...rest }) {
  const nav = useContext(NavContext);

  React.useEffect(() => {
    window.addEventListener('resize', () =>
      window.innerWidth < 1200 ? nav.setOpen(false) : nav.setOpen(true)
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderChildren = () => {
    return React.Children.map(children, (child) => {
      return React.cloneElement(child, {
        ...rest,
      });
    });
  };

  return (
    <div className='flex h-full w-full'>
      <Sidebar open={nav.open} onClose={() => nav.setOpen(false)} {...rest} />
      {/* Navbar & Main Content */}
      <div className='h-full w-full bg-gray-200'>
        {/* Main Content */}
        <main
          className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-64`}
        >
          {/* Routes */}
          <div className='h-full'>
            <Navbar {...rest} />
            <div className='breadcrumbs mx-2 text-sm'>
              <ul>
                <li>
                  <Link to='/dashboard'>Home</Link>
                </li>
                <li>
                  <Link href='#'>{nav.menu}</Link>
                </li>
                {/* <li>Add Document</li> */}
              </ul>
            </div>
            <div className='mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2'>
              {renderChildren()}
            </div>
            <div className='p-3'>
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
