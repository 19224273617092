import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const MapContainer = (props) => {
    const apiKey = process.env.REACT_APP_API_KEY;

    return (
        <LoadScript googleMapsApiKey={apiKey}>
            <GoogleMap
                mapContainerStyle={{ width: '100%', height: '200px' }}
                zoom={10}
                center={{
                    lat: -7.86432,
                    lng: 110.1594624,
                }}
                onClick={(e)=>props.handleMapClick(e)}
            >
                {props.markerPosition && <Marker position={props.markerPosition} />}
            </GoogleMap>
        </LoadScript>
    );
};

export default MapContainer;

