import { useEffect, useMemo, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { ToastContainer, toast } from 'react-toastify';
import { routes } from 'routes';
import { useAuth } from 'hooks/useAuth';
import Admin from 'layouts/admin';
import Auth from 'layouts/auth';
import HttpClient from 'lib/HttpClient';
import firebaseConfig from 'config/firebase';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const { user, token, login, logout } = useAuth();
  const request = useMemo(() => new HttpClient(token, logout), [token]); // eslint-disable-line react-hooks/exhaustive-deps
  const app = useMemo(() => initializeApp(firebaseConfig.common), []);
  const messaging = useMemo(() => getMessaging(app), [app]);
  const analytics = useMemo(() => getAnalytics(app), [app]);
  const [notification, setNotification] = useState({ title: '', body: '' });

  const requestPermission = () => {
    console.log('Requesting User Permission......');
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Notification User Permission Granted.');
        return getToken(messaging, {
          vapidKey: firebaseConfig.vapidKey,
        })
          .then((currentToken) => {
            if (currentToken) {
              console.log('Client Token: ', currentToken);
              request.patch('/users/device-token', { token: currentToken });
            } else {
              console.log('Failed to generate the app registration token.');
            }
          })
          .catch((err) => {
            console.log(
              'An error occurred when requesting to receive the token.',
              err
            );
          });
      } else {
        console.log('User Permission Denied.');
      }
    });
  };

  const onMessageListener = () =>
    new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
    });

  useEffect(() => {
    requestPermission();
    const unsubscribe = onMessageListener().then((payload) => {
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
      });
      toast(`${payload?.notification?.title}: ${payload?.notification?.body}`);
    });
    return () => {
      unsubscribe.catch((err) => console.log('failed: ', err));
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Routes>
        {routes.map((route, index) => {
          if (route.protected) {
            if (user) {
              return (
                <>
                  <Route
                    key={index}
                    path={route.path + '/*'}
                    element={
                      <Admin
                        request={request}
                        user={user}
                        token={token}
                        logout={logout}
                        notification={notification}
                        analytics={analytics}
                      >
                        {route.element}
                      </Admin>
                    }
                  />
                  {route.submenu !== undefined && route.submenu.map((sub, index) => {
                    return (
                      <Route
                        key={index}
                        path={sub.path + '/*'}
                        element={
                          <Admin
                            request={request}
                            user={user}
                            token={token}
                            logout={logout}
                            notification={notification}
                            analytics={analytics}
                          >
                            {sub.element}
                          </Admin>
                        }
                      />
                    )
                  })}
                </>
              );
            }
            return (
              <Route
                key={index}
                path={route.path}
                element={<Navigate to='/login' replace />}
              />
            );
          }

          if (user) {
            return (
              <Route
                key={index}
                path={route.path}
                element={<Navigate to='/dashboard' replace />}
              />
            );
          }

          return (
            <Route
              key={index}
              path={route.path}
              element={
                <Auth request={request} login={login} analytics={analytics}>
                  {route.element}
                </Auth>
              }
            />
          );
        })}
        <Route path='*' element={<Navigate to='/dashboard' />} />
      </Routes>
      <ToastContainer />
    </>
  );
};

export default App;
