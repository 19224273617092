import Card from 'components/card';
import React from 'react';
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import PinMap from 'assets/svg/pin-map.svg';
import L from 'leaflet';
import Skeleton from 'react-loading-skeleton';

const Maps = ({ submission, loading }) => {
  const icon = L.icon({ iconUrl: PinMap, iconSize: 50 });

  return Object.keys(submission).length > 0 ? (
    <Card extra={'w-full h-full p-3 rounded-[20px]'}>
      <div className='col-span-12 md:col-span-7 lg:col-span-8'>
        <MapContainer
          center={submission.submission_coordinates}
          zoom={10}
          style={{ height: '500px', zIndex: '20', borderRadius: '12px' }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          />
          <Marker position={submission.submission_coordinates} icon={icon}>
            <Popup>Lokasi Pemasangan</Popup>
          </Marker>
        </MapContainer>
      </div>
      <div className='col-span-12 md:col-span-5 lg:col-span-4'>
        <div className='flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500'>
          <p className='text-sm text-gray-600'>Alamat</p>
          {!loading ? (
            <p className='text-base font-medium text-navy-700'>
              {submission.submission_address || '-'}
            </p>
          ) : (
            <Skeleton height={29} width={100} />
          )}
        </div>
      </div>
    </Card>
  ) : (
    'Loading'
  );
};

export default Maps;
