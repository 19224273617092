import {Route, Routes} from "react-router-dom";
import List from './components/List';
import Edit from './components/Edit';
import Withdraw from "./components/Withdraw";

const ResellersWithdraw =(props)=> {
    return (
        <Routes>
            <Route path='/' element={<List {...props} />} />
            <Route path='/:id/edit' element={<Edit {...props} />} />
            <Route path='/penarikan' element={<Withdraw {...props} />} />
        </Routes>
    );
};
export default  ResellersWithdraw;