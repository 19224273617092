import React, {useMemo, useState, useCallback} from 'react';
import { toast } from 'react-toastify';
import { createColumnHelper } from '@tanstack/react-table';
import Datepicker from 'react-tailwindcss-datepicker';
import Table from 'components/table/Table';
import Card from 'components/card';
import { dateFormat } from 'utils/date';
import { convertToRupiah } from 'utils/general';
import { Link } from 'react-router-dom';
import { FiEye } from 'react-icons/fi';
import moment from 'moment';

const List = ({ request }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalRow, setTotalRow] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [filterMethod, setFilterMethod] = useState('');
  const [filterGateway, setFilterGateway] = useState('');
  const [filterBank, setFilterBank] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);
  const today = moment(new Date()).format('YYYY-MM-DD');

  const [value, setValue] = useState({
    startDate: today,
    endDate: today,
  });

  const handleValueChange = (newValue) => {
    setValue(newValue);
  };

  const handleFilterByMethod = (event) => {
    setFilterMethod(event.target.value);
  };

  const handleFilterByGateway = (event) => {
    setFilterGateway(event.target.value);
  };

  const handleFilterByBank = (event) => {
    setFilterBank(event.target.value);
  };

  const handleFilterByStatus = (event) => {
    setFilterStatus(event.target.value);
  };

  const fetchData = useCallback(
    async (pageSize, pageIndex, search) => {
      setIsLoading(true);
      const params = {
        per_page: pageSize,
        page: pageIndex,
        keyword: search,
        transaction_status: filterStatus,
        transaction_method: filterMethod,
        transaction_gateway: filterGateway,
        bank_id: filterBank,
        start_capture_date: value?.startDate,
        end_capture_date: value?.endDate,
      };

      try {
        const response = await request.get('/transactions', { params });
        const startIndex = response.data.from;
        const updatedData = response.data.data.map((item, index) => ({
          ...item,
          listingNumber: startIndex + index,
        }));
        setData(updatedData);
        setPageCount(response.data.last_page);
        setTotalRow(response.data.total);
        setTotalAmount(response.sum)
      } catch (err) {
        if (err.response?.data?.errors) {
          Object.keys(err.response.data.errors).map((field) =>
            err.response.data.errors[field].map((message) =>
              toast.error(message)
            )
          );
        } else if (err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      }

      setIsLoading(false);
      setRefresh(false);
    },
    [refresh, value, filterMethod, filterGateway, filterBank, filterStatus] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const columnHelper = createColumnHelper();

  const columns = useMemo(
    () => [
      columnHelper.accessor('listingNumber', {
        header: () => '#',
        cell: (info) => (
          <div className='text-sm'>{info.row.original.listingNumber}</div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('customer_name', {
        header: () => 'Pelanggan',
        cell: (info) => (
          <div className='min-w-max max-w-2xl'>
            <div className='truncate text-sm'>{info.getValue()}</div>
            <div className='text-xs'>
              {
                info.row.original.c_number_value
              }
            </div>
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('transaction_type', {
        header: () => 'Tipe',
        cell: (info) => (
          <div className='badge badge-info badge-outline text-sm'>
            {info.getValue()}
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('transaction_amount', {
        header: () => 'Nominal',
        cell: (info) => (
          <div className='max-w-2xl truncate text-sm'>
            {convertToRupiah(info.getValue(), true)}
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('transaction_method', {
        header: () => 'Metode Pengaduan',
        cell: (info) => (
          <div className='min-w-max max-w-2xl text-sm uppercase'>
            {info.getValue() ? (
              <>
                {info.getValue()}{' '}
                {(info.row.original.transaction_gateway === 'otomax' ||
                  info.row.original.transaction_gateway === 'manual') &&
                info.row.original.transaction_method === 'bank_transfer' &&
                info.row.original.transaction_meta.bank_name ? (
                  <span className='badge badge-neutral font-bold'>
                    {info.row.original.transaction_meta.bank_name}
                  </span>
                ) : (
                  ''
                )}
              </>
            ) : (
              '-'
            )}
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('transaction_gateway', {
        header: () => 'Gateway',
        cell: (info) => (
          <div className='max-w-2xl text-sm uppercase'>{info.getValue()}</div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('transaction_status', {
        header: () => 'Status',
        cell: (info) => (
          <div className='max-w-2xl text-sm'>
            {info.getValue() === 'captured' ? (
              <span className='badge badge-success'>Sukses</span>
            ) : info.getValue() === 'pending' ? (
              <span className='badge badge-primary'>{info.getValue()}</span>
            ) : (
              <span className='badge badge-error'>{info.getValue()}</span>
            )}
          </div>
        ),
        footer: (info) => info.column.id,
      }),
        columnHelper.accessor('invoice_periods', {
            header: () => 'Periode',
            cell: (info) => (
                <div className='min-w-max max-w-2xl text-sm'>{info.getValue()}</div>
            ),
            footer: (info) => info.column.id,
        }),
      columnHelper.accessor('transaction_capture_date', {
        header: () => 'Tanggal Sukses',
        cell: (info) => (
          <div className='max-w-2xl truncate text-sm'>
            {!info.getValue()
              ? '-'
              : dateFormat(info.getValue(), 'DD MMM YYYY H.mm')}
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('transaction_create_date', {
        header: () => 'Tanggal Buat',
        cell: (info) => (
          <div className='max-w-2xl truncate text-sm'>
            {dateFormat(info.getValue(), 'DD MMM YYYY H.mm')}
          </div>
        ),
        footer: (info) => info.column.id,
      }),
        columnHelper.accessor('transaction_ref', {
            header: () => <div className='min-w-max max-w-2xl'>Ref ID</div>,
            cell: (info) => (
                <div className='max-w-2xl truncate text-sm'>
                    {info.getValue()}
                </div>
            ),
            footer: (info) => info.column.id,
        }),
      columnHelper.accessor('transaction_id', {
        header: () => <div className='min-w-max max-w-2xl'>ID Transaksi</div>,
        cell: (info) => (
          <div className='max-w-2xl truncate text-sm'>
            {info.getValue().slice(-5)}
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('transaction_id', {
        header: () => 'Aksi',
        cell: (info) => (
          <div
            className='max-w-2xl truncate text-xs'
            style={{ cursor: 'pointer' }}
          >
            <Link to={`/transactions/${info.getValue()}`}>
              <FiEye size={20} color='#FBBD29' />
            </Link>
          </div>
        ),
        footer: (info) => info.column.id,
      }),
    ],
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <>
      <Card extra={'w-full sm:overflow-auto p-4'}>
        <div className='flex'>
          <h2 className='mb-1 text-xl font-bold text-secondary flex-1'>
            Daftar Transaksi
          </h2>
          <div>Total Nominal: {convertToRupiah(totalAmount, true)}</div>
        </div>
        <div className='overflow-x-scroll xl:overflow-x-hidden'>
          <Table
            columns={columns}
            data={data}
            isLoading={isLoading}
            fetchData={fetchData}
            setRefresh={setRefresh}
            pageCount={pageCount}
            totalRow={totalRow}
            filters={() => (
              <>
                <select
                  onChange={handleFilterByMethod}
                  className='select select-sm max-w-xs'
                  value={filterMethod}
                >
                  <option value='' selected>
                    Semua Metode
                  </option>
                  <option value='bank_transfer'>BANK TRANSFER</option>
                  <option value='cash'>CASH</option>
                  <option value='h2h'>H2H</option>
                  <option value='manual'>MANUAL</option>
                </select>
                <select
                  onChange={handleFilterByGateway}
                  className='select select-sm max-w-xs'
                  value={filterGateway}
                >
                  <option value='' selected>
                    Semua Gateway
                  </option>
                  <option value='otomax'>OTOMAX</option>
                  <option value='partner'>PARTNER</option>
                  <option value='manual'>MANUAL</option>
                  <option value='durianpay'>DURIANPAY</option>
                </select>
                <select
                  onChange={handleFilterByBank}
                  className='select select-sm max-w-xs'
                  value={filterBank}
                >
                  <option value='' selected>
                    Semua Bank
                  </option>
                  <option value='1'>MANDIRI</option>
                  <option value='2'>BRI</option>
                  <option value='3'>BCA</option>
                  <option value='4'>BNI</option>
                  <option value='5'>AKUN EB</option>
                </select>
                <select
                  onChange={handleFilterByStatus}
                  className='select select-sm max-w-xs'
                  value={filterStatus}
                >
                  <option value='' selected>
                    Semua Status
                  </option>
                  <option value='pending'>Pending</option>
                  <option value='failed'>Failed</option>
                  <option value='captured'>Sukses</option>
                </select>
                <Datepicker
                  maxDate={new Date()}
                  toggleClassName='relative left-2 right-0 h-full px-1 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed'
                  containerClassName='input input-sm relative flex bg-white justify-self-start'
                  inputClassName='outline-none w-full md:w-48 transition-all duration-300 font-light text-sm placeholder-gray-400'
                  value={value}
                  onChange={handleValueChange}
                  showShortcuts={true}
                  placeholder='Pilih Tanggal Sukses'
                />
              </>
            )}
          />
        </div>
      </Card>
    </>
  );
};

export default List;
