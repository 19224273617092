/* eslint-disable */

import { useContext } from 'react';
import { HiX } from 'react-icons/hi';
import moment from 'moment';
import preval from 'preval.macro';
import Links from './components/Links';

import { getMenu } from 'routes.js';
import { NavContext } from 'contexts/NavContext';
import packageJson from '../../../package.json';

const buildTimestamp = preval`module.exports = new Date().getTime();`;

const getDateString = () => {
  const lastUpdateMoment = moment.unix(buildTimestamp / 1000);
  const formattedDate = lastUpdateMoment.format('DD.MM.YYYY HH:mm:ss');

  return formattedDate;
};

const Sidebar = ({ user }) => {
  const nav = useContext(NavContext);
const imageLogo = process.env.REACT_APP_STORAGE_SERVICE_URL+'/images/brand_logo/'+user.brand.brand_logo

  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex h-full flex-col bg-primary pb-10 shadow-2xl shadow-white/5 transition-all md:!z-50 lg:!z-50 xl:!z-0 ${
        nav.open ? 'translate-x-0' : '-translate-x-96'
      }`}
    >
      <span
        className='absolute top-4 right-4 block cursor-pointer xl:hidden'
        onClick={() => nav.setOpen(false)}
      >
        <HiX />
      </span>

      <div className={`flex items-center justify-center py-4`}>
        <img
          className='inline-block'
          height={100}
          width={100}
          src={imageLogo}
          alt='Innet Logo'
        />
      </div>
      <div className='mb-5 h-px bg-accent' />
      {/* Nav item */}

      <ul className='mb-auto overflow-y-auto pt-1 scrollbar-thin scrollbar-track-[#F2A647] scrollbar-thumb-[#E6462E] scrollbar-track-rounded-full scrollbar-thumb-rounded-full'>
        <Links routes={getMenu(user)} />
      </ul>

      <div className='flex flex-col items-center text-xs'>
        <span>
          Build {' v'}
          {packageJson.version}
          {'.'}
          {buildTimestamp}
        </span>
        <span>
          {'('}
          {getDateString()}
          {')'}
        </span>
      </div>

      {/* Nav item end */}
    </div>
  );
};

export default Sidebar;
