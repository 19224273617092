import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import Card from 'components/card';
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';


const Add = ({ request }) => {
    const navigate = useNavigate();
    const { register, handleSubmit } = useForm();
    const [categories, setCategories] = useState([]);

    useEffect(()=>{
        request.get('/tiket-categories').then((response)=>{
            setCategories(response);
        });
    },[request]);

    const submitHandler = async (data) => {
        try {
            await request.post('/tikets', data);
            toast.success('Berhasil menyimpan data');
            return navigate('/tiket-list');
        } catch (err) {
            if (err.response?.data?.errors) {
                Object.keys(err.response.data.errors).map((field) =>
                    err.response.data.errors[field].map((message) => toast.error(message))
                );
            } else if (err.response?.data?.message) {
                toast.error(err.response.data.message);
            } else {
                toast.error(err.message);
            }
        }
    };

    const errorHandler = (errors) => {
        Object.keys(errors).map((key) => {
            if (Array.isArray(errors[key])) {
                return errors[key].map((err) => {
                    return toast.error(err.message);
                });
            }

            return toast.error(errors[key].message);
        });
    };

    const handleCategoryChange = (e) => {
        const selectedCategoryId = e.target.value;

        const selectedCategory = categories.find(
            category => category.t_category_id === selectedCategoryId
        );

        if (selectedCategory) {
            handleSubmit('brand_id', selectedCategory.brand_id);
        } else {
            handleSubmit('brand_id', '');
        }
    };

    return (
        <>
            <Card extra={'w-full sm:overflow-auto p-4'}>
                <h2 className='text-xl font-bold text-navy-700'>Tambah Daftar Tiket</h2>
                <form
                    onSubmit={handleSubmit(submitHandler, errorHandler)}
                    className='mt-4 flex flex-col gap-y-1 overflow-x-scroll xl:overflow-x-hidden'
                >
                    <div className='grid grid-cols-2'>
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Nama Tiket</span>
                            </label>
                            <input
                                {...register('tiket_name', {
                                    required: 'Nama Kategori harus diisi',
                                })}
                                type='text'
                                placeholder='Ketikan Nama Tiket'
                                className='input input-bordered w-full max-w-sm'
                            />
                        </div>
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Kategori</span>
                            </label>
                            <select
                                {...register('t_category_id', {
                                    required: 'Tiket Kategori',
                                    onChange: handleCategoryChange,
                                })}
                                className='select select-bordered'
                            >
                                <option value="">Pilih Kategori</option>
                                {categories.map(item => (
                                    <option key={item.t_category_id} value={item.t_category_id}>
                                        {item.t_category_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Status</span>
                            </label>
                            <select
                                {...register('tiket_status', {
                                    required: 'Status harus dipilih',
                                })}
                                className='select select-bordered'
                            >
                                <option value='' disabled selected>
                                    Pilih Status
                                </option>
                                <option value='active'>Aktif</option>
                                <option value='non-active'>Non-Aktif</option>
                            </select>
                        </div>
                    </div>
                    <div className='flex gap-x-2'>
                        <button type='submit' className='btn btn-primary'>
                            Simpan
                        </button>
                        <Link to='/tiket-list' className='btn'>
                            Batal
                        </Link>
                    </div>
                </form>
            </Card>
        </>
    );
};

export default Add;
