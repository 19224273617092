import React, {useCallback, useMemo, useState} from 'react';
import Card from 'components/card';
import { toast } from 'react-toastify';
import { createColumnHelper } from '@tanstack/react-table';
import Table from '../../../components/table/Table';
import { FiEdit } from 'react-icons/fi';
import {convertToRupiah} from "../../../utils/general";
import {Link} from "react-router-dom";

const List = ({ request }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [totalRow, setTotalRow] = useState(0);
    const [refresh, setRefresh] = useState(false);

    const fetchData = useCallback(
        async (pageSize, pageIndex, search) => {
            setIsLoading(true);

            const params = {
                per_page: pageSize,
                page: pageIndex,
                keyword: search,
            };

            try {
                const response = await request.get('/installation-price', { params });
                const startIndex = response.from;

                const updatedData = response.data.map((item, index) => ({
                    ...item,
                    listingNumber: startIndex + index,
                }));

                setData(updatedData);
                setPageCount(response.last_page);
                setTotalRow(response.total);
            } catch (err) {
                if (err.response?.data?.errors) {
                    Object.keys(err.response.data.errors).map((field) =>
                        err.response.data.errors[field].map((message) =>
                            toast.error(message)
                        )
                    );
                } else if (err.response?.data?.message) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error(err.message);
                }
            }

            setIsLoading(false);
            setRefresh(false);
        },
        [refresh ] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const columnHelper = createColumnHelper();

    const columns = useMemo(
        () => [
            columnHelper.accessor('listingNumber', {
                header: () => '#',
                cell: (info) => (
                    <div className='text-sm'>{info.row.original.listingNumber}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('zone.zone_hamlet', {
                header: () => 'daerah',
                cell: (info) => (
                    <div className='max-w-xs truncate text-sm'>{info.getValue() === undefined ? '-' : info.getValue()}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('package_prices.p_price_total', {
                header: () => 'harga paket',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>{convertToRupiah(info.getValue(), true)}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('i_price_value', {
                header: () => 'Harga Installasi',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>{convertToRupiah(info.getValue(), true)}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('i_price_id', {
                header: () => 'Aksi',
                cell: (info) => (
                    <div
                        className='max-w-2xl truncate text-xs'
                        style={{ cursor: 'pointer' }}
                    >
                        <a href={`/installation-price/${info.getValue()}/edit`}>
                            <FiEdit size={20} color='#FBBD29' />
                        </a>
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
        ],
        [] // eslint-disable-line react-hooks/exhaustive-deps
    );

    return (
        <div>
            <Card extra={'w-full sm:overflow-auto p-4'}>
                <h2 className='mb-1 text-xl font-bold text-secondary'>
                    Harga Instalasi
                </h2>
                <div className='overflow-x-scroll xl:overflow-x-hidden'>
                    <Table
                        columns={columns}
                        data={data}
                        isLoading={isLoading}
                        fetchData={fetchData}
                        setRefresh={setRefresh}
                        pageCount={pageCount}
                        totalRow={totalRow}
                        actions={() => (
                            <Link className='btn btn-secondary btn-sm' to={'/installation-price/Add'}>tambah</Link>
                        )}
                    />
                </div>
            </Card>
        </div>
    );
};
export default List;
