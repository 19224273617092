import React from 'react';
import { Navigate } from 'react-router-dom';

import {
  MdHome,
  MdPerson,
  MdPayment,
  MdOutlineAttachMoney,
  MdFmdBad, MdEngineering
} from 'react-icons/md';
import { ImTree } from 'react-icons/im';
import {
  FaBell,
  FaTruck,
  FaUsers,
  FaBloggerB,
  FaMoneyBillAlt,
  FaUserFriends,
  FaMapMarkerAlt,
  FaMapMarkedAlt,
  FaRegNewspaper,
  FaMailBulk,
  FaSignOutAlt,
} from 'react-icons/fa';

import {IoIosConstruct, IoIosPricetags, IoMdSettings} from 'react-icons/io';

// Admin Imports
import Dashboard from 'pages/dashboard';
import Login from 'pages/login';
import Packages from 'pages/packages';
import PackagePrices from 'pages/package-prices';
import Subscriptions from 'pages/subscriptions';
import Installations from 'pages/installations';
import PackageCategories from 'pages/package-categories';
import Blog from 'pages/blog';
import UserLevels from 'pages/user-levels';
import Users from 'pages/users';
import Invoices from 'pages/invoices';
import Customers from 'pages/customers';
import Transactions from 'pages/transactions';
import Submissions from 'pages/submissions';
import Resellers from 'pages/reseller';
import ResellersWithdraw from 'pages/reseller-withdraw';
import Settings from 'pages/setting';
import Zone from 'pages/zone';
import RequestZone from 'pages/request-zone';
import Coverages from 'pages/coverages';
import Report from 'pages/report';
import Inbox from 'pages/inbox'
import ResellerMutation from "./pages/reseller-mutation";
import InstallationsPrice from "./pages/installation-price";
import PackageServices from "./pages/package-services";
import ReportSubscriptions from 'pages/report-subscription';
import SubmissionUpdates from "./pages/submission-updates";
import TiketSubsmission from "./pages/tiket-subsmissions";
import TiketCategory from "./pages/tiket-category";
import TiketList from "./pages/tiket-list";
import Teknisi from "./pages/teknisi";
import Loket from "./pages/loket";
import {AiFillShop} from "react-icons/ai";

const routes = [
  { path: '/', element: <Navigate to='/dashboard' /> },
  { path: '/login/*', element: <Login /> },
  {
    path: '/dashboard',
    protected: true, // Add this flag for the protected route
    element: <Dashboard />,
    name: 'Dashboard',
    icon: <MdHome className='h-6 w-6' />,
    role: ['Admin'],
  },

  {
    protected: true,
    name: 'Marketing',
    icon: <FaTruck className='h-6 w-6'/>,
    submenu: [
      {
        path: '/marketing',
        protected: true, // Add this flag for the protected route
        element: <Resellers />,
        name: 'Marketing Daftar',
        icon: <FaUserFriends className='h-6 w-6' />,
        role: ['Admin'],
      },
      {
        path: '/withdraw',
        protected: true, // Add this flag for the protected route
        element: <ResellersWithdraw />,
        name: 'Penarikan',
        icon: <FaSignOutAlt className='h-6 w-6' />,
        role: ['Admin'],
      },
      {
        path: '/mutation',
        protected: true, // Add this flag for the protected route
        element: <ResellerMutation />,
        name: 'Mutasi',
        icon: <FaSignOutAlt className='h-6 w-6' />,
        role: ['Admin'],
      },
    ]
  },

  {
    protected: true, // Add this flag for the protected route
    name: 'Pelanggan',
    icon: <FaUsers className='h-6 w-6' />,
    submenu: [
      {
        path: '/customers',
        protected: true, // Add this flag for the protected route
        element: <Customers />,
        name: 'List Pelanggan',
        icon: <FaUsers className='h-6 w-6' />,
        role: ['Admin'],
      },
      {
        path: '/subscriptions',
        protected: true, // Add this flag for the protected route
        element: <Subscriptions />,
        name: 'List Langganan',
        icon: <FaBell className='h-6 w-6' />,
      },
    ]
  },
  {
    path: '/submissions',
    protected: true, // Add this flag for the protected route
    element: <Submissions />,
    name: 'Pengajuan',
    icon: <MdPerson className='h-6 w-6' />,
    role: ['Admin'],
  },
  {
    path: '/installations',
    protected: true, // Add this flag for the protected route
    element: <Installations />,
    name: 'Instalasi',
    icon: <IoIosConstruct className='h-6 w-6' />,
  },
  {
    protected: true, // Add this flag for the protected route
    name: 'Tikets',
    icon: <MdFmdBad className='h-6 w-6' />,
    submenu: [
      {
        path: '/tiket',
        protected: true, // Add this flag for the protected route
        element: <TiketSubsmission />,
        name:'Tiket Pengaduan',
      },
      {
        path: '/tiket-category',
        protected: true, // Add this flag for the protected route
        element: <TiketCategory />,
        name:'Kategori Tiket',
      },
      {
        path: '/tiket-list',
        protected: true, // Add this flag for the protected route
        element: <TiketList />,
        name:'Daftar tiket',
      },
    ]
  },
  {
    path: '/installation-price',
    protected: true, // Add this flag for the protected route
    element: <InstallationsPrice />,
    name: 'Harga Instalasi',
    icon: <IoIosPricetags className='h-6 w-6' />,
  },
  {
    path: '/invoices',
    protected: true, // Add this flag for the protected route
    element: <Invoices />,
    name: 'Invoice',
    icon: <FaMoneyBillAlt className='h-6 w-6' />,
  },
  {
    path: '/inboxs',
    protected: true, // Add this flag for the protected route
    element: <Inbox />,
    name: 'Inboxes',
    icon: <FaMailBulk className='h-6 w-6' />,
  },
  {
    path: '/transactions',
    protected: true, // Add this flag for the protected route
    element: <Transactions />,
    name: 'Transaksi',
    icon: <MdPayment className='h-6 w-6' />,
    role: ['Admin'],
  },
  {
    protected: true,
    name: 'Paket',
    icon: <FaTruck className='h-6 w-6' />,
    submenu: [
      {
        path: '/packages',
        protected: true, // Add this flag for the protected route
        element: <Packages />,
        name: 'Daftar Paket',
        icon: <FaTruck className='h-6 w-6' />,
      },
      {
        path: '/package-prices',
        protected: true, // Add this flag for the protected route
        element: <PackagePrices />,
        name: 'Harga Paket',
        icon: <MdOutlineAttachMoney className='h-6 w-6' />,
      },
      {
        path: '/package-categories',
        protected: true, // Add this flag for the protected route
        element: <PackageCategories />,
        name: 'Kategori Paket',
        icon: <ImTree className='h-6 w-6' />,
        secondary: true,
      },
      {
        path: '/package-services',
        protected: true, // Add this flag for the protected route
        element: <PackageServices />,
        name: 'Service',
        icon: <ImTree className='h-6 w-6' />,
        secondary: true,
      },
      {
        path: '/submission-update',
        protected: true, // Add this flag for the protected route
        name: 'Upgrade Paket',
        element: <SubmissionUpdates />,
      },

    ]
  },
  {
    protected: true,
    name: 'User',
    icon: <MdPerson className='h-6 w-6' />,
    submenu: [
      {
        path: '/users',
        protected: true, // Add this flag for the protected route
        element: <Users />,
        name: 'Daftar User',
        icon: <MdPerson className='h-6 w-6' />,
      },
      {
        path: '/user-levels',
        protected: true, // Add this flag for the protected route
        element: <UserLevels />,
        icon: <FaUsers className='h-6 w-6' />,
        name: 'User Level',
      },
    ],
  },
  {
    path: '/blog',
    protected: true, // Add this flag for the protected route
    element: <Blog />,
    name: 'Blog',
    icon: <FaBloggerB className='h-6 w-6' />,
    secondary: true,
  },
  {
    protected: true,
    name: 'Daerah',
    icon: <FaMapMarkerAlt className='h-6 w-6' />,
    submenu: [
      {
        path: '/zones',
        protected: true, // Add this flag for the protected route
        element: <Zone />,
        icon: <FaMapMarkerAlt className='h-6 w-6' />,
        name: 'Daftar Daerah',
      },
      {
        path: '/coverages',
        protected: true, // Add this flag for the protected route
        element: <Coverages />,
        name: 'Cakupan',
        icon: <FaMapMarkedAlt className='h-6 w-6' />,
      },
      {
        path: '/request-zone',
        protected: true, // Add this flag for the protected route
        element: <RequestZone />,
        icon: <FaMapMarkerAlt className='h-6 w-6' />,
        name: 'Req Daerah',
      },
    ]
  },
  {
    protected: true, // Add this flag for the protected route
    icon: <FaRegNewspaper className='h-6 w-6' />,
    name: 'Laporan',
    submenu: [
      {
        path: '/report-invoice',
        protected: true, // Add this flag for the protected route
        element: <Report />,
        icon: <FaMapMarkerAlt className='h-6 w-6' />,
        name: 'Laporan Invoice',
      },
      {
        path: '/report-subscriptions',
        protected: true, // Add this flag for the protected route
        element: <ReportSubscriptions />,
        icon: <FaMapMarkerAlt className='h-6 w-6' />,
        name: 'Laporan Langganan',
      }
    ]
  },
  {
    path: '/teknisi',
    protected: true, // Add this flag for the protected route
    element: <Teknisi />,
    icon: <MdEngineering className='h-6 w-6' />,
    name: 'Teknisi',
  },
  {
    path: '/loket',
    protected: true, // Add this flag for the protected route
    element: <Loket />,
    icon: <AiFillShop className='h-6 w-6' />,
    name: 'Loket',
  },
  {
    path: '/setting-general',
    protected: true, // Add this flag for the protected route
    element: <Settings />,
    icon: <IoMdSettings className='h-6 w-6' />,
    name: 'Setting',
  }

];

const getMenu = () => {
  return routes.filter(
    (route) => route.name !== undefined && route.icon !== undefined
  );
};

export { getMenu, routes };
