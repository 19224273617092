import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import Card from 'components/card';
import {Link, useNavigate, useParams} from 'react-router-dom';
import 'react-loading-skeleton/dist/skeleton.css';
import React, {useEffect, useState} from "react";
import MapContainer from './maps';
import Skeleton from "react-loading-skeleton";

const Edit = ({ request }) => {
    const params                                        = useParams();
    const navigate                                      = useNavigate();
    const [packages, setPackages]                       = useState([]);
    const [markerPosition, setMarkerPosition]           = useState(null);
    const [prices, setPrices]                           = useState([]);
    const [dataProvinces, setProvinces]                 = useState([]);
    const [dataDistrict, setDistrict]                   = useState([]);
    const [dataSubdistrict, setSubdistrict]             = useState([]);
    const [dataWards, setWards]                         = useState([]);
    const [dataHamlets, setHamlets]                     = useState([]);
    const [dataCoverages, setCoverages]                 = useState([]);
    const [pCatId, setPCatId]                           = useState('');

    useEffect(() => {
        request.get('/provinces').then((response) => {
            setProvinces(response);
        });

        request.get('/package-list').then((response) => {
            setPackages(response);
        });

        request.get('/coverages').then((response) => {
            setCoverages(response);
        });
    }, [request])

    const {
        setValue,
        register,
        handleSubmit,
        formState: { isLoading },
    } = useForm({
        defaultValues: async () => {
            try {
                const data = await request.get(`/submissions/${params.id}`);
                const [lat, lng] = data.submission_coordinates
                setMarkerPosition({ lat, lng });
                await request.get(`/districts?province_id=${data.province_id}`).then((response) => {
                    setDistrict(response);
                })
                await request.get(`/subdistricts?district_id=${data.district_id}`).then((response) => {
                    setSubdistrict(response);
                })
                await request.get(`/wards?subdistrict_id=${data.subdistrict_id}`).then((response) => {
                    setWards(response);
                })
                await request.get(`/hamlets?ward_id=${data.ward_id}`).then((response) => {
                    setHamlets(response);
                })
                await request.get(`/update-price?package_id=${data.package_id}&&p_category_id=${data.p_category_id}`).then((response) => {
                    setPrices(response);
                });
                setPCatId(data.p_category_id)
                return {
                    customerName          : data.customer.customer_name,
                    paketId               : data.package_id,
                    provinceId            : data.province_id,
                    districtId            : data.district_id,
                    subdistrictId         : data.subdistrict_id,
                    wardId                : data.ward_id,
                    hamletId              : data.hamlet_id,
                    coverageId            : data.coverage_id,
                    p_price_id            : data.p_price_id,
                    titik_koordinat       : data.submission_coordinates,
                };
            } catch (err) {
                if (err.response?.data?.errors) {
                    Object.keys(err.response.data.errors).map((field) =>
                        err.response.data.errors[field].map((message) =>
                            toast.error(message)
                        )
                    );
                } else if (err.response?.data?.message) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error(err.message);
                }
            }
        },
    });

    const submitHandler = async (data) => {
        try {
            await request.post(`/submissions/update/${params.id}`, data);
            toast.success('Berhasil menyimpan data');
            navigate('/submissions');
        } catch (err) {
            if (err.response?.data?.errors) {
                Object.keys(err.response.data.errors).map((field) =>
                    err.response.data.errors[field].map((message) => toast.error(message))
                );
            } else if (err.response?.data?.message) {
                toast.error(err.response.data.message);
            } else {
                toast.error(err.message);
            }
        }
    };

    const errorHandler = (errors, e) => {
        Object.keys(errors).map((key) => {
            if (Array.isArray(errors[key])) {
                return errors[key].map((err) => {
                    return toast.error(err.message);
                });
            }

            return toast.error(errors[key].message);
        });
    };

    const handleMapClick = (event) => {
        setMarkerPosition({
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
        });
        setValue('titik_koordinat',`${event.latLng.lat()} , ${event.latLng.lng()}`)
    };

    return (
        <Card extra={'w-full sm:overflow-auto p-4'}>
            <h2 className='text-xl font-bold text-navy-700'>Edit Pengajuan</h2>
            <form onSubmit={handleSubmit(submitHandler, errorHandler)} className='mt-4 flex flex-col gap-y-1 overflow-x-scroll xl:overflow-x-hidden'>
                <div className='grid grid-cols-2'>
                    {/*Nama*/}
                    <div className='form-control w-full max-w-sm'>
                        <label className='label'>
                            <span className='label-text'>Nama</span>
                        </label>
                        {isLoading ? (
                            <Skeleton height={45} />
                        ) : (
                            <input
                                {...register('customerName', {
                                    required: 'Nama harus diisi',
                                })}
                                type='text'
                                placeholder='Ketikan nama pelanggan'
                                className='input input-bordered w-full max-w-sm'
                            />
                        )}
                    </div>
                    {/*Provinsi*/}
                    <div className='form-control w-full max-w-sm'>
                        <label className='label'>
                            <span className='label-text'>Provinsi</span>
                        </label>
                        <select
                            {...register('provinceId', {
                                required: 'Provinsi harus dipilih',
                                onChange:(e)=>{
                                    const params = {
                                        province_id: e.target.value,
                                    };
                                    request.get('/districts', {params}).then((response) => {
                                        setDistrict(response);
                                    });
                                }
                            })}
                            className='select select-bordered w-full'
                        >
                            <option value='' disabled selected>
                                Pilih Provinsi
                            </option>
                            {dataProvinces.map((item) => (
                                <option key={item.province_id} value={item.province_id}>
                                    {item.province_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    {/*Kabupaten*/}
                    <div className='form-control w-full max-w-sm'>
                        <label className='label'>
                            <span className='label-text'>Kabupaten</span>
                        </label>
                        <select
                            {...register('districtId', {
                                required: 'Kabupaten harus dipilih',
                                onChange:(e)=>{
                                    const params = {
                                        district_id: e.target.value,
                                    };
                                    request.get('/subdistricts', {params}).then((response) => {
                                        setSubdistrict(response);
                                    });
                                }
                            })}
                            className='select select-bordered w-full'
                        >
                            <option value='' disabled selected>
                                Pilih Kabupaten
                            </option>
                            {dataDistrict.map((item) => (
                                <option key={item.district_id} value={item.district_id}>
                                    {item.district_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    {/*Kecamatan*/}
                    <div className='form-control w-full max-w-sm'>
                        <label className='label'>
                            <span className='label-text'>Kecamatan</span>
                        </label>
                        <select
                            {...register('subdistrictId', {
                                required: 'Kecamatan harus dipilih',
                                onChange:(e)=>{
                                    const params = {
                                        subdistrict_id: e.target.value,
                                    };
                                    request.get('/wards', {params}).then((response) => {
                                        setWards(response);
                                    });
                                }
                            })}
                            className='select select-bordered w-full'
                        >
                            <option value='' disabled selected>
                                Pilih Kecamatan
                            </option>
                            {dataSubdistrict.map((item) => (
                                <option key={item.subdistrict_id} value={item.subdistrict_id}>
                                    {item.subdistrict_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    {/*Desa*/}
                    <div className='form-control w-full max-w-sm'>
                        <label className='label'>
                            <span className='label-text'>Desa</span>
                        </label>
                        <select
                            {...register('wardId', {
                                required: 'Desa harus dipilih',
                                onChange:(e)=>{
                                    const params = {
                                        ward_id: e.target.value,
                                    };
                                    request.get('/hamlets', {params}).then((response) => {
                                        setHamlets(response);
                                    });
                                }
                            })}
                            className='select select-bordered w-full'
                        >
                            <option value='' disabled selected>
                                Pilih Desa
                            </option>
                            {dataWards.map((item) => (
                                <option key={item.ward_id} value={item.ward_id}>
                                    {item.ward_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    {/*Dusun*/}
                    <div className='form-control w-full max-w-sm'>
                        <label className='label'>
                            <span className='label-text'>Dusun</span>
                        </label>
                        <select
                            {...register('hamletId', {
                                required: 'Dusun harus dipilih',
                            })}
                            className='select select-bordered w-full'
                        >
                            <option value='' disabled selected>
                                Pilih Dusun
                            </option>
                            {dataHamlets.map((item) => (
                                <option key={item.hamlet_id} value={item.hamlet_id}>
                                    {item.hamlet_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    {/*Jangkauan*/}
                    <div className='form-control w-full max-w-sm'>
                        <label className='label'>
                            <span className='label-text'>Jangkauan</span>
                        </label>
                        <select
                            {...register('coverageId', {
                                required: 'Cakupan harus dipilih',
                            })}
                            className='select select-bordered w-full'
                        >
                            <option value='' disabled selected>
                                Pilih Jangkauan
                            </option>
                            {dataCoverages.map((item) => (
                                <option key={item.coverage_id} value={item.coverage_id}>
                                    {item.coverage_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    {/*Paket*/}
                    <div className='form-control w-full max-w-sm'>
                        <label className='label'>
                            <span className='label-text'>Pilih Paket</span>
                        </label>
                        <select
                            {...register('paketId', {
                                required: 'Paket harus dipilih',
                                onChange: (e) => {
                                    const params = {
                                        package_id: e.target.value,
                                        p_category_id: pCatId,
                                    };
                                    request.get('/update-price', {params}).then((response) => {
                                        setPrices(response);
                                        if (response.length > 0) {
                                            setValue('p_price_id', response[0].p_price_id);
                                        }
                                    });
                                }
                            })}
                            className='select select-bordered w-full'
                        >
                            <option value='' disabled selected>Pilih Paket</option>
                            {packages.map((item) => (
                                <option key={item.package_id} value={item.package_id}>
                                    {item.package_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    {/*Titik Koordinat*/}
                    <div className='form-control w-full max-w-sm'>
                        <label className='label'>
                            <span className='label-text'>Titik Koordinat</span>
                        </label>
                        <MapContainer
                            handleMapClick ={(e)=>handleMapClick(e)}
                            markerPosition ={markerPosition}
                        />
                    </div>
                    {/*Harga Langganan*/}
                    <div className='form-control w-full max-w-sm'>
                        <label className='label'>
                            <span className='label-text'>Harga (Termasuk PPN 11%)</span>
                        </label>
                        <div className="place-items-start rounded-lg border h-12 border-gray-300 content-center pl-4">
                            <div>
                                {isNaN(prices.p_price_total) || prices.p_price_total === 0
                                    ? 'Rp 0'
                                    : new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(prices.p_price_total)}
                            </div>
                        </div>
                        <input type="hidden" {...register('p_price_id')} />
                    </div>
                </div>
                <div className='flex gap-x-2'>
                    <button type='submit' className='btn btn-primary'>Simpan</button>
                    <Link to='/submissions' className='btn'>Batal</Link>
                </div>
            </form>
        </Card>
    );
};

export default Edit;