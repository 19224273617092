import React, { useMemo, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FiEye} from 'react-icons/fi';
import { createColumnHelper } from '@tanstack/react-table';
import Table from 'components/table/Table';
import Card from 'components/card';
import { dateFormat } from 'utils/date';
import {FaWhatsapp} from "react-icons/fa";

const List = ({ request }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [totalRow, setTotalRow] = useState(0);
    const [refresh, setRefresh] = useState(false);

    const fetchData = useCallback(
        async (pageSize, pageIndex, search) => {
            setIsLoading(true);
            const params = {
                per_page: pageSize,
                page: pageIndex,
                keyword: search,
            };

            try {
                const response = await request.get('/inboxes', { params });
                const startIndex = response.from;
                const updatedData = response.data.map((item, index) => ({
                    ...item,
                    listingNumber: startIndex + index,
                }));
                setData(updatedData);
                setPageCount(response.last_page);
                setTotalRow(response.total);
            } catch (err) {
                if (err.response?.data?.errors) {
                    Object.keys(err.response.data.errors).map((field) =>
                        err.response.data.errors[field].map((message) =>
                            toast.error(message)
                        )
                    );
                } else if (err.response?.data?.message) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error(err.message);
                }
            }

            setIsLoading(false);
            setRefresh(false);
        },
        [refresh] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const columnHelper = createColumnHelper();

    const columns = useMemo(
        () => [
            columnHelper.accessor('listingNumber', {
                header: () => '#',
                cell: (info) => (
                    <div className='text-sm'>{info.row.original.listingNumber}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('inbox_name', {
                header: () => 'Nama',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>{info.getValue()}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('inbox_subject', {
                header: () => 'keterangan',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>{info.getValue()}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('inbox_phone', {
                header: () => 'nomor',
                cell: (info) => (
                    <div className='inline-flex max-w-2xl gap-x-1 text-sm'>
                        {info.getValue()}
                        <a
                            aria-disabled={true}
                            href={`https://wa.me/send?phone=${info.getValue()}&text=asdfsf&app_absent=0`}
                            target='_blank'
                            rel='noreferrer'
                            className='text-green font-medium'
                        >
                            <FaWhatsapp size={24}/>
                        </a>
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('inbox_create_date', {
                header: () => 'Tanggal',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>
                        {dateFormat(info.getValue(), 'DD MMM YYYY')}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('inbox_id', {
                header: () => 'AKSI',
                cell: (info) => (
                    <div className='flex gap-x-1'>
                        <Link
                            aria-disabled={true}
                            to={`/inboxs/${info.getValue()}`}
                            className='font-medium text-success'
                        >
                            <FiEye size={24} />
                        </Link>
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
        ],
        [] // eslint-disable-line react-hooks/exhaustive-deps
    );
    return (
        <>
            <Card extra={'w-full sm:overflow-auto p-4'}>
                <h2 className='mb-1 text-xl font-bold text-secondary'>
                    Inboxes
                </h2>
                <div className='overflow-x-scroll xl:overflow-x-hidden'>
                    <Table
                        columns={columns}
                        data={data}
                        isLoading={isLoading}
                        fetchData={fetchData}
                        setRefresh={setRefresh}
                        pageCount={pageCount}
                        totalRow={totalRow}
                    />
                </div>
            </Card>
        </>
    );
};

export default List;
