import { Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import Verify from './components/Verify';

const Index = (props) => {
  return (
    <Routes>
      <Route path='/' element={<Login {...props} />} />
      <Route path='/verify' element={<Verify {...props} />} />
    </Routes>
  );
};

export default Index;
