const Footer = () => {
  return (
    <div className='flex w-full flex-col items-center justify-between px-1 pb-4 pt-2 lg:px-8 xl:flex-row'>
      <span className='mb-4 text-center text-sm text-secondary sm:!mb-0 md:text-base'>
        ©Copyright {1900 + new Date().getYear()} PT Iktiar Doa Tawakal.
        All Rights Reserved.
      </span>
    </div>
  );
};

export default Footer;
