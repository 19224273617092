import Card from '../../../components/card';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Select from 'react-select';

const Add = ({ request }) => {
  const navigate = useNavigate();
  const { register, handleSubmit, setValue } = useForm();
  const [data, setData] = useState([]);
  const [selectCus, setSelectCus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [datanumber, setnumber] = useState([]);
  const [invoicePeriod, setInvoicePeriod] = useState([]);
  const [lastInvoicePeriod, setLastInvoicePeriod] = useState([]);
  const [customerId, setCustomerId] = useState(null);
  const [cNumberId, setCNumberId] = useState(null);
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const selectMonth = [
    {
      value: '01',
      label: 'Januari',
    },
    {
      value: '02',
      label: 'Februari',
    },
    {
      value: '03',
      label: 'Maret',
    },
    {
      value: '04',
      label: 'April',
    },
    {
      value: '05',
      label: 'Mei',
    },
    {
      value: '06',
      label: 'Juni',
    },
    {
      value: '07',
      label: 'Juli',
    },
    {
      value: '08',
      label: 'Agustus',
    },
    {
      value: '09',
      label: 'September',
    },
    {
      value: '10',
      label: 'Oktober',
    },
    {
      value: '11',
      label: 'November',
    },
    {
      value: '12',
      label: 'Desember',
    },
  ];
  const selectYear = ['2023', '2024', '2025', '2026', '2027', '2028'];

  useEffect(() => {
    const today = new Date();
    today.setDate(today.getDate() + 3);
    const formattedDate = today.toISOString().substr(0, 10);
    setValue('date', formattedDate);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (customerId !== null && cNumberId !== null) {
      getInvoice(customerId, cNumberId);
    }
  }, [customerId, cNumberId]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (invoicePeriod.length !== 0) {
      const index = invoicePeriod.length - 1;
      const last_invoice_period = invoicePeriod[index].split('-');
      setLastInvoicePeriod(last_invoice_period);
      const intMonth = parseInt(last_invoice_period[0]);
      const intYear = parseInt(last_invoice_period[1]);
      const monthPlus = intMonth + 1;
      let yearPlus = intYear;
      if (monthPlus === 13) {
        yearPlus = intYear + 1;
      }
      let strMonth = monthPlus.toString();
      const strYear = yearPlus.toString();
      if (strMonth.length === 1) {
        strMonth = '0' + strMonth;
      }
      if (strMonth === '13') {
        strMonth = '01';
      }
      setMonth(strMonth);
      setYear(strYear);
      setValue('month', strMonth);
      setValue('year', strYear);
    }
  }, [invoicePeriod]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeSelect = (selectedOption) => {
    if (!selectedOption) {
      setSelectCus(null);
      setValue('customerId', '');
      setCustomerId(null);
    } else {
      setCustomerId(selectedOption.value);
      const params = {
        customer_id: selectedOption.value,
      };
      request.get('/customer-numbers', { params }).then((response) => {
        setnumber(response);
      });
      setSelectCus(selectedOption);
      setValue('customerId', selectedOption.value);
    }
  };

  const loadData = async (search) => {
    const params = {
      per_page: 5,
      page: 1,
      keyword: search,
    };
    try {
      const response = await request.get('/customers', { params });
      const startIndex = response.from;
      const updatedData = response.data.map((item, index) => ({
        ...item,
        listingNumber: startIndex + index,
      }));
      setData(updatedData);
    } catch (error) {
      console.error('Error loading data:', error);
      toast.error('Error loading data. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const getInvoice = async () => {
    try {
      const response = await request.get(
        `/invoices/customer/${customerId}/${cNumberId}`,
        {}
      );
      if (response.data) {
        setInvoicePeriod(response.data.invoice_periods);
      } else {
        setMonth('');
        setYear('');
      }
    } catch (err) {
      toast.error('Error loading data. Please try again.');
    }
  };

  const submitHandler = async (data) => {
    try {
      await request.post('/create-invoice', data);
      toast.success('Berhasil menyimpan data');
      return navigate('/invoices');
    } catch (err) {
      if (err.response?.data?.errors) {
        Object.keys(err.response.data.errors).map((field) =>
          err.response.data.errors[field].map((message) => toast.error(message))
        );
      } else if (err.response?.data?.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    }
  };

  const errorHandler = (errors) => {
    Object.keys(errors).map((key) => {
      if (Array.isArray(errors[key])) {
        return errors[key].map((err) => {
          return toast.error(err.message);
        });
      }

      return toast.error(errors[key].message);
    });
  };
  return (
    <Card extra={'w-full sm:overflow-auto p-4'}>
      <h2 className='text-xl font-bold text-navy-700'>Tambah Invoice</h2>
      <form
        onSubmit={handleSubmit(submitHandler, errorHandler)}
        className='mt-4 flex flex-col gap-y-1 overflow-x-scroll xl:overflow-x-hidden'
      >
        <div className='grid grid-cols-2'>
          <div className='form-control w-full max-w-sm'>
            <label className='label'>
              <span className='label-text'>Pelanggan</span>
            </label>
            <Select
              {...register('customerId', {
                required: 'Nama Pelanggan harus dipilih',
              })}
              value={selectCus}
              isLoading={isLoading}
              onChange={handleChangeSelect}
              onKeyDown={(event) => {
                if (event.key.length > 0) {
                  loadData(event.target.valueOf().value);
                }
              }}
              options={
                data.length !== null
                  ? data.map((item, index) => ({
                      key: index,
                      label: `${item.customer_name}`,
                      value: item.customer_id,
                    }))
                  : []
              }
              isClearable={true}
            />
          </div>
          <div className='form-control w-full max-w-sm'>
            <label className='label'>
              <span className='label-text'>No langganan</span>
            </label>
            <select
              {...register('c_number_id')}
              className='select select-bordered w-full'
              onChange={(e) => setCNumberId(e.target.value)}
            >
              <option value='' disabled selected>
                Pilih No Langganan
              </option>
              {datanumber.map((item) => (
                <option key={item.c_number_id} value={item.c_number_id}>
                  {item.c_number_value}
                </option>
              ))}
            </select>
          </div>
          {month !== '' && year !== '' ? (
            <>
              <div className='form-control w-full max-w-sm'>
                <label className='label'>
                  <span className='label-text'>Bulan</span>
                </label>
                <select
                  //   {...register('month')}
                  className='select select-bordered'
                  disabled={lastInvoicePeriod.length !== 0}
                >
                  {selectMonth.map((item, index) => (
                    <option
                      key={index}
                      value={item.value}
                      selected={month !== '' && month === item.value}
                    >
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className='form-control w-full max-w-sm'>
                <label className='label'>
                  <span className='label-text'>Tahun</span>
                </label>
                <select
                  //   {...register('year')}
                  className='select select-bordered'
                  disabled={lastInvoicePeriod.length !== 0}
                >
                  {selectYear.map((item, index) => (
                    <option
                      key={index}
                      value={item}
                      selected={year !== '' && year === item}
                    >
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </>
          ) : (
            <>
              <div className='form-control w-full max-w-sm'>
                <label className='label'>
                  <span className='label-text'>Bulan</span>
                </label>
                <select
                  {...register('month')}
                  className='select select-bordered'
                >
                  {selectMonth.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className='form-control w-full max-w-sm'>
                <label className='label'>
                  <span className='label-text'>Tahun</span>
                </label>
                <select
                  {...register('year')}
                  className='select select-bordered'
                >
                  {selectYear.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </>
          )}
          <div className='form-control w-full max-w-sm'>
            <label className='label'>
              <span className='label-text'>Jumlah Bulan</span>
            </label>
            <select
              {...register('numberOfMonth')}
              className='select select-bordered'
            >
              <option value={1} selected>
                1 bulan
              </option>
              <option value={2}>2 bulan</option>
              <option value={3}>3 bulan</option>
              <option value={4}>4 bulan</option>
              <option value={5}>5 bulan</option>
              <option value={6}>6 bulan</option>
            </select>
          </div>
          <div className='form-control w-full max-w-sm'>
            <label className='label'>
              <span className='label-text'>Jatuh Tempo</span>
            </label>
            <input
              {...register('date')}
              type='date'
              className='input input-bordered w-full max-w-sm'
            />
          </div>
        </div>
        <div className='flex-cols-reverse flex gap-x-2'>
          <button type='submit' className='btn btn-primary'>
            Tambah
          </button>
          <Link to='/invoices' className='btn'>
            Batal
          </Link>
        </div>
      </form>
    </Card>
  );
};
export default Add;
