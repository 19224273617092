import DashIcon from '../../icons/DashIcon';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { Link, useLocation } from 'react-router-dom';
import React from 'react';
import LinkSubMenu from './LinkSubMenu';

export default function LinkMenu(index, route, activeRoute, pare) {
  const [subMenuActive, setSubMenuActive] = React.useState([]);
  const location = useLocation();

  const subMenuIsActive = (path) => {
    return subMenuActive.includes(path);
  };

  React.useEffect(() => {
    const split = location.pathname.split('/');

    if (split.length > 2) {
      const path = '/' + split[1];

      let newObj = subMenuActive;

      if (subMenuIsActive(path)) {
        newObj = newObj.filter((item) => item !== path);
      } else {
        newObj = [path];
      }

      setSubMenuActive(newObj);
    }
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <LinkItem
      key={index}
      hasSubmenu={
        typeof route.submenu !== 'undefined' && route.submenu.length > 0
      }
      setSubMenuActive={(path) => {
        var newObj = subMenuActive;

        if (subMenuIsActive(path)) {
          newObj = newObj.filter((item) => item !== path);
        } else {
          newObj = [...newObj, path];
        }

        setSubMenuActive(newObj);
      }}
      route={route}
    >
      <div className='relative mb-3  hover:cursor-pointer'>
        <li className='my-[3px]  cursor-pointer items-center px-8' key={index}>
          <div className='flex flex-row justify-between'>
            <div className='row flex'>
              <span
                className={`${
                  activeRoute(route) === true
                    ? 'font-bold text-secondary'
                    : 'font-medium text-gray-800'
                }`}
              >
                {route.icon ? route.icon : <DashIcon />}{' '}
              </span>
              <p
                className={`leading-1 ml-4 flex ${
                  activeRoute(route) === true
                    ? 'font-bold text-navy-700'
                    : 'font-medium text-gray-800'
                }`}
              >
                {route.name}
              </p>
            </div>
            <div style={{ marginLeft: '35px' }}>
              {route.submenu !== undefined ? (
                subMenuIsActive(route.path) === true ? (
                  <FiChevronDown size={20} color='#1C1917' />
                ) : (
                  <FiChevronUp size={20} color='#1C1917' />
                )
              ) : (
                ''
              )}
            </div>
          </div>
        </li>
        {activeRoute(route) ? (
          <div className='absolute top-px h-9 w-1 rounded-lg bg-secondary' />
        ) : null}
      </div>
      <div>
        {route.submenu !== undefined && subMenuIsActive(route.path) === true
          ? route.submenu
              .filter((subRoute) => subRoute.name !== undefined)
              .map((subRoute, subIndex) => (
                <LinkSubMenu
                  key={subIndex}
                  subRoute={subRoute}
                  isActiveRoute={activeRoute(subRoute)}
                />
              ))
          : ''}
      </div>
    </LinkItem>
  );
}

const LinkItem = ({ hasSubmenu, setSubMenuActive, route, children }) => {
  if (hasSubmenu) {
    return (
      <div className='flex'>
        <button className='flex-1' onClick={() => setSubMenuActive(route.path)}>
          {children}
        </button>
      </div>
    );
  } else {
    return <Link to={route.path}>{children}</Link>;
  }
};
