import { Route, Routes } from 'react-router-dom';
import List from './components/List';
import Add from "./components/Add";
import Edit from './components/Edit';

const Loket = (props) => {
    return (
        <Routes>
            <Route path='/' element={<List {...props} />} />
            <Route path='/add' element={<Add {...props} />} />
            <Route path='/:id/edit' element={<Edit {...props} />} />
        </Routes>
    );
};

export default Loket;