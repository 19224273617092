import React, { useState, useEffect, useCallback } from 'react';
import { Button, ButtonGroup, Card, CardBody, CardHeader, Container, Spinner } from "reactstrap";
import { dateFormat, dateNow } from "../../../../utils/date";
import Select from 'react-select';
import Loader from "react-loaders";
import Chart from 'react-apexcharts';
import {toast} from "react-toastify";

const minOffset = 0;
const maxOffset = new Date().getFullYear() - 2018;

const PpobMonth = ({ props }) => {
    const thisYear = new Date().getFullYear();

    const [tahun, setTahun] = useState(dateFormat(dateNow(), 'YYYY'));
    const [isLoading, setIsLoading] = useState(true);
    const [transaksiMonth, setTransaksiMonth] = useState([]);
    const [transaksiNominal, setTransaksiNominal] = useState([]);
    const [statusCaptured, setStatusCaptured] = useState([]);
    const [statusPending, setStatusPending] = useState([]);
    const [statusFailed, setStatusFailed] = useState([]);

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await props.request.get(`/grafik-transaksi?type=month&tahun=${tahun}`);
            let transaksiMonth = [];
            let nominalTransaksi = [];
            let capturedStatus = [];
            let pendingStatus = [];
            let failedStatus = [];
            response.data.forEach(element=>{
                transaksiMonth.push(element.month)
                nominalTransaksi.push(element.nominal)
                capturedStatus.push(element.captured)
                pendingStatus.push(element.pending)
                failedStatus.push(element.failed)
            })
            setTransaksiMonth(transaksiMonth)
            setTransaksiNominal(nominalTransaksi)
            setStatusCaptured(capturedStatus)
            setStatusPending(pendingStatus)
            setStatusFailed(failedStatus)
        } catch (err) {
            handleErrors(err);
        }
        setIsLoading(false);
    }, [tahun, props.request]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const refresh = () => {
        fetchData();
    };

    const handleErrors = (err) => {
        if (err.response?.data?.errors) {
            Object.keys(err.response.data.errors).forEach((field) =>
                err.response.data.errors[field].forEach((message) => toast.error(message))
            );
        } else if (err.response?.data?.message) {
            toast.error(err.response.data.message);
        } else {
            toast.error(err.message);
        }
    };

    // Handle year change from the dropdown
    const handleChange = (selectedOption) => {
        setTahun(selectedOption.value);
        setIsLoading(true);
    };

    // Generate year options for the Select dropdown
    const options = [];
    for (let i = minOffset; i <= maxOffset; i++) {
        const year = thisYear - i;
        options.push({ value: year, label: year });
    }

    const series = [
        {
            name: 'Total Transaksi',
            data: transaksiNominal,
            color: '#f78a2a',
        },
        {
            name: 'Sukses',
            data: statusCaptured,
            color: '#02db60',
        },
        {
            name: 'Gagal',
            data: statusFailed,
            color: '#ff405a',
        },
        {
            name: 'Proses',
            data: statusPending,
            color: '#fafa00',
        },
    ];

    const setting = {
        chart: {
            toolbar: { show: false },
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            },
        },
        dataLabels: { enabled: false },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: { categories: transaksiMonth },
        yaxis: [{
            title: { text: 'Total Transaksi' },
            labels: {
                formatter: (value) => value ? `Rp ${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}` : "Rp 0"
            }
        }],
        fill: { opacity: 1 }
    };

    return (
        <Container fluid>
            <Card className="p-5 bg-white">
                <CardHeader className="flex flex-col md:flex-row justify-between pb-4">
                    <div className="flex flex-col md:flex-row items-start md:items-center card-header-title font-size-lg text-capitalize font-weight-normal">
                        <span className="content-center">Transaksi</span>
                        <span className="mt-2 md:mt-0 md:ml-2 w-full md:w-auto" style={{ width: 200 }}>
                            <Select
                                value={options.find(option => option.value === tahun)}
                                onChange={handleChange}
                                defaultValue={options[0]}
                                options={options}
                            />
                        </span>
                    </div>
                    <div className="btn-actions-pane-right mt-4 md:mt-0">
                        <ButtonGroup size="sm">
                            <Button color="warning"
                                    className="btn-shadow bg-primary"
                                    disabled={isLoading}
                                    onClick={refresh}>
                                {isLoading ? (
                                    <span>
                                        <Spinner size="sm" color="secondary" /> Please wait...
                                    </span>
                                ) : (
                                    <span><i className="pe-7s-refresh-2"></i> Refresh</span>
                                )}
                            </Button>
                        </ButtonGroup>
                    </div>
                </CardHeader>
                <CardBody className="pt-0">
                    {isLoading ? (
                        <tr>
                            <td colSpan="12">
                                <div className="loader-overlay">
                                    <Loader active type='ball-beat' />
                                </div>
                            </td>
                        </tr>
                    ) : (
                        <div className="bar" style={{ marginTop: '-20px' }}>
                            <Chart options={setting} series={series} type="bar" width="100%" height="325px" />
                        </div>
                    )}
                </CardBody>
            </Card>
        </Container>
    );
};

export default PpobMonth;
