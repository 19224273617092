import { Route, Routes } from 'react-router-dom';
import List from './components/List';
import Details from './components/details';
import Edit from './components/Edit';
import Add from './components/Add'

const Customers = (props) => {
  return (
    <Routes>
      <Route path='/' element={<List {...props} />} />
      <Route path='/add' element={<Add {...props} />} />
      <Route path='/:id' element={<Details {...props} />} />
      <Route path='/:id/edit' element={<Edit {...props} />} />
    </Routes>
  );
};

export default Customers;
