import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import Card from 'components/card';
import { Link, useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import React, { useState} from 'react';

const TeknisiAdd = (props) => {
    const navigate = useNavigate();
    const { register, handleSubmit } = useForm();
    const [isLoading, setIsLoading] = useState(false);


    const submitHandler = async (data) => {
        try {
            setIsLoading(true);
            await props.request.post('/technician', data);
            toast.success('Berhasil menyimpan data');
            return navigate('/teknisi');
        } catch (err) {
            if (err.response?.data?.errors) {
                Object.keys(err.response.data.errors).map((field) =>
                    err.response.data.errors[field].map((message) => toast.error(message))
                );
            } else if (err.response?.data?.message) {
                toast.error(err.response.data.message);
            } else {
                toast.error(err.message);
            }
        } finally {
            setIsLoading(false);
        }
    };
    return (
        <Card extra={'w-full sm:overflow-auto p-4'}>
            <h2 className='text-xl font-bold text-navy-700'>Tambah Teknisi</h2>
            <form
                onSubmit={handleSubmit(submitHandler)}
                className='mt-4 flex flex-col gap-y-1 overflow-x-scroll xl:overflow-x-hidden'
            >
                <div className='grid grid-cols-2'>
                    <div className='form-control w-full max-w-sm'>
                        <label className='label'>
                            <span className='label-text'>Nama</span>
                        </label>
                        {isLoading ? (
                            <Skeleton height={45} />
                        ) : (
                            <input
                                {...register('technician_name', {
                                    required: 'Nama harus diisi',
                                })}
                                type='text'
                                placeholder='Ketikan nama teknisi'
                                className='input input-bordered w-full max-w-sm'
                            />
                        )}
                    </div>
                    <div className='form-control w-full max-w-sm'>
                        <label className='label'>
                            <span className='label-text'>Email</span>
                        </label>
                        {isLoading ? (
                            <Skeleton height={45} />
                        ) : (
                            <input
                                {...register('technician_email')}
                                type='email'
                                placeholder='Ketikan email teknisi'
                                className='input input-bordered w-full max-w-sm'
                            />
                        )}
                    </div>
                    <div className='form-control w-full max-w-sm'>
                        <label className='label'>
                            <span className='label-text'>Nomor Telepon</span>
                        </label>
                        {isLoading ? (
                            <Skeleton height={45} />
                        ) : (
                            <input
                                {...register('technician_phone', {
                                    required: 'Nomor Telepon harus diisi',
                                })}
                                type='tel'
                                placeholder='Ketikan Nomor Telepon teknisi'
                                className='input input-bordered w-full max-w-sm'
                                onKeyPress={(e) => {
                                    if (!/[0-9]/.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        )}
                    </div>
                    <div className='form-control w-full max-w-sm'>
                        <label className='label'>
                            <span className='label-text'>Gender</span>
                        </label>
                        {isLoading ? (
                            <Skeleton height={45}/>
                        ) : (
                            <select
                                {...register('technician_gender', {
                                    required: 'Gender harus dipilih',
                                })}
                                className='select select-bordered'
                            >
                                <option value='' disabled selected>
                                    Pilih Gender
                                </option>
                                <option value='male'>Pria</option>
                                <option value='female'>Wanita</option>
                            </select>
                        )}
                    </div>
                    <div className='form-control w-full max-w-sm'>
                        <label className='label'>
                            <span className='label-text'>Alamat</span>
                        </label>
                        {isLoading ? (
                            <Skeleton height={95} />
                        ) : (
                            <textarea
                                {...register('technician_address')}
                                className='textarea textarea-bordered h-24'
                                placeholder='Ketikan alamat teknisi'
                            />
                        )}
                    </div>
                </div>
                <div className='flex flex-row-reverse gap-x-2'>
                    <button type='submit' className='btn btn-primary'>
                        Simpan
                    </button>
                    <Link to='/teknisi' className='btn'>
                        Batal
                    </Link>
                </div>
            </form>
        </Card>
    );
};
export default TeknisiAdd;