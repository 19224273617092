import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import Card from "../../../components/card";
import Skeleton from "react-loading-skeleton";
import CurrencyInput from "react-currency-input-field";
import {Link} from "react-router-dom";

const Business = ({ request }) => {

    const onSubmit = async (data) => {
        try {

            let cleanedData = {
                ...data,
                b_setting_payment_admin_fee: parseCurrencyInput(data.b_setting_payment_admin_fee),
                b_setting_deposit_admin_fee: parseCurrencyInput(data.b_setting_deposit_admin_fee)
            };

            await request.post(`/settings/update-business`, cleanedData);
            toast.success('Berhasil mengubah data');

        } catch (err) {
            if (err.response?.data?.errors) {
                Object.keys(err.response.data.errors).map((field) =>
                    err.response.data.errors[field].map((message) => toast.error(message))
                );
            } else if (err.response?.data?.message) {
                toast.error(err.response.data.message);
            } else {
                toast.error(err.message);
            }
        }
    }

    const parseCurrencyInput = (value) => {
        if (!value) return 0;

        const numericValue = value
            .replace('Rp ', '')
            .replace(/\./g, '')
            .replace(',', '.');

        return parseFloat(numericValue);
    };

        const {
        register,
        getValues,
        handleSubmit,
        formState: { isLoading },
    } = useForm({
        defaultValues: async () => {
            try {
                const data = await request.get(`/settings/all-business`);
                return {
                    b_setting_vat_percentage: data.b_setting_vat_percentage,
                    b_setting_payment_admin_fee: data.b_setting_payment_admin_fee.slice(0,-3),
                    b_setting_deposit_admin_fee: data.b_setting_deposit_admin_fee.slice(0,-3),

                };
            } catch (err) {
                if (err.response?.data?.errors) {
                    Object.keys(err.response.data.errors).map((field) =>
                        err.response.data.errors[field].map((message) =>
                            toast.error(message)
                        )
                    );
                } else if (err.response?.data?.message) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error(err.message);
                }
            }
        },
    });

    const errorHandler = (errors, e) => {
        Object.keys(errors).map((key) => {
            if (Array.isArray(errors[key])) {
                return errors[key].map((err) => {
                    return toast.error(err.message);
                });
            }

            return toast.error(errors[key].message);
        });
    };

    return(
        <>
            <Card extra={'w-full sm:overflow-auto p-4 mt-4'}>
                <h2 className='text-xl font-bold text-navy-700'>General Business</h2>
                <form  onSubmit={handleSubmit(onSubmit, errorHandler)}
                       className='mt-4 flex flex-col gap-y-1 overflow-x-scroll xl:overflow-x-hidden'>
                    <div className='grid xl:grid-cols-2'>
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Persentase PPN</span>
                            </label>
                            {isLoading ? (
                                <Skeleton height={45} />
                            ) : (
                                <input
                                    {...register('b_setting_vat_percentage', )}
                                    type='number'
                                    placeholder='Masukkan Durasi Bonus'
                                    className='input input-bordered w-full max-w-sm'
                                />
                            )}
                        </div>
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Biaya Admin Pembayaran</span>
                            </label>
                            {isLoading ? (
                                <Skeleton height={45} />
                            ) : (
                                <CurrencyInput
                                    {...register('b_setting_payment_admin_fee')}
                                    prefix='Rp '
                                    placeholder='Silahkan masukan Nominal Bonus'
                                    defaultValue={getValues('b_setting_payment_admin_fee')}
                                    decimalSeparator=','
                                    groupSeparator='.'
                                    intlConfig={{ locale: 'id-ID', currency: 'IDR' }}
                                    className='input input-bordered w-full max-w-sm'
                                />
                            )}
                        </div>
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Biaya Admin Setor</span>
                            </label>
                            {isLoading ? (
                                <Skeleton height={45} />
                            ) : (
                                <CurrencyInput
                                    {...register('b_setting_deposit_admin_fee')}
                                    prefix='Rp '
                                    placeholder='Silahkan masukan Nominal Bonus'
                                    defaultValue={getValues('b_setting_deposit_admin_fee')}
                                    decimalSeparator=','
                                    groupSeparator='.'
                                    intlConfig={{ locale: 'id-ID', currency: 'IDR' }}
                                    className='input input-bordered w-full max-w-sm'
                                />
                            )}
                        </div>
                    </div>
                    <div className='flex gap-x-2'>
                        <button type='submit' className='btn btn-primary'>
                            Simpan
                        </button>
                        <Link to='/package-prices' className='btn'>
                            Batal
                        </Link>
                    </div>
                </form>
            </Card>
        </>
    );
};

export default Business