import { useParams} from "react-router-dom";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import Card from 'components/card';
import {toast} from "react-toastify";
import {createColumnHelper} from "@tanstack/react-table";
import {dateFormat} from "../../../../utils/date";
import {convertToRupiah} from "../../../../utils/general";

const DetailInbox = ({ request }) =>{
    const params = useParams();
    const [inbox, setInbox]                   = useState({});
    const [, setIsLoading]                 = useState(true);
    const [, setDataReferal]             = useState([]);
    const [, setPageCountReferal]   = useState(0);
    const [, setTotalRowReferal]     = useState(0);
    const [, setData]                           = useState([]);
    const [, setPageCount]                 = useState(0);
    const [, setTotalRow]                   = useState(0);
    const [refresh, setRefresh]                     = useState(false);

    useEffect(() => {
        request.get(`/inboxes/${params.id}`).then((response) => {
            setInbox(response);
            setIsLoading(false);
            setRefresh(true);
        });

    }, [params]); // eslint-disable-line react-hooks/exhaustive-deps
    useCallback(
        async (pageSize, pageIndex, search) => {
            setIsLoading(true);
            setRefresh(false);

            const params = {
                per_page    : pageSize,
                page        : pageIndex,
                keyword     : search,

            };
            try {
                const response = await request.get('/list-downline', { params });
                const startIndex = response.from;

                const updatedData = response.data.map((item, index) => ({
                    ...item,
                    listingNumber: startIndex + index,
                }));
                setData(updatedData);
                setPageCount(response.last_page);
                setTotalRow(response.total);
            } catch (err) {
                if (err.response?.data?.errors) {
                    Object.keys(err.response.data.errors).map((field) =>
                        err.response.data.errors[field].map((message) =>
                            toast.error(message)
                        )
                    );
                } else if (err.response?.data?.message) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error(err.message);
                }
            }

            setIsLoading(false);
            setRefresh(false);
        },
        [refresh,] // eslint-disable-line react-hooks/exhaustive-deps
    );
    useCallback(
        async (pageSize, pageIndex, search) => {
            setIsLoading(true);
            setRefresh(false);

            const params = {
                per_page    : pageSize,
                page        : pageIndex,
                keyword     : search,
                inbox_id     : inbox.inbox_id

            };
            try {
                const response = await request.get('/list-bonus', { params });
                const startIndex = response.from;

                const updatedData = response.data.map((item, index) => ({
                    ...item,
                    listingNumber: startIndex + index,
                }));
                setDataReferal(updatedData);
                setPageCountReferal(response.last_page);
                setTotalRowReferal(response.total);
            } catch (err) {
                if (err.response?.data?.errors) {
                    Object.keys(err.response.data.errors).map((field) =>
                        err.response.data.errors[field].map((message) =>
                            toast.error(message)
                        )
                    );
                } else if (err.response?.data?.message) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error(err.message);
                }
            }

            setIsLoading(false);
            setRefresh(false);
        },
        [refresh,] // eslint-disable-line react-hooks/exhaustive-deps
    );
    const columnHelper = createColumnHelper();
    useMemo(
        ()=>[
            columnHelper.accessor('listingNumber', {
                header: () => '#',
                cell: (info) => (
                    <div className='text-sm'>{info.row.original.listingNumber}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('invoice.customer.customer_name', {
                header: () => 'Nama',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>{info.getValue()}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('r_bonus_section', {
                header: () => 'Type',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-xs'>
                        {info.getValue() === 'bulanan' ? (
                            <span className='badge badge-warning'>{info.getValue()}</span>
                        ) :  (
                            <span className='badge badge-secondary'>{info.getValue()}</span> // Default badge style
                        )}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('r_bonus_nominal', {
                header: () => 'nominal',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>
                        {convertToRupiah(info.getValue(), true)}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('r_bonus_create_date', {
                header: () => 'tanggal',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>
                        {dateFormat(info.getValue(), 'DD MMM YYYY')}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
        ],[] // eslint-disable-line react-hooks/exhaustive-deps
    );
    return (
        <>
            <Card extra={'w-full sm:overflow-auto p-4 mt-4'}>
                <h2 className='text-xl font-bold text-navy-700'>Detail Inboxes</h2>
                <div className='flex h-full flex-col justify-between'>
                    <div className='grid grid-cols-2 xl:grid-cols-2 gap-3 px-2'>
                        <div className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
                            <p className='text-sm text-gray-600'>Nama</p>
                            <p className='text-base font-medium text-navy-700'>
                                {inbox.inbox_name}
                            </p>
                        </div>
                        <div className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
                            <p className='text-sm text-gray-600'>Keterangan</p>
                            <p className='text-base font-medium text-navy-700'>
                                {inbox.inbox_subject}
                            </p>
                        </div>
                        <div className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
                            <p className='text-sm text-gray-600'>Pesan</p>
                            <p className='text-base font-medium text-navy-700'>
                                {inbox.inbox_message}
                            </p>
                        </div>
                    </div>
                </div>
            </Card>
        </>
    );
};

export default DetailInbox;
