export default function AvatarAlpabet({ customer_name = '' }) {
  var initial = '';
  var fontSize = 50;

  var customer_name_split = customer_name.split(' ');

  if (customer_name_split.length > 1) {
    var initial1 = customer_name_split[0].slice(0, 1);
    var initial2 = customer_name_split[1].slice(0, 1);

    initial = initial1 + initial2;
    fontSize = 35;
  } else {
    initial = customer_name_split[0].slice(0, 1);
  }

  return (
    <div>
      <h1 style={{ fontSize }}>{initial}</h1>
    </div>
  );
}
