import Card from "../../../components/card";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {Link, useNavigate, useParams} from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import {dateFormat} from "../../../utils/date";

const Edit = ({ request }) => {
    const params = useParams();
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { isLoading },
    } = useForm({
        defaultValues: async () => {
            try {
                const data = await request.get(`/invoices/${params.id}`);
                return {
                    invoice_status: data.data.invoice_status,
                    invoice_issue_date: dateFormat(data.data.invoice_issue_date, 'YYYY-MM-DD'),
                    invoice_due_date: dateFormat(data.data.invoice_due_date, 'YYYY-MM-DD')
                };
            } catch (err) {
                if (err.response?.data?.errors) {
                    Object.keys(err.response.data.errors).map((field) =>
                        err.response.data.errors[field].map((message) =>
                            toast.error(message)
                        )
                    );
                } else if (err.response?.data?.message) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error(err.message);
                }
            }
        },
    });

    const submitHandler = async (data, e) => {
        try {
            await request.put(`/invoices/${params.id}`, data);
            toast.success('Berhasil menyimpan data');
            return navigate('/invoices');
        } catch (err) {
            if (err.response?.data?.errors) {
                Object.keys(err.response.data.errors).map((field) =>
                    err.response.data.errors[field].map((message) => toast.error(message))
                );
            } else if (err.response?.data?.message) {
                toast.error(err.response.data.message);
            } else {
                toast.error(err.message);
            }
        }
    };

    const errorHandler = (errors, e) => {
        Object.keys(errors).map((key) => {
            if (Array.isArray(errors[key])) {
                return errors[key].map((err) => {
                    return toast.error(err.message);
                });
            }

            return toast.error(errors[key].message);
        });
    };

    return (
        <Card extra={'w-full sm:overflow-auto p-4'}>
            <h2 className='text-xl font-bold text-navy-700'>Edit Invoice</h2>
            <form
                onSubmit={handleSubmit(submitHandler, errorHandler)}
                className='mt-4 flex flex-col gap-y-1 overflow-x-scroll xl:overflow-x-hidden'
            >
                <div className='grid grid-cols-2'>
                    <div className='form-control w-full max-w-sm'>
                        <label className='label'>
                            <span className='label-text'>Status</span>
                        </label>
                        {isLoading ? (
                            <Skeleton height={45} />
                        ) : (
                            <select
                                {...register('invoice_status',{
                                    required: 'Status harus dipilih',
                                })}
                                className='select select-bordered'
                            >
                                <option value='' disabled>
                                    Pilih Status Invoice
                                </option>
                                <option value='draft'>Belum Lunas</option>
                                <option value='write-off'>Invoice Terhapus</option>
                                <option value='void'>Invoice Batal</option>
                                <option value='overdue'>Lewat Jatuh Tempo</option>
                                <option value='sent'>Terkirim</option>
                            </select>
                        )}
                    </div>
                    <div className='form-control w-full max-w-sm'>
                        <label className='label'>
                            <span className='label-text'>Tenggat Waktu</span>
                        </label>
                        <input
                            {...register('invoice_due_date')}
                            type='date'
                            className='input input-bordered w-full max-w-sm'
                        />
                    </div>
                    <div className='form-control w-full max-w-sm'>
                        <label className='label'>
                            <span className='label-text'>Jatuh Tempo</span>
                        </label>
                        <input
                            {...register('invoice_issue_date')}
                            type='date'
                            // value="2024-08-10"
                            className='input input-bordered w-full max-w-sm'
                        />
                    </div>
                </div>
                <div className='flex gap-x-2'>
                    <button type='submit' className='btn btn-primary'>
                        Simpan
                    </button>
                    <Link to='/invoices' className='btn'>
                        Batal
                    </Link>
                </div>
            </form>
        </Card>
    )
};

export default Edit;
