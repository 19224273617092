import {Route, Routes} from "react-router-dom";
import List from './Components/List';
import Edit from './Components/Edit';

const RequestZone = (props) =>{
    return(
        <Routes>
            <Route path='/' element={<List {...props} />} />
            <Route path=':id/edit' element={<Edit {...props} />} />
        </Routes>

    );

};

export default RequestZone;