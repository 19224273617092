import React, { useState, useEffect, useCallback } from 'react';
import { Button, ButtonGroup, Card, CardBody, CardHeader, Container, Spinner } from "reactstrap";
import Loader from "react-loaders";
import Chart from "react-apexcharts";
import {toast} from "react-toastify";

const PpobYear = ({ props }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [transaksiYear, setTransaksiYear] = useState([]);
    const [transaksiNominal, setTransaksiNominal] = useState([]);
    const [statusCaptured, setStatusCaptured] = useState([]);
    const [statusPending, setStatusPending] = useState([]);
    const [statusFailed, setStatusFailed] = useState([]);

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await props.request.get(`/grafik-transaksi?type=year`);
            let transaksiYear = [];
            let nominalTransaksi = [];
            let capturedStatus = [];
            let pendingStatus = [];
            let failedStatus = [];
            response.data.forEach(element=>{
                transaksiYear.push(element.year)
                nominalTransaksi.push(element.nominal)
                capturedStatus.push(element.captured)
                pendingStatus.push(element.pending)
                failedStatus.push(element.failed)
            })
            setTransaksiYear(transaksiYear)
            setTransaksiNominal(nominalTransaksi)
            setStatusCaptured(capturedStatus)
            setStatusPending(pendingStatus)
            setStatusFailed(failedStatus)
        } catch (err) {
            handleErrors(err);
        }
        setIsLoading(false);
    }, [props.request]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const refresh = () => {
        fetchData();
    };

    const handleErrors = (err) => {
        if (err.response?.data?.errors) {
            Object.keys(err.response.data.errors).forEach((field) =>
                err.response.data.errors[field].forEach((message) => toast.error(message))
            );
        } else if (err.response?.data?.message) {
            toast.error(err.response.data.message);
        } else {
            toast.error(err.message);
        }
    };

    const series = [
        {
            name: 'Total Transaksi',
            data: transaksiNominal,
            color: '#f78a2a',
        },
        {
            name: 'Sukses',
            data: statusCaptured,
            color: '#02db60',
        },
        {
            name: 'Gagal',
            data: statusFailed,
            color: '#ff405a',
        },
        {
            name: 'Proses',
            data: statusPending,
            color: '#fafa00',
        },
    ];

    const options = {
        chart: {
            toolbar: { show: false },
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            },
        },
        dataLabels: { enabled: false },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: transaksiYear,
        },
        yaxis: [
            {
                title: { text: 'Total Transaksi' },
                labels: {
                    formatter: value => `Rp ${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") || "0"}`
                },
            },
        ],
        fill: { opacity: 1 }
    };

    return (
        <Container fluid>
            <Card className="p-5 bg-white">
                <CardHeader className="flex flex-row card-header-tab justify-between">
                    <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                        Transaksi Tahunan
                    </div>
                    <div className="btn-actions-pane-right">
                        <ButtonGroup size="sm">
                            <Button
                                color="warning"
                                className={"btn-shadow bg-primary"}
                                disabled={isLoading}
                                onClick={refresh}
                            >
                                {isLoading ? (
                                    <span>
                                        <Spinner size="sm" color="secondary" /> Please wait...
                                    </span>
                                ) : (
                                    <span>
                                        <i className="pe-7s-refresh-2"></i> Refresh
                                    </span>
                                )}
                            </Button>
                        </ButtonGroup>
                    </div>
                </CardHeader>
                <CardBody className="pt-0">
                    {isLoading ? (
                        <div className="loader-overlay">
                            <Loader active type='ball-beat' />
                        </div>
                    ) : (
                        <div className="bar" style={{ marginTop: '-20px' }}>
                            <Chart options={options} series={series} type="bar" width="100%" height="325px" />
                        </div>
                    )}
                </CardBody>
            </Card>
        </Container>
    );
};

export default PpobYear;
