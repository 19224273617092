import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import Card from 'components/card';
import { Link, useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import React, { useState} from 'react';

const CustomerAdd = (props) => {
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();
  const [isLoading, setIsLoading] = useState(false);


  const submitHandler = async (data) => {
    try {
      setIsLoading(true);
      await props.request.post('/customers', data);
      toast.success('Berhasil menyimpan data');
      return navigate('/customers');
    } catch (err) {
      if (err.response?.data?.errors) {
        Object.keys(err.response.data.errors).map((field) =>
          err.response.data.errors[field].map((message) => toast.error(message))
        );
      } else if (err.response?.data?.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Card extra={'w-full sm:overflow-auto p-4'}>
      <h2 className='text-xl font-bold text-navy-700'>Tambah Pelanggan</h2>
      <form
        onSubmit={handleSubmit(submitHandler)}
        className='mt-4 flex flex-col gap-y-1 overflow-x-scroll xl:overflow-x-hidden'
      >
        <div className='grid grid-cols-2'>
          <div className='form-control w-full max-w-sm'>
            <label className='label'>
              <span className='label-text'>Nama</span>
            </label>
            {isLoading ? (
              <Skeleton height={45} />
            ) : (
              <input
                {...register('customer_name', {
                  required: 'Nama harus diisi',
                })}
                type='text'
                placeholder='Ketikan nama pelanggan'
                className='input input-bordered w-full max-w-sm'
              />
            )}
          </div>
          <div className='form-control w-full max-w-sm'>
            <label className='label'>
              <span className='label-text'>Email</span>
            </label>
            {isLoading ? (
              <Skeleton height={45} />
            ) : (
              <input
                {...register('customer_email')}
                type='email'
                placeholder='Ketikan email pelanggan'
                className='input input-bordered w-full max-w-sm'
              />
            )}
          </div>
          <div className='form-control w-full max-w-sm'>
            <label className='label'>
              <span className='label-text'>Nomor Telepon</span>
            </label>
            {isLoading ? (
              <Skeleton height={45} />
            ) : (
              <input
                {...register('customer_phone', {
                  required: 'Nomor Telepon harus diisi',
                })}
                type='text'
                placeholder='Ketikan Nomor Telepon pelanggan'
                className='input input-bordered w-full max-w-sm'
              />
            )}
          </div>
          <div className='form-control w-full max-w-sm'>
            <label className='label'>
              <span className='label-text'>Nomor Whatsapp</span>
            </label>
            {isLoading ? (
              <Skeleton height={45} />
            ) : (
              <input
                {...register('customer_whatsapp', {
                  required: 'Nomor Whatsapp harus diisi',
                })}
                type='text'
                placeholder='Ketikan nomor Whatsapp pelanggan'
                className='input input-bordered w-full max-w-sm'
              />
            )}
          </div>
          <div className='form-control w-full max-w-sm'>
            <label className='label'>
              <span className='label-text'>Status</span>
            </label>
            {isLoading ? (
              <Skeleton height={45} />
            ) : (
              <select
                {...register('customer_status', {
                  required: 'Status harus dipilih',
                })}
                className='select select-bordered'
              >
                <option value='' disabled>
                  Pilih Status Pelanggan
                </option>
                <option value='active'>Aktif</option>
                <option value='non-active'>Non-Aktif</option>
              </select>
            )}
          </div>
          <div className='form-control w-full max-w-sm'>
            <label className='label'>
              <span className='label-text'>Alamat</span>
            </label>
            {isLoading ? (
              <Skeleton height={95} />
            ) : (
              <textarea
                {...register('customer_address')}
                className='textarea textarea-bordered h-24'
                placeholder='Ketikan alamat pelanggan'
              />
            )}
          </div>
        </div>
        <div className='flex flex-row-reverse gap-x-2'>
          <button type='submit' className='btn btn-primary'>
            Simpan
          </button>
          <Link to='/customers' className='btn'>
            Batal
          </Link>
        </div>
      </form>
    </Card>
  );
};
export default CustomerAdd;
