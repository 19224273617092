import Card from "../../../components/card";
import React, {useCallback, useMemo, useState} from "react";
import Table from "../../../components/table/Table";
import {toast} from "react-toastify";
import {createColumnHelper} from "@tanstack/react-table";
import {Link} from "react-router-dom";
import {FiEye} from "react-icons/fi";
import {dateFormat} from "../../../utils/date";
import {convertToRupiah} from "../../../utils/general";

const List = ({ request }) => {
    const today                     = new Date();
    const months                                        = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
    const [isLoading, setIsLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [data, setData]           = useState([]);
    const [totalRow, setTotalRow]   = useState(0);
    const [refresh, setRefresh]     = useState(false);
    const [month, setMonth]         = useState(months[today.getMonth()]);
    const [year, setYear]           = useState(today.getFullYear());
    const [status, setStatus]       = useState('');
    const [statusSubscription, setStatusSubscription]   = useState('');
    const [nominal, setNominal]     = useState(0);

    const monthChange = (event) => {
        setMonth(event.target.value);
        setIsLoading(true)
    };

    const yearChange = (event) => {
        setYear(event.target.value);
        setIsLoading(true)
    };

    const statusChange = (event) => {
        setStatus(event.target.value);
        setIsLoading(true)
    };

    const statusSubscriptionChange = (event) => {
        setStatusSubscription(event.target.value);
        setIsLoading(true)
    };

    const fetchData = useCallback(
        async (pageSize, pageIndex, search) => {
            setIsLoading(true);

            const params = {
                per_page: pageSize,
                page: pageIndex,
                keyword: search,
                month: month,
                year: year,
                status: status,
                subscription_status: statusSubscription,
            };

            try {

                const response = await request.get('/report-invoice', { params });
                const startIndex = response.data.from;

                const updatedData = response.data.data.map((item, index) => ({
                    ...item,
                    listingNumber: startIndex + index,
                }));
                setData(updatedData);
                setPageCount(response.data.last_page);
                setTotalRow(response.data.total);
                setNominal(response.nominal_subscription);
            } catch (err) {
                if (err.response?.data?.errors) {
                    Object.keys(err.response.data.errors).map((field) =>
                        err.response.data.errors[field].map((message) =>
                            toast.error(message)
                        )
                    );
                } else if (err.response?.data?.message) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error(err.message);
                }
            }

            setIsLoading(false);
            setRefresh(false);
        },
        [refresh, month, year, status, statusSubscription] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const columnHelper = createColumnHelper();

    const columns = useMemo(
        () => [
            columnHelper.accessor('listingNumber', {
                header: () => '#',
                cell: (info) => (
                    <div className='text-sm'>{info.row.original.listingNumber}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('customer_name', {
                header: () => 'Pelanggan',
                cell: (info) => (
                    <div>
                        <div className='max-w-xs truncate text-sm'>{info.getValue()}</div>
                        <div className='text-xs'>
                            {info.row.original.c_number_value}
                        </div>
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('subscription_total', {
                header: () => 'Nominal',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>
                        {convertToRupiah(info.getValue(), true)}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('invoice_status', {
                header: () => 'Status Invoice',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-xs'>
                        {info.getValue() === null ? (
                            <span className='badge badge-error'>Belum Generate</span>
                        ) : info.getValue() === 'paid' ? (
                            <span className='badge badge-success'>Terbayar</span>
                        ) : (
                            <span className='badge badge-warning'>Belum Bayar</span>
                        )}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('status', {
                header: () => 'Status Langganan',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-xs'>
                        {info.getValue() === 'active' ? (
                            <span className='badge badge-success'>Active</span>
                        ) : (
                            <span className='badge badge-warning'>Non-Active</span>
                        )}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('subscription_start_date', {
                header: () => 'TGL REGISTRASI',
                cell: (info) => (
                    <div className='max-w-2xl text-sm'>
                        {dateFormat(info.getValue(), 'DD MMM YY')}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('subscription_id', {
                header: () => 'Aksi',
                cell: (info) => (
                    <div className='flex gap-x-1'>
                        <Link
                            to={`/report-subscriptions/${info.getValue()}`}
                            className='font-medium text-success'
                        >
                            <FiEye size={20} />
                        </Link>
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
        ],
        [] // eslint-disable-line react-hooks/exhaustive-deps
    );

    return (
        <Card extra={'w-full sm:overflow-auto p-4'}>
            <div className='flex'>
                <h2 className='mb-1 text-xl font-bold text-secondary flex-1'>
                    Daftar Laporan Langganan
                </h2>
                <div>Total Nominal: {convertToRupiah(nominal, true)}</div>
            </div>
            <div className='overflow-x-scroll xl:overflow-x-hidden'>
                <Table
                    columns={columns}
                    data={data}
                    isLoading={isLoading}
                    fetchData={fetchData}
                    setRefresh={setRefresh}
                    pageCount={pageCount}
                    totalRow={totalRow}
                    filters={() => (
                        <>
                            <select
                                onChange={monthChange}
                                className='select select-sm max-w-xs'
                                value={month}
                            >
                                <option value='' selected>
                                    Bulan
                                </option>
                                <option value='01'>Januari</option>
                                <option value='02'>Februari</option>
                                <option value='03'>Maret</option>
                                <option value='04'>April</option>
                                <option value='05'>Mei</option>
                                <option value='06'>Juni</option>
                                <option value='07'>Juli</option>
                                <option value='08'>Agustus</option>
                                <option value='09'>September</option>
                                <option value='10'>Oktober</option>
                                <option value='11'>November</option>
                                <option value='12'>Desember</option>
                            </select>
                            <select
                                onChange={yearChange}
                                className='select select-sm max-w-xs'
                                value={year}
                            >
                                <option value='' selected>
                                    Tahun
                                </option>
                                <option value='2023'>2023</option>
                                <option value='2024'>2024</option>
                                <option value='2025'>2025</option>
                                <option value='2026'>2026</option>
                                <option value='2027'>2027</option>
                            </select>
                            <select
                                onChange={statusChange}
                                className='select select-sm max-w-xs'
                                value={status}
                            >
                                <option value='' selected>
                                    Status Invoice
                                </option>
                                <option value='belum generate'>Belum Generate</option>
                                <option value='bayar'>Bayar</option>
                                <option value='belum bayar'>Belum Bayar</option>
                            </select>
                            <select
                                onChange={statusSubscriptionChange}
                                className='select select-sm max-w-xs'
                                value={statusSubscription}
                            >
                                <option value='' selected>
                                    Status Langganan
                                </option>
                                <option value='active'>Active</option>
                                <option value='non-actice'>Non-Active</option>
                            </select>
                        </>
                    )}
                />
            </div>
        </Card>
    );
};
export default List;
