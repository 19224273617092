import {  useState } from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import CurrencyInput from 'react-currency-input-field';
import Card from 'components/card';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const Edit = ({ request }) => {
  const params = useParams();
  const navigate = useNavigate();
  const [packages, setPackages] = useState([]);
  const [packageCategories, setPackageCategories] = useState([]);

  const {
    register,
    getValues,
    handleSubmit,
    formState: { isLoading },
  } = useForm({
    // reValidateMode: 'onChange',
    defaultValues: async () => {
      try {
        const data = await request.get(`/package-prices/${params.id}`);

        await request.get('/packages').then((response) => {
          setPackages(response);
        });

        await request.get('/package-categories').then((response) => {
          setPackageCategories(response);
        });
        return {
          package_id: data.package_id,
          p_category_id: data.p_category_id,
          p_price_status: data.p_price_status,
          p_price_value: data.p_price_value,
        };
      } catch (err) {
        if (err.response?.data?.errors) {
          Object.keys(err.response.data.errors).map((field) =>
            err.response.data.errors[field].map((message) =>
              toast.error(message)
            )
          );
        } else if (err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      }
    },
  });

  const submitHandler = async (data, e) => {
    try {
      await request.put(`/package-prices/${params.id}`, data);
      toast.success('Berhasil menyimpan data');
      return navigate('/package-prices');
    } catch (err) {
      if (err.response?.data?.errors) {
        Object.keys(err.response.data.errors).map((field) =>
          err.response.data.errors[field].map((message) => toast.error(message))
        );
      } else if (err.response?.data?.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    }
  };

  const errorHandler = (errors, e) => {
    Object.keys(errors).map((key) => {
      if (Array.isArray(errors[key])) {
        return errors[key].map((err) => {
          return toast.error(err.message);
        });
      }

      return toast.error(errors[key].message);
    });
  };

  return (
    <>
      <Card extra={'w-full sm:overflow-auto p-4'}>
        <h2 className='text-xl font-bold text-navy-700'>Edit Kategori Paket</h2>
        <form
          onSubmit={handleSubmit(submitHandler, errorHandler)}
          className='mt-4 flex flex-col gap-y-1 overflow-x-scroll xl:overflow-x-hidden'
        >
          <div className='grid grid-cols-2'>
            <div className='form-control w-full max-w-sm'>
              <label className='label'>
                <span className='label-text'>Paket</span>
              </label>
              {isLoading ? (
                <Skeleton height={45} />
              ) : (
                <select
                  {...register('package_id', {
                    required: 'Paket harus dipilih',
                  })}
                  className='select select-bordered w-full'
                >
                  <option value='' disabled selected>
                    Pilih Paket
                  </option>
                  {packages.map((item) => (
                    <option key={item.package_id} value={item.package_id}>
                      {item.package_name}
                    </option>
                  ))}
                </select>
              )}
            </div>
            <div className='form-control w-full max-w-sm'>
              <label className='label'>
                <span className='label-text'>Kategori Paket</span>
              </label>
              {isLoading ? (
                <Skeleton height={45} />
              ) : (
                <select
                  {...register('p_category_id', {
                    required: 'Kategori Paket harus dipilih',
                  })}
                  className='select select-bordered w-full'
                >
                  <option value='' disabled selected>
                    Pilih Kategori Paket
                  </option>
                  {packageCategories.map((item) => (
                    <option key={item.p_category_id} value={item.p_category_id}>
                      {item.p_category_name}
                    </option>
                  ))}
                </select>
              )}
            </div>
            <div className='form-control w-full max-w-sm'>
              <label className='label'>
                <span className='label-text'>Status</span>
              </label>
              {isLoading ? (
                <Skeleton height={45} />
              ) : (
                <select
                  {...register('p_price_status', {
                    required: 'Status harus dipilih',
                  })}
                  className='select select-bordered'
                >
                  <option value='' disabled selected>
                    Pilih Status Paket
                  </option>
                  <option value='active'>Aktif</option>
                  <option value='non-active'>Non-Aktif</option>
                </select>
              )}
            </div>
            <div className='form-control w-full max-w-sm'>
              <label className='label'>
                <span className='label-text'>Harga (Termasuk PPN 11%)</span>
              </label>
              {isLoading ? (
                <Skeleton height={45} />
              ) : (
                <CurrencyInput
                  {...register('p_price_value', {
                    required: 'Harga harus diisi',
                  })}
                  prefix='Rp '
                  placeholder='Silahkan masukan harga paket'
                  defaultValue={getValues('p_price_value')}
                  decimalSeparator=','
                  groupSeparator='.'
                  intlConfig={{ locale: 'id-ID', currency: 'IDR' }}
                  className='input input-bordered w-full max-w-sm'
                />
              )}
            </div>
          </div>
          <div className='flex gap-x-2'>
            <button type='submit' className='btn btn-primary'>
              Simpan
            </button>
            <Link to='/package-prices' className='btn'>
              Batal
            </Link>
          </div>
        </form>
      </Card>
    </>
  );
};

export default Edit;
