import React, { useCallback, useMemo, useState } from 'react';
import Card from '../../../components/card';
import { createColumnHelper } from '@tanstack/react-table';
import { convertToRupiah } from '../../../utils/general';
import { dateFormat } from '../../../utils/date';
import { Link } from 'react-router-dom';
import { FiEdit } from 'react-icons/fi';
import { toast } from 'react-toastify';
import Table from '../../../components/table/Table';

const List = ({ request }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [totalRow, setTotalRow] = useState(0);
  const [status, setStatus] = useState('');
  const [refresh, setRefresh] = useState(false);

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const fetchData = useCallback(
    async (pageSize, pageIndex, search) => {
      setIsLoading(true);

      const params = {
        per_page: pageSize,
        page: pageIndex,
        keyword: search,
        status: status,
      };

      try {
        const response = await request.get('/withdraw', { params });
        const startIndex = response.from;

        const updatedData = response.data.map((item, index) => ({
          ...item,
          listingNumber: startIndex + index,
        }));
        setData(updatedData);
        setPageCount(response.last_page);
        setTotalRow(response.total);
      } catch (err) {
        if (err.response?.data?.errors) {
          Object.keys(err.response.data.errors).map((field) =>
            err.response.data.errors[field].map((message) =>
              toast.error(message)
            )
          );
        } else if (err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      }

      setIsLoading(false);
      setRefresh(false);
    },
    [refresh, status] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const columnHelper = createColumnHelper();

  const columns = useMemo(
    () => [
      columnHelper.accessor('listingNumber', {
        header: () => '#',
        cell: (info) => (
          <div className='text-sm'>{info.row.original.listingNumber}</div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('reseller.reseller_name', {
        header: () => 'Nama',
        cell: (info) => (
          <div>
            <div className='max-w-xs truncate text-sm'>{info.getValue()}</div>
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('user.user_name', {
        header: () => 'Update by',
        cell: (info) => (
          <div>
            <div className='max-w-xs truncate text-sm'>{info.getValue()}</div>
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('r_withdraw_amount', {
        header: () => 'Nominal',
        cell: (info) => (
          <div>
            <div className='max-w-xs truncate text-sm'>
              {convertToRupiah(info.getValue(), true)}
            </div>
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('r_withdraw_status', {
        header: () => 'Status',
        cell: (info) => (
          <div>
            <div className='max-w-xs truncate text-sm'>
              {info.getValue() === 'process' ? (
                <span className='badge badge-warning'>{info.getValue()}</span>
              ) : info.getValue() === 'success' ? (
                <span className='badge badge-success'>{info.getValue()}</span>
              ) : info.getValue() === 'cancel' ? (
                <span className='badge badge-error'>{info.getValue()}</span>
              ) : (
                <span className='badge badge-secondary'>{info.getValue()}</span> // Default badge style
              )}
            </div>
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('r_withdraw_update_reason', {
        header: () => 'keterangan',
        cell: (info) => (
          <div>
            <div className='max-w-xs truncate text-sm'>
              {info.getValue() == null ? '-' : info.getValue()}
            </div>
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('r_withdraw_update_date', {
        header: () => 'Tanggal update',
        cell: (info) => (
          <div>
            <div className='max-w-xs truncate text-sm'>
              {info.getValue() == null
                ? '-'
                : dateFormat(info.getValue(), 'DD MMM YY')}
            </div>
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('r_withdraw_create_date', {
        header: () => 'Tanggal Pengajuan',
        cell: (info) => (
          <div>
            <div className='max-w-xs truncate text-sm'>
              {dateFormat(info.getValue(), 'DD MMM YY')}
            </div>
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('r_withdraw_id', {
        header: () => 'Aksi',
        cell: (info) => (
          <div className='flex gap-x-1'>
            <Link
              to={`/withdraw/${info.getValue()}/edit`}
              className='font-medium text-warning'
            >
              <FiEdit size={24} />
            </Link>
          </div>
        ),
        footer: (info) => info.column.id,
      }),
    ],
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Card extra={'w-full sm:overflow-auto p-4 mt-4'}>
      <h2 className='text-xl font-bold text-navy-700'>
        Daftar Marketing Penarikan
      </h2>
      <div className='mt-4 overflow-x-scroll xl:overflow-x-hidden'>
        <Table
          columns={columns}
          data={data}
          isLoading={isLoading}
          fetchData={fetchData}
          setRefresh={setRefresh}
          pageCount={pageCount}
          totalRow={totalRow}
          filters={() => (
            <select
              onChange={handleStatusChange}
              className='select select-sm max-w-xs '
              value={status}
            >
              <option value='' selected>
                Semua Status
              </option>
              <option value='new'>New</option>
              <option value='process'>Proses</option>
              <option value='success'>Sukses</option>
              <option value='cancel'>Cancel</option>
            </select>
          )}
          actions={() => (
            <Link
              className='btn btn-secondary btn-sm'
              to={'/withdraw/penarikan'}
            >
              Penarikan
            </Link>
          )}
        />
      </div>
    </Card>
  );
};

export default List;
