import { Controller } from 'react-hook-form';
import { CKEditor } from '@ckeditor/ckeditor5-react'; // Import CKEditor from the package
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditorUploadAdapter from 'lib/CKEditorUploadAdapter'; // Import ClassicEditor from the package

function Editor({
  onSigning,
  token,
  name,
  defaultValue,
  control,
  required,
  label,
}) {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      // rules={{ required: (required === undefined || typeof required === 'string') ? required : `${label} harus diisi!` }}
      rules={{
        validate: {
          required: (value) => {
            if (value === null)
              return required === undefined || typeof required === 'string'
                ? required
                : `${label} harus diisi!`;
            return true;
          },
        },
      }}
      render={({ field: { onChange, value } }) => (
        <CKEditor
          type=''
          name={name}
          editor={ClassicEditor}
          config={{
            ckfinder: {
              // Upload the images to the server using the CKFinder QuickUpload command
              // You have to change this address to your server that has the ckfinder php connector
              uploadUrl: '/ckeditor/sync-images', // Enter your upload URL
            },
          }}
          onReady={(editor) => {
            editor.plugins.get('FileRepository').createUploadAdapter = (
              loader
            ) => {
              const dec2hex = (dec) => {
                return dec.toString(16).padStart(2, '0');
              };

              const arr = new Uint8Array(20);
              window.crypto.getRandomValues(arr);
              const signature = Array.from(arr, dec2hex).join('');
              onSigning(signature);
              const options = {
                acess_token: token.access_token,
                page_signature: signature,
              };

              return new CKEditorUploadAdapter(loader, options);
            };
            editor.editing.view.change((writer) => {
              writer.setStyle(
                'min-height',
                '400px',
                editor.editing.view.document.getRoot()
              );
            });
          }}
          data={value}
          onChange={(event, editor) => {
            onChange(editor.getData());
          }}
        />
      )}
    />
  );
}

export default Editor;
