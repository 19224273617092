import React, {useState, useEffect, useCallback} from 'react';
import { dateFormat, dateNow } from "../../../../utils/date";
import { Button, ButtonGroup, Card, CardBody, CardHeader, Spinner } from "reactstrap";
import Select from 'react-select';
import Loader from "react-loaders";
import Chart from 'react-apexcharts';
import {toast} from "react-toastify";

const minOffset = 0;
const maxOffset = new Date().getFullYear() - 2018;

const CustomerMonth = ({ props }) => {
    const thisYear = (new Date()).getFullYear();

    const [tahun, setTahun] = useState(dateFormat(dateNow(), "YYYY"));
    const [isLoading, setIsLoading] = useState(true);
    const [customerMonth, setCustomerMonth] = useState([]);
    const [customerCountMonth, setCustomerCountMonth] = useState([]);
    const [customerStatusActive, setCustomerStatusActive] = useState([]);
    const [customerStatusNonActive, setCustomerStatusNonActive] = useState([]);

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await props.request.get(`/grafik-customer?type=month&tahun=${tahun}`);
            let customerMonth = [];
            let count = [];
            let statusActive = [];
            let statusNonActive = [];
            response.data.forEach(element => {
                customerMonth.push(element.month);
                count.push(element.count);
                statusActive.push(element.active);
                statusNonActive.push(element.non_active);
            });
            setCustomerMonth(customerMonth);
            setCustomerCountMonth(count);
            setCustomerStatusActive(statusActive);
            setCustomerStatusNonActive(statusNonActive);
        } catch (err) {
            handleErrors(err);
        }
        setIsLoading(false);
    }, [tahun, props.request]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const refresh = () => {
        fetchData();
    };

    const handleErrors = (err) => {
        if (err.response?.data?.errors) {
            Object.keys(err.response.data.errors).forEach((field) =>
                err.response.data.errors[field].forEach((message) => toast.error(message))
            );
        } else if (err.response?.data?.message) {
            toast.error(err.response.data.message);
        } else {
            toast.error(err.message);
        }
    };

    // Handle year change
    const handleChange = (selectedOption) => {
        setTahun(selectedOption.value);
        setIsLoading(true);
    };

    // Dropdown options
    const options = [];
    for (let i = minOffset; i <= maxOffset; i++) {
        const year = thisYear - i;
        options.push({ value: year, label: year });
    }

    const optio = {
        chart: {
            toolbar: { show: false },
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            },
        },
        dataLabels: { enabled: false },
        stroke: {
            show: true,
            width: 2,
        },
        labels: customerMonth,
        yaxis: [{
            title: {
                text: 'Total Customer',
            },
            labels: {
                formatter: function (value) {
                    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                }
            },
        }],
        fill: { opacity: 1 },
    };

    const series = [
        { name: 'Total Customer',
            type: 'bar',
            data: customerCountMonth,
            color: '#f78a2a',
        },
        { name: 'Pelanggan Active',
            type: 'bar',
            data: customerStatusActive,
            color: '#37bd75',
        },
        { name: 'Pelanggan Non-Active',
            type: 'bar',
            data: customerStatusNonActive,
            color: '#ff405a',
        },
    ];

    return (
        <Card className="p-5 bg-white">
            <CardHeader className="flex flex-col md:flex-row justify-between pb-4">
                <div className="flex flex-col md:flex-row items-start md:items-center card-header-title font-size-lg text-capitalize font-weight-normal">
                    <span className="content-center">Customer</span>
                    <span className="mt-2 md:mt-0 md:ml-2 w-full md:w-auto" style={{ width: 200 }}>
                <Select
                    value={options.find(option => option.value === tahun)}
                    onChange={handleChange}
                    defaultValue={options[0]}
                    options={options}
                />
            </span>
                </div>
                <div className="btn-actions-pane-right mt-4 md:mt-0">
                    <ButtonGroup size="sm">
                        <Button color="warning" className="btn-shadow bg-primary" disabled={isLoading} onClick={refresh}>
                            {isLoading ? (
                                <span>
                            <Spinner size="sm" color="secondary" /> Please wait...
                        </span>
                            ) : (
                                <span>
                            <i className="pe-7s-refresh-2"></i> Refresh
                        </span>
                            )}
                        </Button>
                    </ButtonGroup>
                </div>
            </CardHeader>
            <CardBody className="pt-0">
                {isLoading ? (
                    <div className="loader-overlay">
                        <Loader active type='ball-beat' />
                    </div>
                ) : (
                    <div className="bar mt-4" style={{ marginTop: '-20px' }}>
                        <Chart options={optio} series={series} type="bar" width="100%" height="325px" />
                    </div>
                )}
            </CardBody>
        </Card>

    );
};

export default CustomerMonth;
