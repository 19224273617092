import { Route, Routes } from 'react-router-dom';
import Component from './components';

const Setting =(props)=>{

    return (
        <Routes>
            <Route path='/' element={<Component props={props} />} />
        </Routes>
    );
};
export default  Setting;