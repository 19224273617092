import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Card from 'components/card';
import { formatDate } from 'utils/date';
import Skeleton from 'react-loading-skeleton';

function isCoverageComplete(submission) {
  const customer = submission.customer;
  const zone = submission.zone;

  if (customer && zone) {
    return (
      customer.province_id === zone.province_id &&
      customer.district_id === zone.district_id &&
      customer.subdistrict_id === zone.subdistrict_id &&
      customer.ward_id === zone.ward_id &&
      customer.hamlet_id === zone.hamlet_id
    );
  }

  return false;
}

const General = ({ submission, loading, request, setRefresh }) => {
  const [isRejecting, setIsRejecting] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [rejectedButtonDisabled, setRejectedButtonDisabled] = useState(false);
  const [approvedButtonDisabled, setApprovedButtonDisabled] = useState(false);

  const { register, handleSubmit } = useForm();

  const confirmReject = async (data) => {
    setIsRejecting(true);
    try {
      await request.patch(
        `/submissions/${submission.submission_id}/reject`,
        data
      );
      toast.success('Pengajuan telah ditolak');
      setRejectedButtonDisabled(true);
      window.confirm_reject_modal.close();
    } catch (err) {
      if (err.response?.data?.errors) {
        Object.keys(err.response.data.errors).map((field) =>
          err.response.data.errors[field].map((message) => toast.error(message))
        );
      } else if (err.response?.data?.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    } finally {
      setIsRejecting(false);
      setRefresh(true);
    }
  };

  const confirmApprove = async () => {
    setIsApproving(true);
    try {
      await request.patch(`/submissions/${submission.submission_id}/approve`);
      toast.success('Pengajuan telah disetujui');
      setApprovedButtonDisabled(false);
    } catch (err) {
      if (err.response?.data?.errors) {
        Object.keys(err.response.data.errors).map((field) =>
          err.response.data.errors[field].map((message) => toast.error(message))
        );
      } else if (err.response?.data?.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    } finally {
      setIsApproving(false);
      setRefresh(true);
    }
  };

  const handleReject = () => {
    window.confirm_reject_modal.showModal();
  };

  const handleCancelReject = () => {
    window.confirm_reject_modal.close();
  };

  const handleApprove = () => {
    window.confirm_approve_modal.showModal();
  };

  const errorHandler = (errors, e) => {
    Object.keys(errors).map((key) => {
      if (Array.isArray(errors[key])) {
        return errors[key].map((err) => {
          return toast.error(err.message);
        });
      }

      return toast.error(errors[key].message);
    });
  };

  return (
    <>
      <Card extra={'w-full h-full p-3 rounded-[20px]'}>
        {/* Header */}
        <div className='mt-2 mb-2 w-full'>
          <h4 className='px-2 text-xl font-bold text-navy-700'>
            Ringkasan Informasi
          </h4>
          <p className='mt-2 px-2 text-base text-gray-600'>
            Informasi umum pengajuan
          </p>
        </div>
        <div className='flex h-full flex-col justify-between'>
          <div className='grid grid-cols-2 gap-2 px-2'>
            <div className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
              <p className='text-sm text-gray-600'>Nama</p>
              {!loading != null ? (
                <p className='text-base font-medium text-navy-700'>
                  {submission.customer?.customer_name || '-'}
                </p>
              ) : (
                <Skeleton height={29} width={100} />
              )}
            </div>
            <div className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
              <p className='text-sm text-gray-600'>Email</p>
              {!loading != null ? (
                <p className='overflow-hidden overflow-ellipsis text-base font-medium text-navy-700'>
                  {submission.customer?.customer_email || '-'}
                </p>
              ) : (
                <Skeleton height={29} width={100} />
              )}
            </div>
            <div className='flex flex-col justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
              <p className='text-sm text-gray-600'>WhatsApp</p>
              {!loading != null ? (
                <p className='text-base font-medium text-navy-700'>
                  {submission.customer?.customer_whatsapp || '-'}
                </p>
              ) : (
                <Skeleton height={29} width={100} />
              )}
            </div>
            <div className='flex flex-col justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
              <p className='text-sm text-gray-600'>Referer</p>
              {!loading != null ? (
                <p className='text-base font-medium text-navy-700'>
                  {submission.customer?.customer_ref_code || '-'}
                </p>
              ) : (
                <Skeleton height={29} width={100} />
              )}
            </div>
            <div className='roundedl flex flex-col items-start justify-center bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
              <p className='text-sm text-gray-600'>Provinsi</p>
              {!loading != null ? (
                <p className='text-base font-medium text-navy-700'>
                  {submission.province?.province_name || '-'}
                </p>
              ) : (
                <Skeleton height={29} width={100} />
              )}
            </div>
            <div className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
              <p className='text-sm text-gray-600'>Kabupaten</p>
              {!loading != null ? (
                <p className='text-base font-medium text-navy-700'>
                  {submission.district?.district_name || '-'}
                </p>
              ) : (
                <Skeleton height={29} width={100}/>
              )}
            </div>
            <div className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
              <p className='text-sm text-gray-600'>Kecamatan</p>
              {!loading != null ? (
                <p className='text-base font-medium text-navy-700'>
                  {submission.subdistrict?.subdistrict_name || '-'}
                </p>
              ) : (
                <Skeleton height={29} width={100} />
              )}
            </div>
            <div className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
              <p className='text-sm text-gray-600'>Kelurahan</p>
              {!loading != null ? (
                <p className='text-base font-medium text-navy-700'>
                  {submission.ward?.ward_name || '-'}
                </p>
              ) : (
                <Skeleton height={29} width={100} />
              )}
            </div>
            <div className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
              <p className='text-sm text-gray-600'>Dusun</p>
              {!loading != null ? (
                <p className='text-base font-medium text-navy-700'>
                  {submission.hamlet?.hamlet_name || '-'}
                </p>
              ) : (
                <Skeleton height={29} width={100} />
              )}
            </div>
            <div className='flex flex-col justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
              <p className='text-sm text-gray-600'>Tanggal Daftar</p>
              {!loading != null ? (
                <p className='text-base font-medium text-navy-700'>
                  {formatDate(submission.customer?.customer_create_date) || '-'}
                </p>
              ) : (
                <Skeleton height={29} width={100} />
              )}
            </div>
            <div className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
              <p className='text-sm text-gray-600'>Status Jangkauan</p>
              {!loading !== null ? (
                <p className='text-base font-medium text-navy-700'>
                  {isCoverageComplete(submission)
                    ? 'Terjangkau'
                    : 'Belum Terjangkau'}
                </p>
              ) : (
                <Skeleton height={29} width={100} />
              )}
            </div>
            <div className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
              <p className='text-sm text-gray-600'>Jangkauan</p>
              {!loading != null ? (
                <p className='text-base font-medium text-navy-700'>
                  {submission.zone?.coverage_name || '-'}
                </p>
              ) : (
                <Skeleton height={29} width={100} />
              )}
            </div>
          </div>
          <div className='grid grid-cols-2 gap-2 px-2'>
            {submission.submission_status === 'new' ? (
              <div className='col-span-2 grid grid-cols-2 gap-x-2'>
                <button
                  onClick={() => handleApprove()}
                  className={`btn btn-success${
                    approvedButtonDisabled ? ' btn-disabled' : ''
                  }`}
                  disabled={isApproving || approvedButtonDisabled}
                >
                  {isApproving
                    ? 'Menyetujui...'
                    : approvedButtonDisabled
                    ? 'Disetujui'
                    : 'Setujui'}
                </button>
                <button
                  onClick={() => handleReject()}
                  className={`btn btn-accent${
                    rejectedButtonDisabled ? ' btn-disabled' : ''
                  }`}
                  disabled={isRejecting || rejectedButtonDisabled}
                >
                  {isRejecting
                    ? 'Menolak...'
                    : rejectedButtonDisabled
                    ? 'Ditolak'
                    : 'Tolak'}
                </button>
              </div>
            ) : (
              submission?.installation?.installation_status === 'waiting' && (
                <div className='col-span-2 grid grid-cols-2 gap-x-2'>
                  <button
                    onClick={() => handleReject()}
                    className={`btn btn-error${
                      rejectedButtonDisabled ? ' btn-disabled' : ''
                    }`}
                    disabled={isRejecting || rejectedButtonDisabled}
                  >
                    {isRejecting
                      ? 'Membatalkan...'
                      : rejectedButtonDisabled
                      ? 'Dibatalkan'
                      : 'Batalkan Persetujuan'}
                  </button>
                </div>
              )
            )}
          </div>
        </div>
      </Card>
      <dialog
        id='confirm_approve_modal'
        className='modal modal-bottom sm:modal-middle'
      >
        <form method='dialog' className='modal-box'>
          <h3 className='text-lg font-bold'>Konfirmasi Persetujuan</h3>
          <p className='py-4'>
            Anda yakin ingin menyetujui pengajuan, proses instalasi akan
            dilakukan!
          </p>
          <div className='modal-action'>
            {/* if there is a button in form, it will close the modal */}
            <button className='btn btn-success' onClick={confirmApprove}>
              Konfirmasi
            </button>
            <button className='btn'>Batal</button>
          </div>
        </form>
      </dialog>
      <dialog
        id='confirm_reject_modal'
        className='modal modal-bottom sm:modal-middle'
      >
        <form
          onSubmit={handleSubmit(confirmReject, errorHandler)}
          method='dialog'
          className='modal-box'
        >
          <h3 className='text-lg font-bold'>Konfirmasi Penolakan</h3>
          <p className='py-4'>
            Anda yakin ingin menolak pengajuan, proses instalasi tidak akan
            dilakukan!
          </p>
          <div className='form-control'>
            <label className='label'>
              <span className='label-text'>Alasan Penolakan</span>
            </label>
            <textarea
              {...register('submission_reject_reason', {
                required: 'Alasan Penolakan harus diisi',
              })}
              className='textarea textarea-bordered h-32 w-full'
              placeholder='Masukan alasan penolakan'
            />
          </div>
          <div className='modal-action'>
             {/*if there is a button in form, it will close the modal */}
            <button type='submit' className='btn btn-accent'>
              Konfirmasi
            </button>
            <button type='button' className='btn' onClick={handleCancelReject}>
              Batal
            </button>
          </div>
        </form>
      </dialog>
    </>
  );
};

export default General;
