import React from 'react';
// import avatar from 'assets/img/avatars/avatar11.png';
import banner from 'assets/img/profile/banner.png';
import Card from 'components/card';
import AvatarAlpabet from 'components/avatar/AvatarAlpabet';
import Skeleton from 'react-loading-skeleton';
import appConfig from 'config/app';

const ShortDesc = ({ customer, loading }) => {
  return (
    <Card extra={'items-center w-full h-full p-[16px] bg-cover'}>
      {/* Background and profile */}
      <div
        className='relative mt-1 flex h-32 w-full justify-center rounded-xl bg-cover'
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className='absolute -bottom-12 flex h-[87px] w-[87px] items-center justify-center rounded-full border-[4px] border-white bg-pink-400 dark:!border-navy-700'>
          {customer.customer_photo ? (
            <img
              style={{ objectFit: 'cover' }}
              className='h-full w-full rounded-full'
              src={
                appConfig.storageServiceUrl +
                '/images/' +
                customer.customer_photo
              }
              alt=''
            />
          ) : (
            <AvatarAlpabet customer_name={customer.customer_name} />
          )}
        </div>
      </div>

      {/* Name and position */}
      <div className='mt-16 flex flex-col items-center'>
        {!loading ? (
          <h4 className='text-xl font-bold text-navy-700 dark:text-white'>
            {customer.customer_name}
          </h4>
        ) : (
          <Skeleton height={29} width={140} />
        )}
        {!loading ? (
          <p className='text-base font-normal text-gray-600'>
            ({customer.customer_code})
          </p>
        ) : (
          <Skeleton height={29} width={120} />
        )}
      </div>

      {/* Post followers */}
      <div className='mt-6 mb-3 flex gap-4 md:!gap-14'>
        <div className='flex flex-col items-center justify-center'>
          {!loading ? (
            <p className='text-2xl font-bold text-navy-700 dark:text-white'>
              {customer.subscriptions_count}
            </p>
          ) : (
            <Skeleton height={29} width={50} />
          )}
          <p className='text-sm font-normal text-gray-600'>Langganan</p>
        </div>
        <div className='flex flex-col items-center justify-center'>
          {!loading ? (
            <p className='text-2xl font-bold text-navy-700 dark:text-white'>
              {customer.transactions_count}
            </p>
          ) : (
            <Skeleton height={29} width={50} />
          )}
          <p className='text-sm font-normal text-gray-600'>Transaksi</p>
        </div>
        <div className='flex flex-col items-center justify-center'>
          {!loading ? (
            <p className='text-2xl font-bold text-navy-700 dark:text-white'>
              {customer.referral_count}
            </p>
          ) : (
            <Skeleton height={29} width={50} />
          )}
          <p className='text-sm font-normal text-gray-600'>Referral</p>
        </div>
      </div>
    </Card>
  );
};

export default ShortDesc;
