import {Link, useNavigate} from "react-router-dom";
import Card from "../../../components/card";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import Select from "react-select";
import {useState} from "react";
import CurrencyInput from "react-currency-input-field";

const Withdraw = ({ request }) => {
    const navigate                              = useNavigate();
    const { register, handleSubmit, setValue  } = useForm();
    const [selectReseller, setSelectReseller]   = useState(null);
    const [isLoading, setIsLoading]             = useState(false);
    const [data, setData]                       = useState([]);

    const handleChangeSelect = (selectedOption) => {
        if (!selectedOption) {
            setSelectReseller(null);
            setValue('reseller_id','');
        } else {
            setSelectReseller(selectedOption);
            setValue('reseller_id',selectedOption.value);
        }
    };

    const loadData = async (search) => {
        const params = {
            per_page: 5,
            page    : 1,
            keyword : search,
        };
        try {
            const response = await request.get('/resellers', { params });
            const startIndex = response.from;
            const updatedData = response.data.map((item, index) => ({
                ...item,
                listingNumber: startIndex + index,
            }));
            setData(updatedData);
        } catch (error) {
            console.error('Error loading data:', error);
            toast.error('Error loading data. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const submitHandler = async (data) => {
        try {
            await request.post('/withdraw', data);
            toast.success('Berhasil melakukan penarikan');
            return navigate('/withdraw');
        } catch (err) {
            if (err.response?.data?.errors) {
                Object.keys(err.response.data.errors).map((field) =>
                    err.response.data.errors[field].map((message) => toast.error(message))
                );
            } else if (err.response?.data?.message) {
                toast.error(err.response.data.message);
            } else {
                toast.error(err.message);
            }
        }
    };

    const errorHandler = (errors, e) => {
        Object.keys(errors).map((key) => {
            if (Array.isArray(errors[key])) {
                return errors[key].map((err) => {
                    return toast.error(err.message);
                });
            }

            return toast.error(errors[key].message);
        });
    };

    return(
        <Card extra={'w-full sm:overflow-auto p-4'}>
            <h2 className='text-xl font-bold text-navy-700'>Penarikan Bonus Reseller</h2>
            <form
                onSubmit={handleSubmit(submitHandler, errorHandler)}
                className='mt-4 flex flex-col gap-y-1 overflow-x-scroll xl:overflow-x-hidden'
            >
                <div className='grid grid-cols-2'>
                    <div className='form-control w-full max-w-sm'>
                        <label className='label'>
                            <span className='label-text'>Nama Marketing</span>
                        </label>
                        <Select
                            {...register('reseller_id', {required: 'Nama marketing harus dipilih',})}
                            value={selectReseller}
                            isLoading={isLoading}
                            onChange={handleChangeSelect}
                            onKeyDown={
                                event=>{
                                    if(event.key.length > 0){
                                        loadData(event.target.valueOf().value)
                                    }
                                }
                            }
                            options={
                                data.length !== null
                                    ? data.map((item, index) => ({
                                        key: index,
                                        label: `${item.reseller_name}`,
                                        value: item.reseller_id,
                                    }))
                                    : []
                            }
                            isClearable={true}

                        />
                    </div>
                    <div className='form-control w-full max-w-sm'>
                        <label className='label'>
                            <span className='label-text'>Nominal Penarikan</span>
                        </label>
                        <CurrencyInput
                            {...register('r_withdraw_amount', {
                                required: 'Nominal penarikan harus diisi',
                            })}
                            prefix='Rp '
                            placeholder='Silahkan masukan harga Biaya Pemasangan'
                            defaultValue={0}
                            decimalSeparator=','
                            groupSeparator='.'
                            intlConfig={{ locale: 'id-ID', currency: 'IDR' }}
                            className='input input-bordered w-full max-w-sm'
                        />
                    </div>
                </div>
                <div className='flex gap-x-2'>
                    <button type='submit' className='btn btn-primary'>
                        Simpan
                    </button>
                    <Link to='/withdraw' className='btn'>
                        Batal
                    </Link>
                </div>
            </form>
        </Card>
    );
};

export default Withdraw;
