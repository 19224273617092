import { Route, Routes } from 'react-router-dom';
import List from './components/List';
import Edit from "./components/Edit";
import Add from "./components/Add";

const InstallationsPrice = (props) => {
    return (
        <Routes>
            <Route path='/' element={<List {...props} />} />
            <Route path='/:id/Edit' element={<Edit {...props} />} />
            <Route path='/Add' element={<Add {...props} />} />
        </Routes>
    );
};

export default InstallationsPrice;
