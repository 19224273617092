import { useMemo, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FiEdit } from 'react-icons/fi';
import { BsTrash2 } from 'react-icons/bs';
import { createColumnHelper } from '@tanstack/react-table';
import Table from 'components/table/Table';
import Card from 'components/card';
import { dateFormat } from 'utils/date';

const List = ({ request }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [totalRow, setTotalRow] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [deletionId, setDeletionId] = useState(null);

  const fetchData = useCallback(
    async (pageSize, pageIndex, search) => {
      setIsLoading(true);
      const params = {
        per_page: pageSize,
        page: pageIndex,
        keyword: search,
      };

      try {
        const response = await request.get('/package-categories', { params });
        const startIndex = response.from;
        const updatedData = response.data.map((item, index) => ({
          ...item,
          listingNumber: startIndex + index,
        }));
        setData(updatedData);
        setPageCount(response.last_page);
        setTotalRow(response.total);
      } catch (err) {
        if (err.response?.data?.errors) {
          Object.keys(err.response.data.errors).map((field) =>
            err.response.data.errors[field].map((message) =>
              toast.error(message)
            )
          );
        } else if (err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      }

      setIsLoading(false);
      setRefresh(false);
    },
    [refresh] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const columnHelper = createColumnHelper();

  const columns = useMemo(
    () => [
      columnHelper.accessor('listingNumber', {
        header: () => '#',
        cell: (info) => (
          <div className='text-sm'>{info.row.original.listingNumber}</div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('p_category_name', {
        header: () => 'Nama',
        cell: (info) => (
          <div className='max-w-2xl truncate text-sm'>{info.getValue()}</div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('p_category_status', {
        header: () => 'Status',
        cell: (info) => (
          <div className='max-w-2xl truncate text-sm'>
            {info.getValue() === 'active' ? (
              <span className='badge badge-success'>{info.getValue()}</span>
            ) : (
              <span className='badge badge-error'>{info.getValue()}</span>
            )}
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('p_category_create_date', {
        header: () => 'Tanggal Buat',
        cell: (info) => (
          <div className='max-w-2xl truncate text-sm'>
            {dateFormat(info.getValue(), 'DD MMM YYYY H.mm')}
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('action', {
        header: () => 'Aksi',
        cell: (info) => (
          <div className='flex'>
            <Link
              to={`/package-categories/${info.row.original.p_category_id}/edit`}
              className='font-medium text-warning'
            >
              <FiEdit size={24} />
            </Link>
            <button
              disabled={!info.row.original.p_category_deletable}
              onClick={() => handleDelete(info.row.original.p_category_id)}
              className='font-medium text-accent disabled:opacity-25'
            >
              <BsTrash2 size={24} />
            </button>
          </div>
        ),
        footer: (info) => info.column.id,
        enableSorting: false,
        enableGrouping: false,
      }),
    ],
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleDelete = (id) => {
    setDeletionId(id);
    window.confirm_delete_modal.showModal();
  };

  const confirmDelete = async () => {
    try {
      await request.delete(`/package-categories/${deletionId}`);
      toast.success('Berhasil menghapus data');
    } catch (err) {
      if (err.response?.data?.errors) {
        Object.keys(err.response.data.errors).map((field) =>
          err.response.data.errors[field].map((message) => toast.error(message))
        );
      } else if (err.response?.data?.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    }
    setRefresh(true);
  };

  return (
    <>
      <Card extra={'w-full sm:overflow-auto p-4'}>
        <h2 className='mb-1 text-xl font-bold text-secondary'>
          Daftar Kategori Paket
        </h2>
        <div className='overflow-x-scroll xl:overflow-x-hidden'>
          <Table
            columns={columns}
            data={data}
            isLoading={isLoading}
            fetchData={fetchData}
            setRefresh={setRefresh}
            pageCount={pageCount}
            totalRow={totalRow}
            actions={() => (
              <Link
                to='/package-categories/create'
                type='button'
                className='btn btn-secondary btn-sm'
              >
                Tambah
              </Link>
            )}
          />
        </div>
      </Card>
      <dialog
        id='confirm_delete_modal'
        className='modal modal-bottom sm:modal-middle'
      >
        <form method='dialog' className='modal-box'>
          <h3 className='text-lg font-bold'>Konfirmasi Penghapusan</h3>
          <p className='py-4'>
            Anda yakin ingin menghapus data, tindakan ini tidak dapat
            dikembalikan!
          </p>
          <div className='modal-action'>
            <button className='btn btn-accent' onClick={confirmDelete}>
              Konfirmasi
            </button>
            <button className='btn'>Batal</button>
          </div>
        </form>
      </dialog>
    </>
  );
};

export default List;
