import React, {useMemo, useState, useCallback} from 'react';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import {FiEdit} from 'react-icons/fi';
import {createColumnHelper} from '@tanstack/react-table';
import Table from 'components/table/Table';
import Card from 'components/card';
import {dateFormat} from 'utils/date';
import {convertToRupiah} from '../../../utils/general';
import Datepicker from 'react-tailwindcss-datepicker';

const List = ({request}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [totalRow, setTotalRow] = useState(0);
    const [refresh, setRefresh] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState('');
    const [pembaharuan, setPembaharuan] = useState('');

    const [value, setValue] = useState({
        startDate: null,
        endDate: null,
    });

    const handleChangePembaharuan = (event) => {
        setPembaharuan(event.target.value);
    };

    const handleValueChange = (newValue) => {
        setValue(newValue);
    };

    const selectDay = [
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23',
        '24',
        '25',
        '26',
        '27',
        '28',
    ];

    const handleStatusChange = (event) => {
        setSelectedStatus(event.target.value);
    };
    const fetchData = useCallback(
        async (pageSize, pageIndex, search) => {
            setIsLoading(true);
            const params = {
                per_page: pageSize,
                page: pageIndex,
                keyword: search,
                subscription_status: selectedStatus,
                start_date: value?.startDate,
                end_date: value?.endDate,
                date_invoice: pembaharuan,
            };
            try {
                const response = await request.get('/subscriptions', {params});
                const startIndex = response.from;
                const updatedData = response.data.map((item, index) => ({
                    ...item,
                    listingNumber: startIndex + index,
                }));
                setData(updatedData);
                setPageCount(response.last_page);
                setTotalRow(response.total);
            } catch (err) {
                if (err.response?.data?.errors) {
                    Object.keys(err.response.data.errors).map((field) =>
                        err.response.data.errors[field].map((message) =>
                            toast.error(message)
                        )
                    );
                } else if (err.response?.data?.message) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error(err.message);
                }
            }

            setIsLoading(false);
            setRefresh(false);
        },
        [refresh, selectedStatus, value, pembaharuan] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const columnHelper = createColumnHelper();

    const columns = useMemo(
        () => [
            columnHelper.accessor('listingNumber', {
                header: () => '#',
                cell: (info) => (
                    <div className='text-sm'>{info.row.original.listingNumber}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('customer_number.customer.customer_name', {
                header: () => 'Pelanggan',
                cell: (info) => (
                    <div>
                        <div className='max-w-xs truncate text-sm'>{info.getValue()}</div>
                        <div className='text-xs'>
                            {info.row.original.customer_number.c_number_value}
                        </div>
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('customer_number.customer.reseller.reseller_name', {
                header: () => 'Marketing',
                cell: (info) => (
                    <div
                        className='max-w-xs truncate text-sm'>{info.getValue() === undefined ? '-' : info.getValue()}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('package.package_name', {
                header: () => 'Paket',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>{info.getValue()}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('subscription_fee', {
                header: () => 'Biaya',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>
                        {convertToRupiah(info.getValue(), true)}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('subscription_invoice_day', {
                header: () => 'tanggal pembaharuan',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>
                        {info.getValue()}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('subscription_start_date', {
                header: () => 'Tanggal Mulai',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>
                        {dateFormat(info.getValue(), 'DD MMM YYYY H.mm')}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('subscription_expiry_date', {
                header: () => 'Tanggal Expired',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>
                        {dateFormat(info.getValue(), 'DD MMM YYYY H.mm')}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('subscription_status', {
                header: () => 'Status',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>
                        {info.getValue() === 'active' ? (
                            <span className='badge badge-success'>{info.getValue()}</span>
                        ) : info.getValue() === 'non-active' ? (
                            <span className='badge badge-error'>{info.getValue()}</span>
                        ) : info.getValue() === 'pending' ? (
                            <span className='badge badge-warning'>Libur Langganan</span>
                        ) : info.getValue() === 'waiting-payment' ? (
                            <span className='badge badge-info'>Menunggu Pembayaran</span>
                        ) : (
                            <span className='badge badge-accent'>{info.getValue()}</span>
                        )}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('action', {
                header: () => 'Aksi',
                cell: (info) => (
                    <>
                        <Link
                            to={`/subscriptions/${info.row.original.subscription_id}/edit`}
                            className='font-medium text-warning'
                        >
                            <FiEdit size={24}/>
                        </Link>
                    </>
                ),
                footer: (info) => info.column.id,
                enableSorting: false,
                enableGrouping: false,
            }),
        ],
        [] // eslint-disable-line react-hooks/exhaustive-deps
    );

    return (
        <>
            <Card extra={'w-full sm:overflow-auto p-4 mt-4'}>
                <h2 className='text-xl font-bold text-navy-700'>Daftar Langganan</h2>
                <div className='mt-4 overflow-x-scroll xl:overflow-x-hidden'>
                    <Table
                        columns={columns}
                        data={data}
                        isLoading={isLoading}
                        fetchData={fetchData}
                        setRefresh={setRefresh}
                        pageCount={pageCount}
                        totalRow={totalRow}
                        filters={() => (
                            <>
                                <select
                                    onChange={handleStatusChange}
                                    className='select select-sm max-w-xs'
                                    value={selectedStatus}
                                >
                                    <option value='' selected>
                                        Semua Status
                                    </option>
                                    <option value='active'>Active</option>
                                    <option value='non-active'>Non-Active</option>
                                    <option value='pending'>Libur Langganan</option>
                                    <option value='waiting-payment'>Menunggu Pembayaran</option>
                                    <option value='expired'>Expired</option>
                                </select>
                                <Datepicker
                                    toggleClassName='relative left-2 right-0 h-full px-1 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed'
                                    containerClassName='input input-sm relative flex bg-white justify-self-start'
                                    inputClassName='outline-none w-full md:w-48 transition-all duration-300 font-light text-sm placeholder-gray-400'
                                    value={value}
                                    onChange={handleValueChange}
                                    showShortcuts={true}
                                    placeholder='Pilih Tanggal Registrasi'
                                />
                                <select
                                    onChange={handleChangePembaharuan}
                                    className='select select-sm max-w-xs'
                                    value={pembaharuan}
                                >
                                    <option value='' selected>
                                        Semua Pembaharuan
                                    </option>
                                    {selectDay.map((item, index) => (
                                        <option key={index} value={item}>
                                            {item}
                                        </option>
                                    ))}
                                </select>

                            </>
                        )}
                        actions={() => (
                            <Link
                                to='/subscriptions/add'
                                type='button'
                                className='btn btn-secondary btn-sm w-full md:w-fit'
                            >
                                Tambah
                            </Link>
                        )}
                    />
                </div>
            </Card>
        </>
    );
};
export default List;
