import Card from "../../../components/card";
import React, {useCallback, useMemo, useState} from "react";
import { useParams} from "react-router-dom";
import Table from "../../../components/table/Table";
import {toast} from "react-toastify";
import {createColumnHelper} from "@tanstack/react-table";
import {dateFormat} from "../../../utils/date";
const ReportDetail = ({ request }) => {
    const param = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [totalRow, setTotalRow] = useState(0);
    const [refresh, setRefresh] = useState(false);
    const [invoiceStatus, setInvoiceStatus] = useState('');

    const handleInvoiceStatusChange = (event) => {
        setInvoiceStatus(event.target.value);
    };

    const fetchData = useCallback(
        async (pageSize, pageIndex, search) => {
            setIsLoading(true);

            const params = {
                per_page: pageSize,
                page: pageIndex,
                keyword: search,
                invoice_status: invoiceStatus,
                subscription_id: param.id,
            };

            try {

                const response = await request.get('/invoices', { params });
                const startIndex = response.from;

                const updatedData = response.data.map((item, index) => ({
                    ...item,
                    listingNumber: startIndex + index,
                }));
                setData(updatedData);
                setPageCount(response.last_page);
                setTotalRow(response.total);
            } catch (err) {
                if (err.response?.data?.errors) {
                    Object.keys(err.response.data.errors).map((field) =>
                        err.response.data.errors[field].map((message) =>
                            toast.error(message)
                        )
                    );
                } else if (err.response?.data?.message) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error(err.message);
                }
            }

            setIsLoading(false);
            setRefresh(false);
        },
        [refresh, invoiceStatus ] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const columnHelper = createColumnHelper();

    const columns = useMemo(
        () => [
            columnHelper.accessor('listingNumber', {
                header: () => '#',
                cell: (info) => (
                    <div className='text-sm'>{info.row.original.listingNumber}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('customer.customer_name', {
                header: () => 'Pelanggan',
                cell: (info) => (
                    <div>
                        <div className='max-w-xs truncate text-sm'>{info.getValue()}</div>
                        <div className='text-xs'>
                            {info.row.original.subscription.customer_number.c_number_value}
                        </div>
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('invoice_no', {
                header: () => 'Nomor Invoice',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>{info.getValue()}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('invoice_periods', {
                header: () => 'Periode',
                cell: (info) => (
                    <div className='min-w-max max-w-2xl text-sm'>{info.getValue()}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('invoice_issue_date', {
                header: () => <div className='min-w-max max-w-2xl'>Jatuh Tempo</div>,
                cell: (info) => (
                    <div className='min-w-max max-w-2xl text-sm'>
                        {dateFormat(info.getValue(), 'DD MMM YY')}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('invoice_status', {
                header: () => 'Status',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-xs'>
                        {info.getValue() === 'draft' ? (
                            <span className='badge badge-warning'>Belum Lunas</span>
                        ) : info.getValue() === 'paid' ? (
                            <span className='badge badge-success'>Lunas</span>
                        ) : info.getValue() === 'overdue' ? (
                            <span className='badge badge-error'>Lewat Jatuh Tempo</span>
                        ) : info.getValue() === 'void' ? (
                            <span className='badge badge-error'>Invoice Batal</span>
                        ) : info.getValue() === 'write-off' ? (
                            <span className='badge badge-error'>Invoice Terhapus</span>
                        ) : (
                            <span className='badge badge-secondary'>Terkirim</span> // Default badge style
                        )}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
        ],
        [] // eslint-disable-line react-hooks/exhaustive-deps
    );
    return (
        <Card extra={'w-full sm:overflow-auto p-4'}>
            <h2 className='mb-1 text-xl font-bold text-secondary'>
                Detail Laporan Langganan
            </h2>
            <div className='overflow-x-scroll xl:overflow-x-hidden'>
                <Table
                    columns={columns}
                    data={data}
                    isLoading={isLoading}
                    fetchData={fetchData}
                    setRefresh={setRefresh}
                    pageCount={pageCount}
                    totalRow={totalRow}
                    filters={() => (
                        <>
                            <select
                                onChange={handleInvoiceStatusChange}
                                className='select select-sm max-w-xs'
                                value={invoiceStatus}
                            >
                                <option value='' selected>
                                    Semua Status
                                </option>
                                <option value='paid'>Lunas</option>
                                <option value='sent'>Terkirim</option>
                                <option value='overdue'>Lewat Jatuh Tempo</option>
                                <option value='draft'>Belum Lunas</option>
                                <option value='void'>Invoice Batal</option>
                                <option value='write-off'>Invoice Terhapus</option>
                            </select>
                        </>
                    )}
                />
            </div>
        </Card>
    );
}

export default ReportDetail;
