import { Link } from 'react-router-dom';
import Card from 'components/card';
import { toast } from 'react-toastify';
// import { FiEdit } from 'react-icons/fi';
import { createColumnHelper } from '@tanstack/react-table';
import { dateFormat } from 'utils/date';
import Table from 'components/table/Table';
import Datepicker from 'react-tailwindcss-datepicker';
import React, { useCallback, useMemo, useState } from 'react';
import { convertToRupiah } from 'utils/general';
import { MdOutlineRemoveRedEye } from 'react-icons/md';

const Invoices = ({ request, customer_id }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [totalRow, setTotalRow] = useState(0);
  const [refresh, setRefresh] = useState(false);

  const [value, setValue] = useState({
    startDate: null,
    endDate: null,
  });

  const handleValueChange = (newValue) => {
    setValue(newValue);
  };

  const fetchData = useCallback(
    async (pageSize, pageIndex, search) => {
      setIsLoading(true);

      const params = {
        per_page: pageSize,
        page: pageIndex,
        keyword: search,
        start_date: value?.startDate,
        end_date: value?.endDate,
        customer_id: customer_id,
      };

      try {
        const response = await request.get('/invoices', { params });
        const startIndex = response.from;

        const updatedData = response.data.map((item, index) => ({
          ...item,
          listingNumber: startIndex + index,
        }));
        setData(updatedData);
        setPageCount(response.last_page);
        setTotalRow(response.total);
      } catch (err) {
        if (err.response?.data?.errors) {
          Object.keys(err.response.data.errors).map((field) =>
            err.response.data.errors[field].map((message) =>
              toast.error(message)
            )
          );
        } else if (err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      }

      setIsLoading(false);
      setRefresh(false);
    },
    [refresh, value] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const columnHelper = createColumnHelper();

  const columns = useMemo(
    () => [
      columnHelper.accessor('listingNumber', {
        header: () => '#',
        cell: (info) => (
          <div className='text-sm'>{info.row.original.listingNumber}</div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('invoice_no', {
        header: () => 'Nomor Invoice',
        cell: (info) => (
          <div className='max-w-2xl truncate text-sm'>{info.getValue()}</div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('invoice_amount_due', {
        header: () => 'Nominal',
        cell: (info) => (
          <div className='max-w-2xl truncate text-sm'>
            {convertToRupiah(info.getValue(), true)}
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('invoice_status', {
        header: () => 'Status',
        cell: (info) => (
          <div className='max-w-2xl truncate text-xs'>
            {info.getValue() === 'draft' ? (
              <span className='badge badge-warning'>{info.getValue()}</span>
            ) : info.getValue() === 'paid' ? (
              <span className='badge badge-success'>{info.getValue()}</span>
            ) : info.getValue() === 'overdue' ? (
              <span className='badge badge-error'>{info.getValue()}</span>
            ) : (
              <span className='badge badge-secondary'>{info.getValue()}</span> // Default badge style
            )}
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('invoice_due_date', {
        header: () => 'Jatuh Tempo',
        cell: (info) => (
          <div className='max-w-2xl truncate text-sm'>
            {dateFormat(info.getValue(), 'DD MMM YYYY')}
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('invoice_issue_date', {
        header: () => 'Tanggal Terbit',
        cell: (info) => (
          <div className='max-w-2xl truncate text-sm'>
            {dateFormat(info.getValue(), 'DD MMM YYYY')}
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('invoice_id', {
        header: () => 'Aksi',
        cell: (info) => (
          <div className='flex'>
            <Link
              to={`/invoices/${info.getValue()}`}
              className='font-medium text-warning'
            >
              <MdOutlineRemoveRedEye size={24} />
            </Link>
          </div>
        ),
        footer: (info) => info.column.id,
        enableSorting: false,
        enableGrouping: false,
      }),
    ],
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <div>
      <Card extra={'w-full sm:overflow-auto p-4'}>
        <h2 className='mb-1 text-xl font-bold text-secondary'>
          Daftar Tagihan
        </h2>
        <div className='overflow-x-scroll xl:overflow-x-hidden'>
          <Table
            columns={columns}
            data={data}
            isLoading={isLoading}
            fetchData={fetchData}
            setRefresh={setRefresh}
            pageCount={pageCount}
            totalRow={totalRow}
            style={{ zIndex: 100 }}
            filters={() => (
              <Datepicker
                maxDate={new Date()}
                toggleClassName='relative left-2 right-0 h-full px-1 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed '
                containerClassName='input input-sm relative flex bg-white justify-self-start '
                inputClassName='outline-none w-full md:w-48 transition-all duration-300 font-light text-sm placeholder-gray-400'
                value={value}
                onChange={handleValueChange}
                showShortcuts={true}
                placeholder='Pilih Tanggal Jatuh Tempo'
                popoverDirection='down'
                dateLooking='backward'
              />
            )}
          />
        </div>
      </Card>
    </div>
  );
};

export default Invoices;
