import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Card from 'components/card';
import { convertToRupiah } from 'utils/general';
import { formatDateTime} from 'utils/date';
import appConfig from 'config/app';

export default function Detail({ request }) {
  const params = useParams();
  const [data, setData] = useState({});

  const handleSubmit = async (file) => {
    const formData = new FormData();
    formData.append('transaction_proof', file);

    try {
      await request.post(`/transactions/${params.id}/update`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      toast.success('Transaction proof uploaded successfully!');

      const response = await request.get(`/transactions/${params.id}`);
      setData(response);

    } catch (err) {
      toast.error('Failed to upload transaction proof');
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      handleSubmit(file);
    }
  };

  useEffect(() => {
    request
      .get(`/transactions/${params.id}`)
      .then((response) => {
        setData(response);
      })
      .catch((err) => {
        if (err.response?.data?.errors) {
          Object.keys(err.response.data.errors).map((field) =>
            err.response.data.errors[field].map((message) =>
              toast.error(message)
            )
          );
        } else if (err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
      <Card extra={'w-full sm:overflow-auto p-4 md:p-8 gap-y-2'}>
        <h2 className='text-xl font-bold text-navy-700'>Transaksi</h2>
        <div className='flex h-full flex-col justify-between'>
          <div className='grid grid-cols-2 xl:grid-cols-2 gap-3 px-2'>
            <div
                className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
              <p className='text-sm text-gray-600'>Tipe</p>
              <p className='badge badge-info badge-outline text-sm font-medium'>
                {data.transaction_type}
              </p>
            </div>
            <div
                className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
              <p className='text-sm text-gray-600'>Nama</p>
              <p className='text-base font-medium text-navy-700'>
                {data?.payment?.invoice?.customer?.customer_name}
              </p>
            </div>
            <div
                className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
              <p className='text-sm text-gray-600'>Status</p>
              <p className='text-base font-medium text-navy-700'>
                {data.transaction_status === 'captured' ? (
                    <span className='badge badge-success'>Sukses</span>
                ) : data.transaction_status === 'pending' ? (
                    <span className='badge badge-primary'>{data.transaction_status}</span>
                ) : (
                    <span className='badge badge-error'>{data.transaction_status}</span>
                )}
              </p>
            </div>
            <div
                className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
              <p className='text-sm text-gray-600'>Nominal</p>
              <p className='text-base font-medium text-navy-700'>
                {convertToRupiah(data.transaction_amount, true)}
              </p>
            </div>
            <div
                className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
              <p className='text-sm text-gray-600'>Metode</p>
              <p className='text-base font-medium text-navy-700'>
                {data.transaction_method || '-'}
              </p>
            </div>
            <div
                className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
              <p className='text-sm text-gray-600'>Gateway</p>
              <p className='text-base font-medium text-navy-700'>
                {' '}
                {data.transaction_gateway === 'manual'
                  ? `${data.transaction_gateway} (${
                  data.transaction_status !== 'captured'
                  ? 'Dibuat ' + data.transaction_meta.created_by.user_name
                  : 'Dibuat ' +
                  data.transaction_meta.created_by.user_name +
                  ' dan Diproses ' +
                  data.transaction_meta.processed_by.user_name
                })`
                  : '-'}
              </p>
            </div>
            <div
                className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
              <p className='text-sm text-gray-600'>Authorization ID</p>
              <p className='text-base font-medium text-navy-700'>
                {data.transaction_auth_id}
              </p>
            </div>
            <div
                className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
              <p className='text-sm text-gray-600'>Capture ID</p>
              <p className='text-base font-medium text-navy-700'>
                {data.transaction_capture_id || '-'}
              </p>
            </div>
            <div
                className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
              <p className='text-sm text-gray-600'>Tanggal Sukses</p>
              <p className='text-base font-medium text-navy-700'>
                {' '}
                {data.transaction_capture_date
                    ? formatDateTime(data.transaction_capture_date, 'DD MMM YYYY')
                    : '-'}
              </p>
            </div>
            <div
                className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
              <p className='text-sm text-gray-600'>Catatan</p>
              <p className='text-base font-medium text-navy-700'>
                {data.transaction_note || '-'}
              </p>
            </div>
            <div
                className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
              <p className='text-sm text-gray-600'>Tanggal Dibuat</p>
              <p className='text-base font-medium text-navy-700'>
                {formatDateTime(data.transaction_create_date, 'DD MMM YYYY')}
              </p>
            </div>
            <div
                className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
              <p className='text-sm text-gray-600'>Bukti</p>
              <p className='text-base font-medium text-navy-700'>
                {' '}
                {data.transaction_meta?.transaction_proof ? (
                    <label
                        htmlFor="transaction_proof"
                        className="cursor-pointer"
                    >
                      <img
                          src={`${appConfig.storageServiceUrl}/images/${data.transaction_meta.transaction_proof}`}
                          alt='Bukti Transaksi'
                      />
                    </label>
                ) : (
                    '-'
                )}
                <input
                    type="file"
                    id="transaction_proof"
                    className="hidden"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                />
              </p>
            </div>
          </div>
        </div>
      </Card>
  );
}
