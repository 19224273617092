import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import General from './General';
import Maps from './Maps';
import { useState } from 'react';

const ProfileOverview = ({ request }) => {
  const params = useParams();
  const [submission, setSubmission] = useState({});
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    request.get(`/submissions/${params.id}`).then((response) => {
      setSubmission(response);
      setLoading(false);
    });
  }, [params, refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className='flex w-full flex-col gap-5'>
      <div className='grid h-full grid-cols-1 gap-5 lg:!grid-cols-12'>
        <div className='3xl:col-span-5 col-span-5 lg:col-span-6 lg:mb-0'>
          <Maps submission={submission} loading={loading} />
        </div>
        <div className='3xl:col-span-5 col-span-5 lg:col-span-6 lg:mb-0'>
          <General
            request={request}
            submission={submission}
            loading={loading}
            setRefresh={setRefresh}
          />
        </div>
      </div>
    </div>
  );
};

export default ProfileOverview;
