import React, { useMemo, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FiEdit, FiEye } from 'react-icons/fi';
import { FaRegShareSquare, FaWhatsapp } from 'react-icons/fa';
import { createColumnHelper } from '@tanstack/react-table';
import format from 'format-number';
import Datepicker from 'react-tailwindcss-datepicker';
import Table from 'components/table/Table';
import Card from 'components/card';
import { dateFormat } from 'utils/date';

const List = ({ request }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [totalRow, setTotalRow] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState('');
  const [coverageStatus, setCoverageStatus] = useState('');
  const [technicians, setTechnicians] = useState([]);
  const [selectedPhone, setSelectedPhone] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  function formatWhatsAppNumber(whatsappNumber) {
    let formattedNumber = whatsappNumber.replace(/\s+/g, '').replace(/^0+/, '');
    if (!formattedNumber.startsWith('+62')) {
      formattedNumber = '62' + formattedNumber;
    }
    return formattedNumber;
  }

  const getMessage = (selectedRow) => {
    if (!selectedRow || !selectedPhone) return '';

    const whatsappNumber = formatWhatsAppNumber(selectedRow.customer_whatsapp);
    const selectedTechnician = technicians.find(
      (technician) => technician.technician_phone === selectedPhone
    );
    const technicianName = selectedTechnician
      ? selectedTechnician.technician_name
      : 'Teknisi';
    const submissionCoordinates = selectedRow.submission_coordinates;
    return `Halo ${technicianName},
        %0a%0aBerikut ini adalah data pemasangan yang perlu Anda kerjakan:
        %0a%0aNama: ${selectedRow.customer_name}
        %0aAlamat: ${selectedRow.submission_address}
        %0aNo HP: wa.me/%2B${whatsappNumber}
        %0aLink Maps: https://www.google.com/maps?q=${submissionCoordinates}
        %0aPaket: ${selectedRow.package_name}`;
  };

  const handleTechnicianClick = async (info) => {
    setSelectedRow(info.row.original);
    if (!technicians.length) {
      const response = await request.get(`/technician`);
      setTechnicians(response);
    }
    window.generate_modal.show();
  };

  const [value, setValue] = useState({
    startDate: null,
    endDate: null,
  });

  const handleValueChange = (newValue) => {
    setValue(newValue);
  };

  const handleSubmissionStatusChange = (event) => {
    setSubmissionStatus(event.target.value);
  };

  const handleCoverageStatusChange = (event) => {
    setCoverageStatus(event.target.value);
  };

  const fetchData = useCallback(
    async (pageSize, pageIndex, search) => {
      setIsLoading(true);
      const params = {
        per_page: pageSize,
        page: pageIndex,
        keyword: search,
        start_date: value?.startDate,
        end_date: value?.endDate,
        submission_status: submissionStatus,
        coverage_status: coverageStatus,
      };
      try {
        const response = await request.get('/submissions', { params });
        const startIndex = response.from;
        const updatedData = response.data.map((item, index) => ({
          ...item,
          listingNumber: startIndex + index,
        }));
        setData(updatedData);
        setPageCount(response.last_page);
        setTotalRow(response.total);
      } catch (err) {
        if (err.response?.data?.errors) {
          Object.keys(err.response.data.errors).map((field) =>
            err.response.data.errors[field].map((message) =>
              toast.error(message)
            )
          );
        } else if (err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      }

      setIsLoading(false);
      setRefresh(false);
    },
    [refresh, value, submissionStatus, coverageStatus] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const columnHelper = createColumnHelper();

  const columns = useMemo(
    () => [
      columnHelper.accessor('listingNumber', {
        header: () => '#',
        cell: (info) => (
          <div className='text-sm'>{info.row.original.listingNumber}</div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('customer_name', {
        header: () => 'Nama',
        cell: (info) => (
          <div className='max-w-xs truncate text-sm'>{info.getValue()}</div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('reseller_name', {
        header: () => 'Marketing',
        cell: (info) => (
          <div className='max-w-xs truncate text-sm'>
            {info.getValue() === null ? '-' : info.getValue()}
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('package_name', {
        header: () => 'Paket',
        cell: (info) => (
          <div className='min-w-max text-sm'>{info.getValue()}</div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('package_price', {
        header: () => 'Harga',
        cell: (info) =>
          info.getValue() > 0 ? (
            <div className='min-w-max text-sm'>
              Rp{' '}
              {format({
                prefix: '',
                integerSeparator: '.',
              })(parseFloat(info.getValue()))}
            </div>
          ) : (
            <div>-</div>
          ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('district_name', {
        header: () => 'Kabupaten',
        cell: (info) => (
          <div className='max-w-2xl truncate text-sm'>{info.getValue()}</div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('subdistrict_name', {
        header: () => 'Kecamatan',
        cell: (info) => (
          <div className='max-w-2xl truncate text-sm'>{info.getValue()}</div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('ward_name', {
        header: () => 'Kelurahan',
        cell: (info) => (
          <div className='max-w-2xl truncate text-sm'>{info.getValue()}</div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('hamlet_name', {
        header: () => 'Dusun',
        cell: (info) => (
          <div className='max-w-2xl truncate text-sm'>{info.getValue()}</div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('submission_create_date', {
        header: () => 'Tanggal',
        cell: (info) => (
          <div className='max-w-2xl truncate text-sm'>
            {dateFormat(info.getValue(), 'DD MMM YYYY H.mm')}
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('-', {
        header: () => 'Terjangkau',
        cell: (info) => {
          const customerSubmission = info.row.original;

          const isTerjangkau = customerSubmission.zone_id != null;

          return (
            <div className='max-w-2xl truncate text-sm'>
              {isTerjangkau ? (
                <span className='badge badge-success'>covered</span>
              ) : (
                <span className='badge badge-warning'>uncovered</span>
              )}
            </div>
          );
        },
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('submission_status', {
        header: () => 'Status',
        cell: (info) => (
          <div className='max-w-2xl truncate text-sm'>
            {info.getValue() === 'approved' ? (
              <span className='badge badge-success'>{info.getValue()}</span>
            ) : info.getValue() === 'new' ? (
              <span className='badge badge-info'>{info.getValue()}</span>
            ) : info.getValue() === 'cancelled' ? (
              <span className='badge badge-error'>{info.getValue()}</span>
            ) : info.getValue() === 'rejected' ? (
              <span className='badge badge-warning'>{info.getValue()}</span>
            ) : (
              <span className='badge badge-neutral'>{info.getValue()}</span>
            )}
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('submission_id', {
        header: () => 'AKSI',
        cell: (info) => (
          <div className='flex gap-x-1'>
            <a
              aria-disabled={true}
              href={`https://wa.me/send?phone=${info.row.original.customer_whatsapp}&text=Hallo&app_absent=0`}
              target='asccafefe'
              rel='noreferrer'
              className='text-green font-medium'
            >
              <FaWhatsapp size={24} />
            </a>
            <Link
              aria-disabled={true}
              to={`/submissions/${info.getValue()}`}
              className='font-medium text-success'
            >
              <FiEye size={24} />
            </Link>
            <Link
              to={`/submissions/${info.row.original.submission_id}/edit`}
              className='font-medium text-warning'
            >
              <FiEdit size={20} />
            </Link>

            <div
              className='inline-flex max-w-2xl gap-x-1 text-sm'
              style={{ cursor: 'pointer' }}
              onClick={() => handleTechnicianClick(info)}
            >
              <FaRegShareSquare size={20} color='#FBBD29' />
            </div>
          </div>
        ),
        footer: (info) => info.column.id,
      }),
    ],
    [data] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <>
      <Card extra={'w-full sm:overflow-auto p-4 mt-4'}>
        <h2 className='text-xl font-bold text-navy-700'>Daftar Pengajuan</h2>
        <div className='mt-4 overflow-x-scroll xl:overflow-x-hidden'>
          <Table
            columns={columns}
            data={data}
            isLoading={isLoading}
            fetchData={fetchData}
            setRefresh={setRefresh}
            pageCount={pageCount}
            totalRow={totalRow}
            filters={() => (
              <>
                <Datepicker
                  maxDate={new Date()}
                  toggleClassName='relative left-2 right-0 h-full px-1 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed'
                  containerClassName='input input-sm relative flex bg-white justify-self-start'
                  inputClassName='outline-none w-full md:w-48 transition-all duration-300 font-light text-sm placeholder-gray-400'
                  value={value}
                  onChange={handleValueChange}
                  showShortcuts={true}
                  placeholder='Pilih Tanggal Pengajuan'
                />
                <select
                  onChange={handleSubmissionStatusChange}
                  className='select select-sm max-w-xs'
                  value={submissionStatus}
                >
                  <option value='' selected>
                    Semua Status Pengajuan
                  </option>
                  <option value='new'>New</option>
                  <option value='approved'>Approved</option>
                  <option value='planned'>Planned</option>
                  <option value='rejected'>Rejected</option>
                  <option value='cancelled'>Cancelled</option>
                </select>
                <select
                  onChange={handleCoverageStatusChange}
                  className='select select-sm max-w-xs'
                  value={coverageStatus}
                >
                  <option value='' selected>
                    Semua Status Jangkauan
                  </option>
                  <option value='covered'>Covered</option>
                  <option value='uncovered'>Uncovered</option>
                </select>
              </>
            )}
          />
        </div>
      </Card>
      <dialog
        id='generate_modal'
        className='modal modal-bottom sm:modal-middle'
      >
        <form method='dialog' className='modal-box'>
          <h3 className='mb-2 text-lg font-bold'>Teknisi</h3>
          <div className='form-control w-full max-w-sm'>
            <select
              id='technician_phone'
              className='select select-bordered'
              onChange={(e) => setSelectedPhone(e.target.value)}
              value={selectedPhone}
            >
              <option value=''>pilih nomor teknisi</option>
              {technicians.map((technician) => (
                <option
                  key={technician.technician_id}
                  value={technician.technician_phone}
                >
                  {`${technician.technician_name} - ${technician.technician_phone}`}
                </option>
              ))}
            </select>
          </div>
          <div className='modal-action'>
            <button
              type='button'
              className='btn btn-success'
              onClick={() => {
                const message = getMessage(selectedRow);
                window.open(
                  `https://wa.me/send?phone=62${selectedPhone.substring(
                    1,
                    selectedPhone.length
                  )}&text=${message}&app_absent=0`,
                  '_blank'
                );
              }}
            >
              Share
            </button>
            <button className='btn'>Batal</button>
          </div>
        </form>
      </dialog>
    </>
  );
};

export default List;
