import {Link, useNavigate, useParams} from "react-router-dom";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import Card from 'components/card';
import {toast} from "react-toastify";
import Table from "../../../../components/table/Table";
import {createColumnHelper} from "@tanstack/react-table";
import {dateFormat} from "../../../../utils/date";
import Tabs from "../../../../components/tabs/Tabs";
import {FiEye} from "react-icons/fi";
import {convertToRupiah} from "../../../../utils/general";
import CurrencyInput from "react-currency-input-field";
import {useForm} from "react-hook-form";


const DetailReseller = ({ request }) =>{
    const params = useParams();
    const [reseller, setReseller]                   = useState({});
    const [isLoading, setIsLoading]                 = useState(true);
    const [isLoadingReferal, setIsLoadingReferal]   = useState(true);
    const [isLoadingWithdraw, setIsLoadingWithdraw] = useState(true)
    const [dataReferal, setDataReferal]               = useState([]);
    const [dataWithdraw, setDataWithdraw]             = useState([]);
    const [pageCountReferal, setPageCountReferal]   = useState(0);
    const [totalRowReferal, setTotalRowReferal]     = useState(0);
    const [pageCountWithdraw, setPageCountWithdraw]   = useState(0);
    const [totalRowWithdraw, setTotalRowWithdraw]     = useState(0);
    const [data, setData]                           = useState([]);
    const [pageCount, setPageCount]                 = useState(0);
    const [totalRow, setTotalRow]                   = useState(0);
    const [refresh, setRefresh]                     = useState(false);
    const [refreshReferal, setRefreshReferal]       = useState(false);
    const [refreshWithdraw, setRefreshWithdraw]     = useState(false);
    const [resellerCode, setResellerCode]           = useState('');
    // const params = useParams();
    const navigate                              = useNavigate();
    const { register, handleSubmit, setValue } = useForm();

    useEffect(() => {
        request.get(`/resellers/${params.id}`).then((response) => {
            setReseller(response);
            setResellerCode(response.reseller_code);
            setIsLoading(false);
            setRefresh(true);
            setIsLoadingReferal(false);
            setRefreshReferal(true);
            setIsLoadingWithdraw(false);
            setRefreshWithdraw(true);
        });

    }, [params]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchData = useCallback(
        async (pageSize, pageIndex, search) => {
            setIsLoading(true);
            setRefresh(false);

            const params = {
                per_page    : pageSize,
                page        : pageIndex,
                keyword     : search,
                referal     : resellerCode

            };
            try {
                const response = await request.get('/list-downline', { params });
                const startIndex = response.from;

                const updatedData = response.data.map((item, index) => ({
                    ...item,
                    listingNumber: startIndex + index,
                }));
                setData(updatedData);
                setPageCount(response.last_page);
                setTotalRow(response.total);
            } catch (err) {
                if (err.response?.data?.errors) {
                    Object.keys(err.response.data.errors).map((field) =>
                        err.response.data.errors[field].map((message) =>
                            toast.error(message)
                        )
                    );
                } else if (err.response?.data?.message) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error(err.message);
                }
            }

            setIsLoading(false);
            setRefresh(false);
        },
        [refresh,] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const fetchDataReferal = useCallback(
        async (pageSize, pageIndex, search) => {
            setIsLoadingReferal(true);
            setRefreshReferal(false);

            const params = {
                per_page    : pageSize,
                page        : pageIndex,
                keyword     : search,
                reseller_id     : reseller.reseller_id

            };
            try {
                const response = await request.get('/list-bonus', { params });
                const startIndex = response.from;

                const updatedData = response.data.map((item, index) => ({
                    ...item,
                    listingNumber: startIndex + index,
                }));
                setDataReferal(updatedData);
                setPageCountReferal(response.last_page);
                setTotalRowReferal(response.total);
            } catch (err) {
                if (err.response?.data?.errors) {
                    Object.keys(err.response.data.errors).map((field) =>
                        err.response.data.errors[field].map((message) =>
                            toast.error(message)
                        )
                    );
                } else if (err.response?.data?.message) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error(err.message);
                }
            }

            setIsLoadingReferal(false);
            setRefreshReferal(false);
        },
        [refreshReferal,] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const fetchDataWithdraw = useCallback(
        async (pageSize, pageIndex, search) => {
            setIsLoadingWithdraw(true);
            setRefreshWithdraw(false);

            const params = {
                per_page    : pageSize,
                page        : pageIndex,
                keyword     : search,
                reseller_withdraw    : reseller.reseller_id

            };
            try {
                const response = await request.get('/list-withdraw', { params });
                const startIndex = response.from;

                const updatedData = response.data.map((item, index) => ({
                    ...item,
                    listingNumber: startIndex + index,
                }));
                setDataWithdraw(updatedData);
                setPageCountWithdraw(response.last_page);
                setTotalRowWithdraw(response.total);
            } catch (err) {
                if (err.response?.data?.errors) {
                    Object.keys(err.response.data.errors).map((field) =>
                        err.response.data.errors[field].map((message) =>
                            toast.error(message)
                        )
                    );
                } else if (err.response?.data?.message) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error(err.message);
                }
            }

            setIsLoadingWithdraw(false);
            setRefreshWithdraw(false);
        },
        [refreshWithdraw,] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const columnHelper = createColumnHelper();

    const columns = useMemo(
        () =>[
            columnHelper.accessor('listingNumber', {
                header: () => '#',
                cell: (info) => (
                    <div className='text-sm'>{info.row.original.listingNumber}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('customer_name', {
                header: () => 'Nama',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>{info.getValue()}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('customer_status', {
                header: () => 'Status',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-xs'>
                        {info.getValue() === 'active' ? (
                            <span className='badge badge-warning'>{info.getValue()}</span>
                        ) :  (
                            <span className='badge badge-secondary'>{info.getValue()}</span> // Default badge style
                        )}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('customer_create_date', {
                header: () => 'TANGGAL DAFTAR',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>
                        {dateFormat(info.getValue(), 'DD MMM YYYY HH.mm')}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('customer_id', {
                header: () => 'AKSI',
                cell: (info) => (
                    <div className='flex gap-x-1'>
                        <Link
                            aria-disabled={true}
                            to={`/customers/${info.getValue()}`}
                            className='font-medium text-success'
                        >
                            <FiEye size={24} />
                        </Link>
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
        ],[] // eslint-disable-line react-hooks/exhaustive-deps
    )

    const columnsReferal = useMemo(
        ()=>[
            columnHelper.accessor('listingNumber', {
                header: () => '#',
                cell: (info) => (
                    <div className='text-sm'>{info.row.original.listingNumber}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('invoice.customer.customer_name', {
                header: () => 'Nama',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>{info.getValue()}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('r_bonus_section', {
                header: () => 'Type',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-xs'>
                        {info.getValue() === 'bulanan' ? (
                            <span className='badge badge-warning'>{info.getValue()}</span>
                        ) :  (
                            <span className='badge badge-secondary'>{info.getValue()}</span> // Default badge style
                        )}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('r_bonus_nominal', {
                header: () => 'nominal',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>
                        {convertToRupiah(info.getValue(), true)}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('r_bonus_create_date', {
                header: () => 'tanggal',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>
                        {dateFormat(info.getValue(), 'DD MMM YYYY HH.mm')}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
        ],[] // eslint-disable-line react-hooks/exhaustive-deps
    )

    const columnsWithdraw = useMemo(
        ()=>[
            columnHelper.accessor('listingNumber', {
                header: () => '#',
                cell: (info) => (
                    <div className='text-sm'>{info.row.original.listingNumber}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('reseller.reseller_name', {
                header: () => 'Nama',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>{info.getValue()}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('r_withdraw_amount', {
                header: () => 'amount',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>
                        {convertToRupiah(info.getValue(), true)}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('r_withdraw_note', {
                header: () => 'Note',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>{info.getValue()}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('r_withdraw_status', {
                header: () => 'Status',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-xs'>
                        {info.getValue() === 'new' ? (
                            <span className='badge badge-success'>{info.getValue()}</span>
                        ) :  (
                            <span className='badge badge-error'>{info.getValue()}</span> // Default badge style
                        )}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('r_withdraw_update_date', {
                header: () => 'tanggal update',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>
                        {dateFormat(info.getValue(), 'DD MMM YYYY')}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('r_withdraw_update_by', {
                header: () => 'update by',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>{info.getValue()}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('r_withdraw_update_reason', {
                header: () => 'alasan',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>{info.getValue()}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('r_withdraw_create_date', {
                header: () => 'tanggal',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>
                        {dateFormat(info.getValue(), 'DD MMM YYYY')}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
        ],[] // eslint-disable-line react-hooks/exhaustive-deps
    )

        const submitHandler = async (data) => {
            setValue('reseller_id',params.id);
            try {
                await request.post('/withdraw', data);
                toast.success('Berhasil melakukan penarikan');
                return navigate('/withdraw');
            } catch (err) {
                if (err.response?.data?.errors) {
                    Object.keys(err.response.data.errors).map((field) =>
                        err.response.data.errors[field].map((message) => toast.error(message))
                    );
                } else if (err.response?.data?.message) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error(err.message);
                }
            }
        };

        const errorHandler = (errors, e) => {
            Object.keys(errors).map((key) => {
                if (Array.isArray(errors[key])) {
                    return errors[key].map((err) => {
                        return toast.error(err.message);
                    });
                }

                return toast.error(errors[key].message);
            });
        };


        const tabs = [
        { title: 'Daftar Downline', content:
                <div className='flex w-full flex-col gap-5 '>
                <Table
                    columns={columns}
                    data={data}
                    isLoading={isLoading}
                    fetchData={fetchData}
                    setRefresh={setRefresh}
                    pageCount={pageCount}
                    totalRow={totalRow}
                />
            </div> },
        { title: 'Bonus Referal', content: <div className='flex w-full flex-col gap-5 '>
                <Table
                    columns={columnsReferal}
                    data={dataReferal}
                    isLoadingReferal={isLoadingReferal}
                    fetchData={fetchDataReferal}
                    refreshReferal={setRefreshReferal}
                    pageCount={pageCountReferal}
                    totalRow={totalRowReferal}
                    />
            </div>  },
        { title: 'Reseller Withdraw', content: <div className='flex w-full flex-col gap-5 '>
                <Table
                    columns={columnsWithdraw}
                    data={dataWithdraw}
                    isLoadingWithdraw={isLoadingWithdraw}
                    fetchData={fetchDataWithdraw}
                    setRefreshWithdraw={setRefreshWithdraw}
                    pageCount={pageCountWithdraw}
                    totalRow={totalRowWithdraw}
                />
            </div>  },
        {
            title: 'Withdraw', content: <div className='flex w-full flex-col gap-5 '>
                <Card extra={'w-full sm:overflow-auto p-4'}>
                    <h2 className='text-xl font-bold text-navy-700'>Penarikan Bonus Reseller</h2>
                    <form
                        onSubmit={handleSubmit(submitHandler, errorHandler)}
                        className='mt-4 flex flex-col gap-y-1 overflow-x-scroll xl:overflow-x-hidden'
                    >
                        <div className='grid grid-cols-2'>
                            <div className='form-control w-full max-w-sm'>
                                <label className='label'>
                                    <span className='label-text'>Nominal Penarikan</span>
                                </label>
                                <CurrencyInput
                                    {...register('r_withdraw_amount', {
                                        required: 'Nominal penarikan harus diisi',
                                    })}
                                    prefix='Rp '
                                    placeholder='Silahkan masukan harga Biaya Pemasangan'
                                    defaultValue={0}
                                    decimalSeparator=','
                                    groupSeparator='.'
                                    intlConfig={{ locale: 'id-ID', currency: 'IDR' }}
                                    className='input input-bordered w-full max-w-sm'
                                />
                            </div>
                        </div>
                        <div className='flex gap-x-2'>
                            <button type='submit' className='btn btn-primary'>
                                Simpan
                            </button>
                            <Link to='/marketing' className='btn'>
                                Batal
                            </Link>
                        </div>
                    </form>
                </Card>
            </div>
        }
    ];
    return (
        <>
            <Card extra={'w-full sm:overflow-auto p-4 mt-4'}>
                <h2 className='text-xl font-bold text-navy-700'>Detail Reseller</h2>
                <div className='flex h-full flex-col justify-between'>
                    <div className='grid grid-cols-2 xl:grid-cols-5 gap-3 px-2'>
                        <div className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
                            <p className='text-sm text-gray-600'>Nama</p>
                            <p className='text-base font-medium text-navy-700'>
                                {reseller.reseller_name}
                            </p>
                        </div>
                        <div className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
                            <p className='text-sm text-gray-600'>Kode Referal</p>
                            <p className='text-base font-medium text-navy-700'>
                                {reseller.reseller_code}
                            </p>
                        </div>
                        <div className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
                            <p className='text-sm text-gray-600'>Jumlah Bonus</p>
                            <p className='text-base font-medium text-navy-700'>
                                {'Rp '+ convertToRupiah(reseller.jumlah_bonus)}
                            </p>
                        </div>
                        <div className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
                            <p className='text-sm text-gray-600'>Sisa Bonus</p>
                            <p className='text-base font-medium text-navy-700'>
                                {'Rp '+ convertToRupiah(reseller.reseller_balance)}
                            </p>
                        </div>
                        <div className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
                            <p className='text-sm text-gray-600'>Jumlah Penarikan</p>
                            <p className='text-base font-medium text-navy-700'>
                                {'Rp '+ convertToRupiah(reseller.jumlah_withdraw)}
                            </p>
                        </div>
                    </div>
                </div>
            </Card>
            <Tabs tabs={tabs} />
        </>
    );
};

export default DetailReseller;
