import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { IoDocuments } from 'react-icons/io5';
import { MdBarChart, MdDashboard } from 'react-icons/md';
import Widget from 'components/widget/Widget';
import Tabs from "../../components/tabs/Tabs";
import CustomerTime from "./components/Customer/CustomerTime";
import CustomerDay from "./components/Customer/CustomerDay";
import CustomerMonth from "./components/Customer/CustomerMonth";
import CustomerYear from "./components/Customer/CustomerYear";
import ComplaintTime from "./components/Pengaduan/ComplaintTime";
import ComplaintDay from "./components/Pengaduan/ComplaintDay";
import ComplaintMonth from "./components/Pengaduan/ComplaintMonth";
import ComplaintYear from "./components/Pengaduan/ComplaintYear";
import TransTime from "./components/Transaksi/TransTime";
import TransDay from "./components/Transaksi/TransDay";
import TransMonth from "./components/Transaksi/TransMonth";
import TransYear from "./components/Transaksi/TransYear";

const Dashboard = (props) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getDashboard = async () => {
        setIsLoading(true);
        try {
            const response = await props.request.get('/dashboard/count', {});
            setData(response);
        } catch (err) {
            if (err.response?.data?.errors) {
                Object.keys(err.response.data.errors).map((field) =>
                    err.response.data.errors[field].map((message) => toast.error(message))
                );
            } else if (err.response?.data?.message) {
                toast.error(err.response.data.message);
            } else {
                toast.error(err.message);
            }
        }
    };

    useEffect(
        () => {
            getDashboard();
        },
        [] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const tabsCustomer = [
        { title: 'Jam', content: <div className='flex w-full flex-col gap-5'>
                <CustomerTime
                    props={props}
                    isLoading={isLoading}
                />
            </div>
        },
        { title: 'Harian', content: <div className='flex w-full flex-col gap-5'>
                <CustomerDay
                    props={props}
                    isLoading={isLoading}
                />
            </div>
        },
        { title: 'Bulanan', content: <div className='flex w-full flex-col gap-5'>
                <CustomerMonth
                    props={props}
                    isLoading={isLoading}
                />
            </div>
        },
        { title: 'Tahunan', content: <div className='flex w-full flex-col gap-5'>
                <CustomerYear
                    props={props}
                    isLoading={isLoading}
                />
            </div>
        },
    ];

    const tabsTransaksi = [
        { title: 'jam', content: <div className='flex w-full flex-col gap-5'>
                <TransTime
                    props={props}
                    isLoading={isLoading}
                />
            </div>
        },
        { title: 'Harian', content: <div className='flex w-full flex-col gap-5'>
                <TransDay
                    props={props}
                    isLoading={isLoading}
                />
            </div>
        },
        { title: 'Bulanan', content: <div className='flex w-full flex-col gap-5'>
                <TransMonth
                    props={props}
                    isLoading={isLoading}
                />
            </div>
        },
        { title: 'Tahunan', content: <div className='flex w-full flex-col gap-5'>
                <TransYear
                    props={props}
                    isLoading={isLoading}
                />
            </div>
        }
    ];

    const tabsPengaduan = [
        { title: 'jam', content: <div className='flex w-full flex-col gap-5'>
                <ComplaintTime
                    props={props}
                    isLoading={isLoading}
                />
            </div>
        },
        { title: 'Harian', content: <div className='flex w-full flex-col gap-5'>
                <ComplaintDay
                    props={props}
                    isLoading={isLoading}
                />
            </div>
        },
        { title: 'Bulanan', content: <div className='flex w-full flex-col gap-5'>
                <ComplaintMonth
                    props={props}
                    isLoading={isLoading}
                />
            </div>
        },
        { title: 'Tahunan', content: <div className='flex w-full flex-col gap-5'>
                <ComplaintYear
                    props={props}
                    isLoading={isLoading}
                />
            </div>
        },
    ];

    return (
        <div>
            {/* Card widget */}

            <div className='3xl:grid-cols-6 mt-8 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3'>
                <Widget
                    icon={<MdBarChart className='h-7 w-7' />}
                    title={'Total Pelanggan'}
                    subtitle={data.customerCounted}
                />
                <Widget
                    icon={<IoDocuments className='h-6 w-6' />}
                    title={'Total Nomor Pelanggan'}
                    subtitle={data.customerNumberCounted}
                />
                {/* <Widget
          icon={<MdBarChart className='h-7 w-7' />}
          title={'Total Mitra Pembayaran'}
          subtitle={'N/A'}
        /> */}
                <Widget
                    icon={<MdDashboard className='h-6 w-6' />}
                    title={'Total Zone'}
                    subtitle={data.zoneCounted}
                />
            </div>
            <div className='mt-8 gap-5'>
                <Tabs tabs={tabsCustomer} />
            </div>
            <div className='mt-8 gap-5'>
                <Tabs tabs={tabsTransaksi} />
            </div>
            <div className='mt-8 gap-5'>
                <Tabs tabs={tabsPengaduan}/>
            </div>

        </div>
    );
};

export default Dashboard;
