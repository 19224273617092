import React, {useMemo, useState, useCallback} from 'react';
import Card from '../../../components/card';
import Table from 'components/table/Table';
import { createColumnHelper } from '@tanstack/react-table';
import { FiEdit } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const List = ({ request }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalRow, setTotalRow] = useState(0);

  const columnHelper = createColumnHelper();

  const columns = useMemo(
    () => [
      columnHelper.accessor('listingNumber', {
        header: () => '#',
        cell: (info) => (
          <div className='text-sm'>{info.row.original.listingNumber}</div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('coverage_name', {
        header: () => 'Nama',
        cell: (info) => (
          <div className='max-w-xs truncate text-sm'>{info.getValue()}</div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('coverage_code', {
        header: () => 'Kode',
        cell: (info) => (
          <div className='min-w-max text-sm'>{info.getValue()}</div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('package_category.p_category_name', {
        header: () => 'Kategori',
        cell: (info) => (
          <div className='min-w-max text-sm'>{info.getValue()}</div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('coverage_desc', {
        header: () => 'Deskripsi',
        cell: (info) => (
          <div className='min-w-max text-sm'>{info.getValue()}</div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('coverage_status', {
        header: () => 'Status',
        cell: (info) => {
          let className;
          if (info.getValue() === 'active') {
            className = 'bg-primary rounded-full px-3 py-1 max-w-fit';
          } else {
            className =
              'bg-secondary text-white rounded-full px-3 py-1 max-w-fit';
          }
          return <div className={className}>{info.getValue()}</div>;
        },
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('aksi', {
        header: () => 'Aksi',
        cell: (info) => {
          return (
            <div className='flex max-w-2xl truncate text-sm'>
              <Link
                className='mr-2 text-warning'
                to={`${info.row.original.coverage_id}/edit`}
              >
                <FiEdit size={24} />
              </Link>
            </div>
          );
        },
        footer: (info) => info.column.id,
      }),
    ],
    [data] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const fetchData = useCallback(
    async (pageSize, pageIndex, search) => {
      setIsLoading(true);
      const params = {
        per_page: pageSize,
        page: pageIndex,
        keyword: search,
      };
      try {
        const response = await request.get('/coverages', { params });
        const startIndex = response.from;
        const updatedData = response.data.map((item, index) => ({
          ...item,
          listingNumber: startIndex + index,
        }));
        setData(updatedData);
        setPageCount(response.last_page);
        setTotalRow(response.total);
      } catch (err) {
        if (err.response?.data?.errors) {
          Object.keys(err.response.data.errors).map((field) =>
            err.response.data.errors[field].map((message) =>
              toast.error(message)
            )
          );
        } else if (err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      }

      setIsLoading(false);
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <div>
      <Card extra={'w-full sm:overflow-auto p-4'}>
        <header className='relative flex items-center justify-between'>
          <div className='text-xl font-bold text-navy-700 dark:text-white'>
            Daftar Coverage
          </div>
        </header>

        <div className='mt-8 overflow-x-scroll xl:overflow-x-hidden'>
          <Table
            columns={columns}
            data={data}
            isLoading={isLoading}
            fetchData={fetchData}
            pageCount={pageCount}
            totalRow={totalRow}
            actions={() => (
              <Link
                to={'/coverages/create'}
                className='btn btn-secondary btn-sm'
              >
                Tambah
              </Link>
            )}
          />
        </div>
      </Card>
    </div>
  );
};
export default List;
