import { Route, Routes } from 'react-router-dom';
import List from './component/List';
import Edit from './component/Edit';
import Add from './component/Add';

const TiketList = (props) => {
    return (
        <Routes>
            <Route path='/' element={<List {...props} />} />
            <Route path='/:id/edit' element={<Edit {...props} />} />
            <Route path='/create' element={<Add {...props} />} />
        </Routes>
    );
};

export default TiketList;
