import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { FiLock } from 'react-icons/fi';

export default function Verify({ request, login }) {
  const [searchParams] = useSearchParams();
  const { register, handleSubmit, setValue } = useForm();

  useEffect(() => {
    toast.info('Silahkan periksa email anda untuk menerima Kode OTP');
    setValue('otp_signature', searchParams.get('otp_signature'));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const submitHandler = (data, e) => {
    request
      .post('/auth/verify', data)
      .then((response) => {
        toast.info('Selamat datang ' + response.user.user_name);
        login(response);
      })
      .catch((err) => {
        if (err.response?.data?.errors) {
          Object.keys(err.response.data.errors).map((field) =>
            err.response.data.errors[field].map((message) =>
              toast.error(message)
            )
          );
        } else if (err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      });
  };

  const errorHandler = (errors, e) => console.error(errors, e);

  return (
    <div className='mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start'>
      {/* Sign in section */}
      <form
        onSubmit={handleSubmit(submitHandler, errorHandler)}
        className='mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]'
      >
        <h4 className='mb-2.5 text-4xl font-bold text-navy-700 dark:text-white'>
          Verifikasi Login
        </h4>
        <p className='mb-9 ml-1 text-base text-gray-600'>
          Silahkan verifikasi diri anda!
        </p>
        <div className='form-control w-full'>
          <label className='label'>
            <span className='label-text'>OTP*</span>
          </label>
          <div className='relative'>
            <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-4'>
              <span className='text-gray-400 focus-within:text-gray-900 sm:text-base'>
                <FiLock />{' '}
              </span>
            </div>
            <input
              {...register('otp_value', { required: 'OTP harus diisi' })}
              type='text'
              placeholder='123456'
              className='input input-bordered w-full pl-10'
            />
          </div>
        </div>

        <button className='btn btn-primary mt-2 w-full'>Masuk</button>
      </form>
    </div>
  );
}
