/* eslint-disable */
import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import DashIcon from 'components/icons/DashIcon';
import { NavContext } from 'contexts/NavContext';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import LinkMenu from './LinkMenu';

export function Links(props) {
  const location = useLocation();
  const nav = useContext(NavContext);

  const { routes } = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (route) => {
    if (route.submenu !== undefined) {
      const subName = [];
      route.submenu.map((item) => {
        subName.push({
          name: item.name,
          path: item.path,
        });
      });
      const filterSub = subName.filter((item) => {
        return item.path === location.pathname;
      });
      if (filterSub.length !== 0) {
        const active = location.pathname.includes(filterSub[0].path);
        if (active) {
          nav.setMenu(filterSub[0].name);
        }
        return active;
      }
    }
    if (route.submenu === undefined) {
      const active = location.pathname.includes(route.path);
      if (active) {
        nav.setMenu(route.name);
      }
      return active;
    }
  };

  const createLinks = (routes) => {
    return routes.map((route, index) => {
      if (route.name !== undefined && route.icon !== undefined) {
        return LinkMenu(index, route, activeRoute);
      }
    });
  };

  return createLinks(routes);
}

export default Links;
