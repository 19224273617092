import { useEffect, useState } from 'react';
import Card from '../../../components/card';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

const Edit = (props) => {
  // state
  const [packageCategories, setPackageCategories] = useState([]);
  const [selectedCategory, setCategory] = useState(null);
  // variable
  const { register, handleSubmit, setValue, getValues } = useForm({
    defaultValues: async () => {
      try {
        const response = await props.request.get(`coverages/${queryUrl.id}`);
        return {
          coverage_id: response.coverage_id,
          p_category_id: response.p_category_id,
          coverage_name: response.coverage_name,
          coverage_desc: response.coverage_desc,
          coverage_status: response.coverage_status,
        };
      } catch (error) {
        toast.error(error.response.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          // transition: Bounce,
        });
      }
    },
  });
  const navigate = useNavigate();
  const queryUrl = useParams();

  // function
  const getCategory = async () => {
    try {
      const response = await props.request.get('package-categories', {});
      setPackageCategories(response);
      const filteredCat = await response.filter(item => item.p_category_id === getValues('p_category_id'));
      setCategory({
        label: filteredCat[0].p_category_name,
        value: filteredCat[0].p_category_id
      });
    } catch (error) {
      toast.error(error.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        // transition: Bounce,
      });
    }
  };
  const updateCoverage = async (updatedData) => {
    try {
      await props.request.put(`/coverages/${queryUrl.id}`, updatedData);
      toast.success('Berhasil menyimpan data');
      return navigate('/coverages');
    } catch (error) {
      toast.error(error.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        // transition: Bounce,
      });
    }
  };

  // function from react
  useEffect(() => {
    getCategory();
  },
  [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Card extra='w-full p-4'>
      <div className='text-xl font-bold text-navy-700 dark:text-white'>
        Tambah Cakupan
      </div>
      <div className='mt-8'>
        <form className='flex flex-wrap' onSubmit={handleSubmit(updateCoverage)}>
          <div className='flex-1'>
            <div className='mb-2 flex w-full flex-col md:pr-4'>
              <label className='mb-2 text-sm font-semibold'>Nama</label>
              <input
                type='text'
                className='rounded-lg border px-3 py-3 text-sm font-normal'
                placeholder='Nama Cakupan ...'
                {...register('coverage_name', {
                  required: 'Nama wajib diisi!',
                })}
                defaultValue={getValues('coverage_name')}
              />
            </div>
            <div className='mb-2 flex w-full flex-col md:pr-4'>
              <label className='mb-2 text-sm font-semibold'>Deskripsi</label>
              <textarea
                className='rounded-lg border px-3 py-2 text-sm font-normal'
                id=''
                cols='30'
                rows='5'
                {...register('coverage_desc', {
                  required: 'Nama wajib diisi!',
                })}
                defaultValue={getValues('coverage_desc')}
              ></textarea>
            </div>
          </div>
          <div className='flex-1'>
            <div className='mb-2 flex w-full flex-col md:pr-4'>
              <label className='mb-2 text-sm font-semibold'>Kategori</label>
              <Select
                {...register('p_category_id', {
                  required: 'Harus memilih kategori',
                })}
                options={
                  packageCategories.length > 0 &&
                  packageCategories.map((item, index) => ({
                    key: index,
                    label: item.p_category_name,
                    value: item.p_category_id,
                  }))
                }
                isClearable={true}
                onChange={(e) => {
                  setCategory(e);
                  setValue('p_category_id', e.value);
                }}
                value={selectedCategory}
              />
            </div>
            <div className='mb-2 flex w-full flex-col md:pr-4'>
              <label className='mb-2 text-sm font-semibold'>status</label>
              <select
                type='text'
                className='select select-bordered'
                {...register('coverage_status', {
                  required: 'Nama wajib diisi!',
                })}
              >
                <option value=''>-- pilih status --</option>
                <option value='active'>Aktif</option>
                <option value='non-active'>Non-Aktif</option>
              </select>
            </div>
            <div className='mb-2 flex w-full flex-row-reverse md:pr-4 md:pt-3'>
              <button className='btn btn-primary ml-2' type='submit'>
                Simpan
              </button>
              <button
                onClick={() => navigate('/coverages')}
                className='btn-danger btn'
              >
                Batal
              </button>
            </div>
          </div>
        </form>
      </div>
    </Card>
  );
};

export default Edit;
