import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from 'contexts/AuthContext';
import { NavProvider } from 'contexts/NavContext';
import App from 'App';
import 'index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <NavProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </NavProvider>
  </BrowserRouter>
);
