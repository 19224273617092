import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import Card from 'components/card';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const Edit = ({ request }) => {
  const params = useParams();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { isLoading },
  } = useForm({
    // reValidateMode: 'onChange',
    defaultValues: async () => {
      try {
        const data = await request.get(`/package-categories/${params.id}`);
        return {
          p_category_name: data.p_category_name,
          p_category_status: data.p_category_status,
        };
      } catch (err) {
        if (err.response?.data?.errors) {
          Object.keys(err.response.data.errors).map((field) =>
            err.response.data.errors[field].map((message) =>
              toast.error(message)
            )
          );
        } else if (err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      }
    },
  });

  const submitHandler = async (data, e) => {
    try {
      await request.put(`/package-categories/${params.id}`, data);
      toast.success('Berhasil menyimpan data');
      return navigate('/package-categories');
    } catch (err) {
      if (err.response?.data?.errors) {
        Object.keys(err.response.data.errors).map((field) =>
          err.response.data.errors[field].map((message) => toast.error(message))
        );
      } else if (err.response?.data?.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    }
  };

  const errorHandler = (errors, e) => {
    Object.keys(errors).map((key) => {
      if (Array.isArray(errors[key])) {
        return errors[key].map((err) => {
          return toast.error(err.message);
        });
      }

      return toast.error(errors[key].message);
    });
  };

  return (
    <>
      <Card extra={'w-full sm:overflow-auto p-4'}>
        <h2 className='text-xl font-bold text-navy-700'>Edit Kategori Paket</h2>
        <form
          onSubmit={handleSubmit(submitHandler, errorHandler)}
          className='mt-4 flex flex-col gap-y-1 overflow-x-scroll xl:overflow-x-hidden'
        >
          <div className='grid grid-cols-2'>
            <div className='form-control w-full max-w-sm'>
              <label className='label'>
                <span className='label-text'>Nama</span>
              </label>
              {isLoading ? (
                <Skeleton height={45} />
              ) : (
                <input
                  {...register('p_category_name', {
                    required: 'Nama harus diisi',
                  })}
                  type='text'
                  placeholder='Nama paket kategori'
                  className='input input-bordered w-full max-w-sm'
                />
              )}
            </div>
            <div className='form-control w-full max-w-sm'>
              <label className='label'>
                <span className='label-text'>Status</span>
              </label>
              {isLoading ? (
                <Skeleton height={45} />
              ) : (
                <select
                  {...register('p_category_status', {
                    required: 'Status harus dipilih',
                  })}
                  className='select select-bordered'
                >
                  <option value='' disabled>
                    Pilih Status Paket
                  </option>
                  <option value='active'>Aktif</option>
                  <option value='non-active'>Non-Aktif</option>
                </select>
              )}
            </div>
          </div>
          <div className='flex gap-x-2'>
            <button type='submit' className='btn btn-primary'>
              Simpan
            </button>
            <Link to='/package-categories' className='btn'>
              Batal
            </Link>
          </div>
        </form>
      </Card>
    </>
  );
};

export default Edit;
