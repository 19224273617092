import { Route, Routes } from 'react-router-dom';
import List from './components/List';
import Details from "./components/details";
import Edit from "./components/Edit";

const Resellers =(props)=>{
    return (
        <Routes>
            <Route path='/' element={<List {...props} />} />
            <Route path='/:id' element={<Details {...props} />} />
            <Route path='/:id/edit' element={<Edit {...props} />} />
        </Routes>
    );
};
export default  Resellers;