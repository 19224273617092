import React, {useState, useEffect, Fragment, useCallback} from 'react';
import { Card, CardHeader, CardBody, ButtonGroup, Button, Spinner, Container } from "reactstrap";
import Loader from 'react-loaders';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import Chart from 'react-apexcharts';
import {toast} from "react-toastify";

const CustomerTime = ({ props }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [customerTime, setCustomerTime] = useState([]);
    const [customerCount, setCustomerCount] = useState([]);
    const [customerStatusActive, setCustomerStatusActive] = useState([]);
    const [customerStatusNonActive, setCustomerStatusNonActive] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await props.request.get(`/grafik-customer?type=time&date=${selectedDate.toISOString().split('T')[0]}`);
            let customerTime = [];
            let count       = [];
            let statusActive = [];
            let statusNonActive = [];
            response.data.forEach(element=>{
                customerTime.push(element.time)
                count.push(element.count)
                statusActive.push(element.active)
                statusNonActive.push(element.non_active)
            })
            setCustomerTime(customerTime)
            setCustomerCount(count)
            setCustomerStatusActive(statusActive)
            setCustomerStatusNonActive(statusNonActive)
        } catch (err) {
            handleErrors(err);
        }
        setIsLoading(false);
    }, [props.request, selectedDate]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const refresh = () => {
        fetchData();
    };

    const handleErrors = (err) => {
        if (err.response?.data?.errors) {
            Object.keys(err.response.data.errors).forEach((field) =>
                err.response.data.errors[field].forEach((message) => toast.error(message))
            );
        } else if (err.response?.data?.message) {
            toast.error(err.response.data.message);
        } else {
            toast.error(err.message);
        }
    };

    const options = {
        chart: {
            toolbar: { show: false },
            type: 'bar',
            height: 350
        },
        stroke: {
            show: true,
            width: 2,
        },
        labels: customerTime,
        dataLabels: {
            enabled: false
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            },
        },
        xaxis: {
            type: 'string'
        },
        yaxis: [{
            title: {
                text: 'Total Customer',
            },
            labels: {
                formatter: function (value) {
                    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                }
            },
        }],
        fill: { opacity: 1 },
    };

    const series = [
        {
            name: 'Total Customer',
            type: 'bar',
            data: customerCount,
            color: '#f78a2a',
        },
        {
            name: 'Pelanggan Active',
            type: 'bar',
            data: customerStatusActive,
            color: '#37bd75',
        },
        {
            name: 'Pelanggan Non-Active',
            type: 'bar',
            data: customerStatusNonActive,
            color: '#ff405a',
        },

    ];

    return (
        <Fragment>
            <Container fluid>
                <Card className="p-5 bg-white">
                    <CardHeader className="flex flex-col sm:flex-row card-header-tab justify-between">
                        <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                            Customer
                            <span className="mt-2 sm:ml-2 sm:mt-0 pb-1 w-full sm:w-auto" style={{ minWidth: '150px' }}>
                            <DatePicker
                                className="form-control dateInput ml-0 sm:ml-4 border-2 rounded-lg"
                                placeholderText="Select Your Date"
                                selected={selectedDate}
                                onChange={setSelectedDate}
                                dateFormat="d MMMM yyyy"
                            />
                        </span>
                        </div>
                        <div className="btn-actions-pane-right justify-between mt-2 sm:mt-0">
                            <ButtonGroup size="sm">
                                <Button color="warning"
                                        className={"btn-shadow bg-primary"}
                                        disabled={isLoading}
                                        onClick={refresh}>
                                    {isLoading ? (
                                        <span>
                                        <Spinner size="sm" color="secondary" /> Please wait...
                                    </span>
                                    ) : (
                                        <span>
                                        <i className="pe-7s-refresh-2"></i> Refresh
                                    </span>
                                    )}
                                </Button>
                            </ButtonGroup>
                        </div>
                    </CardHeader>
                    <CardBody className="pt-0">
                        {isLoading ? (
                            <div className="loader-overlay">
                                <Loader active type='ball-beat' />
                            </div>
                        ) : (
                            <div className="bar" style={{ marginTop: '-20px' }}>
                                <Chart options={options} series={series} type="bar" width="100%" height="325px" />
                            </div>
                        )}
                    </CardBody>
                </Card>
            </Container>
        </Fragment>

    );
};

export default CustomerTime;
