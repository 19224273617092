import React, {useState, useEffect, useCallback} from 'react';
import Chart from 'react-apexcharts';
import { Button, ButtonGroup, Card, CardBody, CardHeader, Spinner } from "reactstrap";
import Loader from "react-loaders";
import {toast} from "react-toastify";

const CustomerYear = ({ props }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [customerYear, setCustomerYear] = useState([]);
    const [customerCountYear, setCustomerCountYear] = useState([]);
    const [customerStatusActive, setCustomerStatusActive] = useState([]);
    const [customerStatusNonActive, setCustomerStatusNonActive] = useState([]);

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await props.request.get(`/grafik-customer?type=year`);
            let customerMonth = [];
            let count = [];
            let statusActive = [];
            let statusNonActive = [];
            response.data.forEach(element => {
                customerMonth.push(element.year);
                count.push(element.count);
                statusActive.push(element.active);
                statusNonActive.push(element.non_active);
            });
            setCustomerYear(customerMonth);
            setCustomerCountYear(count);
            setCustomerStatusActive(statusActive);
            setCustomerStatusNonActive(statusNonActive);
        } catch (err) {
            handleErrors(err);
        }
        setIsLoading(false);
    }, [props.request]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const refresh = () => {
        fetchData();
    };

    const handleErrors = (err) => {
        if (err.response?.data?.errors) {
            Object.keys(err.response.data.errors).forEach((field) =>
                err.response.data.errors[field].forEach((message) => toast.error(message))
            );
        } else if (err.response?.data?.message) {
            toast.error(err.response.data.message);
        } else {
            toast.error(err.message);
        }
    };

    const optio = {
        chart: {
            toolbar: { show: false },
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            },
        },
        dataLabels: { enabled: false },
        stroke: {
            show: true,
            width: 2,
        },
        labels: customerYear,
        yaxis: [{
            title: {
                text: 'Total Customer',
            },
            labels: {
                formatter: function (value) {
                    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                }
            },
        }],
        fill: { opacity: 1 },
    };

    const series = [
        {
            name: 'Total Customer',
            type: 'bar',
            data: customerCountYear,
            color: '#f78a2a',
        },
        {
            name: 'Pelanggan Active',
            type: 'bar',
            data: customerStatusActive,
            color: '#37bd75',
        },
        {
            name: 'Pelanggan Non-Active',
            type: 'bar',
            data: customerStatusNonActive,
            color: '#ff405a',
        }
    ];

    return (
        <Card className="p-5 bg-white">
            <CardHeader className="flex flex-row card-header-tab justify-between">
                <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                    Customer Tahunan
                </div>
                <div className="btn-actions-pane-right">
                    <ButtonGroup size="sm">
                        <Button color="warning"
                                className={"btn-shadow bg-primary"}
                                disabled={isLoading}
                                onClick={refresh}>
                            {isLoading &&
                                <span>
                                    <Spinner size="sm" color="secondary"/> Please wait...
                                </span>
                            }
                            {!isLoading &&
                                <span>
                                    <i className="pe-7s-refresh-2"></i> Refresh
                                </span>
                            }
                        </Button>
                    </ButtonGroup>
                </div>
            </CardHeader>
            <CardBody className="pt-0">
                {isLoading &&
                    <div className="loader-overlay">
                        <Loader active type='ball-beat' />
                    </div>
                }
                {!isLoading &&
                    <div className="bar" style={{ marginTop: '-20px' }}>
                        <Chart options={optio} series={series} type="bar" width="100%" height="325px" />
                    </div>
                }
            </CardBody>
        </Card>
    );
};

export default CustomerYear;
