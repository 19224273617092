import React, {useCallback, useEffect, useMemo, useState} from "react";
import {toast} from "react-toastify";
import {createColumnHelper} from "@tanstack/react-table";
import Card from "../../../components/card";
import Table from "../../../components/table/Table";
import {Link} from "react-router-dom";
import {FiEdit} from "react-icons/fi";

const List = ({ request }) => {
    const [data, setData]                   = useState([]);
    const [isLoading, setIsLoading]         = useState(false);
    const [pageCount, setPageCount]         = useState(0);
    const [totalRow, setTotalRow]           = useState(0);
    const [refresh, setRefresh]             = useState(false);
    const [dataProvinces, setProvinces]     = useState([]);
    const [dataDistrict, setDistrict]       = useState([]);
    const [dataSubdistrict, setSubdistrict] = useState([]);
    const [dataWards, setWards]             = useState([]);
    const [dataHamlets, setHamlets]         = useState([]);
    const [provinceId, setprovinceId]       = useState('');
    const [districtId, setDistrictId]       = useState('');
    const [subdistrictId, setSubdistrictId] = useState('');
    const [hamletId, setHamletId]           = useState('');
    const [wardsId, setWardsId]             = useState('');

    useEffect(() => {
        request.get('/provinces').then((response) => {
            setProvinces(response);
        });
    }, [request]);

    const handleProvince = (event) => {
        setprovinceId(event.target.value);
        setDistrictId('');
        const params = {
            province_id: event.target.value,
        };
        request.get('/districts', {params}).then((response) => {
            setDistrict(response);
        });
    };

    const handleDistrict = (event) => {
        setDistrictId(event.target.value);
        setSubdistrictId('');
        const params = {
            district_id: event.target.value,
        };
        request.get('/subdistricts', {params}).then((response) => {
            setSubdistrict(response);
        });
    };

    const handleSubdistrict = (event) => {
        setSubdistrictId(event.target.value);
        setWardsId('');
        const params = {
            subdistrict_id: event.target.value,
        };
        request.get('/wards', {params}).then((response) => {
            setWards(response);
        });
    };

    const handleWard = (event) => {
        setWardsId(event.target.value);
        setHamletId('');
        const params = {
            ward_id: event.target.value,
        };
        request.get('/hamlets', {params}).then((response) => {
            setHamlets(response);
        });
    };

    const handleHamlet = (event) => {
        setHamletId(event.target.value);
    };

    const fetchData = useCallback(
        async (pageSize, pageIndex, search) => {
            setIsLoading(true);
            const params = {
                per_page: pageSize,
                page: pageIndex,
                keyword: search,
                province_id: provinceId,
                district_id: districtId,
                subdistrict_id: subdistrictId,
                ward_id: wardsId,
                hamlet_id: hamletId,
            };

            try {
                const response = await request.get('/zones', { params });
                const startIndex = response.from;
                const updatedData = response.data.map((item, index) => ({
                    ...item,
                    listingNumber: startIndex + index,
                }));
                setData(updatedData);
                setPageCount(response.last_page);
                setTotalRow(response.total);
            } catch (err) {
                if (err.response?.data?.errors) {
                    Object.keys(err.response.data.errors).map((field) =>
                        err.response.data.errors[field].map((message) =>
                            toast.error(message)
                        )
                    );
                } else if (err.response?.data?.message) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error(err.message);
                }
            }

            setIsLoading(false);
            setRefresh(false);
        },
        [refresh, provinceId, districtId, subdistrictId, wardsId, hamletId] // eslint-disable-line react-hooks/exhaustive-deps
    );
    const columnHelper = createColumnHelper();

    const columns = useMemo(
        ()=>[
            columnHelper.accessor('listingNumber', {
                header: () => '#',
                cell: (info) => (
                    <div className='text-sm'>{info.row.original.listingNumber}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('coverage.coverage_name', {
                header: () => 'Cakupan',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>
                        {info.getValue() === undefined ? (
                            '-'
                        ) : (
                            info.getValue()
                        )}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('zone_province', {
                header: () => 'Provinsi',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>{info.getValue()}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('zone_district', {
                header: () => 'Kabupaten',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>{info.getValue()}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('zone_subdistrict', {
                header: () => 'Kecamatan',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>{info.getValue()}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('zone_ward', {
                header: () => 'Kelurahan',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>{info.getValue()}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('zone_hamlet', {
                header: () => 'Dusun',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>{info.getValue()}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('action', {
                header: () => 'Aksi',
                cell: (info) => (
                    <div className='flex'>
                        <Link
                            to={`/zones/${info.row.original.zone_id}/edit`}
                            className='font-medium text-warning'
                        >
                            <FiEdit size={24} />
                        </Link>
                    </div>
                ),
                footer: (info) => info.column.id,
                enableSorting: false,
                enableGrouping: false,
            }),
        ],[] // eslint-disable-line react-hooks/exhaustive-deps
    );

    return (
        <>
            <Card extra={'w-full sm:overflow-auto p-4'}>
                <h2 className='mb-1 text-xl font-bold text-secondary'>Daftar Daerah</h2>
                <div className='overflow-x-scroll xl:overflow-x-hidden'>
                    <Table
                        columns={columns}
                        data={data}
                        isLoading={isLoading}
                        fetchData={fetchData}
                        setRefresh={setRefresh}
                        pageCount={pageCount}
                        totalRow={totalRow}
                        filters={() => (
                            <>
                                <select
                                    onChange={handleProvince}
                                    value={provinceId}
                                    className='select select-sm max-w-xs'
                                >
                                    <option value='' disabled selected>
                                        Pilih Provinsi
                                    </option>
                                    {dataProvinces.map((item) => (
                                        <option key={item.province_id} value={item.province_id}>
                                            {item.province_name}
                                        </option>
                                    ))}
                                </select>
                                <select
                                    onChange={handleDistrict}
                                    value={districtId}
                                    className='select select-sm max-w-xs'
                                >
                                    <option value='' disabled selected>
                                        Pilih Kabupaten
                                    </option>
                                    {dataDistrict.map((item) => (
                                        <option key={item.district_id} value={item.district_id}>
                                            {item.district_name}
                                        </option>
                                    ))}
                                </select>
                                <select
                                    onChange={handleSubdistrict}
                                    value={subdistrictId}
                                    className='select select-sm max-w-xs'
                                >
                                    <option value='' disabled selected>
                                        Pilih Kecamatan
                                    </option>
                                    {dataSubdistrict.map((item) => (
                                        <option key={item.subdistrict_id} value={item.subdistrict_id}>
                                            {item.subdistrict_name}
                                        </option>
                                    ))}
                                </select>
                                <select
                                    onChange={handleWard}
                                    value={wardsId}
                                    className='select select-sm max-w-xs'
                                >
                                    <option value='' disabled selected>
                                        Pilih Desa
                                    </option>
                                    {dataWards.map((item) => (
                                        <option key={item.ward_id} value={item.ward_id}>
                                            {item.ward_name}
                                        </option>
                                    ))}
                                </select>
                                <select
                                    onChange={handleHamlet}
                                    value={hamletId}
                                    className='select select-sm max-w-xs'
                                >
                                    <option value='' disabled selected>
                                        Pilih Dusun
                                    </option>
                                    {dataHamlets.map((item) => (
                                        <option key={item.hamlet_id} value={item.hamlet_id}>
                                            {item.hamlet_name}
                                        </option>
                                    ))}
                                </select>
                            </>
                        )}
                        actions={() => (
                                <Link
                                    to='/zones/add'
                                    type='button'
                                    className='btn btn-secondary btn-sm ml-1'
                                >
                                    Tambah
                                </Link>
                        )}
                    />
                </div>
            </Card>
        </>
    );
}

export default List;
