import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Maps from "./Maps";
import General from "./General";

const ProfileOverview = ({ request }) => {
  const params = useParams();
  const [instalations, setInstalations] = useState({});
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    request.get(`/installations/${params.id}`).then((response) => {
      setInstalations(response);
      setLoading(false);
    });
  }, [params, refresh]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
      <div className='flex w-full flex-col gap-5'>
        <div className='grid h-full grid-cols-1 gap-5 lg:!grid-cols-12'>
          <div className='3xl:col-span-5 col-span-5 lg:col-span-6 lg:mb-0'>
            <Maps
                instalations  = {instalations}
                loading       = {loading}
                request       = {request}
                refresh       = {refresh}
                setRefresh    = {setRefresh}
            />
          </div>
          <div className='3xl:col-span-5 col-span-5 lg:col-span-6 lg:mb-0'>
            <General
                request       = {request}
                instalations  = {instalations}
                loading       = {loading}
                setRefresh    = {setRefresh}
            />
          </div>
        </div>
      </div>
  );
};
export default ProfileOverview;
