import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Card from 'components/card';
import { toast } from 'react-toastify';
import { createColumnHelper } from '@tanstack/react-table';
import { dateFormat } from '../../../utils/date';
import Table from '../../../components/table/Table';
import { convertToRupiah } from 'utils/general';
import Datepicker from 'react-tailwindcss-datepicker';
import { FiEdit, FiEye } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { FaWhatsapp } from 'react-icons/fa';
import moment from 'moment';

const List = ({ request }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [totalRow, setTotalRow] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [invoiceStatus, setInvoiceStatus] = useState('');
  const [year, setYear] = useState('');
  const [month, setMonth] = useState('');
  const [day, setDay] = useState('');
  const [days, setDays] = useState([]);
  const allDays = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
  ];
  const months = [
    {
      label: 'January',
      value: '01',
    },
    {
      label: 'Februari',
      value: '02',
    },
    {
      label: 'Maret',
      value: '03',
    },
    {
      label: 'April',
      value: '04',
    },
    {
      label: 'Mei',
      value: '05',
    },
    {
      label: 'Juni',
      value: '06',
    },
    {
      label: 'July',
      value: '07',
    },
    {
      label: 'Agustus',
      value: '08',
    },
    {
      label: 'September',
      value: '09',
    },
    {
      label: 'Oktober',
      value: '10',
    },
    {
      label: 'November',
      value: '11',
    },
    {
      label: 'Desember',
      value: '12',
    },
  ];

  const [value, setValue] = useState({
    startDate: null,
    endDate: null,
  });
  const handleValueChange = (newValue) => {
    setValue(newValue);
  };

  const handleInvoiceStatusChange = (event) => {
    setInvoiceStatus(event.target.value);
  };

  const fetchData = useCallback(
    async (pageSize, pageIndex, search) => {
      setIsLoading(true);

      const params = {
        per_page: pageSize,
        page: pageIndex,
        keyword: search,
        start_date: value?.startDate,
        end_date: value?.endDate,
        invoice_status: invoiceStatus,
      };

      try {
        const response = await request.get('/invoices', { params });
        const startIndex = response.from;

        const updatedData = response.data.map((item, index) => ({
          ...item,
          listingNumber: startIndex + index,
        }));
        setData(updatedData);
        setPageCount(response.last_page);
        setTotalRow(response.total);
      } catch (err) {
        if (err.response?.data?.errors) {
          Object.keys(err.response.data.errors).map((field) =>
            err.response.data.errors[field].map((message) =>
              toast.error(message)
            )
          );
        } else if (err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      }

      setIsLoading(false);
      setRefresh(false);
    },
    [refresh, value, invoiceStatus] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const setAllDay = () => {
    const monthAndYear = year + '-' + month;
    const maxDays = moment(monthAndYear, 'YYYY-MM').daysInMonth();
    const slicedDays = allDays.slice(0, maxDays);
    setDays(slicedDays);
  };

  const generateInvoice = async () => {
    const dateGenerate = day + '-' + month + '-' + year;
    const response = await request.post('/invoices/generate', {
      generateDate: dateGenerate,
    });
    toast.success(response.message);
    setRefresh(!refresh);
    setDay('');
    setMonth('');
    setYear('');
  };

  useEffect(() => {
    if (year !== '' && month !== '') {
      setAllDay();
    }
    if (year === '' || month === '') {
      setDay('');
      setDays([]);
    }
  }, [year, month]); // eslint-disable-line

  const columnHelper = createColumnHelper();

  const columns = useMemo(
    () => [
      columnHelper.accessor('listingNumber', {
        header: () => '#',
        cell: (info) => (
          <div className='text-sm'>{info.row.original.listingNumber}</div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('customer.customer_name', {
        header: () => 'Pelanggan',
        cell: (info) => (
          <div>
            <div className='max-w-xs truncate text-sm'>{info.getValue()}</div>
            <div className='text-xs'>
              {info.row.original.subscription.customer_number.c_number_value}
            </div>
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('invoice_no', {
        header: () => 'Nomor Invoice',
        cell: (info) => (
          <div className='max-w-2xl truncate text-sm'>{info.getValue()}</div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('customer.customer_whatsapp', {
        header: () => 'Nomor Whatsapp',
        cell: (info) => {
          const dataRow = info.row.original;
          const message = `Halo ${dataRow.customer?.customer_name},
          %0a%0aTerima kasih atas kepercayaan Pelanggan kepada ${
            dataRow.brand.brand_name
          }. Kami informasikan bahwa nomor langganan anda ${
            dataRow.subscription.customer_number.c_number_value
          } Periode ${dataRow.invoice_periods}
          %0a%0aAkan jatuh tempo pada tanggal ${dateFormat(
            dataRow.invoice_due_date,
            'DD MMM YY'
          )}
          %0a%0aPerbarui paket anda dengan info lebih detail di my.${
            dataRow.brand.brand_code
          }.id , atau hubungi kami via cs : wa.me/+6283878100200
          %0a%0aTerima kasih 🙏`;
          return (
            <div className='max-w-2xl truncate text-sm'>
              <button
                className={'text-green flex gap-2 font-medium'}
                onClick={() => {
                  window.open(
                    `https://wa.me/send?phone=62${dataRow.customer.customer_whatsapp.substring(
                      1,
                      dataRow.customer.customer_whatsapp.length
                    )}&text=${message}&app_absent=0`,
                    '_blank'
                  );
                }}
                disabled={dataRow.invoice_status !== 'draft'}
              >
                {info.row.original.customer?.customer_whatsapp}

                <FaWhatsapp size={24} />
              </button>
            </div>
          );
        },
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('package_name', {
        header: () => 'Nama Paket',
        cell: (info) => (
            <div className='max-w-2xl truncate text-sm'>{info.getValue()}</div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('invoice_type', {
        header: () => 'Jenis Tagihan',
        cell: (info) => (
          <div className='max-w-2xl truncate text-sm'>{info.getValue()}</div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('invoice_amount_due', {
        header: () => 'Tagihan',
        cell: (info) => (
          <div className='max-w-2xl truncate text-sm'>
            {convertToRupiah(info.getValue(), true)}
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('subscription.subscription_start_date', {
        header: () => <div className='min-w-max max-w-2xl'>Tgl Registrasi</div>,
        cell: (info) => (
          <div className='max-w-2xl text-sm'>
            {dateFormat(info.getValue(), 'DD MMM YY')}
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('invoice_periods', {
        header: () => 'Periode',
        cell: (info) => (
          <div className='min-w-max max-w-2xl text-sm'>{info.getValue()}</div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('invoice_due_date', {
        header: () => <div className='min-w-max max-w-2xl'>Tenggat Waktu</div>,
        cell: (info) => (
          <div className='min-w-max max-w-2xl text-sm'>
            {dateFormat(info.getValue(), 'DD MMM YY')}
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('invoice_issue_date', {
        header: () => <div className='min-w-max max-w-2xl'>Jatuh Tempo</div>,
        cell: (info) => (
          <div className='min-w-max max-w-2xl text-sm'>
            {dateFormat(info.getValue(), 'DD MMM YY')}
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('payment.transaction.transaction_capture_date', {
        header: () => <div className='min-w-max max-w-2xl'>Tanggal Bayar</div>,
        cell: (info) => (
          <div className='min-w-max max-w-2xl text-sm'>
            {info.getValue() ? dateFormat(info.getValue(), 'DD MMM YY') : '-'}
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('payment.transaction.transaction_method', {
        header: () => (
          <div className='min-w-max max-w-2xl'>Metode Pembayaran</div>
        ),
        cell: (info) => (
          <div className='max-w-2xl truncate text-sm'>
            {info.row.original.invoice_status === 'paid' ? (
              <Link
                to={`/transactions/${info.row.original.payment.transaction_id}`}
                target='_blank'
                className='flex flex-col underline'
              >
                <span className='font-bold uppercase text-emerald-700'>
                  {info.getValue()}
                </span>
                <span>{}</span>
              </Link>
            ) : (
              '-'
            )}
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('invoice_status', {
        header: () => 'Status',
        cell: (info) => (
          <div className='max-w-2xl truncate text-xs'>
            {info.getValue() === 'draft' ? (
              <span className='badge badge-warning'>Belum Lunas</span>
            ) : info.getValue() === 'paid' ? (
              <span className='badge badge-success'>Lunas</span>
            ) : info.getValue() === 'overdue' ? (
              <span className='badge badge-error'>Lewat Jatuh Tempo</span>
            ) : info.getValue() === 'void' ? (
              <span className='badge badge-error'>Invoice Batal</span>
            ) : info.getValue() === 'write-off' ? (
              <span className='badge badge-error'>Invoice Terhapus</span>
            ) : (
              <span className='badge badge-secondary'>Terkirim</span> // Default badge style
            )}
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('invoice_id', {
        header: () => 'Aksi',
        cell: (info) => (
          <div className='flex gap-x-1'>
            <Link
              to={`/invoices/${info.getValue()}`}
              className='font-medium text-success'
            >
              <FiEye size={20} />
            </Link>
            <Link
              to={`/invoices/${info.getValue()}/edit`}
              className='font-medium text-warning'
            >
              <FiEdit size={24} />
            </Link>
          </div>
        ),
        footer: (info) => info.column.id,
      }),
    ],
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );
  return (
    <div>
      <Card extra={'w-full sm:overflow-auto p-4'}>
        <h2 className='mb-1 text-xl font-bold text-secondary'>
          Daftar Invoice
        </h2>
        <div className='overflow-x-scroll xl:overflow-x-hidden'>
          <Table
            columns={columns}
            data={data}
            isLoading={isLoading}
            fetchData={fetchData}
            setRefresh={setRefresh}
            pageCount={pageCount}
            totalRow={totalRow}
            filters={() => (
              <>
                <Datepicker
                  toggleClassName=' h-full px-1 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed'
                  containerClassName='input input-sm relative flex bg-white justify-self-start w-auto '
                  inputClassName='outline-none w-full md:w-48 transition-all duration-300 font-light text-sm placeholder-gray-400'
                  value={value}
                  onChange={handleValueChange}
                  showShortcuts={true}
                  placeholder='Pilih Tanggal Jatuh Tempo'
                />
                <select
                  onChange={handleInvoiceStatusChange}
                  className='select select-sm max-w-xs'
                  value={invoiceStatus}
                >
                  <option value='' selected>
                    Semua Status
                  </option>
                  <option value='paid'>Lunas</option>
                  <option value='sent'>Terkirim</option>
                  <option value='overdue'>Lewat Jatuh Tempo</option>
                  <option value='draft'>Belum Lunas</option>
                  <option value='void'>Invoice Batal</option>
                  <option value='write-off'>Invoice Terhapus</option>
                </select>
              </>
            )}
            actions={() => (
              <div className='flex gap-2'>
                {/*<button*/}
                {/*  className='btn btn-secondary btn-sm w-full md:w-fit'*/}
                {/*  onClick={() => {*/}
                {/*    window.generate_modal.show();*/}
                {/*  }}*/}
                {/*>*/}
                {/*  generate*/}
                {/*</button>*/}
                <Link
                  to='/invoices/add'
                  type='button'
                  className='btn btn-secondary btn-sm w-full md:w-fit'
                >
                  Tambah
                </Link>
              </div>
            )}
          />
        </div>
      </Card>
      <dialog
        id='generate_modal'
        className='modal modal-bottom sm:modal-middle'
      >
        <form method='dialog' className='modal-box'>
          <h3 className='mb-2 text-lg font-bold'>Generate Invoice</h3>
          <div className='flex gap-2'>
            <select
              id='year'
              className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500'
              onChange={(e) => setYear(e.target.value)}
            >
              <option value=''>pilih tahun</option>
              <option value='2023'>2023</option>
              <option value='2024'>2024</option>
              <option value='2025'>2025</option>
              <option value='2026'>2026</option>
              <option value='2027'>2027</option>
              <option value='2028'>2028</option>
              <option value='2029'>2029</option>
            </select>
            <select
              id='month'
              className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500'
              onChange={(e) => setMonth(e.target.value)}
            >
              <option value=''>pilih bulan</option>
              {months.map((item, index) => {
                return (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                );
              })}
            </select>
            <select
              id='day'
              className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500'
              disabled={year === '' || month === ''}
              onChange={(e) => setDay(e.target.value)}
            >
              <option value=''>pilih hari</option>
              {days.map((item, index) => {
                return (
                  <option selected={day === item} key={index} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
          </div>
          <div className='modal-action'>
            {/* if there is a button in form, it will close the modal */}
            <button
              className='btn btn-success'
              onClick={() => generateInvoice()}
              disabled={day === '' || month === '' || year === ''}
            >
              Generate
            </button>
            <button className='btn'>Batal</button>
          </div>
        </form>
      </dialog>
    </div>
  );
};
export default List;
