import React, {useState, useEffect, Fragment, useCallback} from 'react';
import Chart from 'react-apexcharts';
import { Card, CardHeader, CardBody, Container, ButtonGroup, Button, Spinner } from 'reactstrap';
import Loader from 'react-loaders';
import { dateFormat, dateNow } from '../../../../utils/date';
import Select from 'react-select';
import {toast} from "react-toastify";

const minOffset = 0;
const maxOffset = (new Date()).getFullYear() - 2018;

const CustomerDay = ({ props }) => {
    const namabulan = '1 2 3 4 5 6 7 8 9 10 11 12'.split(' ');
    const tgl = new Date();
    const bulan = tgl.getMonth();
    const thisYear = (new Date()).getFullYear();

    const [tahun, setTahun] = useState(dateFormat(dateNow(), 'YYYY'));
    const [month, setMonth] = useState(namabulan[bulan]);
    const [isLoading, setIsLoading] = useState(true);
    const [customerDay, setCustomerDay] = useState([]);
    const [customerCountDay, setCustomerCountDay] = useState([]);
    const [customerStatusActive, setCustomerStatusActive] = useState([]);
    const [customerStatusNonActive, setCustomerStatusNonActive] = useState([]);

    const handleChange = (selectedOption) => {
        setTahun(selectedOption.value);
        setIsLoading(true);
    };

    const handleChangeMonth = (selectedOption) => {
        setMonth(selectedOption.value);
        setIsLoading(true);
    };

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await props.request.get(`/grafik-customer?type=day&tahun=${tahun}&month=${month}`);
            let customerDay = [];
            let count = [];
            let statusActive = [];
            let statusNonActive = [];
            response.data.forEach(element => {
                customerDay.push(element.date);
                count.push(element.count);
                statusActive.push(element.active);
                statusNonActive.push(element.non_active);
            });
            setCustomerDay(customerDay);
            setCustomerCountDay(count);
            setCustomerStatusActive(statusActive);
            setCustomerStatusNonActive(statusNonActive);
        } catch (err) {
            handleErrors(err);
        }
        setIsLoading(false);
    }, [tahun, month, props.request]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const refresh = () => {
        fetchData();
    };

    const handleErrors = (err) => {
        if (err.response?.data?.errors) {
            Object.keys(err.response.data.errors).forEach((field) =>
                err.response.data.errors[field].forEach((message) => toast.error(message))
            );
        } else if (err.response?.data?.message) {
            toast.error(err.response.data.message);
        } else {
            toast.error(err.message);
        }
    };

    const option = [];
    for (let i = minOffset; i <= maxOffset; i++) {
        const year = thisYear - i;
        option.push({ value: year, label: year });
    }

    const optio = [
        { value: '1', label: 'Januari' },
        { value: '2', label: 'Februari' },
        { value: '3', label: 'Maret' },
        { value: '4', label: 'April' },
        { value: '5', label: 'Mei' },
        { value: '6', label: 'Juni' },
        { value: '7', label: 'Juli' },
        { value: '8', label: 'Agustus' },
        { value: '9', label: 'September' },
        { value: '10', label: 'Oktober' },
        { value: '11', label: 'November' },
        { value: '12', label: 'Desember' },
    ];

    const options = {
        chart: {
            toolbar: { show: false },
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            },
        },
        dataLabels: { enabled: false },
        stroke: {
            show: true,
            width: 2,
        },
        labels: customerDay,
        yaxis: [{
            title: {
                text: 'Total Customer',
            },
            labels: {
                formatter: function (value) {
                    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                }
            },
        }],
        fill: { opacity: 1 },
    };

    const series = [
        {
            name: 'Total Customer',
            type: 'bar',
            data: customerCountDay,
            color: '#f78a2a',
        },
        {
            name: 'Pelanggan Active',
            type: 'bar',
            data: customerStatusActive,
            color: '#37bd75',
        },
        {
            name: 'Pelanggan Non-Active',
            type: 'bar',
            data: customerStatusNonActive,
            color: '#ff405a',
        },
    ];

    return (
        <Fragment>
            <Container fluid>
                <Card className="p-5 bg-white">
                    <CardHeader className="flex flex-wrap md:flex-nowrap flex-row card-header-tab justify-between pb-4">
                        <div className="flex flex-wrap md:flex-nowrap flex-row card-header-title font-size-lg text-capitalize font-weight-normal">
                            <span className="content-center">Customer</span>
                            <span className="ml-2 w-full md:w-48">
                                    <Select
                                        value={optio.find((opt) => opt.value === month)}
                                        onChange={handleChangeMonth}
                                        options={optio}
                                    />
                                </span>
                            <span className="ml-2 w-full md:w-48">
                                    <Select
                                        value={option.find((opt) => opt.value === tahun)}
                                        onChange={handleChange}
                                        defaultValue={option[0]}
                                        options={option}
                                    />
                                </span>
                        </div>
                        <div className="btn-actions-pane-right mt-4 md:mt-0">
                            <ButtonGroup size="sm">
                                <Button
                                    color="warning"
                                    className="btn-shadow bg-primary"
                                    disabled={isLoading}
                                    onClick={refresh}
                                >
                                    {isLoading ? (
                                        <span>
                                                <Spinner size="sm" color="secondary" /> Please wait...
                                            </span>
                                    ) : (
                                        <span>
                                                <i className="pe-7s-refresh-2"></i> Refresh
                                            </span>
                                    )}
                                </Button>
                            </ButtonGroup>
                        </div>
                    </CardHeader>
                    <CardBody className="pt-0">
                        {isLoading && (
                            <div className="loader-overlay">
                                <Loader active type='ball-beat' />
                            </div>
                        )}
                        {!isLoading && (
                            <div className="bar mt-5 md:mt-0">
                                <Chart options={options} series={series} type="bar" width="100%" height="325px" />
                            </div>
                        )}
                    </CardBody>
                </Card>
            </Container>
        </Fragment>

    );
};

export default CustomerDay;
