import React, { useState} from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import Card from "components/card";
import Skeleton from "react-loading-skeleton";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const Edit = ({ request }) => {
    const params = useParams();
    const navigate = useNavigate();
    const [sTiketStatus, setSTiketStatus] = useState('');

    const {
        register,
        handleSubmit,
        formState: { isLoading },
    } = useForm({
        defaultValues: async () => {
            try {
                const data = await request.get(`/tiket/${params.id}`);
                setSTiketStatus(data.s_tiket_status)
                return {
                    s_tiket_status: data.s_tiket_status,
                };
            } catch (err) {
                if (err.response?.data?.errors) {
                    Object.keys(err.response.data.errors).map((field) =>
                        err.response.data.errors[field].map((message) =>
                            toast.error(message)
                        )
                    );
                } else if (err.response?.data?.message) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error(err.message);
                }
            }
        },
    });


    const submitHandler = async (data) => {
        try {
            await request.post(`tiket/${params.id}/update-status`, data);
            toast.success('Berhasil menyimpan data');
            return navigate('/tiket');
        } catch (err) {
            if (err.response?.data?.errors) {
                Object.keys(err.response.data.errors).map((field) =>
                    err.response.data.errors[field].map((message) =>
                        toast.error(message)
                    )
                );
            } else if (err.response?.data?.message) {
                toast.error(err.response.data.message);
            } else {
                toast.error(err.message);
            }
        }
    };

    const errorHandler = (errors) => {
        Object.keys(errors).map((key) => {
            if (Array.isArray(errors[key])) {
                return errors[key].map((err) => {
                    return toast.error(err.message);
                });
            }

            return toast.error(errors[key].message);
        });
    };

    return (
        <Card extra={'w-full sm:overflow-auto p-4'}>
            <h2 className='text-xl font-bold text-navy-700'>Ubah Status Pengaduan</h2>
            <form
                onSubmit={handleSubmit(submitHandler, errorHandler)}
                className='mt-4 flex flex-col gap-y-1 overflow-x-scroll xl:overflow-x-hidden'
            >
                <div className='grid grid-cols-2'>
                    {sTiketStatus === 'new' && (
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Catatan</span>
                            </label>
                            <input
                                {...register('s_process_note', )}
                                type='text'
                                placeholder='Ketikan Catatan Status'
                                className='input input-bordered w-full max-w-sm'
                            />
                        </div>
                    )}
                    {sTiketStatus === 'process' && (
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Catatan</span>
                            </label>
                            <input
                                {...register('s_done_note', )}
                                type='text'
                                placeholder='Ketikan Catatan Status'
                                className='input input-bordered w-full max-w-sm'
                            />
                        </div>
                    )}
                    <div className='form-control w-full max-w-sm'>
                        <label className='label'>
                            <span className='label-text'>Status</span>
                        </label>
                        {isLoading ? (
                            <Skeleton height={45} />
                        ) : (
                            <select
                                {...register('s_tiket_status', {
                                    required: 'Status harus dipilih',
                                })}
                                className='select select-bordered'
                            >
                                <option value='' disabled>
                                    Pilih Status Pengaduan
                                </option>
                                <option value='new'>Baru</option>
                                <option value='process'>Proses</option>
                                <option value='done'>Selesai</option>
                            </select>
                        )}
                    </div>
                </div>
                <div className='flex gap-x-2'>
                    <button type='submit' className='btn btn-primary'>
                        Simpan
                    </button>
                    <Link to='/tiket' className='btn'>
                        Batal
                    </Link>
                </div>
            </form>
        </Card>
    );
};

export default Edit;
