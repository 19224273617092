import Footer from 'components/footer/FooterAuthDefault';
import authImg from 'assets/img/auth/auth.jpg';
import React from 'react';
// import FixedPlugin from "components/fixedPlugin/FixedPlugin";

export default function Auth({ children, ...rest }) {
  const renderChildren = () => {
    return React.Children.map(children, (child) => {
      return React.cloneElement(child, {
        ...rest,
      });
    });
  };

  return (
    <div>
      <div className='relative float-right h-full min-h-screen w-full !bg-white dark:!bg-secondary'>
        {/*<FixedPlugin />*/}
        <main className={`mx-auto min-h-screen`}>
          <div className='relative flex'>
            <div className='mx-auto flex min-h-full w-full flex-col justify-start pt-12 md:max-w-[75%] lg:h-screen lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0 xl:pl-[70px]'>
              <div className='mb-auto flex flex-col pl-5 pr-5 md:pr-0 md:pl-12 lg:max-w-[48%] lg:pl-0 xl:max-w-full'>
                {renderChildren()}
                <div className='absolute right-0 hidden h-full min-h-screen md:block lg:w-[49vw] 2xl:w-[44vw]'>
                  <div
                    className='absolute flex h-full w-full items-end justify-center bg-cover bg-center lg:rounded-bl-[120px] xl:rounded-bl-[200px]'
                    style={{ backgroundImage: `url(${authImg})` }}
                  />
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
