import { createContext, useMemo, useState } from 'react';

export const NavContext = createContext();

export const NavProvider = ({ children }) => {
  const [menu, setMenu] = useState('');
  const [open, setOpen] = useState(true);

  const value = useMemo(
    () => ({
      menu,
      setMenu,
      open,
      setOpen,
    }),
    [menu, open] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return <NavContext.Provider value={value}>{children}</NavContext.Provider>;
};
