import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import Card from 'components/card';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import React, {useEffect, useState} from 'react';

const Edit = ({ request }) => {
  const params = useParams();
  const navigate = useNavigate();
  const [tampilkanPassword, setTampilkanPassword] = useState(false);
  const [userLevel, setUserLevel] = useState([]);

  useEffect(()=>{
    request.get('/user-levels?status=active').then((response)=>{
      setUserLevel(response);
    });
  },[request]);
  const toggleVisibilitasPassword = () => {
    setTampilkanPassword(!tampilkanPassword);
  };

  const {
    register,
    handleSubmit,
    formState: { isLoading },
  } = useForm({
    defaultValues: async () => {
      try {
        const data = await request.get(`/users/${params.id}`);
        return {
          user_name: data.user_name,
          user_phone: data.user_phone,
          user_status: data.user_status,
          user_email: data.user_email,
          u_level_id: data.u_level_id,
          user_password: data.user_password,
        };
      } catch (err) {
        if (err.response?.data?.errors) {
          Object.keys(err.response.data.errors).map((field) =>
            err.response.data.errors[field].map((message) =>
              toast.error(message)
            )
          );
        } else if (err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      }
    },
  });

  const submitHandler = async (data, e) => {
    try {
      await request.put(`/users/${params.id}`, data);
      toast.success('Berhasil menyimpan data');
      return navigate('/users');
    } catch (err) {
      if (err.response?.data?.errors) {
        Object.keys(err.response.data.errors).map((field) =>
          err.response.data.errors[field].map((message) => toast.error(message))
        );
      } else if (err.response?.data?.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    }
  };

  const errorHandler = (errors, e) => {
    Object.keys(errors).map((key) => {
      if (Array.isArray(errors[key])) {
        return errors[key].map((err) => {
          return toast.error(err.message);
        });
      }
      return toast.error(errors[key].message);
    });
  };

  return (
    <>
      <Card extra={'w-full sm:overflow-auto p-4'}>
        <h2 className='text-xl font-bold text-navy-700'>Edit User</h2>
        <form
          onSubmit={handleSubmit(submitHandler, errorHandler)}
          className='mt-4 flex flex-col gap-y-1 overflow-x-scroll xl:overflow-x-hidden'
        >
          <div className='grid grid-cols-2 gap-y-1'>
            <div className='form-control w-full max-w-sm'>
              <label className='label'>
                <span className='label-text'>Nama</span>
              </label>
              {isLoading ? (
                <Skeleton height={45} />
              ) : (
                <input
                  {...register('user_name', {
                    required: 'Nama harus diisi',
                  })}
                  type='text'
                  placeholder='Nama User'
                  className='input input-bordered w-full max-w-sm'
                />
              )}
            </div>
            <div className='form-control w-full max-w-sm'>
              <label className='label'>
                <span className='label-text'>Level User</span>
              </label>
              {isLoading ? (
                <Skeleton height={45} />
              ) : (
                  <select
                      {...register('u_level_id')}
                      className='select select-bordered'
                  >
                    <option value='' disabled>
                      Pilih Servis
                    </option>
                    {userLevel.map((item) => (
                        <option key={item.u_level_id} value={item.u_level_id}>
                          {item.u_level_name}
                        </option>
                    ))}
                  </select>
              )}
            </div>
            <div className='form-control w-full max-w-sm'>
              <label className='label'>
                <span className='label-text'>Email</span>
              </label>
              {isLoading ? (
                <Skeleton height={45} />
              ) : (
                <input
                  {...register('user_email', {
                    required: 'Email harus diisi',
                  })}
                  type='text'
                  placeholder='Email User'
                  className='input input-bordered w-full max-w-sm'
                />
              )}
            </div>
            <div className='form-control w-full max-w-sm'>
              <label className='label'>
                <span className='label-text'>No Telephone</span>
              </label>
              {isLoading ? (
                <Skeleton height={45} />
              ) : (
                <input
                  {...register('user_phone', {
                    required: 'Telephone harus diisi',
                  })}
                  type='text'
                  placeholder='No Telephone User'
                  className='input input-bordered w-full max-w-sm'
                />
              )}
            </div>
            <div className='form-control w-full max-w-sm'>
              <label className='label'>
                <span className='label-text'>Password</span>
              </label>
              <div className='relative'>
                <input
                  {...register('user_password')}
                  type={tampilkanPassword ? 'text' : 'password'}
                  placeholder='Masukkan Password Pengguna'
                  className='input input-bordered w-full max-w-sm'
                />
                <button
                  type='button'
                  onClick={toggleVisibilitasPassword}
                  className='absolute inset-y-0 right-0 px-3 py-2'
                >
                  {tampilkanPassword ? (
                    <span style={{ fontWeight: 'bold', fontSize: '12px' }}>
                      Hide
                    </span>
                  ) : (
                    <span style={{ fontWeight: 'bold', fontSize: '12px' }}>
                      Show
                    </span>
                  )}
                </button>
              </div>
            </div>
            <div className='form-control w-full max-w-sm'>
              <label className='label'>
                <span className='label-text'>Status</span>
              </label>
              {isLoading ? (
                <Skeleton height={45} />
              ) : (
                <select
                  {...register('user_status', {
                    required: 'Status harus dipilih',
                  })}
                  className='select select-bordered'
                >
                  <option value='' disabled>
                    Pilih Status
                  </option>
                  <option value='active'>Aktif</option>
                  <option value='non-active'>Non-Aktif</option>
                </select>
              )}
            </div>
          </div>
          <div className='flex gap-x-2'>
            <button type='submit' className='btn btn-primary'>
              Simpan
            </button>
            <Link to='/users' className='btn'>
              Batal
            </Link>
          </div>
        </form>
      </Card>
    </>
  );
};

export default Edit;
