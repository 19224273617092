import Card from 'components/card';
import React from 'react';
import { formatDate } from 'utils/date';
import Skeleton from 'react-loading-skeleton';

const General = ({ customer, loading }) => {
  return (
    <Card extra={'w-full h-full p-4'}>
      {/* Header */}
      <div className='mt-2 mb-8 w-full'>
        <h4 className='px-2 text-xl font-bold text-navy-700 dark:text-white'>
          Informasi
        </h4>
        <p className='mt-2 px-2 text-base text-gray-600'>
          Informasi umum pelanggan
        </p>
      </div>
      {/* Cards */}
      <div className='grid grid-cols-2 gap-4 px-2'>
        <div className='flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none'>
          <p className='text-sm text-gray-600'>No. HP</p>
          {!loading != null ? (
            <p className='text-base font-medium text-navy-700 dark:text-white'>
              {customer.customer_phone || '-'}
            </p>
          ) : (
            <Skeleton height={29} width={100} />
          )}
        </div>

        <div className='flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none'>
          <p className='text-sm text-gray-600'>WhatsApp</p>
          {!loading != null ? (
            <p className='text-base font-medium text-navy-700 dark:text-white'>
              {customer.customer_whatsapp || '-'}
            </p>
          ) : (
            <Skeleton height={29} width={100} />
          )}
        </div>

        <div className='flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none'>
          <p className='text-sm text-gray-600'>Email</p>
          {!loading != null ? (
            <p className='text-base font-medium text-navy-700 dark:text-white'>
              {customer.customer_email || '-'}
            </p>
          ) : (
            <Skeleton height={29} width={100} />
          )}
        </div>

        <div className='flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none'>
          <p className='text-sm text-gray-600'>Referer</p>
          {!loading != null ? (
            <p className='text-base font-medium text-navy-700 dark:text-white'>
              {customer.customer_parent || '-'}
            </p>
          ) : (
            <Skeleton height={29} width={100} />
          )}
        </div>

        <div className='flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none'>
          <p className='text-sm text-gray-600'>Alamat</p>
          {!loading != null ? (
            <p className='text-base font-medium text-navy-700 dark:text-white'>
              {customer.customer_address || '-'}
            </p>
          ) : (
            <Skeleton height={29} width={100} />
          )}
        </div>

        <div className='flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none'>
          <p className='text-sm text-gray-600'>Tanggal Daftar</p>
          {!loading != null ? (
            <p className='text-base font-medium text-navy-700 dark:text-white'>
              {formatDate(customer.customer_create_date) || '-'}
            </p>
          ) : (
            <Skeleton height={29} width={100} />
          )}
        </div>
      </div>
    </Card>
  );
};

export default General;
