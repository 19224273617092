import { useState} from "react";
import Pdf from "./Pdf";
import General from "./General";

const InvoiceDetail = ({ request }) => {
    const [refresh, setRefresh] = useState(false);

    return (
        <div className='flex w-full flex-col gap-5'>
            <div className='grid h-full grid-cols-1 gap-5 lg:!grid-cols-12'>
                <div className='3xl:col-span-5 col-span-5 lg:col-span-6 lg:mb-0'>
                    <Pdf
                        request       = {request}
                        refresh       = {refresh}
                        setRefresh    = {setRefresh}
                    />
                </div>
                <div className='3xl:col-span-5 col-span-5 lg:col-span-6 lg:mb-0'>
                    <General
                        request       = {request}
                        setRefresh    = {setRefresh}
                    />
                </div>
            </div>
        </div>
    );
};
export default InvoiceDetail;
