import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import Card from 'components/card';
import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Editor from 'components/ck-editor/CKEditor5';
import config from 'config/app';

const Edit = ({ request, token }) => {
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await request.get(`/blog-posts/${params.id}`);
        const imgUrl = `${config.storageServiceUrl}/images/${data.data.b_post_image}`;
        setValue('b_post_image', imgUrl);
      } catch (error) {}
    }
    fetchData();
  }, [params.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    control,
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { isLoading },
  } = useForm({
    defaultValues: async () => {
      try {
        const data = await request.get(`/blog-posts/${params.id}`);
        return {
          b_post_title    : data.data.b_post_title,
          b_post_status   : data.data.b_post_status,
          b_post_content  : data.data.b_post_content,
          b_post_image    : data.data.b_post_image,
        };
      } catch (err) {
        if (err.response?.data?.errors) {
          Object.keys(err.response.data.errors).map((field) =>
            err.response.data.errors[field].map((message) =>
              toast.error(message)
            )
          );
        } else if (err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      }
    },
  });

  const onImageStateChange = async (data) => {
    const encodeIMG = await encodeImage(data);
    setValue('b_post_image', encodeIMG);
  };

  const encodeImage = async (imageFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.readAsDataURL(imageFile);
    });
  };

  const submitHandler = async (data, e) => {
    try {
      await request.put(`/blog-posts/${params.id}`, data);
      toast.success('Berhasil menyimpan data');
      return navigate('/blog');
    } catch (err) {
      if (err.response?.data?.errors) {
        Object.keys(err.response.data.errors).map((field) =>
          err.response.data.errors[field].map((message) => toast.error(message))
        );
      } else if (err.response?.data?.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    }
  };

  const onEditorSigning = (data) => {
    setValue('page_signature', data);
  };

  const errorHandler = (errors, e) => {
    Object.keys(errors).map((key) => {
      if (Array.isArray(errors[key])) {
        return errors[key].map((err) => {
          return toast.error(err.message);
        });
      }

      return toast.error(errors[key].message);
    });
  };

  return (
    <>
      <Card extra={'w-full sm:overflow-auto p-4'}>
        <h2 className='text-xl font-bold text-navy-700'>Edit Paket</h2>
        <form
          onSubmit={handleSubmit(submitHandler, errorHandler)}
          className='mt-4 flex flex-col gap-y-1 overflow-x-scroll xl:overflow-x-hidden'
        >
          <div className='grid grid-cols-2 gap-y-1'>
            <div className='form-control w-full max-w-sm'>
              <label className='label'>
                <span className='label-text'>Judul</span>
              </label>
              {isLoading ? (
                <Skeleton height={45} />
              ) : (
                  <input
                      {...register('b_post_title', { required: 'Judul harus diisi' })}
                      type='text'
                      placeholder='Masukan judul'
                      className='input input-bordered w-full max-w-sm'
                  />
              )}
            </div>
            <div className='form-control w-full max-w-sm'>
              <label className='label'>
                <span className='label-text'>Status</span>
              </label>
              {isLoading ? (
                <Skeleton height={45} />
              ) : (
                  <select
                      {...register('b_post_status', {
                        required: 'Status harus dipilih',
                      })}
                      className='select select-bordered'
                  >
                    <option value='' disabled selected>
                      Pilih Status
                    </option>
                    <option value='published'>Publikasikan</option>
                    <option value='draft'>Draft</option>
                  </select>
              )}
            </div>
            <div className='form-control w-full max-w-sm'>
              <span className='label-text'>Gambar</span>
              <label className='label'>
                {getValues('b_post_image') instanceof File ? (
                  <img
                    src={URL.createObjectURL(getValues('b_post_image'))}
                    alt='Blog'
                    style={{
                      maxWidth: '100%',
                      maxHeight: '200px',
                    }}
                  />
                ) : (
                  <img
                    src={getValues('b_post_image')}
                    alt='Blog'
                    style={{ maxWidth: '100%', maxHeight: '200px' }}
                  />
                )}
              </label>
              <input
                type='file'
                className='file-input file-input-bordered file-input-warning w-full max-w-xs'
                onChange={(event) => {
                  onImageStateChange(event.target.files[0]);
                }}
              />
            </div>
          </div>
          <div className='gap-y-1'>
            <div className='w-full'>
              <label className='label'>
                <span className='label-text'>Deskripsi</span>
              </label>
              {isLoading ? (
                <Skeleton height={95} />
              ) : (
                <Editor
                  control={control}
                  name='b_post_content'
                  label='Deskripsi'
                  onSigning={onEditorSigning}
                  token={token}
                />
              )}
            </div>
          </div>
          <div className='flex gap-x-2'>
            <button type='submit' className='btn btn-primary'>
              Simpan
            </button>
            <Link to='/blog' className='btn'>
              Batal
            </Link>
          </div>
        </form>
      </Card>
    </>
  );
};

export default Edit;
