import React from 'react';
import Card from 'components/card';
import Pagination from 'components/pagination';
import { convertToRupiah } from 'utils/general';
import Skeleton from 'react-loading-skeleton';
import appConfig from 'config/app';
import { toast } from 'react-toastify';

const Subscriptions = ({ customer_id, request }) => {
  const [customerSubs, setCusSubs] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [number, setNumber] = React.useState('');
  const [status, setStatus] = React.useState('');

  const [page, setPage] = React.useState(1);
  const [per_page, setPerPage] = React.useState(4);
  const [last_page, setLastPage] = React.useState(1);

  React.useEffect(() => {
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async (page = 1) => {
    setLoading(true);

    setPage(page);

    request
      .get(`/subscriptions`, { params: { customer_id, page, per_page } })
      .then((response) => {
        if (response) {
          setCusSubs(response.data || []);
          setLastPage(response.last_page || 1);
          setLoading(false);
        }
      });
  };

  const ubahStatus = async () => {
    await request.put(`/customer-numbers/${number}?c_number_status=${status}`);
    setShowModal(false);
    toast.success('Sukses mengubah data');
    getData();
  };

  return (
    <Card extra={'w-full h-full p-3'}>
      <div style={{ padding: 4, paddingBottom: 70 }}>
        <div className='mb-4 mt-2 ml-3 w-full'>
          <h4 className='text-xl font-bold text-navy-700'>Daftar Langganan</h4>
          <p className='py-2 text-base font-normal text-gray-600 '>
            Semua langganan yang dimiliki pelanggan
          </p>
        </div>
        {customerSubs.map((item, index) => {
          return (
            <div className='flex w-full items-center justify-between rounded-2xl bg-white px-3 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none'>
              <div className='flex items-center gap-x-3'>
                <div className=''>
                  <img
                    className='h-20 w-20 rounded-lg'
                    src={appConfig.storageServiceUrl + '/images/router.png'}
                    alt=''
                  />
                </div>
                <div>
                  <p className='text-lg font-bold text-navy-700'>
                    {item.package ? item.package.package_name : ''} (
                    {item.subscription_type
                      ? item.subscription_type.s_type_name
                      : ''}
                    )
                  </p>
                  <p className='mt-2 text-sm text-gray-600'>
                    Rp. {convertToRupiah(item.subscription_fee)}
                  </p>
                </div>
              </div>
              {item.customer_number.c_number_status === 'active' ? (
                <button
                  className='btn btn-success btn-sm md:w-36 xs:w-32 xs:text-sm'
                  onClick={() => {
                    setShowModal(true);
                    setNumber(item.customer_number.c_number_id);
                    setStatus('non-active');
                  }}
                >
                  ACTIVE
                </button>
              ) : (
                <button
                  className='btn btn-error btn-sm md:w-36 xs:w-32 xs:text-sm'
                  onClick={() => {
                    setShowModal(true);
                    setNumber(item.customer_number.c_number_id);
                    setStatus('active');
                  }}
                >
                  NON-ACTIVE
                </button>
              )}
            </div>
          );
        })}
        {loading && (
          <div>
            <div className=' w-full items-center justify-between rounded-2xl bg-white p-3 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none'>
              <Skeleton height={60} width={'100%'} />
            </div>
            <div className=' w-full items-center justify-between rounded-2xl bg-white p-3 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none'>
              <Skeleton height={60} width={'100%'} />
            </div>
            <div className=' w-full items-center justify-between rounded-2xl bg-white p-3 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none'>
              <Skeleton height={60} width={'100%'} />
            </div>
            <div className=' w-full items-center justify-between rounded-2xl bg-white p-3 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none'>
              <Skeleton height={60} width={'100%'} />
            </div>
          </div>
        )}
      </div>
      <Pagination
        page={page}
        per_page={per_page}
        setPerPage={setPerPage}
        setPage={setPage}
        last_page={last_page}
        getData={(page) => getData(page)}
      />
      {showModal ? (
        <>
          <div
            className='fixed inset-0 z-50 flex flex-col items-center justify-center overflow-auto rounded-xl shadow-lg backdrop-blur-sm'
            onClick={() => setShowModal(false)}
          >
            <div
              className='relative w-fit items-center justify-center gap-3 rounded-xl border-2 border-[#DCDCDC] bg-[#F6F6F6] pt-2'
              onClick={(e) => e.stopPropagation()}
            >
              <div className='p-2 '>
                <h2 className='ml-2 p-2 text-xs font-bold sm:text-sm md:text-base lg:text-lg'>
                  Yakin ingin mengubah status langganan pelanggan?
                </h2>
                <div className='flex flex-row-reverse'>
                  <button
                    className='mr-1 mb-1 rounded bg-emerald-500 px-6 py-3 text-sm font-bold uppercase text-white shadow outline-none transition-all duration-150 ease-linear hover:shadow-lg focus:outline-none active:bg-emerald-600'
                    type='submit'
                    onClick={ubahStatus}
                  >
                    Ubah
                  </button>
                  <button
                    className='mr-1 mb-1 rounded bg-red-500 px-6 py-3 text-sm font-bold uppercase shadow outline-none transition-all duration-150 ease-linear hover:shadow-lg focus:outline-none active:bg-red-600'
                    type='button'
                    onClick={() => setShowModal(false)}
                  >
                    Batal
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </Card>
  );
};

export default Subscriptions;
