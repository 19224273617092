import React from "react";
import Tabs from "../../../components/tabs/Tabs";
import General from "./General";
import Sistem from "./Sistem";
import Business from "./Business";

const Index = (props) =>{

    const tabs = [
        { title: 'General', content:
                <General {...props.props} />
        },
        { title: 'Sistem', content:
                <Sistem {...props.props} />
        },
        { title: 'Business', content:
                <Business {...props.props} />
        }
    ];
    return (
        <>
            <Tabs tabs={tabs} />
        </>
    );
};

export default Index;
