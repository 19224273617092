import { Route, Routes } from 'react-router-dom';
import List from './component/List';
import Details from "./component/details";

const TiketSubsmissions = (props) => {
    return (
        <Routes>
            <Route path='/' element={<List {...props} />} />
            <Route path='/:id' element= {<Details {...props}  />}/>
        </Routes>
    );
};

export default TiketSubsmissions;
