import React, {useCallback, useMemo, useState} from 'react';
import Card from 'components/card';
import {toast} from 'react-toastify';
import {createColumnHelper} from '@tanstack/react-table';
import {dateFormat} from '../../../utils/date';
import Table from '../../../components/table/Table';
import {FiEdit} from 'react-icons/fi';
import Datepicker from 'react-tailwindcss-datepicker';
import {FaRegShareSquare} from "react-icons/fa";

const List = ({request}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [totalRow, setTotalRow] = useState(0);
    const [refresh, setRefresh] = useState(false);
    const [status, setStatus] = useState('');
    const [filterDate, setFilterDate] = useState({
        startDate: '',
        endDate: ''
    });
    const [technicians, setTechnicians] = useState([]);
    const [selectedPhone, setSelectedPhone] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);

    function formatWhatsAppNumber(whatsappNumber) {
        let formattedNumber = whatsappNumber.replace(/\s+/g, '').replace(/^0+/, '');
        if (!formattedNumber.startsWith('+62')) {
            formattedNumber = '62' + formattedNumber;
        }
        return formattedNumber;
    }

    const getMessage = (selectedRow) => {
        if (!selectedRow || !selectedPhone) return '';

        const whatsappNumber = formatWhatsAppNumber(selectedRow.submission?.customer?.customer_whatsapp);
        const selectedTechnician = technicians.find(technician => technician.technician_phone === selectedPhone);
        const technicianName = selectedTechnician ? selectedTechnician.technician_name : 'Teknisi';
        const installationCoordinates = selectedRow.installation_coordinates;
        return `Halo ${technicianName},
        %0a%0aBerikut ini adalah data pemasangan yang perlu Anda kerjakan:
        %0a%0aNama: ${selectedRow.submission?.customer?.customer_name}
        %0aAlamat: ${selectedRow.installation_address}
        %0aNo HP: wa.me/%2B${whatsappNumber}
        %0aLink Maps: https://www.google.com/maps?q=${installationCoordinates}
        %0aPaket: ${selectedRow.submission?.package?.package_name}`;
    };

    const handleTechnicianClick = async (info) => {
        setSelectedRow(info.row.original);
        if (!technicians.length) {
            const response = await request.get(`/technician`);
            setTechnicians(response);
        }
        window.generate_modal.show();
    };

    const fetchData = useCallback(
        async (pageSize, pageIndex, search) => {
            setIsLoading(true);

            const params = {
                per_page: pageSize,
                page: pageIndex,
                keyword: search,
                status: status,
                startDate: filterDate.startDate,
                endDate: filterDate.endDate,
            };

            try {
                const response = await request.get('/installations', {params});
                const startIndex = response.from;

                const updatedData = response.data.map((item, index) => ({
                    ...item,
                    listingNumber: startIndex + index,
                }));

                setData(updatedData);
                setPageCount(response.last_page);
                setTotalRow(response.total);
            } catch (err) {
                if (err.response?.data?.errors) {
                    Object.keys(err.response.data.errors).map((field) =>
                        err.response.data.errors[field].map((message) =>
                            toast.error(message)
                        )
                    );
                } else if (err.response?.data?.message) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error(err.message);
                }
            }

            setIsLoading(false);
            setRefresh(false);
        },
        [refresh, status, filterDate] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const columnHelper = createColumnHelper();

    const columns = useMemo(
        () => [
            columnHelper.accessor('listingNumber', {
                header: () => '#',
                cell: (info) => (
                    <div className='text-sm'>{info.row.original.listingNumber}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('submission.customer.customer_name', {
                header: () => 'Pelanggan',
                cell: (info) => (
                    <div className='max-w-xs truncate text-sm'>{info.getValue()}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('submission.customer.reseller.reseller_name', {
                header: () => 'Marketing',
                cell: (info) => (
                    <div
                        className='max-w-xs truncate text-sm'>{info.getValue() === undefined ? '-' : info.getValue()}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('installation_address', {
                header: () => 'Alamat Pemasangan',
                cell: (info) => (
                    <div className='max-w-xs truncate text-sm'>{info.getValue()}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('installation_status', {
                header: () => 'Status',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-xs'>
                        {info.getValue() === 'waiting' ? (
                            <span className='badge badge-warning'>{info.getValue()}</span>
                        ) : info.getValue() === 'executing' ? (
                            <span className='badge badge-info'>{info.getValue()}</span>
                        ) : info.getValue() === 'executed' ? (
                            <span className='badge badge-success'>{info.getValue()}</span>
                        ) : info.getValue() === 'cancelled' ? (
                            <span className='badge badge-error'>{info.getValue()}</span>
                        ) : (
                            <span className='badge'>{info.getValue()}</span> // Default badge style
                        )}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('installation_execution_date', {
                header: () => 'Tanggal Eksekusi',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>
                        {info.getValue()
                            ? dateFormat(info.getValue(), 'DD MMM YYYY HH.mm')
                            : '-'}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('installation_create_date', {
                header: () => <div className='min-w-max'>Tanggal Pengajuan</div>,
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>
                        {dateFormat(info.getValue(), 'DD MMM YYYY HH.mm')}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('-', {
                header: () => 'Aksi',
                cell: (info) => (
                    <div
                        className='flex gap-x-1'
                        style={{cursor: 'pointer'}}
                    >
                        <a href={`installations/${info.row.original.installation_id}`}>
                            <FiEdit size={20} color='#FBBD29'/>
                        </a>
                        <div className='inline-flex max-w-2xl gap-x-1 text-sm'
                             style={{cursor: 'pointer'}}
                             onClick={() => handleTechnicianClick(info)}
                        >
                            {info.getValue()}
                            <FaRegShareSquare size={20} className='font-medium text-success'/>
                        </div>
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
        ],
        [] // eslint-disable-line react-hooks/exhaustive-deps
    );

    return (
        <div>
            <Card extra={'w-full sm:overflow-auto p-4'}>
                <h2 className='mb-1 text-xl font-bold text-secondary'>
                    Daftar Instalasi
                </h2>
                <div className='overflow-x-scroll xl:overflow-x-hidden'>
                    <Table
                        columns={columns}
                        data={data}
                        isLoading={isLoading}
                        fetchData={fetchData}
                        setRefresh={setRefresh}
                        pageCount={pageCount}
                        totalRow={totalRow}
                        filters={() => {
                            return (
                                <>
                                    <select name="" id="" value={status} className='select select-sm'
                                            onChange={(e) => setStatus(e.target.value)}>
                                        <option value="">semua</option>
                                        <option value="waiting">waiting</option>
                                        <option value="cancelled">cancelled</option>
                                        <option value="executing">executing</option>
                                        <option value="executed">executed</option>
                                    </select>
                                    <Datepicker
                                        maxDate={new Date()}
                                        toggleClassName='relative left-2 right-0 h-full px-1 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed'
                                        containerClassName='input input-sm relative flex bg-white justify-self-start'
                                        inputClassName='outline-none w-full md:w-48 transition-all duration-300 font-light text-sm placeholder-gray-400'
                                        value={filterDate}
                                        onChange={(e) => setFilterDate(e)}
                                        showShortcuts={true}
                                        placeholder='Pilih Tanggal Pengajuan'
                                    />
                                </>
                            )
                        }}
                    />
                </div>
            </Card>
            <dialog
                id='generate_modal'
                className='modal modal-bottom sm:modal-middle'
            >
                <form method='dialog' className='modal-box'>
                    <h3 className='mb-2 text-lg font-bold'>Teknisi</h3>
                    <div className='form-control w-full max-w-sm'>
                        <select
                            id='technician_phone'
                            className='select select-bordered'
                            onChange={(e) => setSelectedPhone(e.target.value)}
                            value={selectedPhone}
                        >
                            <option value=''>pilih nomor teknisi</option>
                            {technicians.map((technician) => (
                                <option key={technician.technician_id} value={technician.technician_phone}>
                                    {`${technician.technician_name} - ${technician.technician_phone}`}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className='modal-action'>
                        <button
                            type="button"
                            className="btn btn-success"
                            onClick={() => {
                                const message = getMessage(selectedRow);
                                window.open(
                                    `https://wa.me/send?phone=62${selectedPhone.substring(1, selectedPhone.length)}&text=${message}&app_absent=0`,
                                    '_blank'
                                );
                            }}
                        >
                            Share
                        </button>
                        <button className="btn">Batal</button>
                    </div>
                </form>
            </dialog>
        </div>
    );
};
export default List;
