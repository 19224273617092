import React from 'react';

export default function Pagination(props) {
  const pages = [
    <div
      onClick={() => props.getData(1)}
      style={{
        padding: 5,
        color: props.page === 1 ? 'orange' : 'grey',
        cursor: 'pointer',
      }}
    >
      1
    </div>,
  ];

  if (props.page <= 6 && props.last_page <= 6) {
    // ketika jumlah data kurang dari 6
    for (let i = 2; i <= props.last_page; i++) {
      pages.push(
        <div
          style={{
            padding: 5,
            color: props.page === i ? 'orange' : 'grey',
            cursor: 'pointer',
          }}
        >
          {i}
        </div>
      );
    }
  } else if (props.page <= 6 && props.last_page > 6) {
    // ketika jumlah data kurang dari 6 | pilihan kurang dari 6
    for (let i = 2; i <= 6; i++) {
      pages.push(
        <div
          style={{
            padding: 5,
            color: props.page === i ? 'orange' : 'grey',
            cursor: 'pointer',
          }}
        >
          {i}
        </div>
      );
    }
  } else if (
    props.page > 6 &&
    props.page <= props.last_page - 6 &&
    props.last_page > 6
  ) {
    const pages = [];

    for (let i = props.page - 2; i <= props.page + 2; i++) {
      pages.push(
        <div
          style={{
            padding: 5,
            color: props.page === i ? 'orange' : 'grey',
            cursor: 'pointer',
          }}
        >
          {i}
        </div>
      );
    }
  } else if (
    props.page > 6 &&
    props.page < props.last_page - 6 &&
    props.page > props.last_page - 6 &&
    props.last_page > 6
  ) {
    const pages = [];

    for (var i = props.last_page - 6; i <= props.last_page; i++) {
      pages.push(
        <div
          style={{
            padding: 5,
            color: props.page === i ? 'orange' : 'grey',
            cursor: 'pointer',
          }}
        >
          {i}
        </div>
      );
    }
  } else if (props.page > props.last_page - 6 && props.last_page > 6) {
    const pages = [];

    for (let i = props.last_page - 5; i <= props.last_page; i++) {
      pages.push(
        <div
          style={{
            padding: 5,
            color: props.page === i ? 'orange' : 'grey',
            cursor: 'pointer',
          }}
        >
          {i}
        </div>
      );
    }
  }

  return (
    <div
      style={{
        alignItems: 'center',
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        bottom: 10,
      }}
    >
      {props.last_page < 6 && (
        <div style={{ display: 'flex' }}>
          {pages.map((item, index) => {
            var itemPage = React.cloneElement(item, {
              onClick: () => props.getData(item.props.children),
            });
            return itemPage;
          })}
        </div>
      )}
      {props.page <= 6 && props.last_page > 6 && (
        <div style={{ display: 'flex' }}>
          {pages.map((item, index) => {
            var itemPage = React.cloneElement(item, {
              onClick: () => props.getData(item.props.children),
            });
            return itemPage;
          })}
          <p
            style={{
              paddingLeft: 5,
              paddingRight: 5,
              paddingBottom: 0,
              marginBottom: 0,
              color: 'grey',
            }}
          >
            ...
          </p>
          <Button
            title='Selanjutnya'
            onClick={() => props.getData(props.page + 1)}
          />
        </div>
      )}
      {props.last_page > 6 &&
        props.page > 6 &&
        props.page <= props.last_page - 6 && (
          <div style={{ display: 'flex' }}>
            <Button
              title='Sebelumnya'
              onClick={() =>
                props.getData(props.page !== 1 ? props.page - 1 : 1)
              }
            />
            <p
              style={{
                paddingLeft: 5,
                paddingRight: 5,
                paddingBottom: 0,
                marginBottom: 0,
                color: 'grey',
              }}
            >
              ...
            </p>
            {pages.map((item, index) => {
              var itemPage = React.cloneElement(item, {
                onClick: () => props.getData(item.props.children),
              });
              return itemPage;
            })}
            <p
              style={{
                paddingLeft: 5,
                paddingRight: 5,
                paddingBottom: 0,
                marginBottom: 0,
                color: 'grey',
              }}
            >
              ...
            </p>
            <Button
              title='Selanjutnya'
              onClick={() => props.getData(props.page + 1)}
            />
          </div>
        )}
      {props.last_page > 6 && props.page > props.last_page - 6 && (
        <div style={{ display: 'flex' }}>
          <Button
            title='Sebelumnya'
            onClick={() => props.getData(props.page !== 1 ? props.page - 1 : 1)}
          />
          <p
            style={{
              paddingLeft: 5,
              paddingRight: 5,
              paddingBottom: 0,
              marginBottom: 0,
              color: 'grey',
            }}
          >
            ...
          </p>
          {pages.map((item, index) => {
            var itemPage = React.cloneElement(item, {
              onClick: () => props.getData(item.props.children),
            });
            return itemPage;
          })}
        </div>
      )}
    </div>
  );
}

function Button({ onClick, title }) {
  return (
    <button
      style={{
        borderRadius: 2,
        paddingLeft: 4,
        paddingRight: 4,
        paddingTop: 0,
        paddingBottom: 0,
        backgroundColor: 'grey',
        color: '#fff',
        fontWeight: 'bold',
        fontSize: 10,
      }}
      onClick={() => onClick()}
    >
      {title}
    </button>
  );
}
