import Dropdown from 'components/dropdown';
import { FiAlignJustify } from 'react-icons/fi';
import { Link } from 'react-router-dom';
// import { BsArrowBarUp } from 'react-icons/bs';
import { FiSearch } from 'react-icons/fi';
import { NavContext } from 'contexts/NavContext';
import { IoMdNotificationsOutline } from 'react-icons/io';
import avatar from 'assets/images/user.png';
import { toast } from 'react-toastify';
import { useContext, useEffect, useRef, useState } from 'react';

const Navbar = ({ request, user, logout }) => {
  const nav = useContext(NavContext);
  const [expandSearch, setExpandSearch] = useState(false);
  const searchInputRef = useRef(null);

  const handleExpandClick = () => {
    setExpandSearch(true);
  };

  const handleExpandBlur = () => {
    setExpandSearch(false);
  };

  useEffect(() => {
    if (expandSearch === true) {
      searchInputRef.current.focus();
    }
  }, [expandSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleLogoutClick = () => {
    request
      .post('/auth/logout')
      .then(() => {
        //
      })
      .catch((err) => {
        console.error(err);
        toast(err ? err?.response?.data?.message : err.message);
      })
      .finally(() => {
        logout();
      });
  };

  return (
    <nav className='mx-2 flex flex-row items-center justify-between p-2'>
      <div className='flex w-full items-center justify-between gap-x-4 md:justify-start'>
        <h2
          className={`${
            expandSearch ? 'hidden ' : ''
          }shrink text-[28px] capitalize text-secondary`}
        >
          Admin
        </h2>
        <div className='relative flex h-full items-center rounded-full bg-zircon text-navy-700 xl:w-[225px]'>
          <button
            onClick={handleExpandClick}
            className='absolute inset-y-0 left-0 pl-3 pr-2 text-xl'
          >
            <FiSearch className='h-4 w-4 text-gray-400' />
          </button>
          <input
            ref={searchInputRef}
            onBlur={handleExpandBlur}
            type='text'
            placeholder='Cari...'
            className={`${
              expandSearch ? 'w-full pl-10' : 'w-0 p-5'
            } input input-sm w-full rounded-full md:w-60 md:pl-10`}
          />
        </div>
      </div>

      <div className='relative flex flex-grow items-center justify-end gap-x-4 px-2 py-2 md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-x-6'>
        <span
          className='flex cursor-pointer text-xl text-secondary xl:hidden'
          onClick={() => nav.setOpen(!nav.open)}
        >
          <FiAlignJustify className='h-5 w-5' />
        </span>
        {/* start Notification */}
        <Dropdown
          button={
            <p className='cursor-pointer'>
              <IoMdNotificationsOutline className='h-8 w-8 text-secondary' />
            </p>
          }
          animation='origin-[65%_0%] md:origin-top-right transition-all duration-300 ease-in-out'
          children={
            <div className='flex w-[360px] flex-col gap-3 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 sm:w-[460px]'>
              <div className='flex items-center justify-between'>
                <p className='text-base font-bold text-navy-700'>Notifikasi</p>
                <button className='text-sm font-bold text-navy-700'>
                  Tandai sudah dibaca semua
                </button>
              </div>

              <div className='w-full py-5 text-center'>
                Tidak ada notifikasi
              </div>

              {/* <button className='flex w-full items-center'>
                <div className='to-brand-500 flex h-full w-[85px] items-center justify-center rounded-xl bg-gradient-to-b from-lavender-blue py-4 text-2xl text-white'>
                  <BsArrowBarUp />
                </div>
                <div className='ml-2 flex h-full w-full flex-col justify-center rounded-lg px-1 text-sm'>
                  <p className='mb-1 text-left text-base font-bold text-gray-900 dark:text-white'>
                    New Update: Horizon UI Dashboard PRO
                  </p>
                  <p className='font-base text-left text-xs text-gray-900 dark:text-white'>
                    A new update for your downloaded item is available!
                  </p>
                </div>
              </button> */}
            </div>
          }
          classNames={'py-2 top-4 -left-[230px] md:-left-[440px] w-max'}
        />
        <div className='flex items-center gap-x-4'>
          <span className='md:blcok hidden'>{user.user_name}</span>
          <Dropdown
            button={
              <img
                className='h-10 w-10 cursor-pointer rounded-full'
                src={avatar}
                alt='User Profile'
              />
            }
            children={
              <div className='flex w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500'>
                <div className='p-4'>
                  <div className='flex items-center gap-2'>
                    <p className='text-sm font-bold text-navy-700'>
                      👋 Hey, {user.user_name}
                    </p>{' '}
                  </div>
                </div>
                <div className='bg-gray-20 h-px w-full' />

                <div className='flex flex-col p-4'>
                  <Link to='/profile' className=' text-gray-800'>
                    Profil
                  </Link>
                  <button
                    onClick={handleLogoutClick}
                    className='mt-3 text-start text-sm font-medium text-red-500 hover:text-red-500'
                  >
                    Log Out
                  </button>
                </div>
              </div>
            }
            classNames={'py-2 top-8 -left-[180px] w-max'}
          />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
