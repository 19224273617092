import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import Card from 'components/card';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import React from "react";

const Edit = ({ request }) => {
    const params = useParams();
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { isLoading },
    } = useForm({
        defaultValues: async () => {
            try {
                const data = await request.get(`/technician/${params.id}`);
                return {
                    technician_name: data.technician_name,
                    technician_email: data.technician_email,
                    technician_phone: data.technician_phone,
                    technician_gender: data.technician_gender,
                    technician_address: data.technician_address,
                };
            } catch (err) {
                if (err.response?.data?.errors) {
                    Object.keys(err.response.data.errors).map((field) =>
                        err.response.data.errors[field].map((message) =>
                            toast.error(message)
                        )
                    );
                } else if (err.response?.data?.message) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error(err.message);
                }
            }
        },
    });

    const submitHandler = async (data, e) => {
        try {
            await request.put(`/technician/${params.id}`, data);
            toast.success('Berhasil menyimpan data');
            return navigate('/teknisi');
        } catch (err) {
            if (err.response?.data?.errors) {
                Object.keys(err.response.data.errors).map((field) =>
                    err.response.data.errors[field].map((message) => toast.error(message))
                );
            } else if (err.response?.data?.message) {
                toast.error(err.response.data.message);
            } else {
                toast.error(err.message);
            }
        }
    };

    const errorHandler = (errors, e) => {
        Object.keys(errors).map((key) => {
            if (Array.isArray(errors[key])) {
                return errors[key].map((err) => {
                    return toast.error(err.message);
                });
            }

            return toast.error(errors[key].message);
        });
    };

    return (
        <>
            <Card extra={'w-full sm:overflow-auto p-4'}>
                <h2 className='text-xl font-bold text-navy-700'>Edit Teknisi</h2>
                <form
                    onSubmit={handleSubmit(submitHandler, errorHandler)}
                    className='mt-4 flex flex-col gap-y-1 overflow-x-scroll xl:overflow-x-hidden'
                >
                    <div className='grid grid-cols-2'>
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Nama</span>
                            </label>
                            {isLoading ? (
                                <Skeleton height={45} />
                            ) : (
                                <input
                                    {...register('technician_name', {
                                        required: 'Nama harus diisi',
                                    })}
                                    type='text'
                                    placeholder='Ketikan nama teknisi'
                                    className='input input-bordered w-full max-w-sm'
                                />
                            )}
                        </div>
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Email</span>
                            </label>
                            {isLoading ? (
                                <Skeleton height={45} />
                            ) : (
                                <input
                                    {...register('technician_email')}
                                    type='email'
                                    placeholder='Ketikan email teknisi'
                                    className='input input-bordered w-full max-w-sm'
                                />
                            )}
                        </div>
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Nomor Telepon</span>
                            </label>
                            {isLoading ? (
                                <Skeleton height={45} />
                            ) : (
                                <input
                                    {...register('technician_phone', {
                                        required: 'Nomor Telepon harus diisi',
                                    })}
                                    type='tel'
                                    placeholder='Ketikan Nomor Telepon teknisi'
                                    className='input input-bordered w-full max-w-sm'
                                    onKeyPress={(e) => {
                                        if (!/[0-9]/.test(e.key)) {
                                            e.preventDefault();
                                        }
                                    }}
                                />
                            )}
                        </div>
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Gender</span>
                            </label>
                            {isLoading ? (
                                <Skeleton height={45}/>
                            ) : (
                                <select
                                    {...register('technician_gender', {
                                        required: 'Gender harus dipilih',
                                    })}
                                    className='select select-bordered'
                                >
                                    <option value='' disabled selected>
                                        Pilih Gender
                                    </option>
                                    <option value='male'>Pria</option>
                                    <option value='female'>Wanita</option>
                                </select>
                            )}
                        </div>
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Alamat</span>
                            </label>
                            {isLoading ? (
                                <Skeleton height={95} />
                            ) : (
                                <textarea
                                    {...register('technician_address')}
                                    className='textarea textarea-bordered h-24'
                                    placeholder='Ketikan alamat teknisi'
                                />
                            )}
                        </div>
                    </div>
                    <div className='flex gap-x-2'>
                        <button type='submit' className='btn btn-primary'>
                            Simpan
                        </button>
                        <Link to='/teknisi' className='btn'>
                            Batal
                        </Link>
                    </div>
                </form>
            </Card>
        </>
    );
};

export default Edit;
