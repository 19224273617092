import { toast } from 'react-toastify';
import {useForm} from 'react-hook-form';
import Card from 'components/card';
import React, { useEffect, useState} from 'react';
import MapContainer from './maps'
import {Link, useNavigate} from "react-router-dom";
import Skeleton from "react-loading-skeleton";

const Add = ({ request }) => {
    const navigate                                      = useNavigate();
    const { register, handleSubmit, setValue  }         = useForm();
    const [isLoading, setIsLoading]                     = useState(false);
    const [dataCoverages, setCoverages]                 = useState([]);
    const [markerPosition, setMarkerPosition]           = useState(null);

    useEffect(() => {
        request.get('/coverages').then((response) => {
            setCoverages(response);
        });
    }, [request]);

    const submitHandler = async (data) => {
        try {
            setIsLoading(true);
            await request.post('/loket', data);
            toast.success('Berhasil menyimpan data');
            return navigate('/loket');
        } catch (err) {
            if (err.response?.data?.errors) {
                Object.keys(err.response.data.errors).map((field) =>
                    err.response.data.errors[field].map((message) => toast.error(message))
                );
            } else if (err.response?.data?.message) {
                toast.error(err.response.data.message);
            } else {
                toast.error(err.message);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const errorHandler = (errors, e) => {
        Object.keys(errors).map((key) => {
            if (Array.isArray(errors[key])) {
                return errors[key].map((err) => {
                    return toast.error(err.message);
                });
            }

            return toast.error(errors[key].message);
        });
    };

    const handleMapClick = (event) => {
        setMarkerPosition({
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
        });
        setValue('titik_koordinat',`${event.latLng.lat()} , ${event.latLng.lng()}`)
    };
    return(
        <>
            <Card extra={'w-full sm:overflow-auto p-4'}>
                <h2 className='text-xl font-bold text-navy-700'>Tambah Loket</h2>
                <form
                    onSubmit={handleSubmit(submitHandler, errorHandler)}
                    className='mt-4 flex flex-col gap-y-1 overflow-x-scroll xl:overflow-x-hidden'
                >
                    <div className='grid grid-cols-2'>
                        {/*Nama*/}
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Nama</span>
                            </label>
                            {isLoading ? (
                                <Skeleton height={45} />
                            ) : (
                                <input
                                    {...register('loketName', {
                                        required: 'Nama harus diisi',
                                    })}
                                    type='text'
                                    placeholder='Ketikan nama loket'
                                    className='input input-bordered w-full max-w-sm'
                                />
                            )}
                        </div>
                        {/*Cakupan*/}
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Cakupan</span>
                            </label>
                            <select
                                {...register('coverageId', {
                                    required: 'Dusun harus dipilih',
                                })}
                                className='select select-bordered w-full'
                            >
                                <option value='' disabled selected>
                                    Pilih Cakupan
                                </option>
                                {dataCoverages.map((item) => (
                                    <option key={item.coverage_id} value={item.coverage_id}>
                                        {item.coverage_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {/*Titik Koordinat*/}
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Titik Koordinat</span>
                            </label>
                            <MapContainer
                                handleMapClick ={(e)=>handleMapClick(e)}
                                markerPosition ={markerPosition}
                            />
                        </div>
                        {/*Alamat*/}
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Alamat</span>
                            </label>
                            <textarea
                                {...register('alamat', {
                                    required: 'Alamat harus diisi'
                                })}
                                className='textarea textarea-bordered h-24'
                                placeholder='Ketikan Alamat'
                            ></textarea>
                        </div>
                    </div>
                    <div className='flex gap-x-2'>
                        <button type='submit' className='btn btn-primary'>
                            Simpan
                        </button>
                        <Link to='/loket' className='btn'>
                            Batal
                        </Link>
                    </div>
                </form>
            </Card>
        </>
    )
};
export default Add;