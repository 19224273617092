import config from 'config/app';

export default class CKEditorUploadAdapter {
  constructor(loader, options) {
    this.loader = loader;
    this.options = options;
  }

  upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          this._initRequest();
          this._initListeners(resolve, reject, file);
          this._handleCompressedUpload(file);
        })
    );
  }

  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  _initRequest() {
    const xhr = (this.xhr = new XMLHttpRequest());

    xhr.open(
      'POST',
      config.apiServiceUrl +
        (this.options.prefixUrl
          ? `${this.options.prefixUrl}/ckeditor/sync-images`
          : '/ckeditor/sync-images'),
      true
    );
    xhr.setRequestHeader('Authorization', `Bearer ${this.options.acess_token}`);
    xhr.responseType = 'json';
  }

  _handleCompressedUpload(file) {
    this._sendRequest(file);
  }

  _initListeners(resolve, reject, file) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = `Couldn't upload file: ${file.name}.`;

    xhr.addEventListener('error', () => reject(genericErrorText));
    xhr.addEventListener('abort', () => reject());
    xhr.addEventListener('load', () => {
      const response = xhr.response;

      if (!response || response.error) {
        return reject(
          response && response.error ? response.error.message : genericErrorText
        );
      }

      resolve({
        default: response.url,
      });
    });

    if (xhr.upload) {
      xhr.upload.addEventListener('progress', (evt) => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  _sendRequest(file) {
    const data = new FormData();

    data.append('images', file);
    // data.append('api_token', this.options.api_token);
    data.append('page_signature', this.options.page_signature);
    data.append('action_type', 'upload');

    this.xhr.send(data);
  }
}
