import Card from "../../../components/card";
import {useForm} from "react-hook-form";
import Skeleton from "react-loading-skeleton";
import CurrencyInput from "react-currency-input-field";
import {toast} from "react-toastify";
import {Link, useNavigate} from "react-router-dom";
import React, {useState, createRef, useRef, useEffect} from "react";
import { Dialog, DialogPanel } from "@headlessui/react";
import { Cropper } from "react-cropper"
import "cropperjs/dist/cropper.css";

const General = ({ request }) =>{
    const navigate =useNavigate();
    const [imageBlobHeaderName, setImageBlobHeaderName] = useState("");
    const [imageBlobReqName, setImageBlobReqName] = useState("");
    const [imageBlobPenawaran1Name, setImageBlobPenawaran1Name] = useState("");
    const [imageBlobPenawaran2Name, setImageBlobPenawaran2Name] = useState("");
    const [imageBlobPenawaran3Name, setImageBlobPenawaran3Name] = useState("");
    const [imageBlobAboutName, setImageBlobAboutName] = useState("");
    const [imageHeaderName, setImageHeaderName] = useState("");
    const [imageReqName, setImageReqName] = useState("");
    const [imagePenawaran1Name, setImagePenawaran1Name] = useState("");
    const [imagePenawaran2Name, setImagePenawaran2Name] = useState("");
    const [imagePenawaran3Name, setImagePenawaran3Name] = useState("");
    const [imageAboutName, setImageAboutName] = useState("");
    const [croppedHeaderImage, setCroppedHeaderImage] = useState("");
    const [croppedReqImage, setCroppedReqImage] = useState("");
    const [croppedPenawaran1Image, setCroppedPenawaran1Image] = useState("");
    const [croppedPenawaran2Image, setCroppedPenawaran2Image] = useState("");
    const [croppedPenawaran3Image, setCroppedPenawaran3Image] = useState("");
    const [croppedAboutImage, setCroppedAboutImage] = useState("");
    const [modalHeader, setModalHeader] = useState(false);
    const [modalReq, setModalReq] = useState(false);
    const [modalPenawaran1, setModalPenawaran1] = useState(false);
    const [modalPenawaran2, setModalPenawaran2] = useState(false);
    const [modalPenawaran3, setModalPenawaran3] = useState(false);
    const [modalAbout, setModalAbout] = useState(false);
    const cropperRef = createRef();
    const deleteRef = useRef(null);
    const [data, setData] = useState({
        header_image: "",
        header_file: "",
        req_image: "",
        req_file: "",
        penawaran_1: "",
        penawaran_2: "",
        penawaran_3: "",
        about_image: "",
        penawaran1_file: "",
        penawaran2_file: "",
        penawaran3_file: "",
        about_file: ""
    });

    function onCropHeader() {
        const cropper = cropperRef.current?.cropper;
        if (typeof cropper !== "undefined") {
            const base64 = cropper.getCroppedCanvas().toDataURL();
            setCroppedHeaderImage(base64);
            setModalHeader(false);
            setValue('header_image', base64);
        }
    }

    function onCropReq() {
        const cropper = cropperRef.current?.cropper;
        if (typeof cropper !== "undefined") {
            const base64 = cropper.getCroppedCanvas().toDataURL();
            setCroppedReqImage(base64);
            setModalReq(false);
            setValue('req_image', base64);
        }
    }

    function onCropPenawaran1() {
        const cropper = cropperRef.current?.cropper;
        if (typeof cropper !== "undefined") {
            const base64 = cropper.getCroppedCanvas().toDataURL();
            setCroppedPenawaran1Image(base64);
            setModalPenawaran1(false);
            setValue('penawaran_1', base64);
        }
    }

    function onCropPenawaran2() {
        const cropper = cropperRef.current?.cropper;
        if (typeof cropper !== "undefined") {
            const base64 = cropper.getCroppedCanvas().toDataURL();
            setCroppedPenawaran2Image(base64);
            setModalPenawaran2(false);
            setValue('penawaran_2', base64);
        }
    }

    function onCropPenawaran3() {
        const cropper = cropperRef.current?.cropper;
        if (typeof cropper !== "undefined") {
            const base64 = cropper.getCroppedCanvas().toDataURL();
            setCroppedPenawaran3Image(base64);
            setModalPenawaran3(false);
            setValue('penawaran_3', base64);
        }
    }

    function onCropAbout() {
        const cropper = cropperRef.current?.cropper;
        if (typeof cropper !== "undefined") {
            const base64 = cropper.getCroppedCanvas().toDataURL();
            setCroppedAboutImage(base64);
            setModalAbout(false);
            setValue('about_image', base64);
        }
    }

    useEffect(() => {
        const dataContainer = data;
        dataContainer.header_image      = imageHeaderName;
        dataContainer.header_file       = croppedHeaderImage;
        dataContainer.req_image         = imageReqName;
        dataContainer.req_file          = croppedReqImage;
        dataContainer.penawaran_1       = imagePenawaran1Name;
        dataContainer.penawaran_2       = imagePenawaran2Name;
        dataContainer.penawaran_3       = imagePenawaran3Name;
        dataContainer.about_image             = imageAboutName;
        dataContainer.penawaran1_file   = croppedPenawaran1Image;
        dataContainer.penawaran2_file   = croppedPenawaran2Image;
        dataContainer.penawaran3_file   = croppedPenawaran3Image;
        dataContainer.about_file        = croppedAboutImage;
        setData(dataContainer);
    }, [
        data,
        imageHeaderName,
        imageReqName,
        imagePenawaran1Name,
        imagePenawaran2Name,
        imagePenawaran3Name,
        croppedHeaderImage,
        croppedReqImage,
        croppedPenawaran1Image,
        croppedPenawaran2Image,
        croppedPenawaran3Image,
        croppedAboutImage,
        imageAboutName
    ]); // eslint-disable-line

    const onSubmit = async (data) => {
        try {
            await request.post(`/settings/update-general`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            toast.success('Berhasil mengubah data');
            navigate(0);
        }catch (err) {
            if (err.response?.data?.errors) {
                Object.keys(err.response.data.errors).map((field) =>
                    err.response.data.errors[field].map((message) => toast.error(message))
                );
            } else if (err.response?.data?.message) {
                toast.error(err.response.data.message);
            } else {
                toast.error(err.message);
            }
        }
    };

    const {
        register,
        getValues,
        setValue,
        handleSubmit,
        formState: { isLoading },
    } = useForm({
        defaultValues: async () => {
            try {
                const data = await request.get(`/settings/all`);
                return {
                    bonus_pendaftaran           : data.general.bonus_pendaftaran,
                    bonus_bulanan               : data.general.bonus_bulanan,
                    durasi_bonus                : data.general.durasi_bonus,
                    installation_price_global   : data.general.installation_price_global,
                    header_title                : data.general.header_title,
                    header_subtitle             : data.general.header_subtitle,
                    link_youtube                : data.general.link_youtube,
                    req_title                   : data.general.req_title,
                    req_subtitle                : data.general.req_subtitle,
                    penawaran_1                 : data.general.penawaran_1,
                    penawaran_2                 : data.general.penawaran_2,
                    penawaran_3                 : data.general.penawaran_3,
                    about_image                 : data.general.about_image,
                    header_image                : data.general.header_image,
                    req_image                   : data.general.req_image
                };
            } catch (err) {
                if (err.response?.data?.errors) {
                    Object.keys(err.response.data.errors).map((field) =>
                        err.response.data.errors[field].map((message) =>
                            toast.error(message)
                        )
                    );
                } else if (err.response?.data?.message) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error(err.message);
                }
            }
        },
    });

    const errorHandler = (errors) => {
        Object.keys(errors).map((key) => {
            if (Array.isArray(errors[key])) {
                return errors[key].map((err) => {
                    return toast.error(err.message);
                });
            }

            return toast.error(errors[key].message);
        });
    };


    return(
      <>
          <Card extra={'w-full sm:overflow-auto p-4 mt-4'}>
              <h2 className='text-xl font-bold text-navy-700'>General Setting</h2>
              <form onSubmit={handleSubmit(onSubmit, errorHandler)}
                    className='mt-4 flex flex-col gap-y-1 overflow-x-scroll xl:overflow-x-hidden'>
                  <div className='grid xl:grid-cols-2'>
                      <div className='form-control w-full max-w-sm'>
                          <label className='label'>
                              <span className='label-text'>Bonus Pendaftaran</span>
                          </label>
                          {isLoading ? (
                              <Skeleton height={45}/>
                          ) : (
                              <CurrencyInput
                                  {...register('bonus_pendaftaran',)}
                                  prefix='Rp '
                                  placeholder='Silahkan masukan Nominal Bonus'
                                  defaultValue={getValues('bonus_pendaftaran')}
                                  decimalSeparator=','
                                  groupSeparator='.'
                                  intlConfig={{locale: 'id-ID', currency: 'IDR'}}
                                  className='input input-bordered w-full max-w-sm'
                              />
                          )}
                      </div>
                      <div className='form-control w-full max-w-sm'>
                          <label className='label'>
                              <span className='label-text'>Bonus Bulannan</span>
                          </label>
                          {isLoading ? (
                              <Skeleton height={45}/>
                          ) : (
                              <CurrencyInput
                                  {...register('bonus_bulanan')}
                                  prefix='Rp '
                                  placeholder='Silahkan masukan Nominal Bonus'
                                  defaultValue={getValues('bonus_bulanan')}
                                  decimalSeparator=','
                                  groupSeparator='.'
                                  intlConfig={{locale: 'id-ID', currency: 'IDR'}}
                                  className='input input-bordered w-full max-w-sm'
                              />
                          )}
                      </div>
                      <div className='form-control w-full max-w-sm'>
                          <label className='label'>
                              <span className='label-text'>Durasi Bonus</span>
                          </label>
                          {isLoading ? (
                              <Skeleton height={45}/>
                          ) : (
                              <input
                                  {...register('durasi_bonus',)}
                                  type='number'
                                  placeholder='Masukkan Durasi Bonus'
                                  className='input input-bordered w-full max-w-sm'
                              />
                          )}
                      </div>
                      <div className='form-control w-full max-w-sm'>
                          <label className='label'>
                              <span className='label-text'>Biaya Instalasi</span>
                          </label>
                          {isLoading ? (
                              <Skeleton height={45}/>
                          ) : (
                              <CurrencyInput
                                  {...register('installation_price_global')}
                                  prefix='Rp '
                                  placeholder='Silahkan masukan Nominal Bonus'
                                  defaultValue={getValues('installation_price_global')}
                                  decimalSeparator=','
                                  groupSeparator='.'
                                  intlConfig={{locale: 'id-ID', currency: 'IDR'}}
                                  className='input input-bordered w-full max-w-sm'
                              />
                          )}
                      </div>
                      <div className='form-control w-full max-w-sm'>
                          <label className='label'>
                              <span className='label-text'>Judul Header</span>
                          </label>
                          {isLoading ? (
                              <Skeleton height={95}/>
                          ) : (
                              <textarea
                                  {...register('header_title')}
                                  className='textarea textarea-bordered h-24'
                                  placeholder='Ketikan judul header'
                              />
                          )}
                      </div>
                      <div className='form-control w-full max-w-sm'>
                          <label className='label'>
                              <span className='label-text'>Header Subtitle</span>
                          </label>
                          {isLoading ? (
                              <Skeleton height={95}/>
                          ) : (
                              <textarea
                                  {...register('header_subtitle')}
                                  className='textarea textarea-bordered h-24'
                                  placeholder='Ketikan header subtitle'
                              />
                          )}
                      </div>
                      <div className="flex flex-col gap-1">
                          <span className='label-text'>Gambar Header</span>
                          <label className='label'>
                              {croppedHeaderImage === "" ? (
                                  getValues('header_image') !== undefined ? (
                                      <div className="flex flex-col gap-2">
                                          <label
                                              htmlFor="header_image"
                                              className="cursor-pointer"
                                          >
                                              <img
                                                  src={
                                                      process.env
                                                          .REACT_APP_STORAGE_SERVICE_URL +
                                                      "/images/setting/" +
                                                      getValues('header_image')
                                                  }
                                                  alt='Gambar Header'
                                                  style={{
                                                      maxWidth: '100%',
                                                      maxHeight: '200px',
                                                  }}
                                              />
                                          </label>
                                      </div>
                                  ) : (
                                      <label
                                          htmlFor="header_image"
                                          className="bg-[#f4d0a4] py-10 border-2 border-dashed border-[#F2A647] rounded-lg text-lg font-bold text-[#F3B66A] text-center cursor-pointer"
                                          onDragOver={(e) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                          }}
                                          onDrop={(e) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                              const file = e.dataTransfer.files[0];
                                              if (file) {
                                                  setImageBlobHeaderName(
                                                      URL.createObjectURL(file)
                                                  );
                                                  setImageHeaderName(file.name);
                                                  setModalHeader(true);
                                              }
                                          }}
                                      >
                                          klik atau tarik gambar kesini
                                      </label>
                                  )
                              ) : (
                                  <div className="flex flex-col gap-2">
                                      <label
                                          htmlFor="header_image"
                                          className="cursor-pointer"
                                      >
                                          <img
                                              src={croppedHeaderImage}
                                              alt='Gambar Header'
                                              style={{
                                                  maxWidth: '100%',
                                                  maxHeight: '200px',
                                              }}
                                          />
                                      </label>
                                  </div>
                              )}
                          </label>
                          <input
                              id="header_image"
                              type="file"
                              accept="image/png, image/jpg, image/jpeg"
                              className="file-input file-input-bordered file-input-warning w-full max-w-xs"
                              onChange={(e) => {
                                  const file = e.target.files[0];
                                  if (file) {
                                      setImageBlobHeaderName(URL.createObjectURL(file));
                                      setImageHeaderName(file.name);
                                      setModalHeader(true);
                                  }
                              }}
                          />
                      </div>
                  </div>
                  <div className='grid xl:grid-cols-2'>
                      <div className='form-control w-full max-w-sm'>
                          <label className='label'>
                              <span className='label-text'>Judul Registrasi</span>
                          </label>
                          {isLoading ? (
                              <Skeleton height={95}/>
                          ) : (
                              <textarea
                                  {...register('req_title')}
                                  className='textarea textarea-bordered h-24'
                                  placeholder='Ketikan judul registrasi'
                              />
                          )}
                      </div>
                      <div className='form-control w-full max-w-sm'>
                          <label className='label'>
                              <span className='label-text'>Subtitle Registrasi</span>
                          </label>
                          {isLoading ? (
                              <Skeleton height={95}/>
                          ) : (
                              <textarea
                                  {...register('req_subtitle')}
                                  className='textarea textarea-bordered h-24'
                                  placeholder='Ketikan subtitle registrasi'
                              />
                          )}
                      </div>
                      <div className="flex flex-col gap-1">
                          <span className='label-text'>Gambar registrasi</span>
                          <label className='label'>
                              {croppedReqImage === "" ? (
                                  getValues('req_image') !== undefined ? (
                                      <div className="flex flex-col gap-2">
                                          <label
                                              htmlFor="req_image"
                                              className="cursor-pointer"
                                          >
                                              <img
                                                  src={
                                                      process.env
                                                          .REACT_APP_STORAGE_SERVICE_URL +
                                                      "/images/setting/" +
                                                      getValues('req_image')
                                                  }
                                                  alt='Gambar registrasi'
                                                  style={{
                                                      maxWidth: '100%',
                                                      maxHeight: '200px',
                                                  }}
                                              />
                                          </label>
                                      </div>
                                  ) : (
                                      <label
                                          htmlFor="req_image"
                                          className="bg-[#f4d0a4] py-10 border-2 border-dashed border-[#F2A647] rounded-lg text-lg font-bold text-[#F3B66A] text-center cursor-pointer"
                                          onDragOver={(e) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                          }}
                                          onDrop={(e) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                              const file = e.dataTransfer.files[0];
                                              if (file) {
                                                  setImageBlobReqName(
                                                      URL.createObjectURL(file)
                                                  );
                                                  setImageReqName(file.name);
                                                  setModalReq(true);
                                              }
                                          }}
                                      >
                                          klik atau tarik gambar kesini
                                      </label>
                                  )
                              ) : (
                                  <div className="flex flex-col gap-2">
                                      <label
                                          htmlFor="req_image"
                                          className="cursor-pointer"
                                      >
                                          <img
                                              src={croppedReqImage}
                                              alt='Gambar registrasi'
                                              style={{
                                                  maxWidth: '100%',
                                                  maxHeight: '200px',
                                              }}
                                          />
                                      </label>
                                  </div>
                              )}
                          </label>
                          <input
                              id="req_image"
                              type="file"
                              accept="image/png, image/jpg, image/jpeg"
                              className="file-input file-input-bordered file-input-warning w-full max-w-xs"
                              onChange={(e) => {
                                  const file = e.target.files[0];
                                  if (file) {
                                      setImageBlobReqName(URL.createObjectURL(file));
                                      setImageReqName(file.name);
                                      setModalReq(true);
                                  }
                              }}
                          />
                      </div>
                  </div>
                  <div className='grid xl:grid-cols-2'>
                      <div className='form-control w-full max-w-sm'>
                          <label className='label'>
                              <span className='label-text'>Link Youtube</span>
                          </label>
                          {isLoading ? (
                              <Skeleton height={45}/>
                          ) : (
                              <input
                                  {...register('link_youtube', {})}
                                  type='url'
                                  placeholder='Masukkan link youtube'
                                  className='input input-bordered w-full max-w-sm'
                              />
                          )}
                      </div>

                      <div className="flex flex-col gap-1">
                          <span className='label-text'>Penawaran 1</span>
                          <label className='label'>
                              {croppedPenawaran1Image === "" ? (
                                  getValues('penawaran_1') !== undefined ? (
                                      <div className="flex flex-col gap-2">
                                          <label
                                              htmlFor="penawaran_1"
                                              className="cursor-pointer"
                                          >
                                              <img
                                                  src={
                                                      process.env
                                                          .REACT_APP_STORAGE_SERVICE_URL +
                                                      "/images/setting/" +
                                                      getValues('penawaran_1')
                                                  }
                                                  alt='Penawaran 1'
                                                  style={{
                                                      maxWidth: '100%',
                                                      maxHeight: '200px',
                                                  }}
                                              />
                                          </label>
                                      </div>
                                  ) : (
                                      <label
                                          htmlFor="penawaran_1"
                                          className="bg-[#f4d0a4] py-10 border-2 border-dashed border-[#F2A647] rounded-lg text-lg font-bold text-[#F3B66A] text-center cursor-pointer"
                                          onDragOver={(e) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                          }}
                                          onDrop={(e) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                              const file = e.dataTransfer.files[0];
                                              if (file) {
                                                  setImageBlobPenawaran1Name(
                                                      URL.createObjectURL(file)
                                                  );
                                                  setImagePenawaran1Name(file.name);
                                                  setModalPenawaran1(true);
                                              }
                                          }}
                                      >
                                          klik atau tarik gambar kesini
                                      </label>
                                  )
                              ) : (
                                  <div className="flex flex-col gap-2">
                                      <label
                                          htmlFor="penawaran_1"
                                          className="cursor-pointer"
                                      >
                                          <img
                                              src={croppedPenawaran1Image}
                                              alt='Penawaran 1'
                                              style={{
                                                  maxWidth: '100%',
                                                  maxHeight: '200px',
                                              }}
                                          />
                                      </label>
                                  </div>
                              )}
                          </label>
                          <input
                              id="penawaran_1"
                              type="file"
                              accept="image/png, image/jpg, image/jpeg"
                              className="file-input file-input-bordered file-input-warning w-full max-w-xs"
                              onChange={(e) => {
                                  const file = e.target.files[0];
                                  if (file) {
                                      setImageBlobPenawaran1Name(URL.createObjectURL(file));
                                      setImagePenawaran1Name(file.name);
                                      setModalPenawaran1(true);
                                  }
                              }}
                          />
                      </div>
                      <div className="flex flex-col gap-1">
                          <span className='label-text'>Penawaran 2</span>
                          <label className='label'>
                              {croppedPenawaran2Image === "" ? (
                                  getValues('penawaran_2') !== undefined ? (
                                      <div className="flex flex-col gap-2">
                                          <label
                                              htmlFor="penawaran_2"
                                              className="cursor-pointer"
                                          >
                                              <img
                                                  src={
                                                      process.env
                                                          .REACT_APP_STORAGE_SERVICE_URL +
                                                      "/images/setting/" +
                                                      getValues('penawaran_2')
                                                  }
                                                  alt='Penawaran 2'
                                                  style={{
                                                      maxWidth: '100%',
                                                      maxHeight: '200px',
                                                  }}
                                              />
                                          </label>
                                      </div>
                                  ) : (
                                      <label
                                          htmlFor="penawaran_2"
                                          className="bg-[#f4d0a4] py-10 border-2 border-dashed border-[#F2A647] rounded-lg text-lg font-bold text-[#F3B66A] text-center cursor-pointer"
                                          onDragOver={(e) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                          }}
                                          onDrop={(e) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                              const file = e.dataTransfer.files[0];
                                              if (file) {
                                                  setImageBlobPenawaran2Name(
                                                      URL.createObjectURL(file)
                                                  );
                                                  setImagePenawaran2Name(file.name);
                                                  setModalPenawaran2(true);
                                              }
                                          }}
                                      >
                                          klik atau tarik gambar kesini
                                      </label>
                                  )
                              ) : (
                                  <div className="flex flex-col gap-2">
                                      <label
                                          htmlFor="penawaran_2"
                                          className="cursor-pointer"
                                      >
                                          <img
                                              src={croppedPenawaran2Image}
                                              alt='Penawaran 2'
                                              style={{
                                                  maxWidth: '100%',
                                                  maxHeight: '200px',
                                              }}
                                          />
                                      </label>
                                  </div>
                              )}
                          </label>
                          <input
                              id="penawaran_2"
                              type="file"
                              accept="image/png, image/jpg, image/jpeg"
                              className="file-input file-input-bordered file-input-warning w-full max-w-xs"
                              onChange={(e) => {
                                  const file = e.target.files[0];
                                  if (file) {
                                      setImageBlobPenawaran2Name(URL.createObjectURL(file));
                                      setImagePenawaran2Name(file.name);
                                      setModalPenawaran2(true);
                                  }
                              }}
                          />
                      </div>
                      <div className="flex flex-col gap-1">
                          <span className='label-text'>Penawaran 3</span>
                          <label className='label'>
                              {croppedPenawaran3Image === "" ? (
                                  getValues('penawaran_3') !== undefined ? (
                                      <div className="flex flex-col gap-2">
                                          <label
                                              htmlFor="penawaran_3"
                                              className="cursor-pointer"
                                          >
                                              <img
                                                  src={
                                                      process.env
                                                          .REACT_APP_STORAGE_SERVICE_URL +
                                                      "/images/setting/" +
                                                      getValues('penawaran_3')
                                                  }
                                                  alt='Penawaran 3'
                                                  style={{
                                                      maxWidth: '100%',
                                                      maxHeight: '200px',
                                                  }}
                                              />
                                          </label>
                                      </div>
                                  ) : (
                                      <label
                                          htmlFor="penawaran_3"
                                          className="bg-[#f4d0a4] py-10 border-2 border-dashed border-[#F2A647] rounded-lg text-lg font-bold text-[#F3B66A] text-center cursor-pointer"
                                          onDragOver={(e) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                          }}
                                          onDrop={(e) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                              const file = e.dataTransfer.files[0];
                                              if (file) {
                                                  setImageBlobPenawaran3Name(
                                                      URL.createObjectURL(file)
                                                  );
                                                  setImagePenawaran3Name(file.name);
                                                  setModalPenawaran3(true);
                                              }
                                          }}
                                      >
                                          klik atau tarik gambar kesini
                                      </label>
                                  )
                              ) : (
                                  <div className="flex flex-col gap-2">
                                      <label
                                          htmlFor="penawaran_3"
                                          className="cursor-pointer"
                                      >
                                          <img
                                              src={croppedPenawaran3Image}
                                              alt='Penawaran 3'
                                              style={{
                                                  maxWidth: '100%',
                                                  maxHeight: '200px',
                                              }}
                                          />
                                      </label>
                                  </div>
                              )}
                          </label>
                          <input
                              id="penawaran_3"
                              type="file"
                              accept="image/png, image/jpg, image/jpeg"
                              className="file-input file-input-bordered file-input-warning w-full max-w-xs"
                              onChange={(e) => {
                                  const file = e.target.files[0];
                                  if (file) {
                                      setImageBlobPenawaran3Name(URL.createObjectURL(file));
                                      setImagePenawaran3Name(file.name);
                                      setModalPenawaran3(true);
                                  }
                              }}
                          />
                      </div>
                      <div className="flex flex-col gap-1">
                          <span className='label-text'>Gambar tentang kami</span>
                          <label className='label'>
                              {croppedPenawaran3Image === "" ? (
                                  getValues('about_image') !== undefined ? (
                                      <div className="flex flex-col gap-2">
                                          <label
                                              htmlFor="about_image"
                                              className="cursor-pointer"
                                          >
                                              <img
                                                  src={
                                                      process.env
                                                          .REACT_APP_STORAGE_SERVICE_URL +
                                                      "/images/setting/" +
                                                      getValues('about_image')
                                                  }
                                                  alt='about_image'
                                                  style={{
                                                      maxWidth: '100%',
                                                      maxHeight: '200px',
                                                  }}
                                              />
                                          </label>
                                      </div>
                                  ) : (
                                      <label
                                          htmlFor="about_image"
                                          className="bg-[#f4d0a4] py-10 border-2 border-dashed border-[#F2A647] rounded-lg text-lg font-bold text-[#F3B66A] text-center cursor-pointer"
                                          onDragOver={(e) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                          }}
                                          onDrop={(e) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                              const file = e.dataTransfer.files[0];
                                              if (file) {
                                                  setImageBlobAboutName(
                                                      URL.createObjectURL(file)
                                                  );
                                                  setImageAboutName(file.name);
                                                  setModalAbout(true);
                                              }
                                          }}
                                      >
                                          klik atau tarik gambar kesini
                                      </label>
                                  )
                              ) : (
                                  <div className="flex flex-col gap-2">
                                      <label
                                          htmlFor="about_image"
                                          className="cursor-pointer"
                                      >
                                          <img
                                              src={croppedAboutImage}
                                              alt='about_image'
                                              style={{
                                                  maxWidth: '350px',
                                                  maxHeight: '350px',
                                              }}
                                          />
                                      </label>
                                  </div>
                              )}
                          </label>
                          <input
                              id="about_image"
                              type="file"
                              accept="image/png, image/jpg, image/jpeg"
                              className="file-input file-input-bordered file-input-warning w-full max-w-xs"
                              onChange={(e) => {
                                  const file = e.target.files[0];
                                  if (file) {
                                      setImageBlobAboutName(URL.createObjectURL(file));
                                      setImageAboutName(file.name);
                                      setModalAbout(true);
                                  }
                              }}
                          />
                      </div>
                  </div>
                  <div className='flex gap-x-2'>
                      <button type='submit' className='btn btn-primary'>
                          Simpan
                      </button>
                      <Link to='/package-prices' className='btn'>
                          Batal
                      </Link>
                  </div>
                  <Dialog
                      open={modalHeader}
                      onClose={() => setModalHeader(false)}
                      className="relative z-50"
                  >
                      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
                          <DialogPanel className="border bg-white rounded-lg shadow-lg max-w-[1080px]">
                              <div className="font-bold text-2xl text-[#1C1B1B] bg-[#F6F6F6] py-[10px] px-[24px]">
                                  Pangkas gambar
                              </div>
                              <div className="">
                                  <Cropper
                                      ref={cropperRef}
                                      src={imageBlobHeaderName}
                                      className="max-w-[1080px] h-[400px]"
                                      minCropBoxHeight={100}
                                      minCropBoxWidth={150}
                                      autoCropArea={1}
                                      responsive={false}
                                  />
                              </div>
                              <div className="flex justify-end py-4 px-4 gap-2">
                                  <button
                                      className="py-2 px-4 bg-blue-500 rounded-lg text-sm font-semibold hover:bg-blue-600 text-white"
                                      onClick={onCropHeader}
                                  >
                                      crop
                                  </button>
                                  <button
                                      className="py-2 px-4 bg-red-500 rounded-lg text-sm font-semibold hover:bg-red-600 text-white"
                                      onClick={() => {
                                          setModalHeader(false);
                                          setImageBlobHeaderName("");
                                          setCroppedHeaderImage("");
                                      }}
                                      ref={deleteRef}
                                  >
                                      cancel
                                  </button>
                              </div>
                          </DialogPanel>
                      </div>
                  </Dialog>
                  <Dialog
                      open={modalReq}
                      onClose={() => setModalReq(false)}
                      className="relative z-50"
                  >
                      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
                          <DialogPanel className="border bg-white rounded-lg shadow-lg max-w-[1080px]">
                              <div className="font-bold text-2xl text-[#1C1B1B] bg-[#F6F6F6] py-[10px] px-[24px]">
                                  Pangkas gambar
                              </div>
                              <div className="">
                                  <Cropper
                                      ref={cropperRef}
                                      src={imageBlobReqName}
                                      className="max-w-[1080px] h-[400px]"
                                      minCropBoxHeight={50}
                                      minCropBoxWidth={100}
                                      autoCropArea={1}
                                      responsive={false}
                                  />
                              </div>
                              <div className="flex justify-end py-4 px-4 gap-2">
                                  <button
                                      className="py-2 px-4 bg-blue-500 rounded-lg text-sm font-semibold hover:bg-blue-600 text-white"
                                      onClick={onCropReq}
                                  >
                                      crop
                                  </button>
                                  <button
                                      className="py-2 px-4 bg-red-500 rounded-lg text-sm font-semibold hover:bg-red-600 text-white"
                                      onClick={() => {
                                          setModalReq(false);
                                          setImageBlobReqName("");
                                          setCroppedReqImage("");
                                      }}
                                      ref={deleteRef}
                                  >
                                      cancel
                                  </button>
                              </div>
                          </DialogPanel>
                      </div>
                  </Dialog>
                  <Dialog
                      open={modalPenawaran1}
                      onClose={() => setModalPenawaran1(false)}
                      className="relative z-50"
                  >
                      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
                          <DialogPanel className="border bg-white rounded-lg shadow-lg max-w-[1080px]">
                              <div className="font-bold text-2xl text-[#1C1B1B] bg-[#F6F6F6] py-[10px] px-[24px]">
                                  Pangkas gambar
                              </div>
                              <div className="">
                                  <Cropper
                                      ref={cropperRef}
                                      src={imageBlobPenawaran1Name}
                                      className="max-w-[1080px] h-[400px]"
                                      minCropBoxHeight={50}
                                      minCropBoxWidth={100}
                                      autoCropArea={1}
                                      responsive={false}
                                      aspectRatio={16 / 9}
                                  />
                              </div>
                              <div className="flex justify-end py-4 px-4 gap-2">
                                  <button
                                      className="py-2 px-4 bg-blue-500 rounded-lg text-sm font-semibold hover:bg-blue-600 text-white"
                                      onClick={onCropPenawaran1}
                                  >
                                      crop
                                  </button>
                                  <button
                                      className="py-2 px-4 bg-red-500 rounded-lg text-sm font-semibold hover:bg-red-600 text-white"
                                      onClick={() => {
                                          setModalPenawaran1(false);
                                          setImageBlobPenawaran1Name("");
                                          setCroppedPenawaran1Image("");
                                      }}
                                      ref={deleteRef}
                                  >
                                      cancel
                                  </button>
                              </div>
                          </DialogPanel>
                      </div>
                  </Dialog>
                  <Dialog
                      open={modalPenawaran2}
                      onClose={() => setModalPenawaran2(false)}
                      className="relative z-50"
                  >
                      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
                          <DialogPanel className="border bg-white rounded-lg shadow-lg max-w-[1080px]">
                              <div className="font-bold text-2xl text-[#1C1B1B] bg-[#F6F6F6] py-[10px] px-[24px]">
                                  Pangkas gambar
                              </div>
                              <div className="">
                                  <Cropper
                                      ref={cropperRef}
                                      src={imageBlobPenawaran2Name}
                                      className="max-w-[1080px] h-[400px]"
                                      minCropBoxHeight={50}
                                      minCropBoxWidth={100}
                                      autoCropArea={1}
                                      responsive={false}
                                      aspectRatio={16 / 9}
                                  />
                              </div>
                              <div className="flex justify-end py-4 px-4 gap-2">
                                  <button
                                      className="py-2 px-4 bg-blue-500 rounded-lg text-sm font-semibold hover:bg-blue-600 text-white"
                                      onClick={onCropPenawaran2}
                                  >
                                      crop
                                  </button>
                                  <button
                                      className="py-2 px-4 bg-red-500 rounded-lg text-sm font-semibold hover:bg-red-600 text-white"
                                      onClick={() => {
                                          setModalPenawaran2(false);
                                          setImageBlobPenawaran2Name("");
                                          setCroppedPenawaran2Image("");
                                      }}
                                      ref={deleteRef}
                                  >
                                      cancel
                                  </button>
                              </div>
                          </DialogPanel>
                      </div>
                  </Dialog>
                  <Dialog
                      open={modalPenawaran3}
                      onClose={() => setModalPenawaran3(false)}
                      className="relative z-50"
                  >
                      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
                          <DialogPanel className="border bg-white rounded-lg shadow-lg max-w-[1080px]">
                              <div className="font-bold text-2xl text-[#1C1B1B] bg-[#F6F6F6] py-[10px] px-[24px]">
                                  Pangkas gambar
                              </div>
                              <div className="">
                                  <Cropper
                                      ref={cropperRef}
                                      src={imageBlobPenawaran3Name}
                                      className="max-w-[1080px] h-[400px]"
                                      minCropBoxHeight={50}
                                      minCropBoxWidth={100}
                                      autoCropArea={1}
                                      responsive={false}
                                      aspectRatio={16 / 9}
                                  />
                              </div>
                              <div className="flex justify-end py-4 px-4 gap-2">
                                  <button
                                      className="py-2 px-4 bg-blue-500 rounded-lg text-sm font-semibold hover:bg-blue-600 text-white"
                                      onClick={onCropPenawaran3}
                                  >
                                      crop
                                  </button>
                                  <button
                                      className="py-2 px-4 bg-red-500 rounded-lg text-sm font-semibold hover:bg-red-600 text-white"
                                      onClick={() => {
                                          setModalPenawaran3(false);
                                          setImageBlobPenawaran3Name("");
                                          setCroppedPenawaran3Image("");
                                      }}
                                      ref={deleteRef}
                                  >
                                      cancel
                                  </button>
                              </div>
                          </DialogPanel>
                      </div>
                  </Dialog>
                  <Dialog
                      open={modalAbout}
                      onClose={() => setModalAbout(false)}
                      className="relative z-50"
                  >
                      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
                          <DialogPanel className="border bg-white rounded-lg shadow-lg max-w-[1080px]">
                              <div className="font-bold text-2xl text-[#1C1B1B] bg-[#F6F6F6] py-[10px] px-[24px]">
                                  Pangkas gambar
                              </div>
                              <div className="">
                                  <Cropper
                                      ref={cropperRef}
                                      src={imageBlobAboutName}
                                      className="max-w-[1080px] h-[400px]"
                                      minCropBoxHeight={200}
                                      minCropBoxWidth={100}
                                      autoCropArea={1}
                                      responsive={false}
                                      aspectRatio={9 / 9}
                                  />
                              </div>
                              <div className="flex justify-end py-4 px-4 gap-2">
                                  <button
                                      className="py-2 px-4 bg-blue-500 rounded-lg text-sm font-semibold hover:bg-blue-600 text-white"
                                      onClick={onCropAbout}
                                  >
                                      crop
                                  </button>
                                  <button
                                      className="py-2 px-4 bg-red-500 rounded-lg text-sm font-semibold hover:bg-red-600 text-white"
                                      onClick={() => {
                                          setModalAbout(false);
                                          setImageBlobAboutName("");
                                          setCroppedAboutImage("");
                                      }}
                                      ref={deleteRef}
                                  >
                                      cancel
                                  </button>
                              </div>
                          </DialogPanel>
                      </div>
                  </Dialog>
              </form>
          </Card>
      </>
    );
};
export default General;