import Card from 'components/card';
import React from 'react';
import { BsCloudCheck } from 'react-icons/bs';

const DataUsage = () => {
  return (
    <Card extra={'w-full h-full p-4'}>
      {/* Your storage */}
      <div className='mb-auto flex flex-col items-center justify-center'>
        <div className='mt-2 flex items-center justify-center rounded-full bg-gray-100 p-[26px] text-5xl font-bold text-primary dark:!bg-navy-700 dark:text-white'>
          <BsCloudCheck />
        </div>
        <h4 className='mb-px mt-3 text-center text-xl font-bold text-navy-700'>
          Total Penggunaan Data
        </h4>
        <p className='px-5 text-center text-base font-normal text-gray-600 md:!px-0 xl:!px-8'>
          Total penggunaan data pelanggan.
        </p>
      </div>

      {/* Progress bar */}

      <div className='flex flex-col'>
        <div className='flex justify-between'>
          <p className='text-sm font-medium text-gray-600'>Unknown GB</p>
          <p className='text-sm font-medium text-gray-600'>Unlimited</p>
        </div>
        <div className='mt-2 flex h-3 w-full items-center rounded-full bg-gray-100'>
          <span className='h-full w-1/2 rounded-full bg-primary' />
        </div>
      </div>
    </Card>
  );
};

export default DataUsage;
