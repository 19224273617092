import General from "./General";
import Edit from "./Edit";

const TiketDetail = ({ request }) => {
    return (
        <div className='flex w-full flex-col gap-5'>
            <div className='grid h-full grid-cols-1 gap-5 lg:!grid-cols-12'>
                <div className='3xl:col-span-5 col-span-5 lg:col-span-6 lg:mb-0'>
                    <Edit
                        request       = {request}
                    />
                </div>
                <div className='3xl:col-span-5 col-span-5 lg:col-span-6 lg:mb-0'>
                    <General
                        request       = {request}
                    />
                </div>
            </div>
        </div>
    );
};
export default TiketDetail;
