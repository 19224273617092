import axios from 'axios';
import appConfig from 'config/app';
import { toast } from 'react-toastify';

const responseBody = (response) => response.data;

class HttpClient {
  constructor(token = null, logout = () => null, config = {}) {
    var self = this;
    this.token = token;
    this.logout = logout;
    this.axios = axios.create({
      baseURL: appConfig.apiServiceUrl,
      timeout: 500000,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        brand: appConfig.appBrand,
      },
      withCredentials: false,
      ...config,
    });
    this.axios.interceptors.request.use(function (config) {
      if (!self.token) {
        try {
          const value = window.localStorage.getItem('token');
          if (value) {
            self.token = JSON.parse(value);
          }
        } catch (err) {
          console.error(err);
        }
      }

      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: self.token?.access_token
            ? `Bearer ${self.token.access_token}`
            : null,
        },
      };
    });

    this.axios.interceptors.response.use(
      (response) => response,
      async function (error) {
        if (error.response?.status === 401) {
          logout();
        }
        if (error.response?.status === 503) {
          toast.error('Server dalam Maintenance');
        }
        throw error;
      }
    );
  }

  get(url, config) {
    return this.axios.get(url, config).then(responseBody);
  }

  post(url, data, config) {
    return this.axios.post(url, data, config).then(responseBody);
  }

  put(url, data, config) {
    return this.axios.put(url, data, config).then(responseBody);
  }

  patch(url, data, config) {
    return this.axios.patch(url, data, config).then(responseBody);
  }

  delete(url, config) {
    return this.axios.delete(url, config).then(responseBody);
  }
}

export default HttpClient;
