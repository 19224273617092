import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import Card from 'components/card';
import IconUpload from 'assets/svg/icon-upload.svg';
import { convertToRupiah } from 'utils/general';
import { AiFillCloseCircle } from 'react-icons/ai';
import useFilePreview from 'hooks/useFilePreview';
import {dateFormat} from 'utils/date';
import CurrencyInput from "react-currency-input-field";
import {BsFillPlusCircleFill, BsTrash3Fill} from "react-icons/bs";
import moment from "moment";

export default function General({ request, loading, user}) {
    const params = useParams();
    const [payment, setPayment] = useState({});
    const [data, setData] = useState({});
    const [type, setType] = useState('');
    const [nominal, setNominal] = useState('');
    const [refresh, setRefresh] = useState(false);
    const [dataItem, setDataItem] = useState([]);
    const [deletionId, setDeletionId] = useState(null);

    useEffect(() => {
        request
            .get(`/invoices/${params.id}`)
            .then((response) => {
                setData(response.data);
                setDataItem(response?.data?.invoice_items)
            })
            .catch((err) => {
                if (err.response?.data?.errors) {
                    Object.keys(err.response.data.errors).map((field) =>
                        err.response.data.errors[field].map((message) =>
                            toast.error(message)
                        )
                    );
                } else if (err.response?.data?.message) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error(err.message);
                }
            });
    }, [payment, refresh]); // eslint-disable-line react-hooks/exhaustive-deps

    const handlePayment = async () => {
        try {
            const response = await request.post('/payments', {
                invoice_id: params.id,
            });
            setPayment(response);
            window.payment_modal.showModal();
        } catch (err) {
            if (err.response?.data?.errors) {
                Object.keys(err.response.data.errors).map((field) =>
                    err.response.data.errors[field].map((message) => toast.error(message))
                );
            } else if (err.response?.data?.message) {
                toast.error(err.response.data.message);
            } else {
                toast.error(err.message);
            }
        }
    };

    const handleDelete = (id) => {
        setDeletionId(id);
        window.confirm_delete_modal.showModal();
    };

    const handleAdd = () => {
        window.confirm_add_modal.showModal();
    };

    const confirmDelete = async () => {
        try {
            await request.delete(`/invoice-item/${deletionId}`);
            toast.success('Berhasil menghapus data');
            setRefresh(true);
        } catch (err) {
            if (err.response?.data?.errors) {
                Object.keys(err.response.data.errors).map((field) =>
                    err.response.data.errors[field].map((message) => toast.error(message))
                );
            } else if (err.response?.data?.message) {
                toast.error(err.response.data.message);
            } else {
                toast.error(err.message);
            }
        }
        setRefresh(true);
    };

    const addInvoice = async() => {
        try {
            await request.post('/invoice-item', {invoice_id: params.id, i_item_type:type, i_item_fee:nominal});
            toast.success('Berhasil menambah data');
            setType('');
            setNominal('');
            setRefresh(true);
        } catch (err) {
            if (err.response?.data?.errors) {
                Object.keys(err.response.data.errors).map((field) =>
                    err.response.data.errors[field].map((message) => toast.error(message))
                );
            } else if (err.response?.data?.message) {
                toast.error(err.response.data.message);
            } else {
                toast.error(err.message);
            }
        }
        setType('');
        setNominal('');
        setRefresh(true)
    }

    return (
        <>
            <Card extra={'w-full h-full p-3 rounded-[20px]'}>
                <div className='mt-2 mb-2 w-full'>
                    <h4 className='px-2 text-xl font-bold text-navy-700'>
                        Ringkasan Informasi
                    </h4>
                    <p className='mt-2 px-2 text-base text-gray-600'>
                        Informasi umum invoice
                    </p>
                </div>
                <div className='flex h-full flex-col justify-between'>
                    <div className='grid grid-cols-2 gap-2 px-2'>
                        <div className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
                            <p className='text-sm text-gray-600'>Nomor Invoice</p>
                            {!loading != null ? (
                                <p className='text-base font-medium text-navy-700'>
                                    {data.invoice_no}
                                </p>
                            ) : (
                                <Skeleton height={29} width={100}/>
                            )}
                        </div>
                        <div className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
                            <p className='text-sm text-gray-600'>Status</p>
                            {!loading != null ? (
                                <p className='text-base font-medium text-navy-700'>
                                    {data.invoice_status === 'draft' ? (
                                        <span className='badge badge-warning'>Belum Lunas</span>
                                    ) : data.invoice_status === 'paid' ? (
                                        <span className='badge badge-success'>Lunas</span>
                                    ) : data.invoice_status === 'overdue' ? (
                                        <span className='badge badge-error'>Lewat Jatuh Tempo</span>
                                    ) : data.invoice_status === 'void' ? (
                                        <span className='badge badge-error'>Invoice Batal</span>
                                    ) : data.invoice_status === 'write-off' ? (
                                        <span className='badge badge-error'>Invoice Terhapus</span>
                                    ) : (
                                        <span className='badge badge-secondary'>Terkirim</span> // Default badge style
                                    )}
                                </p>
                            ) : (
                                <Skeleton height={29} width={100}/>
                            )}
                        </div>
                        <div className='roundedl flex flex-col items-start justify-center bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
                            <p className='text-sm text-gray-600'>Total Tagihan</p>
                            {!loading != null ? (
                                <p className='text-base font-medium text-navy-700'>
                                    {convertToRupiah(data.invoice_amount_due, true)}
                                </p>
                            ) : (
                                <Skeleton height={29} width={100}/>
                            )}
                        </div>
                        <div className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
                            <p className='text-sm text-gray-600'>Tanggal Terbit</p>
                            {!loading != null ? (
                                <p className='text-base font-medium text-navy-700'>
                                    {dateFormat(data.invoice_issue_date, 'DD MMM YYYY')}
                                </p>
                            ) : (
                                <Skeleton height={29} width={100}/>
                            )}
                        </div>
                        <div className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
                            <p className='text-sm text-gray-600'>Tanggal Jatuh Tempo</p>
                            {!loading != null ? (
                                <p className='text-base font-medium text-navy-700'>
                                    {dateFormat(data.invoice_due_date, 'DD MMM YYYY')}
                                </p>
                            ) : (
                                <Skeleton height={29} width={100}/>
                            )}
                        </div>
                    </div>
                    <div className="w-full py-5">
                        <div className="flex justify-between items-center">
                            <p className='text-sm text-gray-600'>Invoice Detail</p>
                            <button
                                onClick={() => handleAdd(data.invoice_id)}
                                className='text-green-700 '
                            >
                                <BsFillPlusCircleFill size={20}/>
                            </button>
                        </div>
                        <table className='w-full'>
                            <thead className='bg-gray-200 text-xs uppercase text-mirage-500'>
                            <tr>
                                <th className='px-2 py-3'>Nama</th>
                                <th className='px-2 py-3'>Nominal</th>
                                <th className='px-2 py-3'>Aksi</th>
                            </tr>
                            </thead>
                            <tbody className='text-thunder'>
                            {dataItem.map((item, index)=>{
                                return(
                                    <tr key={index}  className={`${
                                        index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                                    } border-b hover:!bg-gray-50`}>
                                        <td className='px-2 py-3 '>{
                                            item?.i_item_type === 'subscription' ? 'Biaya langganan' : (
                                                item?.i_item_type === 'vat' ? "PPN 11%" :(
                                                    item?.i_item_type === 'installation' ? "Biaya Installasi":(
                                                        item?.i_item_type === 'discount' ? "Diskon" : (
                                                            item?.i_item_type === 'compensation' ? "Kompensasi" : 'Lian - Lain'
                                                        )
                                                    )
                                                )
                                            )
                                        }</td>
                                        <td className='px-2 py-3 '>{convertToRupiah(item?.i_item_fee, true)}</td>
                                        <td className='px-2 py-3 text-center'>
                                            <button
                                                onClick={() => handleDelete(item?.i_item_id)}
                                                className={`font-medium ${ item?.i_item_type === 'subscription' || item?.i_item_type === 'vat'  ? 'text-gray-400' : 'text-accent'}`}
                                                disabled={item?.i_item_type === 'subscription' || item?.i_item_type === 'vat'}
                                            >
                                                <BsTrash3Fill size={24} />
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                    <div className="w-full py-5">
                        {data.invoice_status !== 'paid' &&
                            user?.user_level.u_level_name !== 'Customer Service' && (
                                <button onClick={handlePayment} className='btn mr-0 bg-primary w-full'>
                                    Bayar Manual
                                </button>
                            )}
                    </div>
                    <Payment request={request} payment={payment}/>
                </div>
            </Card>
            <dialog
                id='confirm_delete_modal'
                className='modal modal-bottom sm:modal-middle'
            >
                <form method='dialog' className='modal-box'>
                    <h3 className='text-lg font-bold'>Konfirmasi Penghapusan</h3>
                    <p className='py-4'>
                        Anda yakin ingin menghapus data, tindakan ini tidak dapat
                        dikembalikan!
                    </p>
                    <div className='modal-action'>
                        <button className='btn btn-accent' onClick={confirmDelete}>
                            Konfirmasi
                        </button>
                        <button className='btn'>Batal</button>
                    </div>
                </form>
            </dialog>
            <dialog
                id='confirm_add_modal'
                className='modal modal-bottom sm:modal-middle'
            >
                <form method='dialog' className='modal-box' >
                    <h3 className='text-lg font-bold'>Tambah Detail invoice</h3>
                    <div className='grid grid-cols-2'>
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Type Invoice</span>
                            </label>
                            <select
                                onChange={(e) => setType(e.target.value)}
                                className='select select-bordered'
                                value={type}
                            >
                                <option value='' disabled selected>
                                    Pilih Type Invoice
                                </option>
                                <option value='installation'>Instalasi</option>
                                <option value='compensation'>Kompensasi</option>
                                <option value='promo'>Promo</option>
                            </select>
                        </div>
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>nominal</span>
                            </label>
                            <CurrencyInput
                                onChange={(e) => setNominal(e.target.value)}
                                prefix='Rp '
                                placeholder='Silahkan masukan Nominal'
                                defaultValue={0}
                                decimalSeparator=','
                                groupSeparator='.'
                                intlConfig={{ locale: 'id-ID', currency: 'IDR' }}
                                className='input input-bordered w-full max-w-sm'
                            />
                        </div>
                    </div>
                    <div className='modal-action'>
                        <button
                            onClick={() => addInvoice()}
                            disabled={ nominal === '' || type === ''}
                            className='btn btn-primary' >
                            Simpan
                        </button>
                        <button className='btn'>Batal</button>
                    </div>
                    {/*</form>*/}
                </form>
            </dialog>
        </>
    );
}

function Payment({request, payment: {payment_id}}) {
    const [payment, setPayment] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const fileInputRef = useRef(null);
    const [year, setYear] = useState('');
    const [month, setMonth] = useState('');
    const [day, setDay] = useState('');
    const [days, setDays] = useState([]);
    const allDays = [
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23',
        '24',
        '25',
        '26',
        '27',
        '28',
        '29',
        '30',
        '31',
    ];
    const months = [
        {
            label: 'January',
            value: '01',
        },
        {
            label: 'Februari',
            value: '02',
        },
        {
            label: 'Maret',
            value: '03',
        },
        {
            label: 'April',
            value: '04',
        },
        {
            label: 'Mei',
            value: '05',
        },
        {
            label: 'Juni',
            value: '06',
        },
        {
            label: 'July',
            value: '07',
        },
        {
            label: 'Agustus',
            value: '08',
        },
        {
            label: 'September',
            value: '09',
        },
        {
            label: 'Oktober',
            value: '10',
        },
        {
            label: 'November',
            value: '11',
        },
        {
            label: 'Desember',
            value: '12',
        },
    ];

    const dateGenerate = year + '-' + month + '-' + day;

    const setAllDay = () => {
        const monthAndYear = year + '-' + month;
        const maxDays = moment(monthAndYear, 'YYYY-MM').daysInMonth();
        const slicedDays = allDays.slice(0, maxDays);
        setDays(slicedDays);
    };

    const {control, watch, register, setValue, getValues, handleSubmit } =
        useForm({
            mode: 'onChange',
        });

    useEffect(() => {
        if (year !== '' && month !== '') {
            setAllDay();
        }
        if (year === "" || month === "") {
            setDay("");
            setDays([]);
        }
        setValue('tanggal',dateGenerate);
    }, [year, month, dateGenerate]); // eslint-disable-line

    useEffect(() => {
        if (payment_id) {
            setIsLoading(true);
            request
                .get(`/payments/${payment_id}`)
                .then((response) => {
                    setPayment(response);
                    setValue('transaction_id', response.transaction_id);
                    setValue('payment_note', response.payment_note);
                })
                .catch((err) => {
                    if (err.response?.data?.errors) {
                        Object.keys(err.response.data.errors).map((field) =>
                            err.response.data.errors[field].map((message) =>
                                toast.error(message)
                            )
                        );
                    } else if (err.response?.data?.message) {
                        toast.error(err.response.data.message);
                    } else {
                        toast.error(err.message);
                    }
                })
                .finally(() => setIsLoading(false));
        }
    }, [payment_id]); // eslint-disable-line react-hooks/exhaustive-deps

    const transactionProof = useWatch({ control, name: 'transaction_proof' });
    const { ref, ...rest } = register('transaction_proof');
    const [transProofPreview] = useFilePreview(transactionProof);

    const submitHandler = async (data) => {
        try {
            await request.post(
                `/transactions/${payment.transaction_id}/capture`,
                data,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            toast.success('Transaksi berhasil');
            window.payment_modal.close();
        } catch (err) {
            if (err.response?.data?.errors) {
                Object.keys(err.response.data.errors).map((field) =>
                    err.response.data.errors[field].map((message) => toast.error(message))
                );
            } else if (err.response?.data?.message) {
                toast.error(err.response.data.message);
            } else {
                toast.error(err.message);
            }
        }
    };

    const errorHandler = (errors, e) => {
        Object.keys(errors).map((key) => {
            if (Array.isArray(errors[key])) {
                return errors[key].map((err) => {
                    return toast.error(err.message);
                });
            }

            return toast.error(errors[key].message);
        });
    };

    const transMethodValue = watch('transaction_method');

    return (
        <dialog id='payment_modal' className='modal modal-bottom sm:modal-middle'>
            <form
                onSubmit={handleSubmit(submitHandler, errorHandler)}
                className='modal-box'
            >
                <h2 className='mb-3 text-lg font-bold'>Pembayaran</h2>
                <div className='flex flex-col gap-y-2'>
                    <div className='flex justify-between'>
                        <span>ID Transaksi: </span>
                        {isLoading ? (
                            <Skeleton width={300} height={18} />
                        ) : (
                            <span>{payment.transaction_id}</span>
                        )}
                    </div>
                    <div className='flex justify-between'>
                        <span>Biaya Admin: </span>
                        {isLoading ? (
                            <Skeleton width={55} height={18} />
                        ) : (
                            <span>{convertToRupiah(payment.payment_admin_fee, true)}</span>
                        )}
                    </div>
                    <div className='flex justify-between'>
                        <span>Nominal Pembayaran: </span>
                        {isLoading ? (
                            <Skeleton width={70} height={18} />
                        ) : (
                            <span>
                {convertToRupiah(payment.transaction?.transaction_amount, true)}
              </span>
                        )}
                    </div>
                    <div className='flex justify-between'>
                        <span>Status: </span>
                        {isLoading ? (
                            <Skeleton width={60} height={18} />
                        ) : (
                            <span className='badge badge-warning'>
                {payment.payment_status}
              </span>
                        )}
                    </div>
                    <div className='form-control w-full'>
                        <label className='label'>
                            <span className='label-text'>Metode Pembayaran</span>
                        </label>
                        <select
                            {...register('transaction_method', {
                                required: 'Metode pembayaran harus dipilih',
                            })}
                            className='select select-bordered w-full'
                        >
                            <option disabled selected value=''>
                                Pilih Metode Transaksi
                            </option>
                            <option value='cash'>Tunai</option>
                            <option value='bank_transfer'>Transfer Bank</option>
                        </select>
                    </div>
                    {transMethodValue === 'bank_transfer' && (
                        <div className='form-control w-full'>
                            <label className='label'>
                                <span className='label-text'>Bank</span>
                            </label>
                            <select
                                {...register('bank_id', {
                                    required: 'Bank harus dipilih',
                                })}
                                className='select select-bordered w-full'
                            >
                                <option disabled selected value=''>
                                    Pilih Bank
                                </option>
                                <option value='1'>MANDIRI</option>
                                <option value='2'>BRI</option>
                                <option value='3'>BCA</option>
                                <option value='4'>BNI</option>
                                <option value='5'>AKUN EB</option>
                            </select>
                        </div>
                    )}
                    <div>
                        <label className='label'>
                            <span className='label-text'>Tanggal Pembayaran (Optional)</span>
                        </label>
                        <div className='flex gap-2'>
                            <select
                                id='year'
                                className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500'
                                onChange={(e) => setYear(e.target.value)}
                            >
                                <option value=''>pilih tahun</option>
                                <option value='2023'>2023</option>
                                <option value='2024'>2024</option>
                                <option value='2025'>2025</option>
                                <option value='2026'>2026</option>
                                <option value='2027'>2027</option>
                                <option value='2028'>2028</option>
                                <option value='2029'>2029</option>
                            </select>
                            <select
                                id='month'
                                className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500'
                                onChange={(e) => setMonth(e.target.value)}
                            >
                                <option value=''>pilih bulan</option>
                                {months.map((item, index) => {
                                    return <option key={index} value={item.value}>{item.label}</option>;
                                })}
                            </select>
                            <select
                                id='day'
                                className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500'
                                disabled={year === "" || month === ""}
                                onChange={(e) => setDay(e.target.value)}
                            >
                                <option value=''>pilih hari</option>
                                {days.map((item, index) => {
                                    return <option selected={day === item} key={index} value={item}>{item}</option>;
                                })}
                            </select>
                        </div>
                    </div>
                    <div className='form-control w-full'>
                        <label className='label'>
                            <span className='label-text'>Catatan Pembayaran (Opsional)</span>
                        </label>
                        {isLoading ? (
                            <Skeleton width='100%' height={60} />
                        ) : (
                            <textarea
                                {...register('payment_note')}
                                type='text'
                                placeholder='Ketikan Catatan Pembayaran'
                                className='textarea textarea-bordered h-16'
                            />
                        )}
                    </div>
                    {/* upload bukti bayar */}
                    <div className='form-control w-full max-w-sm'>
                        <label className='label'>
                            <span className='label-text'>Bukti Transaksi (Opsional)</span>
                        </label>
                        <input
                            {...rest}
                            {...register('transaction_proof')}
                            type='file'
                            className='hidden'
                            accept='image/*'
                            ref={(e) => {
                                ref(e);
                                fileInputRef.current = e; // you can still assign to ref
                            }}
                        />

                        {!getValues('transaction_proof')?.length ? (
                            <div
                                onClick={() => fileInputRef.current.click()}
                                className='flex w-52 cursor-pointer flex-col items-center justify-center rounded-md border bg-zinc-200 py-2'
                            >
                                <img src={IconUpload} alt='Upload Icon' />
                                <p className='mb-2 text-xs'>Upload Bukti</p>
                                <span className='rounded-xl border-2 border-slate-300 bg-white py-1 px-2 text-sm text-black'>
                  Cari Gambar
                </span>
                            </div>
                        ) : (
                            <div className='relative flex w-52 flex-col items-center justify-center rounded-md border bg-zinc-200'>
                                <button
                                    type='button'
                                    className='absolute z-50'
                                    style={{ top: 5, right: 5 }}
                                >
                                    <AiFillCloseCircle
                                        onClick={() => setValue('transaction_proof', null)}
                                        size={30}
                                        color='black'
                                    />
                                </button>
                                {transProofPreview && (
                                    <img
                                        src={transProofPreview}
                                        className='rounded-md'
                                        style={{ contain: 'content' }}
                                        alt='Transaction Proof Preview'
                                    />
                                )}
                            </div>
                        )}
                    </div>

                    <div className='modal-action'>
                        <button type='submit' className='btn mr-2 bg-primary'>
                            Konfirmasi Bayar
                        </button>
                        <button
                            type='button'
                            className='btn btn-secondary'
                            onClick={() => window.payment_modal.close()}
                        >
                            Batal
                        </button>
                    </div>
                </div>
            </form>
        </dialog>
    );
}