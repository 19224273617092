import React, {useCallback, useMemo, useRef, useState} from 'react';
import Card from 'components/card';
import { toast } from 'react-toastify';
import { createColumnHelper } from '@tanstack/react-table';
import { dateFormat } from 'utils/date';
import Table from 'components/table/Table';
import { Link } from 'react-router-dom';
import { FiEdit, FiEye } from 'react-icons/fi';
import { AiFillDelete } from 'react-icons/ai';
import { FaWhatsapp } from 'react-icons/fa';

const List = ({ request }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [totalRow, setTotalRow] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [fileSelected, setFileSelected] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileLabel, setFileLabel] = useState('');
  const fileInputRef = useRef(null);

  const fetchData = useCallback(
    async (pageSize, pageIndex, search) => {
      setIsLoading(true);

      const params = {
        per_page: pageSize,
        page: pageIndex,
        keyword: search,
      };

      try {
        const response = await request.get('/customers', { params });
        const startIndex = response.from;

        const updatedData = response.data.map((item, index) => ({
          ...item,
          listingNumber: startIndex + index,
        }));
        setData(updatedData);
        setPageCount(response.last_page);
        setTotalRow(response.total);

      } catch (err) {
        if (err.response?.data?.errors) {
          Object.keys(err.response.data.errors).map((field) =>
            err.response.data.errors[field].map((message) =>
              toast.error(message)
            )
          );
        } else if (err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      }

      setIsLoading(false);
      setRefresh(false);
    },
    [refresh] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const columnHelper = createColumnHelper();

  const columns = useMemo(
    () => [
      columnHelper.accessor('listingNumber', {
        header: () => '#',
        cell: (info) => (
          <div className='text-sm'>{info.row?.original?.listingNumber}</div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('customer_code', {
        header: () => 'Pelanggan',
        cell: (info) => {
          const cName = info.row.original.customer_name;
          return (
            <div className='max-w-xs truncate text-sm'>
              {cName}
              <br />({info.getValue()})
            </div>
          );
        },
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('customer_phone', {
        header: () => 'No. Telepon',
        cell: (info) => (
          <div className='max-w-2xl truncate text-sm'>{info.getValue()}</div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('customer_whatsapp', {
        header: () => 'No. Whatsapp',
        cell: (info) => (
          <div className='inline-flex max-w-2xl gap-x-1 text-sm'>
            {info.getValue()}
            <a
              aria-disabled={true}
              href={`https://wa.me/${info.getValue()}`}
              target='_blank'
              rel='noreferrer'
              className='text-green font-medium'
            >
              <FaWhatsapp size={24} />
            </a>
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('customer_email', {
        header: () => 'Email',
        cell: (info) => (
          <div className='max-w-2xl truncate text-sm'>
            {info.getValue() || '-'}
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('customer_tin', {
        header: () => 'NPWP',
        cell: (info) => (
          <div className='max-w-2xl truncate text-sm'>
            {info.getValue() || '-'}
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('customer_status', {
        header: () => 'Status',
        cell: (info) => (
          <div className='max-w-2xl truncate text-xs'>
            {info.getValue() === 'active' ? (
              <span className='badge badge-success'>{info.getValue()}</span>
            ) : info.getValue() === 'non-active' ? (
              <span className='badge badge-error'>{info.getValue()}</span>
            ) : (
              <span className='badge'>{info.getValue()}</span>
            )}
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('customer_create_date', {
        header: () => 'TANGGAL DAFTAR',
        cell: (info) => (
          <div className='max-w-2xl truncate text-sm'>
            {dateFormat(info.getValue(), 'DD MMM YYYY HH.mm')}
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('customer_id', {
        header: () => 'AKSI',
        cell: (info) => (
          <div className='flex gap-x-1'>
            <Link
              aria-disabled={true}
              to={`/customers/${info.getValue()}`}
              className='font-medium text-success'
            >
              <FiEye size={24} />
            </Link>
            <Link
              to={`/customers/${info.getValue()}/edit`}
              className='font-medium text-warning'
            >
              <FiEdit size={24} />
            </Link>
          </div>
        ),
        footer: (info) => info.column.id,
      }),
    ],
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const label = event.target.files[0]?.name;
    if (file) {
      setSelectedFile(file);
      setFileLabel(label);
      setFileSelected(true);
    }
  };

  const handleImportClick = () => {
    fileInputRef.current.click();
  };

  const importExcel = async () => {
    const id = toast.loading('Mem-proses data...');
    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      await request.post('/customers/import', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Important for file uploads
        },
      });
      toast.update(id, {
        render: 'Berhasil Impor Pelanggan',
        type: 'success',
        isLoading: false,
        closeButton: true,
      });
      setRefresh(true);
    } catch (err) {
      if (err.response?.data?.errors) {
        Object.keys(err.response.data.errors).map((field) =>
          err.response.data.errors[field].map((message) =>
            toast.update(id, {
              render: message,
              type: 'error',
              isLoading: false,
              closeButton: true,
            })
          )
        );
      } else if (err.response?.data?.message) {
        toast.update(id, {
          render: err.response.data.message,
          type: 'error',
          isLoading: false,
          closeButton: true,
        });
      } else {
        toast.update(id, {
          render: err.message,
          type: 'error',
          isLoading: false,
          closeButton: true,
        });
      }
    }

    setSelectedFile(null);
    setFileLabel('');
    setFileSelected(false);
  };

  return (
    <div>
      <Card extra={'w-full sm:overflow-auto p-4'}>
        <h2 className='mb-1 text-xl font-bold text-secondary'>
          Daftar Pelanggan
        </h2>
        <div className='overflow-x-scroll xl:overflow-x-hidden'>
          <Table
            columns={columns}
            data={data}
            isLoading={isLoading}
            fetchData={fetchData}
            setRefresh={setRefresh}
            pageCount={pageCount}
            totalRow={totalRow}
            actions={() => (
              <div className='flex flex-col items-center sm:flex-row xs:flex-row gap-2'>
                <div className='flex items-center sm:mb-0 sm:mr-1'>
                  <input
                    ref={fileInputRef}
                    type='file'
                    className='hidden'
                    onChange={handleFileChange}
                  />
                  <div className='gap-y-1 md:flex-col'>
                    {fileSelected && (
                      <div className='font-[0.875rem] text-decoration-none inline-flex h-[2rem] min-h-[2rem] w-full flex-wrap items-center justify-center rounded-[0.5rem] border-[1px] border-transparent bg-gray-100 px-[0.75rem] text-center text-[0.875rem] leading-[1.25rem] hover:border-opacity-100 sm:w-auto md:rounded-r-none md:pr-[4px]'>
                        <div className='mr-1 items-center'>
                          <div className='max-w-[150px] truncate text-sm'>
                            {fileLabel}
                          </div>
                        </div>
                        <div className='flex items-center'>
                          <button
                            onClick={() => {
                              setFileLabel('');
                              setFileSelected(false);
                              setSelectedFile(null);
                            }}
                          >
                            <AiFillDelete size={24} />
                          </button>
                        </div>
                      </div>
                    )}
                    {!fileSelected && (
                      <button
                        className='btn btn-secondary btn-sm w-full sm:w-auto'
                        onClick={handleImportClick}
                      >
                        <span className='text-sm text-white'>Impor Excel</span>
                      </button>
                    )}
                    {fileSelected && (
                      <button
                        className='btn btn-secondary btn-sm mt-2 w-full sm:mt-0 sm:w-auto md:rounded-l-none'
                        onClick={importExcel}
                      >
                        <span className='truncate text-sm text-white'>
                          Unggah
                        </span>
                      </button>
                    )}
                  </div>
                </div>
                <Link className='btn btn-secondary btn-sm' to={'/customers/add'}>Tambah</Link>
              </div>
            )}
          />
        </div>
      </Card>
    </div>
  );
};
export default List;
