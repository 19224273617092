import React, { useState } from 'react';
import './Tabs.css'; // Import your CSS file for styling

const Tabs = ({ tabs }) => {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    return (
        <div className="pt-3">
            <div className="tabs">
                {/*<div className="flex rounded-lg">*/}
                <div className="tab-buttons">
                    {tabs.map((tab, index) => (
                        <button
                            key={index}
                            className={index === activeTab ? 'active' : ''}
                            onClick={() => handleTabClick(index)}
                        >
                            {tab.title}
                        </button>
                    ))}
                </div>
            </div>
            <div className="tab-content">
                {tabs[activeTab].content}
            </div>
        </div>

    );
};

export default Tabs;