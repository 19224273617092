import React, {useCallback, useEffect, useMemo, useState} from "react";
import {toast} from "react-toastify";
import {createColumnHelper,} from "@tanstack/react-table";
import Card from "../../../components/card";
import {Link} from "react-router-dom";
import {FiEdit} from "react-icons/fi";
import Table from "../../../components/table/Table";
import { dateFormat } from 'utils/date';

const List =({ request }) =>{
    const [data, setData]               =useState([]);
    const [isLoading, setIsLoading]   =useState(false);
    const [pageCount, setPageCount]   =useState(0);
    const [totalRow, setTotalRow]     =useState(0);
    const [, setRefresh]                       =useState(false);
    const [, setZoneRequest]                   =useState([]);
    const [requestId]                   =useState([]);
    const [requestName]                 =useState([]);
    const [phoneNumber]                 =useState([]);
    const [requestAddress]              =useState([]);
    const [requestStatus]               =useState([]);
    const [requestDate]                 =useState([]);

    useEffect(() => {
        request.get('/zone-request').then((response) => {
            setZoneRequest(response);
        });
    }, [request]);


    const fetchData = useCallback(
        async (pageSize, pageIndex, search) =>{
            setIsLoading(true);
            const params={
                per_page:pageSize,
                page: pageIndex,
                keyword:search,
                request_Id:requestId,
                request_Name:requestName,
                phone_number:phoneNumber,
                request_Address:requestAddress,
                request_Status:requestStatus,
                request_Date:requestDate,
            };

            try {
                const response = await request.get('/zone-request',{params});
                const startIndex = response.from;
                const updateData = response.data.map((item, index) =>({
                    ...item,
                    listingNumber: startIndex + index,
                }));
                setData(updateData);
                setPageCount(response.last_page);
                setTotalRow(response.total);
            } catch (err){
                if(err.response?.data?.error){
                    Object.keys(err.response.data.error).map((field)=>
                        err.response.data.errors[field].map((message) =>
                            toast.error(message)
                        )
                    );
                } else if(err.response?.data.message){
                    toast.error(err.response.data.message);
                } else {
                    toast.error(err.message);
                }
            }
            setIsLoading(false);
            setRefresh(false);
        },
        [ request, requestId, requestName, phoneNumber, requestAddress, requestDate, requestStatus]
    );
    const columnHelper = createColumnHelper();


    const columns = useMemo(
        ()=>[
            columnHelper.accessor('listingNumber', {
                header: () => '#',
                cell: (info) => (
                    <div className='text-sm'>{info.row.original.listingNumber}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('z_request_from_name', {
                header: () => 'Nama Pemohon',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>{info.getValue()}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('z_request_from_phone', {
                header: () => 'Nomor',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>{info.getValue()}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('z_request_from_address', {
                header: () => 'Alamat Pemohon',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>{info.getValue()}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('z_request_status', {
                header: () => 'Status',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>
                        {info.getValue() === 'new' ? (
                            <span className='badge badge-info'>{info.getValue()}</span>
                        ) : info.getValue() === 'reviewed' ? (
                            <span className='badge badge-warning'>{info.getValue()}</span>
                        ) : info.getValue() === 'rejected' ? (
                            <span className='badge badge-error'>{info.getValue()}</span>
                        ) :info.getValue() === 'done' ? (
                            <span className='badge badge-success'>{info.getValue()}</span>
                        ) : (
                            <span className='badge'>{info.getValue()}</span>
                        )}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('z_request_create_date', {
                header: () => 'Tanggal Permohonan',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-sm'>{dateFormat(info.getValue(), 'DD MMM YYYY')}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('action', {
                header: () => 'Aksi',
                cell: (info) => (
                    <div className='flex'>
                        <Link
                            to={`/request-zone/${info.row.original.z_request_id}/edit`}
                            className='font-medium text-warning'
                        >
                            <FiEdit size={24} />
                        </Link>
                    </div>
                ),
                footer: (info) => info.column.id,
                enableSorting: false,
                enableGrouping: false,
            }),
        ],[columnHelper]
        );
    return(
        <>
            <Card extra={'w-full sm:overflow-auto p-4'}>
                <h2 className='mb-1 text-xl font-bold text-secondary'>Daftar Permohonan Daerah</h2>
                <div className='overflow-x-scroll xl:overflow-x-hidden'>
                    <Table
                        columns={columns}
                        data={data}
                        isLoading={isLoading}
                        fetchData={fetchData}
                        setRefresh={setRefresh}
                        pageCount={pageCount}
                        totalRow={totalRow}
                        filters={() => (
                            <>

                            </>
                        )}
                    />
                </div>
            </Card>
        </>
    );
}

export default List;