export const convertToRupiah = (angka, showRp) => {
  if (!angka) {
    angka = 0;
  }
  const parts = angka.toString().split('.');
  let rupiah = '';

  const integerPart = parts[0].toString();
  const integerReversed = integerPart.split('').reverse().join('');
  for (let i = 0; i < integerReversed.length; i++) {
    if (i % 3 === 0 && i !== 0) {
      rupiah += '.';
    }
    rupiah += integerReversed[i];
  }

  rupiah = rupiah.split('').reverse().join('');
  if (parts.length > 1 && parseInt(parts[1]) !== 0) {
    rupiah += ',' + parts[1];
  }

  if (showRp) {
    rupiah = 'Rp ' + rupiah;
  }

  return rupiah;
};

export const rupiahToNumber = (textRupiah) => {
  var resultNumber1 = textRupiah.replace('Rp ', '');

  var resultNumber2 = resultNumber1.replace('.', '');
  var resultNumber3 = resultNumber2.replace('.', '');
  var resultNumber4 = resultNumber3.replace('.', '');

  return parseInt(resultNumber4);
};
