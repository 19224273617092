import { Route, Routes } from 'react-router-dom';
import List from './components/List';
import Edit from './components/Edit';
import Add from './components/Add';

const PackageCategories = (props) => {
  return (
    <Routes>
      <Route path='/' element={<List {...props} />} />
      <Route path='/:id/edit' element={<Edit {...props} />} />
      <Route path='/create' element={<Add {...props} />} />
    </Routes>
  );
};

export default PackageCategories;
