import Card from "../../../components/card";
import {Link, useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {useForm} from "react-hook-form";
import {useEffect, useState} from "react";


const Edit = ({request})=>{
    const params                            = useParams();
    const navigate                          = useNavigate();
    const [dataCoverage, setCoverage]       = useState([]);
    const [dataProvinces, setProvinces]     = useState([]);
    const [dataDistrict, setDistrict]       = useState([]);
    const [dataSubdistrict, setSubdistrict] = useState([]);
    const [dataWards, setWards]             = useState([]);
    const [dataHamlets, setHamlets]         = useState([]);

    useEffect(() => {
        request.get('/provinces').then((response) => {
            setProvinces(response);
        });
        request.get('/coverages').then((response) => {
            setCoverage(response);
        });

    }, [request]);
    const {
        register,
        handleSubmit,
    } = useForm({
        defaultValues: async () => {
            try {
                const data = await request.get(`/zones/${params.id}`);
                await request.get(`/districts?province_id=${data.province_id}`).then((response) => {
                    setDistrict(response);
                })
                await request.get(`/subdistricts?district_id=${data.district_id}`).then((response) => {
                    setSubdistrict(response);
                })
                await request.get(`/wards?subdistrict_id=${data.subdistrict_id}`).then((response) => {
                    setWards(response);
                })
                await request.get(`/hamlets?ward_id=${data.ward_id}`).then((response) => {
                    setHamlets(response);
                })

                return {
                    coverage_id     : data.coverage_id,
                    province_id     : data.province_id,
                    district_id     : data.district_id,
                    subdistrict_id  : data.subdistrict_id,
                    ward_id         : data.ward_id,
                    hamlet_id       : data.hamlet_id,
                    zone_postcode   : data.zone_postcode    ,
                };
            } catch (err) {
                if (err.response?.data?.errors) {
                    Object.keys(err.response.data.errors).map((field) =>
                        err.response.data.errors[field].map((message) =>
                            toast.error(message)
                        )
                    );
                } else if (err.response?.data?.message) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error(err.message);
                }
            }
        },
    });

    const submitHandler = async (data) => {
        try {
            await request.put(`/zones/${params.id}`, data);
            toast.success('Berhasil menyimpan data');
            return navigate('/zones');
        } catch (err) {
            if (err.response?.data?.errors) {
                Object.keys(err.response.data.errors).map((field) =>
                    err.response.data.errors[field].map((message) => toast.error(message))
                );
            } else if (err.response?.data?.message) {
                toast.error(err.response.data.message);
            } else {
                toast.error(err.message);
            }
        }
    };

    const errorHandler = (errors, e) => {
        Object.keys(errors).map((key) => {
            if (Array.isArray(errors[key])) {
                return errors[key].map((err) => {
                    return toast.error(err.message);
                });
            }

            return toast.error(errors[key].message);
        });
    };
    return (
        <>
            <Card extra={'w-full sm:overflow-auto p-4'}>
                <h2 className='text-xl font-bold text-navy-700'>Edit Daerah</h2>
                <form
                    onSubmit={handleSubmit(submitHandler, errorHandler)}
                    className='mt-4 flex flex-col gap-y-1 overflow-x-scroll xl:overflow-x-hidden'
                >
                    <div className='grid grid-cols-2'>
                        {/*Cakupan*/}
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Cakupan</span>
                            </label>
                            <select
                                {...register('coverage_id', {
                                    required: 'Cakupan harus dipilih',
                                })}
                                className='select select-bordered w-full'
                            >
                                <option value='' disabled selected>
                                    Pilih Cakupan
                                </option>
                                {dataCoverage.map((item) => (
                                    <option key={item.coverage_id} value={item.coverage_id}>
                                        {item.coverage_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {/*Provinsi*/}
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Provinsi</span>
                            </label>
                            <select
                                {...register('province_id', {
                                    required: 'Provinsi harus dipilih',
                                    onChange:(e)=>{
                                        const params = {
                                            province_id: e.target.value,
                                        };
                                        request.get('/districts', {params}).then((response) => {
                                            setDistrict(response);
                                        });
                                    }
                                })}
                                className='select select-bordered w-full'
                            >
                                <option value='' disabled selected>
                                    Pilih Provinsi
                                </option>
                                {dataProvinces.map((item) => (
                                    <option key={item.province_id} value={item.province_id}>
                                        {item.province_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {/*Kabupaten*/}
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Kabupaten</span>
                            </label>
                            <select
                                {...register('district_id', {
                                    required: 'Kabupaten harus dipilih',
                                    onChange:(e)=>{
                                        const params = {
                                            district_id: e.target.value,
                                        };
                                        request.get('/subdistricts', {params}).then((response) => {
                                            setSubdistrict(response);
                                        });
                                    }
                                })}
                                className='select select-bordered w-full'
                            >
                                <option value='' disabled selected>
                                    Pilih Kabupaten
                                </option>
                                {dataDistrict.map((item) => (
                                    <option key={item.district_id} value={item.district_id}>
                                        {item.district_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {/*Kecamatan*/}
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Kecamatan</span>
                            </label>
                            <select
                                {...register('subdistrict_id', {
                                    required: 'Kecamatan harus dipilih',
                                    onChange:(e)=>{
                                        const params = {
                                            subdistrict_id: e.target.value,
                                        };
                                        request.get('/wards', {params}).then((response) => {
                                            setWards(response);
                                        });
                                    }
                                })}
                                className='select select-bordered w-full'
                            >
                                <option value='' disabled selected>
                                    Pilih Kecamatan
                                </option>
                                {dataSubdistrict.map((item) => (
                                    <option key={item.subdistrict_id} value={item.subdistrict_id}>
                                        {item.subdistrict_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {/*Desa*/}
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Desa</span>
                            </label>
                            <select
                                {...register('ward_id', {
                                    required: 'Desa harus dipilih',
                                    onChange:(e)=>{
                                        const params = {
                                            ward_id: e.target.value,
                                        };
                                        request.get('/hamlets', {params}).then((response) => {
                                            setHamlets(response);
                                        });
                                    }
                                })}
                                className='select select-bordered w-full'
                            >
                                <option value='' disabled selected>
                                    Pilih Desa
                                </option>
                                {dataWards.map((item) => (
                                    <option key={item.ward_id} value={item.ward_id}>
                                        {item.ward_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {/*Dusun*/}
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Dusun</span>
                            </label>
                            <select
                                {...register('hamlet_id', {
                                    required: 'Dusun harus dipilih',
                                })}
                                className='select select-bordered w-full'
                            >
                                <option value='' disabled selected>
                                    Pilih Dusun
                                </option>
                                {dataHamlets.map((item) => (
                                    <option key={item.hamlet_id} value={item.hamlet_id}>
                                        {item.hamlet_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {/*Postcode*/}
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Kode Pos</span>
                            </label>
                            <input
                                {...register('zone_postcode', {
                                    required: 'kode pos harus dipilih',
                                })}
                                className='select select-bordered w-full'
                                type='number'
                            />
                        </div>
                    </div>
                    <div className='flex gap-x-2'>
                        <button type='submit' className='btn btn-primary'>
                            Simpan
                        </button>
                        <Link to='/zones' className='btn'>
                            Batal
                        </Link>
                    </div>
                </form>
            </Card>
        </>
    );
};

export default Edit;