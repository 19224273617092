import { Route, Routes } from 'react-router-dom';
import List from './components/List';
import Create from './components/Create';
import Details from './components/details';
import Edit from './components/Edit';

const Submissions = (props) => {
  return (
    <Routes>
      <Route path='/' element={<List {...props} />} />
      <Route path='/create' element={<Create {...props} />} />
      <Route path='/:id' element={<Details {...props} />} />
      <Route path='/:id/edit' element={<Edit {...props} />} />
    </Routes>
  );
};

export default Submissions;
