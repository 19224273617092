import Card from 'components/card';
import React, {useEffect, useState} from 'react';
import 'leaflet/dist/leaflet.css';
import {toast} from "react-toastify";
import {useParams} from "react-router-dom";

const Pdf = ({ request }) => {
    const params = useParams();
    const [pdfUrl, setPdfUrl] = useState('');

    useEffect(() => {
        request
            .get(`/invoices/${params.id}`)
            .then((response) => {
                setPdfUrl(response.pdf_url);
            })
            .catch((err) => {
                if (err.response?.data?.errors) {
                    Object.keys(err.response.data.errors).map((field) =>
                        err.response.data.errors[field].map((message) =>
                            toast.error(message)
                        )
                    );
                } else if (err.response?.data?.message) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error(err.message);
                }
            });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Card extra={'w-full h-[500px] p-3 rounded-[20px]'}>
                <div className='flex h-full flex-col justify-between'>
                    <object
                        data={pdfUrl}
                        type='application/pdf'
                        width='100%'
                        height='100%'
                        className='h-256'
                    >
                        <p className='font-bold text-accent'>Dokumen Invoice belum dibuat!</p>
                    </object>
                </div>
            </Card>
        </>
    );
}

export default Pdf;