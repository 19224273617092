import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import Card from 'components/card';
import React, {useEffect, useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';

const specRowCom = (props) => (
  <li {...props}>
    <input
      {...props.register('package_info[' + props.index + ']', {
        required: `Info ${props.index + 1} harus diisi`,
      })}
      type='text'
      placeholder='Ketikan info paket'
      className='input input-bordered w-full max-w-sm'
    />
  </li>
);

const Add = ({ request }) => {
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();
  const [specRows, setSpecRows] = useState([specRowCom]);
  const [service, setService]=useState([])

  useEffect(()=>{
    request.get('/package-services').then((response)=>{
      setService(response);
    });
  },[request]);

  const handleAddSpecRow = () => {
    setSpecRows((prev) => {
      return [...prev, specRowCom];
    });
  };

  const submitHandler = async (data, e) => {
    try {
      await request.post('/packages', data);
      toast.success('Berhasil menyimpan data');
      return navigate('/packages');
    } catch (err) {
      if (err.response?.data?.errors) {
        Object.keys(err.response.data.errors).map((field) =>
          err.response.data.errors[field].map((message) => toast.error(message))
        );
      } else if (err.response?.data?.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    }
  };

  const errorHandler = (errors, e) => {
    Object.keys(errors).map((key) => {
      if (Array.isArray(errors[key])) {
        return errors[key].map((err) => {
          return toast.error(err.message);
        });
      }

      return toast.error(errors[key].message);
    });
  };

  return (
    <>
      <Card extra={'w-full sm:overflow-auto p-4'}>
        <h2 className='text-xl font-bold text-navy-700'>Tambah Daftar Paket</h2>
        <form
          onSubmit={handleSubmit(submitHandler, errorHandler)}
          className='mt-4 flex flex-col gap-y-1 overflow-x-scroll xl:overflow-x-hidden'
        >
          <div className='grid grid-cols-2'>
            <div className='form-control w-full max-w-sm'>
              <label className='label'>
                <span className='label-text'>Nama Paket</span>
              </label>
              <input
                  {...register('package_name', {
                    required: 'Nama harus diisi'})}
                  type='text'
                  placeholder='Ketikan nama paket'
                  className='input input-bordered w-full max-w-sm'
              />
            </div>
            <div className='form-control w-full max-w-sm'>
              <label className='label'>
                <span className='label-text'>Speed (Mbps)</span>
              </label>
              <input
                  {...register('package_speed', {
                    required: 'Kecepatan harus diisi',
                  })}
                  type='number'
                  placeholder='Ketikan kecepatan paket'
                  className='input input-bordered w-full max-w-sm'
              />
            </div>
            <div className='form-control row-span-3 w-full max-w-sm'>
              <label className='label'>
                <span className='label-text'>Info</span>
              </label>
              <ul className='flex list-decimal flex-col gap-y-1'>
                {specRows.map((Row, index) => (
                    <Row key={index} index={index} register={register}/>
                ))}
                <button
                    type='button'
                    onClick={handleAddSpecRow}
                    className='btn btn-secondary mt-2 w-fit self-end'
                >
                  Baris Baru
                </button>
              </ul>
            </div>
            <div className='form-control w-full max-w-sm'>
              <label className='label'>
                <span className='label-text'>Status</span>
              </label>
              <select
                  {...register('package_status', {
                    required: 'Status harus dipilih',
                  })}
                  className='select select-bordered'
              >
                <option value='' disabled selected>
                  Pilih Status Paket
                </option>
                <option value='active'>Aktif</option>
                <option value='non-active'>Non-Aktif</option>
              </select>
            </div>
            <div className='form-control w-full max-w-sm'>
              <label className='label'>
                <span className='label-text'>Deskripsi</span>
              </label>
              <textarea
                  {...register('package_desc')}
                  className='textarea textarea-bordered h-24'
                  placeholder='Ketikan deskripsi paket'
              ></textarea>
            </div>
            <div className='form-control w-full max-w-sm'>
              <label className='label'>
                <span className='label-text'>Service</span>
              </label>

              <select
                  {...register('service_id', {
                    // required: 'Servis harus dipilih',
                  })}
                  className='select select-bordered'
              >
                <option value='' disabled>
                  Pilih Servis
                </option>
                {service.map((item) => (
                    <option key={item.service_id} value={item.service_id}>
                      {item.service_name}
                    </option>
                ))}
              </select>
            </div>
          </div>
          <div className='flex gap-x-2'>
            <button type='submit' className='btn btn-primary'>
              Simpan
            </button>
            <Link to='/packages' className='btn'>
              Batal
            </Link>
          </div>
        </form>
      </Card>
    </>
  );
};

export default Add;
