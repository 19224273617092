import { toast } from 'react-toastify';
import {useForm} from 'react-hook-form';
import Card from 'components/card';
import { useEffect, useState} from 'react';
import Select from 'react-select';
import MapContainer from './maps'
import {Link, useNavigate} from "react-router-dom";
import CurrencyInput from "react-currency-input-field";

const Add = ({ request }) => {
    const navigate                                      = useNavigate();
    const [data, setData]                               = useState([]);
    const { register, handleSubmit, setValue  }         = useForm();
    const [selectCus, setSelectCus]                     = useState(null);
    const [isLoading, setIsLoading]                     = useState(false);
    const [dataProvinces, setProvinces]                 = useState([]);
    const [dataDistrict, setDistrict]                   = useState([]);
    const [dataSubdistrict, setSubdistrict]             = useState([]);
    const [dataWards, setWards]                         = useState([]);
    const [dataHamlets, setHamlets]                     = useState([]);
    const [dataPackages, setPackages]                   = useState([]);
    const [dataSubscriptionType, setSubscriptionType]   = useState([]);
    const [dataCoverages, setCoverages]                 = useState([]);
    const [markerPosition, setMarkerPosition]           = useState(null);


    const handleChangeSelect = (selectedOption) => {
        if (!selectedOption) {
            setSelectCus(null);
            setValue('customerId','');
        } else {
            setSelectCus(selectedOption);
            setValue('customerId',selectedOption.value);
        }
    };

    const loadData = async (search) => {
        const params = {
            per_page: 5,
            page    : 1,
            keyword : search,
        };
        try {
            const response = await request.get('/customers', { params });
            const startIndex = response.from;
            const updatedData = response.data.map((item, index) => ({
                ...item,
                listingNumber: startIndex + index,
            }));
            setData(updatedData);
        } catch (error) {
            console.error('Error loading data:', error);
            toast.error('Error loading data. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        request.get('/provinces').then((response) => {
            setProvinces(response);
        });

        request.get('/packages').then((response) => {
            setPackages(response);
        });
        request.get('/subscription-type').then((response) => {
            setSubscriptionType(response);
        });

        request.get('/coverages').then((response) => {
            setCoverages(response);
        });
    }, [request]);

    const submitHandler = async (data) => {
        try {
            await request.post('/create-subscriptions', data);
            toast.success('Berhasil menyimpan data');
            return navigate('/subscriptions');
        } catch (err) {
            if (err.response?.data?.errors) {
                Object.keys(err.response.data.errors).map((field) =>
                    err.response.data.errors[field].map((message) => toast.error(message))
                );
            } else if (err.response?.data?.message) {
                toast.error(err.response.data.message);
            } else {
                toast.error(err.message);
            }
        }
    };

    const errorHandler = (errors, e) => {
        Object.keys(errors).map((key) => {
            if (Array.isArray(errors[key])) {
                return errors[key].map((err) => {
                    return toast.error(err.message);
                });
            }

            return toast.error(errors[key].message);
        });
    };

    const handleMapClick = (event) => {
        setMarkerPosition({
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
        });
        setValue('titik_koordinat',`${event.latLng.lat()} , ${event.latLng.lng()}`)
    };
    return(
        <>
            <Card extra={'w-full sm:overflow-auto p-4'}>
                <h2 className='text-xl font-bold text-navy-700'>Tambah Langganan</h2>
                <form
                    onSubmit={handleSubmit(submitHandler, errorHandler)}
                    className='mt-4 flex flex-col gap-y-1 overflow-x-scroll xl:overflow-x-hidden'
                >
                    <div className='grid grid-cols-2'>
                        {/*Nama*/}
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Nama</span>
                            </label>
                            <Select
                                {...register('customerId', {required: 'Nama Pelanggan harus dipilih',})}
                                value={selectCus}
                                isLoading={isLoading}
                                onChange={handleChangeSelect}
                                onKeyDown={
                                    event=>{
                                        if(event.key.length > 0){
                                            loadData(event.target.valueOf().value)
                                        }
                                    }
                                }
                                options={
                                    data.length !== null
                                        ? data.map((item, index) => ({
                                            key: index,
                                            label: `${item.customer_name}`,
                                            value: item.customer_id,
                                        }))
                                        : []
                                }
                                isClearable={true}

                            />
                        </div>
                        {/*Provinsi*/}
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Provinsi</span>
                            </label>
                            <select
                                {...register('provinceId', {
                                    required: 'Provinsi harus dipilih',
                                    onChange:(e)=>{
                                        const params = {
                                            province_id: e.target.value,
                                        };
                                        request.get('/districts', {params}).then((response) => {
                                            setDistrict(response);
                                        });
                                    }
                                })}
                                className='select select-bordered w-full'
                            >
                                <option value='' disabled selected>
                                    Pilih Provinsi
                                </option>
                                {dataProvinces.map((item) => (
                                    <option key={item.province_id} value={item.province_id}>
                                        {item.province_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {/*Kabupaten*/}
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Kabupaten</span>
                            </label>
                            <select
                                {...register('districtId', {
                                    required: 'Kabupaten harus dipilih',
                                    onChange:(e)=>{
                                        const params = {
                                            district_id: e.target.value,
                                        };
                                        request.get('/subdistricts', {params}).then((response) => {
                                            setSubdistrict(response);
                                        });
                                    }
                                })}
                                className='select select-bordered w-full'
                            >
                                <option value='' disabled selected>
                                    Pilih Kabupaten
                                </option>
                                {dataDistrict.map((item) => (
                                    <option key={item.district_id} value={item.district_id}>
                                        {item.district_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {/*Kecamatan*/}
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Kecamatan</span>
                            </label>
                            <select
                                {...register('subdistrictId', {
                                    required: 'Kecamatan harus dipilih',
                                    onChange:(e)=>{
                                        const params = {
                                            subdistrict_id: e.target.value,
                                        };
                                        request.get('/wards', {params}).then((response) => {
                                            setWards(response);
                                        });
                                    }
                                })}
                                className='select select-bordered w-full'
                            >
                                <option value='' disabled selected>
                                    Pilih Kecamatan
                                </option>
                                {dataSubdistrict.map((item) => (
                                    <option key={item.subdistrict_id} value={item.subdistrict_id}>
                                        {item.subdistrict_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {/*Desa*/}
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Desa</span>
                            </label>
                            <select
                                {...register('wardId', {
                                    required: 'Desa harus dipilih',
                                    onChange:(e)=>{
                                        const params = {
                                            ward_id: e.target.value,
                                        };
                                        request.get('/hamlets', {params}).then((response) => {
                                            setHamlets(response);
                                        });
                                    }
                                })}
                                className='select select-bordered w-full'
                            >
                                <option value='' disabled selected>
                                    Pilih Desa
                                </option>
                                {dataWards.map((item) => (
                                    <option key={item.ward_id} value={item.ward_id}>
                                        {item.ward_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {/*Dusun*/}
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Dusun</span>
                            </label>
                            <select
                                {...register('hamletId', {
                                    required: 'Dusun harus dipilih',
                                })}
                                className='select select-bordered w-full'
                            >
                                <option value='' disabled selected>
                                    Pilih Dusun
                                </option>
                                {dataHamlets.map((item) => (
                                    <option key={item.hamlet_id} value={item.hamlet_id}>
                                        {item.hamlet_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {/*Paket*/}
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Paket</span>
                            </label>
                            <select
                                {...register('paketId', {
                                    required: 'Paket harus dipilih',
                                })}
                                className='select select-bordered w-full'
                            >
                                <option value='' disabled selected>
                                    Pilih Paket
                                </option>
                                {dataPackages.map((item) => (
                                    <option key={item.package_id} value={item.package_id}>
                                        {item.package_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {/*Type Langganan*/}
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Type Langganan</span>
                            </label>
                            <select
                                {...register('type_langganan_id', {
                                    required: 'Dusun harus dipilih',
                                })}
                                className='select select-bordered w-full'
                            >
                                <option value='' disabled selected>
                                    Pilih Type Langganan
                                </option>
                                {dataSubscriptionType.map((item) => (
                                    <option key={item.s_type_id} value={item.s_type_id}>
                                        {item.s_type_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {/*Biaya Pemasangan*/}
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Biaya Pemasangan</span>
                            </label>
                            <CurrencyInput
                                {...register('biaya_pemasangan', {
                                    required: 'Biaya Pemasangan harus diisi',
                                })}
                                prefix='Rp '
                                placeholder='Silahkan masukan harga Biaya Pemasangan'
                                defaultValue={0}
                                decimalSeparator=','
                                groupSeparator='.'
                                intlConfig={{ locale: 'id-ID', currency: 'IDR' }}
                                className='input input-bordered w-full max-w-sm'
                            />
                        </div>
                        {/*Tanggal Aktif*/}
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Tanggal Aktif</span>
                            </label>
                            <input
                                {...register('tanggal_aktif', { required: 'Tanggal Aktif harus diisi' })}
                                type='date'
                                className='input input-bordered w-full max-w-sm'
                            />
                        </div>
                        {/*Jangkauan*/}
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Jangkauan</span>
                            </label>
                            <select
                                {...register('coverageId', {
                                    required: 'Dusun harus dipilih',
                                })}
                                className='select select-bordered w-full'
                            >
                                <option value='' disabled selected>
                                    Pilih Jangkauan
                                </option>
                                {dataCoverages.map((item) => (
                                    <option key={item.coverage_id} value={item.coverage_id}>
                                        {item.coverage_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {/*Alamat*/}
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Alamat</span>
                            </label>
                            <textarea
                                {...register('alamat')}
                                className='textarea textarea-bordered h-24'
                                placeholder='Ketikan Alamat'
                            ></textarea>
                        </div>
                        {/*Titik Koordinat*/}
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Titik Koordinat</span>
                            </label>
                            <MapContainer
                                handleMapClick ={(e)=>handleMapClick(e)}
                                markerPosition ={markerPosition}
                            />
                        </div>

                    </div>
                    <div className='flex gap-x-2'>
                        <button type='submit' className='btn btn-primary'>
                            Simpan
                        </button>
                        <Link to='/subscriptions' className='btn'>
                            Batal
                        </Link>
                    </div>
                </form>
            </Card>
        </>
    )
};
export default Add;