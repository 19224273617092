import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import Card from 'components/card';
import { Link, useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {useState} from 'react';
import CurrencyInput from "react-currency-input-field";
import {convertToRupiah} from "../../../utils/general";
import Select from "react-select";

const Add = ({ request }) => {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        setValue,
        getValues
    } = useForm();

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData]                               = useState([]);
    const [selectPrice, setSelectPrice] = useState([]);
    const [selectZones, setSelectZones] = useState([]);

    const handleChangeSelectZones = (selectedOption) => {
        if (!selectedOption) {
            setSelectZones(null);
            setValue('zone_id','zone_id');
        } else {
            setSelectZones(selectedOption);
            setValue('zone_id',selectedOption.value);
        }
    };

    const handleChangeSelectPrices = (selectedOption) => {
        if (!selectedOption) {
            setSelectPrice(null);
            setValue('p_price_id','p_price_id');
        } else {
            setSelectPrice(selectedOption);
            setValue('p_price_id',selectedOption.value);
        }
    };

    const loadDataZones = async (search) => {
        const params = {
            per_page: 20,
            page    : 1,
            keyword : search,
        };
        try {
            const response = await request.get('/zones', { params });
            const startIndex = response.from;
            const updatedData = response.data.map((item, index) => ({
                ...item,
                listingNumber: startIndex + index,
            }));
            setData(updatedData);
        } catch (error) {
            console.error('Error loading data:', error);
            toast.error('Error loading data. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const loadDataPrices = async (search) => {
        const params = {
            per_page: 20,
            page    : 1,
            keyword : search,
        };
        try {
            const response = await request.get('/package-prices', { params });
            const startIndex = response.from;
            const updatedData = response.data.map((item, index) => ({
                ...item,
                listingNumber: startIndex + index,
            }));
            setData(updatedData);
        } catch (error) {
            console.error('Error loading data:', error);
            toast.error('Error loading data. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const submitHandler = async (data) => {
        try {
            setIsLoading(true);
            await request.post('/installation-price', data);
            toast.success('Berhasil menyimpan data');
            return navigate('/installation-price');
        } catch (err) {
            if (err.response?.data?.errors) {
                Object.keys(err.response.data.errors).map((field) =>
                    err.response.data.errors[field].map((message) => toast.error(message))
                );
            } else if (err.response?.data?.message) {
                toast.error(err.response.data.message);
            } else {
                toast.error(err.message);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const errorHandler = (errors) => {
        Object.keys(errors).map((key) => {
            if (Array.isArray(errors[key])) {
                return errors[key].map((err) => {
                    return toast.error(err.message);
                });
            }

            return toast.error(errors[key].message);
        });
    };

    return (
        <Card extra={'w-full sm:overflow-auto p-4'}>
            <h2 className='text-xl font-bold text-navy-700'>Tambah Harga Installasi</h2>
            <form
                onSubmit={handleSubmit(submitHandler, errorHandler)}
                className='mt-4 flex flex-col gap-y-1 overflow-x-scroll xl:overflow-x-hidden'
            >
                <div className='grid grid-cols-2'>
                    <div className='form-control w-full max-w-sm'>
                        <label className='label'>
                            <span className='label-text'>Daerah</span>
                        </label>
                        <Select
                            {...register('zone_id', {
                                required: 'Daerah harus dipilih',
                            })}
                            value={selectZones}
                            isLoading={isLoading}
                            onChange={handleChangeSelectZones}
                            onKeyDown={
                                event => {
                                    if (event.key.length > 0) {
                                        loadDataZones(event.target.valueOf().value)
                                    }
                                }
                            }
                            options={
                                data.length !== null
                                    ? data.map((item, index) => ({
                                        key: index,
                                        label: `${(item.zone_hamlet)}`,
                                        value: item.zone_id,
                                    }))
                                    : []
                            }
                            isClearable={true}
                        />
                    </div>
                    <div className='form-control w-full max-w-sm'>
                        <label className='label'>
                            <span className='label-text'>Harga Paket</span>
                        </label>
                        <Select
                            {...register('p_price_id', {
                                required: 'Harga paket harus dipilih',
                            })}
                            value={selectPrice}
                            defaultValue={getValues('p_price_id')}
                            isLoading={isLoading}
                            onChange={handleChangeSelectPrices}
                            onKeyDown={
                                event => {
                                    if (event.key.length > 0) {
                                        loadDataPrices(event.target.valueOf().value)
                                    }
                                }
                            }
                            options={
                                data.length !== null
                                    ? data.map((item, index) => ({
                                        key: index,
                                        label: `${'Rp ' + convertToRupiah(item.p_price_total)}`,
                                        value: item.p_price_id,
                                    }))
                                    : []
                            }
                            isClearable={true}
                        />
                    </div>
                    <div className='form-control w-full max-w-sm'>
                        <label className='label'>
                            <span className='label-text'>Harga Installasi</span>
                        </label>
                        {isLoading ? (
                            <Skeleton height={45}/>
                        ) : (
                            <CurrencyInput
                                {...register('i_price_value', {
                                    required: 'Harga installasi harus diisi',
                                })}
                                prefix='Rp '
                                placeholder='Silahkan masukan harga installasi'
                                decimalSeparator=','
                                groupSeparator='.'
                                intlConfig={{locale: 'id-ID', currency: 'IDR'}}
                                className='input input-bordered w-full max-w-sm'
                            />
                        )}
                    </div>
                    <div className='form-control w-full max-w-sm'>
                        <label className='label'>
                            <span className='label-text'>Status</span>
                        </label>
                        {isLoading ? (
                            <Skeleton height={45}/>
                        ) : (
                            <select
                                {...register('p_price_status', {
                                    required: 'Status harus dipilih',
                                })}
                                className='select select-bordered'
                            >
                                <option value='' disabled selected>
                                    Pilih Status Paket
                                </option>
                                <option value='active'>Aktif</option>
                                <option value='non-active'>Non-Aktif</option>
                            </select>
                        )}
                    </div>
                </div>
                <div className='flex flex-row-reverse gap-x-2'>
                    <button type='submit' className='btn btn-primary'>
                        Simpan
                    </button>
                    <Link to='/installation-price' className='btn'>
                        Batal
                    </Link>
                </div>
            </form>
        </Card>
    );
};
export default Add;
