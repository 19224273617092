import { Route, Routes } from 'react-router-dom';
import List from './components/List';
import Details from "./components/details";
const inboxes = (props) => {
    return (
        <Routes>
            <Route path='/' element={<List {...props} />} />
            <Route path='/:id' element={<Details {...props} />} />
        </Routes>
    );
};

export default inboxes;
