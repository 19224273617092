import { useState} from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import {FiLock, FiMail, FiEye, FiEyeOff} from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

export default function Login({ request }) {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();

  const submitHandler = (data) => {
    request
      .post('/auth/login', data)
      .then((response) => {
        return navigate(
          '/login/verify?otp_signature=' + response.otp_signature
        );
      })
      .catch((err) => {
        if (err.response?.data?.errors) {
          Object.keys(err.response.data.errors).map((field) =>
            err.response.data.errors[field].map((message) =>
              toast.error(message)
            )
          );
        } else if (err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      });
  };

  const errorHandler = (errors, e) => {
    Object.keys(errors).map((key) => {
      if (Array.isArray(errors[key])) {
        return errors[key].map((err) => {
          return toast.error(err.message);
        });
      }

      return toast.error(errors[key].message);
    });
  };

  return (
    <div className='mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start'>
      {/* Sign in section */}
      <form
        onSubmit={handleSubmit(submitHandler, errorHandler)}
        className='mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]'
      >
        <h4 className='mb-2.5 text-4xl font-bold text-navy-700 dark:text-white'>
          Login
        </h4>
        <p className='mb-9 ml-1 text-base text-gray-600'>
          Silahkan masukan email anda!
        </p>
        <div className='form-control w-full'>
          <label className='label'>
            <span className='label-text'>Email*</span>
          </label>
          <div className='relative'>
            <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-4'>
              <span className='text-gray-400 focus-within:text-gray-900 sm:text-base'>
                <FiMail />{' '}
              </span>
            </div>
            <input
              {...register('user_email', { required: 'Email harus diisi' })}
              type='email'
              placeholder='email@innet.id'
              className='input input-bordered w-full pl-10'
            />
          </div>
        </div>
        <div className='form-control w-full'>
          <label className='label'>
            <span className='label-text'>Password*</span>
          </label>
          <div className='relative'>
            <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-4'>
              <span className='text-gray-400 focus-within:text-gray-900 sm:text-base'>
                <FiLock />{' '}
              </span>
            </div>
            <input
              {...register('user_password', {
                required: 'Password harus diisi',
              })}
              type={passwordVisible ? 'text' : 'password'}
              placeholder='Min. 8 karakter'
              className='input input-bordered w-full pl-10'
            />
            <div
              className='absolute inset-y-0 right-0 flex cursor-pointer items-center pr-4 text-gray-400'
              onClick={togglePasswordVisibility}
            >
              {passwordVisible ? <FiEyeOff /> : <FiEye />}
            </div>
          </div>
        </div>
        <button className='btn btn-primary mt-2 w-full'>Masuk</button>
      </form>
    </div>
  );
}
