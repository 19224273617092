import React, {useEffect, useMemo, useState} from "react";
import Card from "../../../components/card";
import Datepicker from "react-tailwindcss-datepicker";
import Select from "react-select";
import {toast} from "react-toastify";
import mutasiImg from "../../../assets/images/Group.svg";
import inImg from "../../../assets/images/in.svg";
import outImg from "../../../assets/images/out.svg";
import noDataImg from "../../../assets/svg/no-data.svg";
import {
    getCoreRowModel,
    getExpandedRowModel, getFilteredRowModel,
    getGroupedRowModel, getPaginationRowModel,
    getSortedRowModel,
    useReactTable
} from "@tanstack/react-table";
import {dateFormat} from "../../../utils/date";
import {convertToRupiah} from "../../../utils/general";

const List = ({ request }) =>{
    const [selectReseller, setSelectReseller]   = useState(null);
    const [valueReseller, setValueReseller]     = useState('');
    const [valueDate, setValueDate]             = useState({});
    const [isLoading, setIsLoading]             = useState(false);
    const [data, setData]                       = useState([]);
    const [dataMutasi, setDataMutasi]           = useState([]);
    const [refresh, setRefresh]                 = useState(false);
    const [pageCountt, setPageCount]            = useState(0);
    const [totalRow, setTotalRow]               = useState(0);
    const [grouping, setGrouping]               = useState([]);
    const [sorting, setSorting]                 = useState([]);
    const [saldoAwal, setsaldoAwal]             = useState('');
    const [saldoAkhir, setsaldoAkhir]           = useState('');
    let [saldo, setsaldo]                       = useState('');

    const finalData = useMemo(
        () => (isLoading ? Array(10).fill({}) : dataMutasi),
        [isLoading, dataMutasi]
    );

    const table = useReactTable({
        data: finalData,
        manualPagination: true,
        manualFiltering: true,
        manualSorting: true,
        initialState: {
            pagination: {
                pageIndex: 0,
                pageSize: 10,
            },
        },
        state: {
            grouping,
            sorting,
        },
        pageCount: pageCountt,
        autoResetAll: false,
        autoResetPageIndex: false,
        autoResetExpanded: false,
        onSortingChange: setSorting,
        onGroupingChange: setGrouping,
        getExpandedRowModel: getExpandedRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getGroupedRowModel: getGroupedRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        debugTable: true,
    });

    const {
        pagination: { pageIndex, pageSize },
    } = table.getState();

    useEffect(()=>{
        const fetchData = async (pageSize, pageIndex) => {
            setIsLoading(true);
            const params = {
                per_page: pageSize,
                page: pageIndex,
                start_date: valueDate?.startDate,
                end_date: valueDate?.endDate,
                reseller_id: valueReseller,
            };
            try {
                const response = await request.get('/reseller-mutation', { params });
                const startIndex = response.data.from;

                const updatedData = response.data.data.map((item, index) => ({
                    ...item,
                    listingNumber: startIndex + index,
                }));
                setDataMutasi(updatedData);
                setPageCount(response.data.last_page);
                setTotalRow(response.data.total);
                setsaldoAwal(response.bonus_awal);
                setsaldo(response.bonus_awal);
                setsaldoAkhir(response?.bonus_akhir?.reseller_balance);
            } catch (err) {
                if (err.response?.data?.errors) {
                    Object.keys(err.response.data.errors).map((field) =>
                        err.response.data.errors[field].map((message) =>
                            toast.error(message)
                        )
                    );
                } else if (err.response?.data?.message) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error(err.message);
                }
            }

            setIsLoading(false);
            setRefresh(false);
        }

        fetchData(pageSize, pageIndex + 1);
    }, [refresh, valueReseller, pageIndex, pageSize, valueDate]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleValueChange = (newValue) => {
        setValueDate(newValue);
    };

    const handleChangeSelect = (selectedOption) => {
        if (!selectedOption) {
            setSelectReseller(null);
            setValueReseller('');
        } else {
            setSelectReseller(selectedOption);
            setValueReseller(selectedOption.value);
        }
    };

    const loadData = async (search) => {
        const params = {
            per_page: 5,
            page    : 1,
            keyword : search,
        };
        try {
            const response = await request.get('/resellers', { params });
            const startIndex = response.from;
            const updatedData = response.data.map((item, index) => ({
                ...item,
                listingNumber: startIndex + index,
            }));
            setData(updatedData);
        } catch (error) {
            console.error('Error loading data:', error);
            toast.error('Error loading data. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Card extra={'w-full sm:overflow-auto p-4 mt-4'}>
            <h2 className='text-xl font-bold text-navy-700'>Marketing Mutasi</h2>
            <div className='overflow-x-scroll xl:overflow-x-hidden'>
                <div className='flex flex-col rounded-t-xl bg-primary p-2 '>
                    <div className="flex flex-col items-center">
                        <div className='gap-1 md:grid grid-cols-3 col'>
                            <div className='form-control w-full max-w-sm'>
                                <label className='label'>
                                    <span className='label-text'>Tanggal</span>
                                </label>
                                <Datepicker
                                    maxDate={new Date()}
                                    toggleClassName='relative left-2 right-0 h-full px-1 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed'
                                    containerClassName='input input-sm relative flex bg-white justify-self-start'
                                    inputClassName='outline-none w-full md:w-48 transition-all duration-300 font-light text-sm placeholder-gray-400'
                                    value={valueDate}
                                    onChange={handleValueChange}
                                    showShortcuts={true}
                                    placeholder='Pilih Tanggal'
                                />
                            </div>
                            <div/>
                            <div className='form-control w-full max-w-sm'>
                                <label className='label'>
                                    <span className='label-text'>Nama Marketing</span>
                                </label>
                                <Select
                                    value={selectReseller}
                                    isLoading={isLoading}
                                    onChange={handleChangeSelect}
                                    onKeyDown={
                                        event=>{
                                            if(event.key.length > 0){
                                                loadData(event.target.valueOf().value)
                                            }
                                        }
                                    }
                                    options={
                                        data.length !== null
                                            ? data.map((item, index) => ({
                                                key: index,
                                                label: `${item.reseller_name}`,
                                                value: item.reseller_id,
                                            }))
                                            : []
                                    }
                                    isClearable={true}

                                />
                            </div>

                        </div>
                    </div>
                    <div className="grid grid-rows-3 grid-flow-col gap-4">
                        <div className="row-start-2 row-span-3">
                            <div className='gap-1 md:grid grid-cols-5  p-2'>
                                <div style={{backgroundColor:"white", border:1, borderRadius:8}} className="my-1">
                                    <div className="grid grid-rows-2 grid-flow-col gap-1" style={{paddingTop:12, paddingLeft:16, paddingRight:16}}>
                                        <div className="col-span-2 ">
                                            <p style={{color:'#FBBD23'}}>{totalRow}</p>
                                        </div>
                                        <div className="col-span-2 ">
                                            <p style={{color:'#908C8C'}}>Jumlah Mutasi</p>
                                        </div>
                                        <div className="row-span-3 ">
                                            <img
                                                className='inline-block'
                                                height={35}
                                                width={35}
                                                src={mutasiImg}
                                                alt='mutasi'
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div style={{backgroundColor:"white", border:1, borderRadius:8}} className="my-1">
                                    <div className="grid grid-rows-2 grid-flow-col gap-1" style={{paddingTop:12, paddingLeft:16, paddingRight:16}}>
                                        <div className="col-span-2 ">
                                            <p style={{color:'#FBBD23'}}>{convertToRupiah( saldoAwal,true)}</p>
                                        </div>
                                        <div className="col-span-2 ">
                                            <p style={{color:'#908C8C'}}>Saldo Awal</p>
                                        </div>
                                        <div className="row-span-3 ">
                                            <img
                                                className='inline-block'
                                                height={40}
                                                width={40}
                                                src={inImg}
                                                alt='in'
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div style={{backgroundColor:"white", border:1, borderRadius:8}} className="my-1">
                                    <div className="grid grid-rows-2 grid-flow-col gap-1" style={{paddingTop:12, paddingLeft:16, paddingRight:16}}>
                                        <div className="col-span-2 ">
                                            <p style={{color:'#FBBD23'}}>{convertToRupiah( saldoAkhir,true)}</p>
                                        </div>
                                        <div className="col-span-2 ">
                                            <p style={{color:'#908C8C'}}>Saldo Akhir</p>
                                        </div>
                                        <div className="row-span-3 ">
                                            <img
                                                className='inline-block'
                                                height={40}
                                                width={40}
                                                src={outImg}
                                                alt='group'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                <table className='w-full'>
                    <thead className='bg-gray-200 text-xs uppercase text-mirage-500'>
                    <tr>
                        <th className='px-2 py-3'>#</th>
                        <th className='px-2 py-3'>Tanggal</th>
                        <th className='px-2 py-3'>Type</th>
                        <th className='px-2 py-3'>Section</th>
                        <th className='px-2 py-3'>Keterangan</th>
                        <th className='px-2 py-3'>Nominal</th>
                        <th className='px-2 py-3'>Saldo</th>
                    </tr>
                    </thead>
                    {isLoading ? "":
                        (dataMutasi.length === 0 ?
                                <tbody>
                                    <tr>
                                        <td colSpan='100%'>
                                            <div className='mt-4 flex flex-col items-center justify-center'>
                                                <div className='w-full overflow-hidden bg-white'>
                                                    <div className='flex flex-col items-center justify-center bg-gray-100 px-6 py-4'>
                                                        <img
                                                            src={noDataImg}
                                                            alt='Empty Table'
                                                            width={256}
                                                            height={256}
                                                        />
                                                        <h2 className='mt-4 font-bold text-mirage-500'>
                                                            Pilih nama Marketing terlebih dahulu
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                           :
                                <tbody className='text-thunder'>
                                {dataMutasi.map((item, index)=>{
                                    if (item.r_mutation_type === 'kredit')
                                        saldo = saldo + item.r_mutation_amount
                                    else
                                        saldo = saldo - item.r_mutation_amount

                                    return(
                                        <tr key={index}  className={`${
                                            index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                                        } border-b hover:!bg-gray-50`}>
                                            <td className='px-2 py-3 text-center'>{item.listingNumber}</td>
                                            <td className='px-2 py-3 text-center'>
                                                {dateFormat(item.r_mutation_create_date,'DD MMM YYYY H:m')}
                                            </td>
                                            <td className='px-2 py-3 text-center'>
                                                {item.r_mutation_type === 'kredit' ?
                                                    <span style={{backgroundColor:'#36D3991A', paddingTop:4,paddingRight:16,paddingLeft:16, paddingBottom:4, borderRadius:8, color:'#258D67'}}>
                                                        {item.r_mutation_type}
                                                    </span> :
                                                    <span style={{backgroundColor:'#FF3A441A', paddingTop:4,paddingRight:16,paddingLeft:16, paddingBottom:4, borderRadius:8, color:'#FF3A44'}}>
                                                        {item.r_mutation_type}
                                                    </span>
                                                }

                                            </td>
                                            <td className='px-2 py-3 text-center'>
                                                {item.r_mutation_section === 'withdraw'?
                                                    <span style={{backgroundColor:'#36D3991A', paddingTop:4,paddingRight:16,paddingLeft:16, paddingBottom:4, borderRadius:8, color:'#258D67'}}>
                                                        {item.r_mutation_section}
                                                    </span> :
                                                    <span style={{backgroundColor:'#FF3A441A', paddingTop:4,paddingRight:16,paddingLeft:16, paddingBottom:4, borderRadius:8, color:'#FF3A44'}}>
                                                        {item.r_mutation_section}
                                                    </span>
                                                }
                                            </td>
                                            <td className='px-2 py-3 text-center'>{item.r_mutation_desc}</td>
                                            <td className='px-2 py-3 text-center'>
                                                {item.r_mutation_type === 'kredit' ?
                                                    <span style={{backgroundColor:'#36D3991A', paddingTop:4,paddingRight:16,paddingLeft:16, paddingBottom:4, borderRadius:8, color:'#258D67'}}>
                                                        + {convertToRupiah(item.r_mutation_amount, true)}
                                                    </span> :
                                                    <span style={{backgroundColor:'#FF3A441A', paddingTop:4,paddingRight:16,paddingLeft:16, paddingBottom:4, borderRadius:8, color:'#FF3A44'}}>
                                                        - {convertToRupiah(item.r_mutation_amount, true)}
                                                    </span>
                                                }
                                            </td>
                                            <td className='px-2 py-3 text-right font-bold'>{convertToRupiah(saldo, true)}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                        )

                    }
                </table>
                <nav
                    className='flex flex-col items-start justify-between gap-y-2 px-4 pt-4 md:flex-row md:items-center'
                    aria-label='Table navigation'
                >
                    <ul className='inline-flex items-center -space-x-px'>
                        <li>
                            <button
                                className='btn btn-secondary btn-sm rounded-r-none leading-tight'
                                onClick={() => table.setPageIndex(0)}
                                disabled={!table.getCanPreviousPage()}
                            >
                                {'<<'}
                            </button>
                        </li>
                        <li>
                            <button
                                className='btn btn-secondary btn-sm rounded-none leading-tight'
                                onClick={() => table.previousPage()}
                                disabled={!table.getCanPreviousPage()}
                            >
                                {'<'}
                            </button>
                        </li>
                        <li>
                            <button
                                className='btn btn-secondary btn-sm rounded-none leading-tight'
                                onClick={() => table.nextPage()}
                                disabled={!table.getCanNextPage()}
                            >
                                {'>'}
                            </button>
                        </li>
                        <li>
                            <button
                                className='btn btn-secondary btn-sm rounded-l-none leading-tight'
                                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                                disabled={!table.getCanNextPage()}
                            >
                                {'>>'}
                            </button>
                        </li>
                    </ul>
                    <span className='flex items-center gap-1'>
            <div>Halaman</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} dari{' '}
                {isLoading ? 1 : table.getPageCount()}
            </strong>
          </span>
                    <span className='flex items-center gap-1'>
            | Pergi ke halaman:
            <input
                type='number'
                defaultValue={table.getState().pagination.pageIndex + 1}
                onChange={(e) => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0;
                    table.setPageIndex(page);
                }}
                className='input input-bordered input-sm w-16'
            />
          </span>
                    <select
                        value={table.getState().pagination.pageSize}
                        className='select select-bordered select-sm'
                        onChange={(e) => {
                            table.setPageSize(Number(e.target.value));
                        }}
                    >
                        {[10, 20, 30, 40, 50].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                Tampilkan {pageSize}
                            </option>
                        ))}
                    </select>
                </nav>
            </div>
        </Card>
    );
};

export default List;
