import Card from 'components/card';
import React from 'react';
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import PinMap from 'assets/svg/pin-map.svg';
import L from 'leaflet';
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";

const Maps = ({ instalations ,request, setRefresh, refresh}) => {
    const icon = L.icon({ iconUrl: PinMap, iconSize: 50 });

    const { register, handleSubmit } = useForm();

    const confirmReject = async (data) => {
        try {
            await request.post(`/installations/${instalations.installation_id}/status`, data);

            toast.success('Instalasi telah ditolak');
            setRefresh(!refresh)
            window.confirm_reject_modal.close();

        } catch (err) {
            if (err.response?.data?.errors) {
                Object.keys(err.response.data.errors).map((field) =>
                    err.response.data.errors[field].map((message) => toast.error(message))
                );
            } else if (err.response?.data?.message) {
                toast.error(err.response.data.message);
            } else {
                toast.error(err.message);
            }
        } finally {
            setRefresh(!refresh);
        }
    };

    const confirmApprove = async () => {
        try {
            await request.post(`/installations/${instalations.installation_id}/status`);
            toast.success('berhasil Mengubah Proses Intalasi');
            setRefresh(!refresh)
        } catch (err) {
            if (err.response?.data?.errors) {
                Object.keys(err.response.data.errors).map((field) =>
                    err.response.data.errors[field].map((message) => toast.error(message))
                );
            } else if (err.response?.data?.message) {
                toast.error(err.response.data.message);
            } else {
                toast.error(err.message);
            }
        } finally {
            setRefresh(!refresh);
        }
    };
    const handleChnge = () => {
        window.confirm_approve_modal.showModal();
    };

    const errorHandler = (errors, e) => {
        Object.keys(errors).map((key) => {
            if (Array.isArray(errors[key])) {
                return errors[key].map((err) => {
                    return toast.error(err.message);
                });
            }

            return toast.error(errors[key].message);
        });
    };
    const handleCancelReject = () => {
        window.confirm_reject_modal.close();
    };
    const handleReject = () => {
        window.confirm_reject_modal.showModal();
    };

    return Object.keys(instalations).length > 0 ? (
        <>
            <Card extra={'w-full h-full p-3 rounded-[20px]'}>
                <div className='col-span-12 md:col-span-7 lg:col-span-8'>
                    <MapContainer
                        center={instalations.installation_coordinates}
                        zoom={10}
                        style={{ height: '500px', zIndex: '20', borderRadius: '12px' }}
                    >
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                        />
                        <Marker position={instalations.installation_coordinates} icon={icon}>
                            <Popup>Lokasi Pemasangan</Popup>
                        </Marker>
                    </MapContainer>
                </div>
                {instalations.installation_status === 'cancelled' || instalations.installation_status === 'executed' ? "" :
                            <div className='col-span-12 md:col-span-7 lg:col-span-8 pt-3'>
                                <p className='text-sm text-gray-600'>Ubah status instalasi</p>
                                <div className='col-span-2 grid grid-cols-2 gap-x-2 pt-3'>
                                    <button
                                        onClick={() => handleChnge()}
                                        className='btn btn-success'
                                    >
                                        Lanjutkan
                                    </button>
                                    <button
                                        onClick={() => handleReject()}
                                        className='btn btn-accent'
                                        // disabled={isRejecting || rejectedButtonDisabled}
                                    >
                                        Tolak
                                    </button>
                                </div>
                            </div>
                }

            </Card>
            <dialog
                id='confirm_approve_modal'
                className='modal modal-bottom sm:modal-middle'
            >
                <form method='dialog' className='modal-box'>
                    <h3 className='text-lg font-bold'>Konfirmasi Persetujuan</h3>
                    <p className='py-4'>
                        Anda yakin ingin melajutkan, proses instalasi akan di lanjutkan ke proses selanjutnya!
                    </p>
                    <div className='modal-action'>
                        {/* if there is a button in form, it will close the modal */}
                        <button className='btn btn-success' onClick={confirmApprove}>
                            Konfirmasi
                        </button>
                        <button className='btn'>Batal</button>
                    </div>
                </form>
            </dialog>
            <dialog
                id='confirm_reject_modal'
                className='modal modal-bottom sm:modal-middle'
            >
                <form
                    onSubmit={handleSubmit(confirmReject, errorHandler)}
                    method='dialog'
                    className='modal-box'
                >
                    <h3 className='text-lg font-bold'>Konfirmasi Penolakan</h3>
                    <p className='py-4'>
                        Anda yakin ingin membatalkan, proses instalasi akan di batalkan!
                    </p>
                    <div className='form-control'>
                        <label className='label'>
                            <span className='label-text'>Alasan Penolakan</span>
                        </label>
                        <textarea
                            {...register('installation_note', {
                                required: 'Alasan Penolakan harus diisi',
                            })}
                            className='textarea textarea-bordered h-32 w-full'
                            placeholder='Masukan alasan penolakan'
                        />
                    </div>
                    <div className='modal-action'>
                        {/* if there is a button in form, it will close the modal */}
                        <button type='submit' className='btn btn-accent'>
                            Konfirmasi
                        </button>
                        <button type='button' className='btn' onClick={handleCancelReject}>
                            Batal
                        </button>
                    </div>
                </form>
            </dialog>
        </>
    ) : (
        'Loading'
    );
};

export default Maps;
