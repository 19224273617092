import React, {useState} from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import Card from "../../../../components/card";
import Skeleton from "react-loading-skeleton";
import {ReactComponent as CircleYellow} from "../../../../assets/svg/circle-yellow.svg";
import {ReactComponent as CircleRed} from "../../../../assets/svg/circle-red.svg";
import {ReactComponent as CircleGrey} from "../../../../assets/svg/circle-grey.svg";
import {FiChevronDown, FiChevronUp,} from "react-icons/fi";
import {formatDateTime} from "../../../../utils/date";

export default function General({instalations, loading}) {
    const [arrowWaiting, setIsArrowWaiting] = useState(false);
    const [arrowExecuting, setIsArrowExecuting] = useState(false);
    const [arrowExecuted, setIsArrowExecuted] = useState(false);
    const [arrowCancelled, setIsArrowCancelled] = useState(false);

    const detailWaiting =()=>{
        setIsArrowWaiting(!arrowWaiting)
    }

    const detailExecuting =()=>{
        setIsArrowExecuting(!arrowExecuting)
    }

    const detailExecuted =()=>{
        setIsArrowExecuted(!arrowExecuted)
    }

    const detailCancelled =()=>{
        setIsArrowCancelled(!arrowCancelled)
    }

    return (
        <>
            <Card extra={'w-full h-full p-3 rounded-[20px]'}>
                <div className='mt-2 mb-2 w-full'>
                    <h4 className='px-2 text-xl font-bold text-navy-700'>
                        Ringkasan Informasi
                    </h4>
                    <p className='mt-2 px-2 text-base text-gray-600'>
                        Informasi umum instalasi
                    </p>
                </div>
                <div className='flex h-full flex-col justify-between'>
                    <div className='grid grid-cols-2 gap-2 px-2'>
                        <div className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
                            <p className='text-sm text-gray-600'>Nama</p>
                            {!loading != null ? (
                                <p className='text-base font-medium text-navy-700'>
                                    {instalations.submission?.customer?.customer_name || '-'}
                                </p>
                            ) : (
                                <Skeleton height={29} width={100} />
                            )}
                        </div>
                        <div className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
                            <p className='text-sm text-gray-600'>WhatsApp</p>
                            {!loading != null ? (
                                <p className='text-base font-medium text-navy-700'>
                                    {instalations.submission?.customer?.customer_whatsapp || '-'}
                                </p>
                            ) : (
                                <Skeleton height={29} width={100} />
                            )}
                        </div>
                        <div className='roundedl flex flex-col items-start justify-center bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
                            <p className='text-sm text-gray-600'>Provinsi</p>
                            {!loading != null ? (
                                <p className='text-base font-medium text-navy-700'>
                                    {instalations.submission?.province?.province_name || '-'}
                                </p>
                            ) : (
                                <Skeleton height={29} width={100} />
                            )}
                        </div>
                        <div className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
                            <p className='text-sm text-gray-600'>Kabupaten</p>
                            {!loading != null ? (
                                <p className='text-base font-medium text-navy-700'>
                                    {instalations.submission?.district?.district_name || '-'}
                                </p>
                            ) : (
                                <Skeleton height={29} width={100} />
                            )}
                        </div>
                        <div className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
                            <p className='text-sm text-gray-600'>Kecamatan</p>
                            {!loading != null ? (
                                <p className='text-base font-medium text-navy-700'>
                                    {instalations.submission?.subdistrict?.subdistrict_name || '-'}
                                </p>
                            ) : (
                                <Skeleton height={29} width={100} />
                            )}
                        </div>
                        <div className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
                            <p className='text-sm text-gray-600'>Kelurahan</p>
                            {!loading != null ? (
                                <p className='text-base font-medium text-navy-700'>
                                    {instalations.submission?.ward?.ward_name || '-'}
                                </p>
                            ) : (
                                <Skeleton height={29} width={100} />
                            )}
                        </div>
                        <div className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
                            <p className='text-sm text-gray-600'>Dusun</p>
                            {!loading != null ? (
                                <p className='text-base font-medium text-navy-700'>
                                    {instalations.submission?.hamlet?.hamlet_name || '-'}
                                </p>
                            ) : (
                                <Skeleton height={29} width={100} />
                            )}
                        </div>
                        <div className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
                            <p className='text-sm text-gray-600'>Alamat</p>
                            {!loading != null ? (
                                <p className='text-base font-medium text-navy-700'>
                                    {instalations.installation_address || '-'}
                                </p>
                            ) : (
                                <Skeleton height={29} width={100} />
                            )}
                        </div>
                    </div>
                    <div className="w-full py-5">
                        <p className='text-sm text-gray-600'>Status pemasangan</p>
                        {instalations.installation_status === 'cancelled'?
                            <div className="py-5">
                                <div className="flex">
                                    <div className="px-5 pt-3">
                                        <CircleYellow/>
                                        {arrowWaiting ?
                                            <div className="pt-3 ">
                                                <div style={{
                                                    borderLeft: '2px solid',
                                                    borderImage: 'linear-gradient(to bottom, #F2A647, #FF3A44)',
                                                    borderImageSlice: '1',
                                                    height: '120px',
                                                    margin: '0 10px',
                                                }} />
                                            </div>
                                            : ""}
                                    </div>
                                    <div style={{border: '1px solid #F2A647', borderRadius: '8px', width: '100%'}}>
                                        <p onClick={detailWaiting}>
                                            <div style={{display: 'flex'}} className="pl-6 py-3" >
                                                <p>Waiting</p>
                                                <div className="justify-end pr-4" style={{marginLeft:"auto"}}>
                                                    {!arrowWaiting ? <FiChevronDown size={20} color='#F2A647' /> : <FiChevronUp size={20} color='#F2A647' />}
                                                </div>
                                            </div>
                                        </p>
                                        {!arrowWaiting ? "" :
                                            <div>
                                                <hr style={{
                                                    color: '#DCDCDC',
                                                    backgroundColor: '#DCDCDC',
                                                    height: 2,
                                                    margin: 8
                                                }}
                                                />
                                                <div className="pl-6">
                                                    <p className="pt-3">
                                                        Dalam proses pengajuan pemasangan
                                                    </p>
                                                    <p className='text-sm text-gray-600 pt-3'>
                                                        {formatDateTime(instalations.installation_create_date) || '-'}
                                                    </p>
                                                </div>

                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="px-5 ">
                                    <div style={{
                                        borderLeft: '2px solid #FF3A44',
                                        height: '20px',
                                        margin: '0 10px',
                                    }} />
                                </div>
                                <div className="row-element flex">
                                    <div className="px-5 pt-3">
                                        <CircleRed />
                                    </div>
                                    <div style={{
                                        border: ' 1px solid #FF3A44',
                                        borderRadius: '8px',
                                        width: '100%'}}>
                                        <p onClick={detailCancelled}
                                        >
                                            <div style={{display: 'flex'}} className="pl-6 py-3" >
                                                <p>Cancelled</p>
                                                <div className="justify-end pr-4" style={{marginLeft:"auto"}}>
                                                    {!arrowCancelled ? <FiChevronDown size={20} color={'#FF3A44'} /> : <FiChevronUp size={20} color={'#FF3A44'} />}
                                                </div>
                                            </div>
                                        </p>
                                        {!arrowCancelled ? "" :
                                            <div>
                                                <hr style={{
                                                    color: '#DCDCDC',
                                                    backgroundColor: '#DCDCDC',
                                                    height: 2,
                                                    margin: 8
                                                }}
                                                />
                                                <div className="pl-6">
                                                    <p className="pt-3">
                                                        Proses Pemasangan Dibatalkan
                                                    </p>
                                                    <p className='text-sm text-gray-600 pt-3'>
                                                        {instalations.installation_cancelled_date !== null ? (formatDateTime(instalations.installation_cancelled_date) || '-') : "-"}
                                                    </p>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="py-5">
                                <div className="flex">
                                    <div className="px-5 pt-3">
                                        <CircleYellow/>
                                        {arrowWaiting ?
                                            <div className="pt-3 ">
                                                <div style={{
                                                    borderLeft: (instalations.installation_status === 'executing' || instalations.installation_status === 'executed'? ' 2px solid #F2A647':'2px solid #DCDCDC'),
                                                    height: '120px',
                                                    margin: '0 10px',
                                                }} />
                                            </div>
                                            : ""}
                                    </div>
                                    <div style={{border: '1px solid #F2A647', borderRadius: '8px', width: '100%'}}>
                                        <p onClick={detailWaiting}>
                                            <div style={{display: 'flex'}} className="pl-6 py-3" >
                                                <p>Waiting</p>
                                                <div className="justify-end pr-4" style={{marginLeft:"auto"}}>
                                                    {!arrowWaiting ? <FiChevronDown size={20} color='#F2A647' /> : <FiChevronUp size={20} color='#F2A647' />}
                                                </div>
                                            </div>
                                        </p>
                                        {!arrowWaiting ? "" :
                                            <div>
                                                <hr style={{
                                                    color: '#DCDCDC',
                                                    backgroundColor: '#DCDCDC',
                                                    height: 2,
                                                    margin: 8
                                                }}
                                                />
                                                <div className="pl-6">
                                                    <p className="pt-3">
                                                        Dalam proses pengajuan pemasangan
                                                    </p>
                                                    <p className='text-sm text-gray-600 pt-3'>
                                                        {formatDateTime(instalations.installation_create_date) || '-'}
                                                    </p>
                                                </div>

                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="px-5 ">
                                    <div style={{
                                        borderLeft: (instalations.installation_status === 'executing' || instalations.installation_status === 'executed'? ' 2px solid #F2A647':'2px solid #DCDCDC'),
                                        height: '20px',
                                        margin: '0 10px',
                                    }} />
                                </div>
                                <div className="row-element flex">
                                    <div className="px-5 pt-3">
                                        {instalations.installation_status === 'executing' || instalations.installation_status === 'executed'  ?  <CircleYellow/>:<CircleGrey/>}
                                        {arrowExecuting ?
                                            <div className="pt-3 ">
                                                <div style={{
                                                    borderLeft: (instalations.installation_status === 'executed'? ' 2px solid #F2A647':'2px solid #DCDCDC'),
                                                    height: '120px',
                                                    margin: '0 10px',
                                                }} />
                                            </div>
                                            : ""}
                                    </div>
                                    <div style={{
                                        border: (instalations.installation_status === 'executing' || instalations.installation_status === 'executed' ? '1px solid #F2A647':' 1px solid #DCDCDC'),
                                        borderRadius: '8px',
                                        width: '100%'}}>
                                        <p onClick={()=>{
                                            if(instalations.installation_status === 'executing' || instalations.installation_status === 'executed'){
                                                detailExecuting()
                                            }
                                        }}
                                        >
                                            <div style={{display: 'flex'}} className="pl-6 py-3" >
                                                <p>Executing</p>
                                                <div className="justify-end pr-4" style={{marginLeft:"auto"}}>
                                                    {!arrowExecuting ? <FiChevronDown size={20} color={instalations.installation_status === 'executing' || instalations.installation_status === 'executed' ? '#F2A647' : '#DCDCDC'} /> : <FiChevronUp size={20} color={instalations.installation_status === 'executing' || instalations.installation_status === 'executed' ? '#F2A647' : '#DCDCDC'} />}
                                                </div>
                                            </div>
                                        </p>
                                        {!arrowExecuting ? "" :
                                            <div>
                                                <hr style={{
                                                    color: '#DCDCDC',
                                                    backgroundColor: '#DCDCDC',
                                                    height: 2,
                                                    margin: 8
                                                }}
                                                />
                                                <div className="pl-6">
                                                    <p className="pt-3">
                                                        Dalam proses pemasangan
                                                    </p>
                                                    <p className='text-sm text-gray-600 pt-3'>
                                                        {instalations.installation_executing_date !== null ? (formatDateTime(instalations.installation_executing_date) || '-') : "-"}
                                                    </p>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="px-5">
                                    <div style={{
                                        borderLeft: (instalations.installation_status === 'executed' ? '2px solid #F2A647':' 2px solid #DCDCDC'),
                                        height: '20px',
                                        margin: '0 10px',
                                    }} />
                                </div>
                                <div className="row-element flex">
                                    <div className="px-5 pt-2">
                                        {instalations.installation_status === 'executed'  ?  <CircleYellow/>:<CircleGrey/> }
                                    </div>
                                    <div style={{
                                        border: (instalations.installation_status === 'executed' ? '1px solid #F2A647':' 1px solid #DCDCDC'),
                                        borderRadius: '8px',
                                        width: '100%'}}>
                                        <p onClick={()=>{
                                            if(instalations.installation_status === 'executed'){
                                                detailExecuted()
                                            }
                                        }}>
                                            <div style={{display: 'flex'}} className="pl-6 py-3" >
                                                <p>Executed</p>
                                                <div className="justify-end pr-4" style={{marginLeft:"auto"}}>
                                                    {!arrowExecuted ? <FiChevronDown size={20} color={instalations.installation_status === 'executed' ? '#F2A647' : '#DCDCDC'} /> : <FiChevronUp size={20} color={instalations.installation_status === 'executed' ? '#F2A647' : '#DCDCDC'} />}
                                                </div>
                                            </div>
                                        </p>
                                        {!arrowExecuted ? "" :
                                            <div>
                                                <hr style={{
                                                    color: '#DCDCDC',
                                                    backgroundColor: '#DCDCDC',
                                                    height: 2,
                                                    margin: 8
                                                }}
                                                />
                                                <div className="pl-6">
                                                    <p className="pt-3">
                                                        Proses Pemasangan Selesai
                                                    </p>
                                                    <p className='text-sm text-gray-600 pt-3'>
                                                        {instalations.installation_executed_date !== null ? (formatDateTime(instalations.installation_executed_date) || '-') : "-"}
                                                    </p>
                                                </div>

                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </Card>
        </>
    );
}

