import {Link} from "react-router-dom";
import React from "react";

export default function LinkSubMenu({subRoute, isActiveRoute}) {
    return (
        <Link to={subRoute.path}>
            <div className='relative mb-3 flex flex-col hover:cursor-pointer pl-5'>
                <li
                    className='my-[3px] flex cursor-pointer items-center px-8'
                >
                    <p
                        className={`leading-1 ml-4 flex  ${
                            isActiveRoute
                                ? 'font-bold text-navy-700'
                                : 'font-medium text-gray-800'
                        }`}
                    >
                        {subRoute.name}
                    </p>
                </li>
            </div>
        </Link>
    )
}