import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import Card from 'components/card';
import { Link, useNavigate } from 'react-router-dom';
import Editor from 'components/ck-editor/CKEditor5';

const Add = ({ request, token }) => {
  const navigate = useNavigate();

  const { control, register, setValue, getValues, handleSubmit } = useForm();

  useEffect(() => {
    register('b_post_content');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const submitHandler = async (data, e) => {
    try {
      await request.post('/blog-posts', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success('Berhasil menyimpan data');
      return navigate('/blog');
    } catch (err) {
      if (err.response?.data?.errors) {
        Object.keys(err.response.data.errors).map((field) =>
          err.response.data.errors[field].map((message) => toast.error(message))
        );
      } else if (err.response?.data?.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    }
  };

  const onImageStateChange = (data) => {
    setValue('b_post_image', data);
  };

  const onEditorSigning = (data) => {
    setValue('page_signature', data);
  };

  const errorHandler = (errors, e) => {
    Object.keys(errors).map((key) => {
      if (Array.isArray(errors[key])) {
        return errors[key].map((err) => {
          return toast.error(err.message);
        });
      }

      return toast.error(errors[key].message);
    });
  };

  return (
    <>
      <Card extra={'w-full sm:overflow-auto p-4'}>
        <h2 className='text-xl font-bold text-navy-700'>Tambah Blog</h2>
        <form
          onSubmit={handleSubmit(submitHandler, errorHandler)}
          className='mt-4 flex flex-col gap-y-1 overflow-x-scroll xl:overflow-x-hidden'
        >
          <div className='grid grid-cols-2 gap-y-1'>
            <div className='form-control w-full max-w-sm'>
              <label className='label'>
                <span className='label-text'>Title</span>
              </label>
              <input
                {...register('b_post_title', { required: 'Judul harus diisi' })}
                type='text'
                placeholder='Masukan judul'
                className='input input-bordered w-full max-w-sm'
              />
            </div>
            <div className='form-control w-full max-w-sm'>
              <label className='label'>
                <span className='label-text'>Status</span>
              </label>
              <select
                {...register('b_post_status', {
                  required: 'Status harus dipilih',
                })}
                className='select select-bordered'
              >
                <option value='' disabled selected>
                  Pilih Status
                </option>
                <option value='published'>Publikasikan</option>
                <option value='draft'>Draft</option>
              </select>
            </div>
            <div className='form-control w-full max-w-sm'>
              <label className='label'>
                <span className='label-text'>Gambar</span>
              </label>
              <input
                type='file'
                className='file-input file-input-bordered file-input-warning w-full max-w-xs'
                value={getValues('b_post_image')?.fileName}
                onChange={(event) => {
                  onImageStateChange(event.target.files[0]);
                }}
              />
              {/*<input  type="file" className="file-input file-input-bordered file-input-warning w-full max-w-xs"*/}
              {/*        {...register('blog_image', { required: 'Gambar harus diisi' })}*/}
              {/*        placeholder='Unggah gambar'*/}
              {/*/>*/}
            </div>
          </div>
          <div className='gap-y-1'>
            <div className='w-full'>
              <label className='label'>
                <span className='label-text'>Deskripsi</span>
              </label>
              <Editor
                control={control}
                name='b_post_content'
                label="'Deskripsi'"
                onSigning={onEditorSigning}
                token={token}
              />
            </div>
          </div>
          <div className='flex gap-x-2'>
            <button type='submit' className='btn btn-primary'>
              Simpan
            </button>
            <Link to='/blog' className='btn'>
              Batal
            </Link>
          </div>
        </form>
      </Card>
    </>
  );
};

export default Add;
