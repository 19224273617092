import { toast } from 'react-toastify';
import {useForm, } from 'react-hook-form';
import Card from 'components/card';
import { Link, useNavigate } from 'react-router-dom';
import React, {} from "react";

const Add = ({ request }) => {
    const navigate = useNavigate();
    const {
        getValues,
        setValue,
        register,
        handleSubmit
    } = useForm();

    const submitHandler = async (data, e) => {
        try {
            await request.post('/package-services', data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            toast.success('Berhasil menyimpan data');
            return navigate('/package-services');
        } catch (err) {
            if (err.response?.data?.errors) {
                Object.keys(err.response.data.errors).map((field) =>
                    err.response.data.errors[field].map((message) => toast.error(message))
                );
            } else if (err.response?.data?.message) {
                toast.error(err.response.data.message);
            } else {
                toast.error(err.message);
            }
        }
    };

    const onImageStateChange = (data) => {
        setValue('service_image', data);
    };

    const errorHandler = (errors, e) => {
        Object.keys(errors).map((key) => {
            if (Array.isArray(errors[key])) {
                return errors[key].map((err) => {
                    return toast.error(err.message);
                });
            }

            return toast.error(errors[key].message);
        });
    };

    return (
        <>
            <Card extra={'w-full sm:overflow-auto p-4'}>
                <h2 className='text-xl font-bold text-navy-700'>Tambah Paket Service</h2>
                <form
                    onSubmit={handleSubmit(submitHandler, errorHandler)}
                    className='mt-4 flex flex-col gap-y-1 overflow-x-scroll xl:overflow-x-hidden'
                >
                    <div className='grid grid-cols-2'>
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Nama Paket Service</span>
                            </label>
                            <input
                                {...register('service_name', {
                                    required: 'Nama harus diisi',
                                })}
                                type='text'
                                placeholder='Ketikan Nama Kategori Paket'
                                className='input input-bordered w-full max-w-sm'
                            />
                        </div>
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Gambar</span>
                            </label>
                            <input
                                type='file'
                                className='file-input file-input-bordered file-input-warning w-full max-w-xs'
                                value={getValues('service_image')?.fileName}
                                onChange={(event) => {
                                    onImageStateChange(event.target.files[0]);
                                }}
                            />
                        </div>
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                                <span className='label-text'>Deskripsi</span>
                            </label>
                            <textarea
                                {...register('service_desc', {
                                    required: 'Deskripsi harus diisi'
                                })}
                                className='textarea textarea-bordered h-24'
                                placeholder='Ketikan deskripsi paket'
                            ></textarea>
                        </div>
                        <div className='form-control w-full max-w-sm'>
                            <label className='label'>
                            <span className='label-text'>Status</span>
                            </label>
                            <select
                                {...register('service_status', {
                                    required: 'Status harus dipilih',
                                })}
                                className='select select-bordered'
                            >
                                <option value='' disabled selected>
                                    Pilih Status Paket
                                </option>
                                <option value='active'>Aktif</option>
                                <option value='non-active'>Non-Aktif</option>
                            </select>
                        </div>
                    </div>
                    <div className='flex gap-x-2'>
                        <button type='submit' className='btn btn-primary'>
                            Simpan
                        </button>
                        <Link to='/package-services' className='btn'>
                            Batal
                        </Link>
                    </div>
                </form>
            </Card>
        </>
    );
};

export default Add;
