import { Route, Routes } from 'react-router-dom';
import List from './components/List';
import Detail from './components/Detail';

const Payments = (props) => {
  return (
    <Routes>
      <Route path='/' element={<List {...props} />} />
      <Route path='/:id' element={<Detail {...props} />} />
    </Routes>
  );
};

export default Payments;
