import React, {useMemo, useState, useCallback, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {FiEdit} from 'react-icons/fi';
import { createColumnHelper } from '@tanstack/react-table';
import Table from 'components/table/Table';
import Card from 'components/card';

const List = ({ request }) => {
    const [isLoading, setIsLoading]     = useState(false);
    const [data, setData]               = useState([]);
    const [pageCount, setPageCount]     = useState(0);
    const [totalRow, setTotalRow]       = useState(0);
    const [refresh, setRefresh]         = useState(false);
    const [selectedCoverage, setSelectedCoverage] = useState('');
    const [dataCoverages, setCoverages] = useState([]);

    useEffect(() => {
        request.get('/coverages').then((response) => {
            setCoverages(response);
        });
    }, [request])

    const [value] = useState({
        startDate: null,
        endDate: null,
    });

    const handleChangeCoverage = (event) => {
        setSelectedCoverage(event.target.value);
    }

    const fetchData = useCallback(
        async (pageSize, pageIndex, search) => {
            setIsLoading(true);
            const params = {
                per_page: pageSize,
                page: pageIndex,
                keyword: search,
                coverage_id: selectedCoverage,
            };
            try {
                const response = await request.get('/loket', { params });
                const startIndex = response.from;
                const updatedData = response.data.map((item, index) => ({
                    ...item,
                    listingNumber: startIndex + index,
                }));
                setData(updatedData);
                setPageCount(response.last_page);
                setTotalRow(response.total);
            } catch (err) {
                if (err.response?.data?.errors) {
                    Object.keys(err.response.data.errors).map((field) =>
                        err.response.data.errors[field].map((message) =>
                            toast.error(message)
                        )
                    );
                } else if (err.response?.data?.message) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error(err.message);
                }
            }

            setIsLoading(false);
            setRefresh(false);
        },
        [refresh, value, selectedCoverage] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const columnHelper = createColumnHelper();

    const columns = useMemo(
        () => [
            columnHelper.accessor('listingNumber', {
                header: () => '#',
                cell: (info) => (
                    <div className='text-sm'>{info.row.original.listingNumber}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('loket_name', {
                header: () => 'Nama',
                cell: (info) => (
                    <div className='max-w-xs truncate text-sm'>{info.getValue()}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('loket_address', {
                header: () => 'Alamat Loket',
                cell: (info) => (
                    <div className='min-w-max text-sm'>{info.getValue()}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('coverage.coverage_name', {
                header: () => 'cakupan',
                cell: (info) => (
                    <div className='min-w-max text-sm'>{info.getValue()}</div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('loket_status', {
                header: () => 'Status',
                cell: (info) => (
                    <div className='max-w-2xl truncate text-xs'>
                        {info.getValue() === 'active' ? (
                            <span className='badge badge-success'>{info.getValue()}</span>
                        ) : info.getValue() === 'non-active' ? (
                            <span className='badge badge-error'>{info.getValue()}</span>
                        ) : (
                            <span className='badge'>{info.getValue()}</span>
                        )}
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
            columnHelper.accessor('loket_id', {
                header: () => 'AKSI',
                cell: (info) => (
                    <div className='flex gap-x-1'>
                        <Link
                            to={`/loket/${info.getValue()}/edit`}
                            className='font-medium text-warning'
                        >
                            <FiEdit size={24} />
                        </Link>
                    </div>
                ),
                footer: (info) => info.column.id,
            }),
        ],
        [] // eslint-disable-line react-hooks/exhaustive-deps
    );

    return (
        <>
            <Card extra={'w-full sm:overflow-auto p-4 mt-4'}>
                <h2 className='text-xl font-bold text-navy-700'>Daftar Loket</h2>
                <div className='mt-4 overflow-x-scroll xl:overflow-x-hidden'>
                    <Table
                        columns={columns}
                        data={data}
                        isLoading={isLoading}
                        fetchData={fetchData}
                        setRefresh={setRefresh}
                        pageCount={pageCount}
                        totalRow={totalRow}
                        filters={() => (
                            <>
                                <select
                                    onChange={handleChangeCoverage}
                                    className='select select-sm max-w-xs'
                                    value={selectedCoverage}
                                >
                                    <option value='' disabled selected>
                                        Pilih cakupan
                                    </option>
                                    {dataCoverages.map((item) => (
                                        <option key={item.coverage_id} value={item.coverage_id}>
                                            {item.coverage_name}
                                        </option>
                                    ))}
                                </select>
                            </>
                        )}
                        actions={() => (
                            <div className='flex gap-2'>
                                <Link
                                    to='/loket/add'
                                    type='button'
                                    className='btn btn-secondary btn-sm w-full md:w-fit'
                                >
                                    Tambah
                                </Link>
                            </div>
                        )}
                    />
                </div>
            </Card>
        </>
    );
};

export default List;