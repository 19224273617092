
import React, {useState, useEffect, Fragment, useCallback} from 'react';
import { Button, ButtonGroup, Card, CardBody, CardHeader, Container, Spinner } from "reactstrap";
import Loader from "react-loaders";
import Chart from 'react-apexcharts';
import { dateFormat, dateNow } from "../../../../utils/date";
import Select from 'react-select';
import {toast} from "react-toastify";

const minOffset = 0;
const maxOffset = (new Date()).getFullYear() - 2018;

const PpobDay = ({ props }) => {
    const namabulan = "1 2 3 4 5 6 7 8 9 10 11 12".split(" ");
    const thisYear = (new Date()).getFullYear();
    const currentMonth = (new Date()).getMonth();

    const [tahun, setTahun] = useState(dateFormat(dateNow(), 'YYYY'));
    const [month, setMonth] = useState(namabulan[currentMonth]);
    const [isLoading, setIsLoading] = useState(true);
    const [transaksiDay, setTransaksiDay] = useState([]);
    const [transaksiNominal, setTransaksiNominal] = useState([]);
    const [statusCaptured, setStatusCaptured] = useState([]);
    const [statusPending, setStatusPending] = useState([]);
    const [statusFailed, setStatusFailed] = useState([]);

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await props.request.get(`/grafik-transaksi?type=day&tahun=${tahun}&month=${month}`);
            let transaksiDay = [];
            let nominalTransaksi = [];
            let capturedStatus = [];
            let pendingStatus = [];
            let failedStatus = [];
            response.data.forEach(element=>{
                transaksiDay.push(element.date)
                nominalTransaksi.push(element.nominal)
                capturedStatus.push(element.captured)
                pendingStatus.push(element.pending)
                failedStatus.push(element.failed)
            })
            setTransaksiDay(transaksiDay)
            setTransaksiNominal(nominalTransaksi)
            setStatusCaptured(capturedStatus)
            setStatusPending(pendingStatus)
            setStatusFailed(failedStatus)
        } catch (err) {
            handleErrors(err);
        }
        setIsLoading(false);
    }, [tahun, month, props.request]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const refresh = () => {
        fetchData();
    };

    const handleErrors = (err) => {
        if (err.response?.data?.errors) {
            Object.keys(err.response.data.errors).forEach((field) =>
                err.response.data.errors[field].forEach((message) => toast.error(message))
            );
        } else if (err.response?.data?.message) {
            toast.error(err.response.data.message);
        } else {
            toast.error(err.message);
        }
    };

    const handleChange = (selectedOption) => {
        setTahun(selectedOption.value);
    };

    const handleChangeMonth = (selectedOption) => {
        setMonth(selectedOption.value);
    };

    const yearOptions = [];
    for (let i = minOffset; i <= maxOffset; i++) {
        const year = thisYear - i;
        yearOptions.push({ value: year, label: year });
    }

    const monthOptions = [
        { value: '1', label: 'Januari' },
        { value: '2', label: 'Februari' },
        { value: '3', label: 'Maret' },
        { value: '4', label: 'April' },
        { value: '5', label: 'Mei' },
        { value: '6', label: 'Juni' },
        { value: '7', label: 'Juli' },
        { value: '8', label: 'Agustus' },
        { value: '9', label: 'September' },
        { value: '10', label: 'Oktober' },
        { value: '11', label: 'November' },
        { value: '12', label: 'Desember' }
    ];

    const series = [
        {
            name: 'Total Transaksi',
            data: transaksiNominal,
            color: '#f78a2a',
        },
        {
            name: 'Sukses',
            data: statusCaptured,
            color: '#02db60',
        },
        {
            name: 'Gagal',
            data: statusFailed,
            color: '#ff405a',
        },
        {
            name: 'Proses',
            data: statusPending,
            color: '#fafa00',
        },
    ];

    const options = {
        chart: {
            toolbar: { show: false },
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            },
        },
        dataLabels: { enabled: false },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: { categories: transaksiDay },
        yaxis: [{
            title: { text: 'Total Transaksi' },
            labels: {
                formatter: value => "Rp " + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            }
        }],
        fill: { opacity: 1 },
    };

    return (
        <Fragment>
            <Container fluid>
                <Card className="p-5 bg-white">
                    <CardHeader className="flex flex-wrap md:flex-nowrap flex-row card-header-tab justify-between pb-4">
                        <div className="flex flex-wrap md:flex-nowrap flex-row card-header-title font-size-lg text-capitalize font-weight-normal">
                            <span className="content-center">Transaksi</span>
                            <span className="ml-2 w-full md:w-48">
                                    <Select value={monthOptions.find(option => option.value === month)}
                                            onChange={handleChangeMonth}
                                            defaultValue={monthOptions[0]}
                                            options={monthOptions}
                                    />
                                </span>
                            <span className="ml-2 w-full md:w-48">
                                    <Select
                                        value={yearOptions.find(option => option.value === tahun)}
                                        onChange={handleChange}
                                        defaultValue={yearOptions[0]}
                                        options={yearOptions}
                                    />
                                </span>
                        </div>
                        <div className="btn-actions-pane-right mt-4 md:mt-0">
                            <ButtonGroup size="sm">
                                <Button color="warning" className="btn-shadow bg-primary" disabled={isLoading} onClick={refresh}>
                                    {isLoading ? <Spinner size="sm" color="secondary" /> : <i className="pe-7s-refresh-2"></i>} Refresh
                                </Button>
                            </ButtonGroup>
                        </div>
                    </CardHeader>
                    <CardBody className="pt-0">
                        {isLoading ? (
                            <div className="loader-overlay">
                                <Loader active type='ball-beat' />
                            </div>
                        ) : (
                            <div className="bar mt-5 md:mt-0">
                                <Chart options={options} series={series} type="bar" width="100%" height="325px" />
                            </div>
                        )}
                    </CardBody>
                </Card>
            </Container>
        </Fragment>
    );
};

export default PpobDay;
