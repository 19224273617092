import React, { useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Card from 'components/card';
import {dateFormat} from 'utils/date';

export default function General({ request}) {
    const params = useParams();
    const [data, setData] = useState(null);

    useEffect(() => {
        request
            .get(`/tiket/${params.id}`).then((response) => {
            setData(response);
        })
            .catch((err) => {
                if (err.response?.data?.errors) {
                    Object.keys(err.response.data.errors).map((field) =>
                        err.response.data.errors[field].map((message) =>
                            toast.error(message)
                        )
                    );
                } else if (err.response?.data?.message) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error(err.message);
                }
            });
    }, [request]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Card extra={'w-full h-full p-3 rounded-[20px]'}>
                <div className='mt-2 mb-2 w-full'>
                    <h4 className='px-2 text-xl font-bold text-navy-700'>
                        Ringkasan Informasi
                    </h4>
                    <p className='mt-2 px-2 text-base text-gray-600'>
                        Informasi umum Tiket
                    </p>
                </div>
                <div className='flex h-full flex-col justify-between'>
                    <div className='grid grid-cols-2 gap-2 px-2'>
                        <div className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
                            <p className='text-sm text-gray-600'>Nomor Tiket</p>
                            <p className='text-base font-medium text-navy-700'>{data?.s_code}</p>
                        </div>
                        <div className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
                            <p className='text-sm text-gray-600'>Status</p>
                            <p className='text-base font-medium text-navy-700'>
                                {data?.s_tiket_status === 'new' ? (
                                    <span className='badge badge-error'>Baru</span>
                                ) : data?.s_tiket_status === 'process' ? (
                                    <span className='badge badge-warning'>Prosess</span>
                                ) : data?.s_tiket_status === 'done' ? (
                                    <span className='badge badge-success'>Selesai</span>
                                ) : (
                                    '-'
                                )}
                            </p>
                        </div>
                        <div className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
                            <p className='text-sm text-gray-600'>Nama Pelanggan</p>
                            <p className='text-base font-medium text-navy-700'>
                                {data?.customer_number?.customer?.customer_name}
                            </p>
                        </div>
                        <div className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
                            <p className='text-sm text-gray-600'>Alamat Pelanggan</p>
                            <p className='text-base font-medium text-navy-700'>
                                {data?.customer_number?.customer?.customer_address}
                            </p>
                        </div>
                        <div className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
                            <p className='text-sm text-gray-600'>Tanggal Pengaduan </p>
                            <p className='text-base font-medium text-navy-700'>
                                {dateFormat(data?.s_tiket_create_date, 'DD MMM YYYY')}
                            </p>
                        </div>
                        <div className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
                            <p className='text-sm text-gray-600'>Pengaduan </p>
                            <p className='text-base font-medium text-navy-700'>
                                {data?.tikets?.tiket_name}
                            </p>
                        </div>
                        <div className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
                            <p className='text-sm text-gray-600'>Dikerjakan Pada </p>
                            <p className='text-base font-medium text-navy-700'>
                                {data?.process_date ? dateFormat(data.process_date, 'DD MMM YYYY') : '-'}
                            </p>
                        </div>
                        <div className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
                            <p className='text-sm text-gray-600'>Alasan Pengaduan </p>
                            <p className='text-base font-medium text-navy-700'>
                                {data?.s_desc}
                            </p>
                        </div>
                        <div className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
                            <p className='text-sm text-gray-600'>Selesai Pada </p>
                            <p className='text-base font-medium text-navy-700'>
                                {data?.done_date ? dateFormat(data.done_date, 'DD MMM YYYY') : '-'}
                            </p>
                        </div>
                        <div className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
                            <p className='text-sm text-gray-600'>Catatan Proses </p>
                            <p className='text-base font-medium text-navy-700'>
                                {data?.s_process_note ? data?.s_process_note : '-'}
                            </p>
                        </div>
                        <div className='flex flex-col items-start justify-center rounded bg-white bg-clip-border px-3 py-2 shadow-3xl shadow-shadow-500'>
                            <p className='text-sm text-gray-600'>Keterangan Selesai </p>
                            <p className='text-base font-medium text-navy-700'>
                                {data?.s_done_note ? data?.s_done_note:'-'}
                            </p>
                        </div>
                    </div>
                </div>
            </Card>
        </>
    );
}
