import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { Button, ButtonGroup, Card, CardBody, CardHeader, Container, Spinner } from 'reactstrap';
import Loader from 'react-loaders';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import Chart from 'react-apexcharts';
import {toast} from "react-toastify";

const TransTime = ({ props }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [transaksiTime, setTransaksiTime] = useState([]);
    const [transaksiNominal, setTransaksiNominal] = useState([]);
    const [statusCaptured, setStatusCaptured] = useState([]);
    const [statusPending, setStatusPending] = useState([]);
    const [statusFailed, setStatusFailed] = useState([]);

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await props.request.get(`/grafik-transaksi?type=time&date=${selectedDate.toISOString().split('T')[0]}`);
            let transaksiTime = [];
            let nominalTransaksi = [];
            let capturedStatus = [];
            let pendingStatus = [];
            let failedStatus = [];
            response.data.forEach(element=>{
                transaksiTime.push(element.time)
                nominalTransaksi.push(element.nominal)
                capturedStatus.push(element.captured)
                pendingStatus.push(element.pending)
                failedStatus.push(element.failed)
            })
            setTransaksiTime(transaksiTime)
            setTransaksiNominal(nominalTransaksi)
            setStatusCaptured(capturedStatus)
            setStatusPending(pendingStatus)
            setStatusFailed(failedStatus)
        } catch (err) {
            handleErrors(err);
        }
        setIsLoading(false);
    }, [props.request, selectedDate]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const refresh = () => {
        fetchData();
    };

    const handleErrors = (err) => {
        if (err.response?.data?.errors) {
            Object.keys(err.response.data.errors).forEach((field) =>
                err.response.data.errors[field].forEach((message) => toast.error(message))
            );
        } else if (err.response?.data?.message) {
            toast.error(err.response.data.message);
        } else {
            toast.error(err.message);
        }
    };

    const options = {
        chart: {
            height: 350,
            type: 'bar',
            toolbar: {
                show: false
            }
        },
        stroke: {
            width: [0, 4]
        },
        labels: transaksiTime,
        dataLabels: {
            enabled: false
        },
        plotOptions: {
            bar: {
                columnWidth: '50%'
            }
        },
        xaxis: {
            type: 'string'
        },
        yaxis: [
            {
                title: {
                    text: 'Total Transaksi'
                },
                labels: {
                    formatter: function (value) {
                        return 'Rp ' + (value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0');
                    }
                }
            }
        ]
    };

    const series = [
        {
            name: 'Total Transaksi',
            type: 'bar',
            data: transaksiNominal,
            color: '#f78a2a',
        },
        {
            name: 'Sukses',
            type: 'bar',
            data: statusCaptured,
            color: '#02db60',
        },
        {
            name: 'Gagal',
            type: 'bar',
            data: statusFailed,
            color: '#ff405a',
        },
        {
            name: 'Proses',
            type: 'bar',
            data: statusPending,
            color: '#fafa00',
        },
    ];

    return (
        <Fragment>
            <Container fluid>
                <Card className="p-5 bg-white">
                    <CardHeader className="flex flex-col sm:flex-row card-header-tab justify-between">
                        <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                            Transaksi
                            <span className="mt-2 sm:ml-2 sm:mt-0 pb-1 w-full sm:w-auto" style={{width: 200}}>
                                    <DatePicker
                                        className="form-control dateInput ml-0 sm:ml-4 border-2 rounded-lg"
                                        placeholderText="Select Your Date"
                                        selected={selectedDate}
                                        onChange={setSelectedDate}
                                        dateFormat="d MMMM yyyy"
                                    />
                                </span>
                        </div>
                        <div className="btn-actions-pane-right justify-between mt-2 sm:mt-0">
                            <ButtonGroup size="sm">
                                <Button
                                    color="warning"
                                    className="btn-shadow bg-primary"
                                    disabled={isLoading}
                                    onClick={refresh}
                                >
                                    {isLoading ? (
                                        <span>
                                                <Spinner size="sm" color="secondary"/> Please wait...
                                            </span>
                                    ) : (
                                        <span>
                                                <i className="pe-7s-refresh-2"></i> Refresh
                                            </span>
                                    )}
                                </Button>
                            </ButtonGroup>
                        </div>
                    </CardHeader>
                    <CardBody className="pt-0">
                        {isLoading ? (
                            <div className="loader-overlay">
                                <Loader active type='ball-beat' />
                            </div>
                        ) : (
                            <div className="bar" style={{marginTop: '-20px'}}>
                                <Chart options={options} series={series} type="bar" width="100%" height="325px"/>
                            </div>
                        )}
                    </CardBody>
                </Card>
            </Container>
        </Fragment>
    );
};

export default TransTime;
