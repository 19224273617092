import Card from 'components/card';
import React from 'react';
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import PinMap from 'assets/svg/pin-map.svg';
import L from 'leaflet';
import Skeleton from 'react-loading-skeleton';

const Maps = ({ customer, loading }) => {
  const icon = L.icon({ iconUrl: PinMap, iconSize: 50 });

  return (
    <Card extra={'w-full h-full p-3 rounded-[20px]'}>
      {loading ? (
        <Skeleton height={350} width={'100%'} />
      ) : customer.customer_numbers.length > 0 ? (
        <div className='col-span-12 md:col-span-7 lg:col-span-8'>
          <MapContainer
            center={
              customer.customer_numbers[0].subscription.submission.installation
                .installation_coordinates
            }
            zoom={10}
            style={{ height: '350px', zIndex: '20', borderRadius: '12px' }}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            />
            {customer.customer_numbers.map((item) => (
              <Marker
                position={
                  item.subscription.submission.installation
                    .installation_coordinates
                }
                icon={icon}
              >
                <Popup>Lokasi Pemasangan {item.c_number_value}</Popup>
              </Marker>
            ))}
          </MapContainer>
        </div>
      ) : (
        <div className='flex h-full items-center justify-center'>
          Tidak memiliki perangkat
        </div>
      )}
    </Card>
  );
};

export default Maps;
